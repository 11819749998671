import React from 'react';
import { Layout } from '../Layout';
import classnames from 'classnames';
import TermsOfServiceRow from './TermsOfServiceRow';
import CopyrightRow from './CopyrightRow';
import LogoAndSocialLinksRow from './LogoAndSocialLinksRow';
import LinksRow from './LinksRow';
import ContactsRow from './ContactsRow';
import LegalAddress from './LegalAddress';
import LoyaltyText from './LoyaltyText';

import style from './style.css';

const DesktopLayout = ({ supportPhones }) => (
  <Layout align="center center">
    <Layout
      className={classnames(style.container, style['container--desktop'])}
      direction="column"
      fill
      nowrap
    >
      {/* First row - logo, links, contacts */}
      <Layout direction="row" align="space-between start" nowrap>
        {/* First column - Logo and social links */}
        <Layout direction="column">
          <LogoAndSocialLinksRow />
          <TermsOfServiceRow />
        </Layout>
        {/* Second column - links and contacts */}
        <Layout direction="column">
          <LinksRow />
          <ContactsRow supportPhones={supportPhones} />
        </Layout>
      </Layout>
      {/* Second row - copyright */}
      <CopyrightRow />
      <LegalAddress />
      <LoyaltyText />
    </Layout>
  </Layout>
);

// TODO: move to Flow types
// DesktopLayout.propTypes = {
//   userType: PropTypes.number,
//   supportPhones: PropTypes.array
// };

export default DesktopLayout;
