import { createReducer } from '../utils/redux';

import { REMOVE_ALL_SUBPAGES } from '../constants';

const initialState = {
  clearedAt: null,
};

const actionHandlers = {
  [REMOVE_ALL_SUBPAGES]: (_, clearedAt) => ({ clearedAt }),
};

export default createReducer(initialState, actionHandlers);
