import React from 'react';
import { FormattedMessage } from 'mz-intl';
import { styled } from 'mz-ui-kit';
import Button from 'mz-ui-kit/core/Button';
import Lightbox from 'mz-ui-kit/core/Lightbox';
import messages from '../../../messages.intl';

const DetailsContainer = styled('div', {
  maxWidth: '500px',
  padding: '2rem',
});

function ESimDetails() {
  return (
    <DetailsContainer>
      <FormattedMessage
        tagName="div"
        message={messages.ESIM_DETAILS_TEXT}
        values={{
          phoneCompatLink: (
            <a
              href="https://airalo.com/help/about-airalo/what-devices-support-esim"
              target="_blank"
              rel="noreferrer"
            >
              <FormattedMessage message={messages.ESIM_DETAILS_PHONE_COMPAT} />
            </a>
          ),
        }}
      />
    </DetailsContainer>
  );
}

function LearnMoreLink() {
  const [moreShowed, setShowMore] = React.useState(false);
  return (
    <>
      <Button appearance="link" onClick={() => setShowMore(true)}>
        <FormattedMessage message={messages.ESIM_LEARN_MORE} />
      </Button>
      <Lightbox
        title={<FormattedMessage message={messages.ESIM_DETAILS_TITLE} />}
        showed={moreShowed}
        onClose={() => setShowMore(false)}
        render={() => <ESimDetails />}
      />
    </>
  );
}

export default function ESimDescription() {
  return (
    <FormattedMessage
      message={messages.ESIM_DESCRIPTION}
      values={{ learnMoreLink: <LearnMoreLink /> }}
    />
  );
}
