import React from 'react';
import { Layout, Flex } from '../Layout';
import FooterLink from './FooterLink';
import Icon from '../Icon';

import classnames from 'classnames';
import config from 'config';

import style from './style.css';

const ContactsRow = ({ supportPhones, ...props }) => {
  const phoneNumberRefs = supportPhones
    .map((p) => ({
      href: `tel:+${p.replace(/\D/g, '')}`,
      text: p,
      dark: true,
    }))
    .filter((x) => x.text);

  return (
    <Layout
      className={style.contacts}
      direction="row"
      align="end center"
      nowrap
      {...props}
    >
      <Flex size="grow" />
      {phoneNumberRefs.map((link, index) => (
        <FooterLink key={index} size="noshrink" {...link} />
      ))}
      {config.SUPPORT_FORM_LINK && (
        <div className={style.link}>
          <a
            className={classnames(style.icon, style['icon--email'])}
            href={config.SUPPORT_FORM_LINK}
            target="_blank"
            rel="noreferrer noopener"
          >
            <Icon family="mozio" inline>
              email-footer
            </Icon>
          </a>
        </div>
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// ContactsRow.propTypes = {
//   supportPhones: PropTypes.array
// };

export default ContactsRow;
