import noop from 'mz-utils/noop';
import { select, call } from 'redux-saga/effects';
import getReservations from './getReservations';
import getPastReservations from './getPastReservations';
import { getActiveReservationsTab } from 'app/sagas/selectors';
import { PRESENT_RESERVATIONS_TAB } from 'app/constants';

export default function* filterReservations() {
  const activeTab = yield select(getActiveReservationsTab);

  const getFunction =
    activeTab === PRESENT_RESERVATIONS_TAB
      ? getReservations
      : getPastReservations;

  yield call(getFunction, {
    payload: { page: 1 },
    resolvePromise: noop,
    rejectPromise: noop,
  });
}
