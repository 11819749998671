import React from 'react';
import classnames from 'classnames';

import style from './style.css';

/**
 * Navigation button. Should be rendered in `ButtonsGroup`
 * @param  {Boolean} small      make text very small
 * @param  {Boolean} inactive   make a button looks more like a text
 * @return {Component}
 */
export const NavbarButton = ({
  small = false,
  inactive = false,
  active = false,
  component = 'a',
  className,
  children,
  ...props
}) => (
  <li
    className={classnames(className, style.button, {
      [style['button--small']]: small,
      [style['button--inactive']]: inactive,
      [style['button--active']]: active,
    })}
  >
    {React.createElement(
      component,
      { className: style.button__wrapper, ...props },
      children
    )}
  </li>
);

// TODO: move to Flow types
// NavbarButton.propTypes = {
//   small: PropTypes.bool,
//   inactive: PropTypes.bool,
//   active: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
//   component: PropTypes.any
// };

export default NavbarButton;
