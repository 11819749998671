// @flow
import * as React from 'react';
import config from 'config';
import { FormattedMessage } from 'mz-intl';

import InfoLabel from '../InfoLabel';
import messages from '../../messages.intl';

const MilesEarnedLabel = ({ miles }) => (
  <InfoLabel color={config.LOYALTY_PROGRAM_COLOR}>
    <FormattedMessage
      message={messages.MILES_EARNED}
      values={{
        miles,
        company: config.LOYALTY_PROGRAM_NAME,
      }}
    />
  </InfoLabel>
);

export default MilesEarnedLabel;
