import React, { Component } from 'react';
import OptionPicker from '../OptionPicker';
import {
  getTimeOptions,
  getMinDate,
  HOUR_FORMAT,
  isSameDate,
} from 'app/utils/types';

class TimePicker extends Component {
  renderItem = (item) => item.format(HOUR_FORMAT);

  render() {
    const { minDate, className, ...props } = this.props;

    const options =
      this.props.options || getTimeOptions(props.input.value, minDate);

    return (
      <OptionPicker
        icon="access_time"
        className={className}
        template={this.renderItem}
        options={options}
        comparator={isSameDate}
        {...props}
      />
    );
  }
}

// TODO: move to Flow types
// TimePicker.propTypes = {
//   input: PropTypes.object.isRequired,
//   meta: PropTypes.object.isRequired,
//   options: PropTypes.array,
//   minDate: PropTypes.object,
//   className: PropTypes.string
// };

TimePicker.defaultProps = {
  minDate: getMinDate(),
};

export default TimePicker;
