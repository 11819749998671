import { call, put, select } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { setSrpMapDirections } from 'app/actions/srp-map';
import { isSRPMapShowed } from 'app/sagas/selectors';
import getTripDirections from './getTripDirections';

export default function* handleTripHover(action) {
  yield call(delay, 320);

  const isMapShowed = yield select(isSRPMapShowed);

  if (!isMapShowed) return;

  const trip = action.payload;
  const directions = yield call(getTripDirections, trip);

  yield put(setSrpMapDirections({ directions }));
}
