import React from 'react';
import { FormattedMessage } from 'translations';
import { FormattedMessage as MzFormatterMessage } from 'mz-intl';
import mzMessages from '../../../messages.intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toggleOptionalAmenity } from 'app/actions/book-trip';
import { Layout } from 'app/components/base/Layout';
import DesktopUpgradeItem from '../UpgradeItem';
import FlexibleCancelDescription from '../FlexibleCancelDescription';
import InteractiveInfoBlock from 'app/components/base/InteractiveInfoBlock';
import SmsNotificationsDescription from '../SmsNotificationsDescription';
import ESimDescription from '../ESimDescription';
import useGoogleOptimize from '@react-hook/google-optimize';
import classnames from 'classnames';
import {
  BOOK_TRIP_FORM,
  UPGRADES_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  RETURN_TRIP_BOOKED,
  UPGRADE_IDS,
} from 'app/constants';

import style from './style.css';

const messages = {
  meetAndGreetTitle: { id: 'BOOK_TRIP.UPGRADES.MEET_AND_GREET_LABEL' },
  meetAndGreetCaption: { id: 'BOOK_TRIP.UPGRADES.MEET_AND_GREET_CAPTION' },
  addUpgradeErrorTitle: { id: 'BOOK_TRIP.UPGRADES.ADD_ERROR_TITLE' },
  addUpgradeError: { id: 'BOOK_TRIP.UPGRADES.ADD_ERROR' },
};

function upgradesReducer(state, action) {
  switch (action.type) {
    case 'LOADING_START':
      return {
        ...state,
        amenities: {
          ...state.amenities,
          [action.payload]: { loading: true, error: false },
        },
      };
    case 'LOADING_SUCCESS':
      return {
        ...state,
        amenities: {
          ...state.amenities,
          [action.payload]: { loading: false, error: false },
        },
      };
    case 'LOADING_FAILED':
      return {
        ...state,
        error: true,
        amenities: {
          ...state.amenities,
          [action.payload]: { loading: false, error: true },
        },
      };
    case 'CLOSE_ERROR':
      return { ...state, error: false };
    default:
      return state;
  }
}

/**
 * Upgrades container component
 */
export function UpgradesContainer({
  upgrades,
  onToggleAmenity,
  roundtrip,
  amenityComponents,
}) {
  const [state, dispatch] = React.useReducer(upgradesReducer, {
    amenities: {},
    error: false,
  });

  const handleOnAdd = (id, action) => () => {
    dispatch({ type: 'LOADING_START', payload: id });

    action()
      .then(() => dispatch({ type: 'LOADING_SUCCESS', payload: id }))
      .catch(() => dispatch({ type: 'LOADING_FAILED', payload: id }));
  };

  const staticDataMap = {
    [UPGRADE_IDS.MEET_AND_GREET]: {
      name: <FormattedMessage {...messages.meetAndGreetTitle} />,
      description: <FormattedMessage {...messages.meetAndGreetCaption} />,
    },
    [UPGRADE_IDS.FLEXIBLE_CANCEL_POLICY]: {
      name: <MzFormatterMessage message={mzMessages.FLEXIBLE_CANCEL_TITLE} />,
      description: <FlexibleCancelDescription />,
    },
    [UPGRADE_IDS.SMS_NOTIFICATION]: {
      description: <SmsNotificationsDescription />,
      perRidePrice: false,
    },
    [UPGRADE_IDS.ESIM]: {
      name: <MzFormatterMessage message={mzMessages.ESIM_TITLE} />,
      description: <ESimDescription />,
      perRidePrice: false,
    },
    [UPGRADE_IDS.CARBON_OFFSET]: {
      green: true,
      name: <MzFormatterMessage message={mzMessages.CARBON_OFFSET_NAME} />,
      description: (
        <MzFormatterMessage message={mzMessages.CARBON_OFFSET_DESCRIPTION} />
      ),
    },
  };

  return (
    <Layout className={classnames(style.container)}>
      {upgrades.map((item, index) => {
        const itemStaticData = staticDataMap[item.id];
        const ItemComponent =
          (amenityComponents || {})[item.id] || DesktopUpgradeItem;

        return (
          <ItemComponent
            horizontal
            onToggleAmenity={handleOnAdd(item.id, () =>
              onToggleAmenity(item.id)
            )}
            perRidePrice={roundtrip}
            {...item}
            {...itemStaticData}
            loading={(state.amenities[item.id] || {}).loading}
            key={index}
          />
        );
      })}
      {state.error && (
        <InteractiveInfoBlock
          className={style.errorBlock}
          type="yellow"
          icon={{ name: 'notification-warning', family: 'mozio' }}
          title={<FormattedMessage {...messages.addUpgradeErrorTitle} />}
          text={<FormattedMessage {...messages.addUpgradeError} />}
          contentAlign="center start"
          autohide
          onClickClose={() => dispatch({ type: 'CLOSE_ERROR' })}
        />
      )}
    </Layout>
  );
}

const DISABLED_UPGRADES = new Set([
  UPGRADE_IDS.CARBON_OFFSET,
  UPGRADE_IDS.SMS_NOTIFICATION,
  UPGRADE_IDS.FLEXIBLE_CANCEL_POLICY,
  UPGRADE_IDS.ESIM,
]);
const EXPERIMENTS = {};

function ExperimentalAmenityItem(props) {
  const shouldShow = useGoogleOptimize(
    EXPERIMENTS[props.id],
    [true, false],
    3000
  );
  if (!shouldShow) return null;
  return <DesktopUpgradeItem {...props} />;
}

function UpgradesWithExperiments(props) {
  const amenityComponents = Object.keys(EXPERIMENTS).reduce(
    (acc, amenityId) => {
      if (!EXPERIMENTS[amenityId]) return acc;
      return { ...acc, [amenityId]: ExperimentalAmenityItem };
    },
    {}
  );

  const filteredUpgrades = props.upgrades.filter(
    (a) => !DISABLED_UPGRADES.has(a.id)
  );

  return (
    <UpgradesContainer
      {...props}
      upgrades={filteredUpgrades}
      amenityComponents={amenityComponents}
    />
  );
}

const selector = formValueSelector(BOOK_TRIP_FORM);
export const mapStateToProps = (state) => ({
  roundtrip:
    selector(state, RETURN_BOOK_STATE_FIELD_NAME) == RETURN_TRIP_BOOKED,
  upgrades: (selector(state, UPGRADES_FIELD_NAME) || []).filter(
    (upgrade) => upgrade.showInUpgradeYourTrip
  ),
});

export const mapDispatchToProps = {
  onToggleAmenity: toggleOptionalAmenity,
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UpgradesWithExperiments
);
