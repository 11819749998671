import { styled } from 'mz-ui-kit';

export const Container = styled('div', {
  marginTop: '5px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  fontWeight: 'bold',
});

export const SuggestedWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const HourlyWrapper = styled('div', {
  marginRight: '5px',
});

export const AlertWrapper = styled('div', {
  margin: '10px',
});
