import { call, put, select } from 'redux-saga/effects';
import { getDirectionsRequestHash } from 'app/utils/trip';
import { getCachedDirection, getSearchArguments } from 'app/sagas/selectors';
import { setCachedDirection } from 'app/actions/srp-map';
import { places } from 'mz-sdk';
import decodePolyline from './decodePolyline';
import _ from 'lodash';

export default function* getTripDirections(trip) {
  const { steps } = trip;

  const { start_address, end_address } = yield select(getSearchArguments);
  const startLocation = { lat: start_address.lat, lng: start_address.lng };
  const endLocation = { lat: end_address.lat, lng: end_address.lng };

  const directionsRequest = steps
    .map((step) => {
      // get origin
      let start = null;
      if (step.from) {
        if (!_.isEmpty(step.from.station)) {
          start = { lat: step.from.station.lat, lng: step.from.station.lng };
        } else {
          start = { lat: step.from.location.lat, lng: step.from.location.lng };
        }
      } else {
        start = { lat: step.startLocation.lat, lng: step.startLocation.lng };
      }

      // get destination
      let end = null;
      if (step.to) {
        if (!_.isEmpty(step.to.station)) {
          end = { lat: step.to.station.lat, lng: step.to.station.lng };
        } else {
          end = { lat: step.to.location.lat, lng: step.to.location.lng };
        }
      } else {
        end = { lat: step.endLocation.lat, lng: step.endLocation.lng };
      }

      const category = step.type;
      if (step.main && (!start.lat || !start.lng || !end.lat || !end.lng)) {
        start = startLocation;
        end = endLocation;
      }

      return {
        category,
        start,
        end,
      };
    })
    .filter(({ start, end }) => {
      return start.lat && start.lng && end.lat && end.lng;
    });

  const requestKey = getDirectionsRequestHash(directionsRequest);
  if (!requestKey) return [];

  const cachedResponse = yield select(getCachedDirection(requestKey));
  let result = cachedResponse;
  if (!cachedResponse) {
    try {
      if (_.isEmpty(steps[0].polylinePoints)) {
        const directionsResponse = yield call(
          places.getDirections,
          directionsRequest
        );
        result = directionsResponse.map((direction) => {
          const directions = decodePolyline(direction.polyline_points);

          return {
            ...direction,
            directions,
            lineColor: direction.line_color,
          };
        });
      } else {
        result = steps.map((step) => {
          const lineColor = _.get(step, 'transitInfo.lineColor');
          const directions = decodePolyline(step.polylinePoints);
          return {
            category: step.type,
            directions,
            lineColor,
          };
        });
      }
    } catch (err) {
      // TODO: handle error here. Show notification etc.
      result = [];
    }
    yield put(setCachedDirection({ key: requestKey, data: result }));
  }

  return result;
}
