import * as React from 'react';
import { FormattedMessage } from 'mz-intl';
import Alert from 'mz-ui-kit/core/Alert';
import ErrorIcon from 'mz-icons/exclamation-circle';
import { Container, Content, IconWrapper } from './elements';
import messages from '../../../messages.intl';

function SearchAlert({ children }) {
  return (
    <Alert color="yellow">
      <Container>
        <IconWrapper>
          <ErrorIcon size="2x" />
          &nbsp;
        </IconWrapper>
        <Content>
          <FormattedMessage tagName="h3" message={messages.ATTENTION} />
          {children}
        </Content>
      </Container>
    </Alert>
  );
}

export default SearchAlert;
