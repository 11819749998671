import React, { Component } from 'react';
import noop from 'lodash/noop';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import style from './style.css';

class Radio extends Component {
  componentDidMount() {
    this.focus = () => this.input.focus();
  }

  focus = noop;

  refInput = (node) => {
    this.input = node;
  };

  handleClick = () => {
    this.props.input.onChange(this.input.value);
  };

  render() {
    /* eslint-disable no-unused-vars */
    const { input, className, children, disabled, clean, inverted, stretch } =
      this.props;
    /* eslint-enable no-unused-vars */

    const modifiers = {
      [style['field--disabled']]: disabled,
      [style['field--clean']]: clean,
      [style['field--stretch']]: stretch,
      [style['field--inverted']]: inverted,
    };

    return (
      <Layout
        nowrap
        className={classnames(style.field, modifiers, className)}
        align="space-between center"
      >
        <input
          {...input}
          type="radio"
          ref={this.refInput}
          className={style.input}
          disabled={disabled}
        />
        <Layout
          size="auto"
          nowrap
          className={style.title}
          onClick={this.handleClick}
        >
          {children}
        </Layout>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Radio.propTypes = {
//   input: PropTypes.object.isRequired,
//   className: PropTypes.string,
//   name: PropTypes.string,
//   children: PropTypes.node,
//   disabled: PropTypes.bool,
//   clean: PropTypes.bool,
//   stretch: PropTypes.bool,
//   inverted: PropTypes.bool
// };

export default Radio;
