import React, { Component } from 'react';
import { eventToKey } from 'app/utils/listeners';

const builder = (ComposedComponent) => {
  class AutocompleteInput extends Component {
    state = { focused: -1 };

    handleChange = (option) => {
      this.setState({ focused: -1 });
      Promise.resolve(this.props.transform(option)).then((value) => {
        this.props.input.onChange(value);
      });
    };

    handleKeyDown = (event) => {
      const { focused } = this.state;
      const { options } = this.props;
      const key = eventToKey(event);
      const currentOption = options[focused === -1 ? 0 : focused];

      if (key === 'ArrowUp') {
        this.setState({ focused: Math.max(focused - 1, 0) });
      } else if (key === 'ArrowDown') {
        this.setState({ focused: Math.min(focused + 1, options.length - 1) });
      } else if (key === 'Enter' && currentOption) {
        this.handleChange(currentOption);
        event.stopPropagation();
        event.preventDefault();
      } else if (key === 'Tab' && currentOption) {
        this.handleChange(currentOption);
      }
    };

    refTextInput = (node) => {
      this.inputNode = node;
    };

    render() {
      const { focused } = this.state;
      const { input, ...props } = this.props;

      const inputProps = {
        ...input,
        onKeyDown: this.handleKeyDown,
      };

      return (
        <ComposedComponent {...props} input={inputProps} focused={focused} />
      );
    }
  }

  // TODO: move to Flow types
  // AutocompleteInput.propTypes = {
  //   input: PropTypes.object.isRequired,
  //   transform: PropTypes.func,
  //   options: PropTypes.array
  // };

  AutocompleteInput.defaultProps = {
    transform: (option) => option,
  };

  return AutocompleteInput;
};

export default builder;
