import { AUTOCOMPLETE_TYPES } from 'app/constants';
import _ from 'lodash';

const defaultFilterFunc = (suggestions, value) => {
  const filteredSuggestions = suggestions.filter(
    (s) =>
      s.display.toLowerCase().includes(value) ||
      (_.isString(s.value) && s.value.toLowerCase().includes(value)) ||
      s.value === 0
  );

  return filteredSuggestions;
};

const defaultSortFunc = (suggestions, value) => {
  const sortedSuggestions = _.sortBy(
    suggestions,
    (item) => !item.display.toLowerCase().startsWith(value)
  );

  return sortedSuggestions;
};

const getFilterFunc = (target) => {
  switch (target) {
    case AUTOCOMPLETE_TYPES.AIRLINE: {
      // first filter matched IATA codes, then matched airline names
      return (suggestions, value) =>
        suggestions.filter(
          (s) =>
            s.value.toLowerCase().includes(value) ||
            s.display.toLowerCase().includes(value) ||
            s.value === 0
        );
    }
    default:
      return defaultFilterFunc;
  }
};

const getSortFunc = (target) => {
  switch (target) {
    case AUTOCOMPLETE_TYPES.AIRLINE: {
      // first show matched IATA codes, then matched airline names
      return (suggestions, value) =>
        _.sortBy(suggestions, [
          (item) => !item.value.toLowerCase().startsWith(value),
          (item) => !item.display.toLowerCase().startsWith(value),
        ]);
    }
    default:
      return defaultSortFunc;
  }
};

/**
 * Function that filters suggestions based on value (query)
 * according to target type(airline, flight, TAcompany)
 */
const doFilter = (suggestions, value, target) => {
  if (!suggestions) return [];

  const lcValue = value.toLowerCase();
  let filteredSuggestions = getFilterFunc(target)(suggestions, lcValue);

  // show items that starts with searched value first
  filteredSuggestions = getSortFunc(target)(filteredSuggestions, lcValue);

  return filteredSuggestions;
};

export default doFilter;
