import React from 'react';
import { getLanguages } from 'app/utils/languages';
import { Layout } from 'app/components/base/Layout';
import MetaInfo from 'app/components/base/MetaInfo';
import Navigation from 'app/components/common/Navigation';
import { getFaviconLinks, getFaviconMetaTags } from './favicons';
import _ from 'lodash';

// Messages for meta info
const headInfoMessages = {
  title: { id: 'META.TITLE' },
  description: { id: 'META.DESCRIPTION' },
  keywords: { id: 'META.KEYWORDS' },
};

// Page information meta tags set for the main page.
const baseMetaTags = [
  { name: 'description', value: headInfoMessages.description },
  { name: 'keywords', value: headInfoMessages.keywords },
  { name: 'og:title', value: headInfoMessages.title },
  { name: 'og:description', value: headInfoMessages.description },
].map((item) => Object.assign(item, { translate: true }));

// Build the languages array to create i18n <link> alternate tags.
const getLanguagesLinks = _.memoize(() => {
  return _.map(getLanguages(), (language) => ({
    rel: 'alternate',
    href: `${window.location.origin}/${language.value}/`,
    hreflang: language.value,
  }));
});

/**
 * Application header that holds meta info, navigation bar
 * and notification components.
 */
export const AppHeader = () => (
  <Layout direction="column" nowrap>
    <MetaInfo
      titleMessage={headInfoMessages.title}
      metaTags={[].concat(baseMetaTags, getFaviconMetaTags())}
      links={[].concat(getLanguagesLinks(), getFaviconLinks())}
    />
    <Navigation languages={getLanguages()} />
  </Layout>
);

export default AppHeader;
