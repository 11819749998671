import medias, { greaterThanMedias } from './medias';
import mediaQueryLists from './mediaQueryLists';

class Provider {
  constructor() {
    Object.keys(greaterThanMedias).forEach((query) => {
      mediaQueryLists[medias[query]].addListener(() => this.update());
    });
    this.update();
  }

  listeners = [];

  screenSize = {
    mobile: false,
    '> mobile': false,
    small: false,
    '> small': false,
    medium: false,
    '> medium': false,
    large: false,
    '> large': false,
  };

  update() {
    Object.keys(this.screenSize).forEach((key) => {
      this.screenSize[key] = mediaQueryLists[key].matches;
    });
    this.listeners.forEach((listener) => listener());
  }

  subscribe(listener) {
    this.listeners.push(listener);
    return () => {
      this.listeners.splice(this.listeners.indexOf(listener), 1);
    };
  }
}

export default new Provider();
