import {
  SHOW_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  NOTIFICATION_CLOSE_TIMEOUT,
} from '../constants';
import debounce from 'lodash/debounce';
import { createAction } from '../utils/redux';

export const clearNotifications = createAction(CLEAR_NOTIFICATIONS);

export const deferredHide = debounce((dispatch) => {
  dispatch(clearNotifications());
}, NOTIFICATION_CLOSE_TIMEOUT);

// Shows a GlobalNotification in the NotificationBar at the top.
export const showNotification = createAction(
  SHOW_NOTIFICATION,
  (options) => options,
  (action) => (dispatch) => {
    deferredHide(dispatch);
    return dispatch(action);
  }
);
