import AbstractTransitionAnimator from './AbstractTransitionAnimator';
import animit from 'app/utils/animit';
import { createElement } from './utils';

/**
 * Lift screen transition.
 */
export default class LiftSlideAnimator extends AbstractTransitionAnimator {
  constructor(options = {}) {
    super({
      duration: 0.4,
      timing: 'cubic-bezier(.1, .7, .1, 1)',
      delay: 0,
      ...options,
    });

    this.blackMaskOpacity = 0.2;
    this.backgroundMask = createElement(`
      <div style="position: absolute; width: 100%; height: 100%;
        background-color: black; opacity: 0;"></div>
    `);
  }

  push(enterPage, leavePage, callback) {
    this.backgroundMask.remove();
    leavePage.parentElement.insertBefore(
      this.backgroundMask,
      leavePage.nextSibling
    );

    animit.runAll(
      animit(this.backgroundMask)
        .saveStyle()
        .queue({
          opacity: 0,
          transform: 'translate3d(0, 0, 0)',
        })
        .wait(this.delay)
        .queue(
          {
            opacity: this.blackMaskOpacity,
          },
          {
            duration: this.duration,
            timing: this.timing,
          }
        )
        .restoreStyle()
        .queue((done) => {
          this.backgroundMask.remove();
          done();
        }),

      animit(enterPage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0, 100%, 0)',
          },
          duration: 0,
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)',
          },
          duration: this.duration,
          timing: this.timing,
        })
        .queue((done) => {
          window.scrollTo(0, 0);
          callback();
          done();
        })
        .restoreStyle(),

      animit(leavePage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)',
            opacity: 1.0,
          },
          duration: 0,
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0, -10%, 0)',
            opacity: 0.9,
          },
          duration: this.duration,
          timing: this.timing,
        })
        .restoreStyle()
    );
  }

  pop(enterPage, leavePage, callback) {
    this.backgroundMask.remove();
    enterPage.parentNode.insertBefore(
      this.backgroundMask,
      enterPage.nextSibling
    );

    animit.runAll(
      animit(this.backgroundMask)
        .saveStyle()
        .queue({
          opacity: this.blackMaskOpacity,
          transform: 'translate3d(0, 0, 0)',
        })
        .wait(this.delay)
        .queue(
          {
            opacity: 0,
          },
          {
            duration: this.duration,
            timing: this.timing,
          }
        )
        .restoreStyle()
        .queue((done) => {
          this.backgroundMask.remove();
          done();
        }),

      animit(enterPage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0, -10%, 0)',
            opacity: 0.9,
          },
          duration: 0,
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)',
            opacity: 1.0,
          },
          duration: this.duration,
          timing: this.timing,
        })
        .queue((done) => {
          callback();
          done();
        })
        .restoreStyle(),

      animit(leavePage)
        .saveStyle()
        .queue({
          css: {
            transform: 'translate3D(0, 0, 0)',
          },
          duration: 0,
        })
        .wait(this.delay)
        .queue({
          css: {
            transform: 'translate3D(0, 100%, 0)',
          },
          duration: this.duration,
          timing: this.timing,
        })
        .restoreStyle()
    );
  }
}
