import React from 'react';
import { FormattedMessage } from 'translations';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import FoldableDropdown from 'app/components/base/FoldableDropdown';
import ScheduleInfo from './ScheduleInfo';
import CN from 'classnames';

import style from './style.css';

const messages = {
  schedule: { id: 'TRIP_DETAILS.SCHEDULE' },
  changeTime: { id: 'TRIP_DETAILS.CHANGE_TIME' },
};

/**
 * Button that displays info about selected departure and return tickets
 * and opens schedules dialog
 */
const ScheduleDepartureBtn = ({
  className,
  onClick,
  departureTicketInfo,
  returnTicketInfo,
}) => {
  const origin = (
    <Button className={style.button} nocaps rounded bordered large>
      <FormattedMessage {...messages.schedule} />
      <Icon className={style.expandIcon} name="expand_more" />
    </Button>
  );

  return (
    <FoldableDropdown
      className={CN(style.container, className)}
      origin={origin}
    >
      <div className={style.schedulesContainer}>
        <ScheduleInfo {...{ departureTicketInfo, returnTicketInfo }} />
        <Button
          nocaps
          rounded
          onClick={onClick}
          className={style.changeTimeBtn}
        >
          <FormattedMessage {...messages.changeTime} />
        </Button>
      </div>
    </FoldableDropdown>
  );
};

// TODO: move to Flow types
// ScheduleDepartureBtn.propTypes = {
//   className: PropTypes.string,
//   departureTicketInfo: PropTypes.object,
//   returnTicketInfo: PropTypes.object,
//   onClick: PropTypes.func
// };

export default ScheduleDepartureBtn;
