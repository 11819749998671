import moment from 'moment';

export function flightToPickupTimeFromAirport(flightObj, defaultPickupTime) {
  if (flightObj?.flightDatetime) {
    return moment.parseZone(flightObj?.flightDatetime).add(30, 'minutes');
  } else if (defaultPickupTime) {
    return moment(defaultPickupTime);
  }
  throw new Error('Given flight has no flight datetime');
}

export function flightToPickupTimeFromHotel(flightObj, tripDuration) {
  if (!flightObj?.flightDatetime) {
    throw new Error('Given flight has no flight datetime');
  }
  return moment
    .parseZone(flightObj.flightDatetime)
    .add(-(tripDuration + 180), 'minutes');
}
