import {
  CLICK_PROMO,
  INIT_CONFIRMATION_PAGE,
  SET_RESERVATION,
  CP_SET_LOADING,
  SEND_APP_LINK,
  TOGGLE_MODAL,
} from 'app/constants';
import { createAction, createDeferredAction } from '../utils/redux';

export const initConfirmationPage = createAction(INIT_CONFIRMATION_PAGE);

export const setReservation = createAction(SET_RESERVATION);

export const setLoading = createAction(CP_SET_LOADING);

export const clickPromo = createAction(CLICK_PROMO);

export const sendAppLink = createDeferredAction(SEND_APP_LINK);

export const toggleModal = createDeferredAction(TOGGLE_MODAL);
