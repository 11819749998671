import { call, select } from 'redux-saga/effects';
import { trips } from 'mz-sdk';
import { getCurrency } from 'app/sagas/selectors';

export default function* getTrip(tripId, sessionId, isOndemand) {
  const currency = yield select(getCurrency);

  // TODO: Add "ref" param?
  const params = {
    id: tripId,
    session_id: sessionId,
    ondemand: !!isOndemand,
    currency,
  };

  const trip = yield call(trips.getTrip, params);
  return trip;
}
