import { call } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import { auth } from 'mz-sdk';

export default function* doLogout() {
  yield call(auth.logout);

  // Delete user data for third-party libs.
  window.mozioUser = null;

  // Redirect to the home page on logout
  const history = yield call(getHistory);
  yield call(history.push, '/');
}
