import config from 'config';

export const BASE_URL = config.BASE_URL;

export const MAX_YEAR_COUNT_FROM_CURRENT = 30;
export const OLDEST_OPERATIONS_YEAR = 2012;
export const MIN_PASSENGERS_COUNT = 1;
export const MAX_PASSENGERS_COUNT = 99;
export const AUTOCOMPLETE_DEBOUNCE = 500;

export const USER_TYPE_TC = 3;
export const USER_TYPE_TA = 1;
export const USER_TYPE_CORP = 2;
export const USER_TYPE_NORMAL = 0;

export const TRAVEL_AGENT_MASTER_ACCOUNT = 1;
export const TRAVEL_AGENT_SLAVE_ACCOUNT = 0;

export const NOTIFICATION_CLOSE_TIMEOUT = 10000;

export const MOZIO_CONTACT_EMAIL = 'info@mozio.com';

// trip related codes
export const TRIP_EXPIRED_ERROR_CODE = 9;
// used string to distinguish with API erros
export const TRIP_UPDATED_ERROR_CODE = 'TRIP_UPDATED_ERROR_CODE';
export const THREEDS_REQUIRED_ERROR_CODE = 'THREEDS_REQUIRED_ERROR_CODE';

export const TA_PAYMENTS_ACCOUNT_TYPE_PAYPAL = 0;

export const MONTH_YEAR_FORMAT = 'MMM YYYY';

export const DROPDOWN_DIRECTIONS = ['top', 'bottom', 'left', 'right'];

// Backend IDs for public transit vehicles
export const BUS_VEHICLE_ID = 21;
export const EXPRESS_TRAIN_VEHICLE_ID = 10;

// autocomplete types
export const AUTOCOMPLETE_TYPES = {
  PHONE_CODE: 'phoneCode',
  AIRLINE: 'airline',
  FLIGHT: 'flight',
  TA_COMPANY: 'taCompany',
  PLACE: 'place',
  CRUISE: 'cruise',
};
