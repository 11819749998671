import React from 'react';
import { Layout } from 'app/components/base/Layout';
import MobileTripDirection from 'app/components/common/TripDirection/Mobile';
import ReservationActions from '../../ReservationActions';
import ReservationConfirmationNumber from '../../ReservationConfirmationNumber';
import builder from '../builder';

import style from './style.css';

export const MobileDepartingDirection = ({ readOnly, formName, ...props }) => (
  <Layout className={style.block} size="noshrink" direction="column" nowrap>
    <ReservationConfirmationNumber formName={formName} direction="departing" />
    <MobileTripDirection {...props} showDateTimes={readOnly} />
    <ReservationActions formName={formName} direction="departing" mobile />
  </Layout>
);

// TODO: move to Flow types
// MobileDepartingDirection.propTypes = {
//   formName: PropTypes.string,
//   readOnly: PropTypes.bool
// };

MobileDepartingDirection.defaultProps = {
  readOnly: false,
};

export default builder(MobileDepartingDirection);
