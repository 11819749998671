import { createReducer } from '../utils/redux';

import {
  GET_FAVOURITE_LOCATIONS,
  SET_FAVOURITE_LOCATIONS,
} from 'app/constants';

const initialState = {
  places: [],
};

const actionHandlers = {
  [GET_FAVOURITE_LOCATIONS]: (state, { clear = true } = {}) => {
    if (clear) return initialState;
    return state;
  },
  [SET_FAVOURITE_LOCATIONS]: (_, { places }) => ({
    places,
  }),
};

export default createReducer(initialState, actionHandlers);
