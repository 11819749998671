import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';

const messages = {
  text: { id: 'BOOK_TRIP.SIDEBAR.ENTRUST_TEXT' },
  title: { id: 'BOOK_TRIP.SIDEBAR.ENTRUST_TITLE' },
};

const Entrust = ({ mobile }) => {
  const modifiers = {
    [style.mobile]: mobile,
  };

  return (
    <Layout
      className={classnames(style['entrust-container'], modifiers)}
      direction="column"
      size="100"
    >
      <span className={style['entrust-title']}>
        <FormattedMessage {...messages.title} />
      </span>
      <span className={style['entrust-logo']}></span>
      <span className={style['entrust-text']}>
        <FormattedMessage {...messages.text} />
      </span>
    </Layout>
  );
};

// TODO: move to Flow types
// Entrust.propTypes = {
//   mobile: PropTypes.bool
// };

export default Entrust;
