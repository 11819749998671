import React from 'react';
import classnames from 'classnames';

import style from './style.css';

/**
 * Container for buttons in the navigation
 * @param  {String} position    "left" or "right" position in the navigation
 * @return {Component}
 */
export const NavbarButtonsGroup = ({
  className,
  position = 'left',
  children,
  ...props
}) => (
  <ul
    className={classnames(
      className,
      style.buttons,
      style[`buttons--${position}`]
    )}
    {...props}
  >
    {children}
  </ul>
);

// TODO: move to Flow types
// NavbarButtonsGroup.propTypes = {
//   className: PropTypes.string,
//   position: PropTypes.oneOf(['left', 'right']),
//   children: PropTypes.node
// };

export default NavbarButtonsGroup;
