import { put, call, select } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { setTravelAgent } from 'app/actions/travel-agent';
import { setUser } from 'app/actions/user-session';
import { getUser } from 'app/sagas/selectors';

/**
 * Actual travel agent profile getter to be able to use the
 * sage directly in user initializatio process.
 */
export function* doGetTravelAgent() {
  const user = yield select(getUser);
  const taProfile = yield call(travelagent.getProfile);
  const finalUser = { ...user, ...taProfile };

  // We are in process of moving default lang/currency from TA profile to
  // user profile. User's profile default currency/lang should have higher
  // priority
  finalUser.currency = user.currency || taProfile.currency;
  finalUser.language = user.language || taProfile.language;

  yield put(setUser(finalUser));
  yield put(setTravelAgent(finalUser));
}

/**
 * If the user is a travel agent, we need to get the TA Profile from the Backend.
 */
export default function* getTravelAgentProfile(action) {
  try {
    yield call(doGetTravelAgent);
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
