import { call, select, put } from 'redux-saga/effects';
import { updateTrip } from 'app/actions/book-trip';
import { getMeetAndGreetTrips } from 'app/sagas/selectors';
import { parseUrlParams } from 'app/sagas/utils';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { preprocessTripObject } from 'app/utils/trip';

export default function* handleUpdateMeetAndGreetTrip(action) {
  const { payload: tripId } = action;

  const trips = yield select(getMeetAndGreetTrips);

  const updatedTrip = trips.find((trip) => trip.id === tripId);

  if (updatedTrip) {
    const preparedTrip = yield call(preprocessTripObject, updatedTrip);
    const { meetAndGreetAvailable, meetAndGreetDefault } = preparedTrip.details;
    const forceMeetAndGreet = !meetAndGreetDefault && meetAndGreetAvailable;

    const urlParams = yield call(parseUrlParams);

    const updatedTripUrlParams = {
      ...urlParams,
      session_id: updatedTrip.searchId,
      trip_id: updatedTrip.id,
    };

    const newBookTripUrl = `/book${objectToQueryString(updatedTripUrlParams)}`;

    yield put(updateTrip({ newBookTripUrl, updatedTrip, forceMeetAndGreet }));
  }
}
