import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import getChangedModeTrip from './getChangedModeTrip';
import bookChangedModeTrip from './bookChangedModeTrip';
import { CHANGE_TRIP_MODE, BOOK_CHANGED_MODE_TRIP } from 'app/constants';

/**
 * Wait for return trip request
 */
export default function* watchTripMode() {
  yield fork(takeLatest, CHANGE_TRIP_MODE, getChangedModeTrip);
  yield fork(takeLatest, BOOK_CHANGED_MODE_TRIP, bookChangedModeTrip);
}
