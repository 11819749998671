import { select } from 'redux-saga/effects';
import {
  getTACustomerProfileForm,
  getTASelectedCustomerProfileId,
} from 'app/sagas/selectors';
import {
  TA_CUSTOMER_PROFILE_NAME,
  TA_CUSTOMER_FIRST_NAME,
  TA_CUSTOMER_LAST_NAME,
  TA_CUSTOMER_EMAIL,
  TA_CUSTOMER_PHONE_CODE,
  TA_CUSTOMER_PHONE_NUMBER,
  CC_FIRST_NAME_FIELD,
  CC_LAST_NAME_FIELD,
  // SELECTED_CREDIT_CARD_ID
} from 'app/constants';

export default function* getCustomerProfileData() {
  const profileId = yield select(getTASelectedCustomerProfileId);
  const formData = yield select(getTACustomerProfileForm);
  const profileData = {
    profile_id: profileId,
    profile_name: formData[TA_CUSTOMER_PROFILE_NAME],
    first_name: formData[TA_CUSTOMER_FIRST_NAME],
    last_name: formData[TA_CUSTOMER_LAST_NAME],
    email: formData[TA_CUSTOMER_EMAIL],
    country_code_name: (
      formData[TA_CUSTOMER_PHONE_CODE].value || ''
    ).toUpperCase(),
    phone_number: formData[TA_CUSTOMER_PHONE_NUMBER],
  };

  const cardData = {
    name: [formData[CC_FIRST_NAME_FIELD], formData[CC_LAST_NAME_FIELD]]
      .filter((a) => a)
      .join(' '),
    email: formData[TA_CUSTOMER_EMAIL]
      ? formData[TA_CUSTOMER_EMAIL]
      : undefined,
  };

  return {
    profileData,
    cardData,
    isCardDataEmpty: !cardData.name,
  };
}
