import React from 'react';

import { connect } from 'react-redux';
import CategoryTooltip from './CategoryTooltip';
import { Layout } from 'app/components/base/Layout';
import TripList from 'app/components/search-results/TripList';
import Button from 'app/components/base/Button';
import style from './style.css';
import Icon from 'app/components/base/Icon';
import { showMore } from 'app/actions/search-results';
import CN from 'classnames';
import { constants } from 'mz-sdk';
import { getMainStep } from 'mz-sdk/models/step';
import { FormattedMessage } from 'translations';
import config from 'config';

const messages = {
  moreWithScore: { id: 'SEARCH_RESULTS.VIEW_MORE_WITH_SCORE' },
  more: { id: 'SEARCH_RESULTS.VIEW_MORE' },
};

class Category extends React.Component {
  state = { showMore: false };

  getIcon(type, filter) {
    return (
      <Icon
        family="mozio"
        name={constants.CATEGORIES_FILTERS[filter]}
        className={style.icon}
      />
    );
  }

  handleClick = (event) => {
    event.stopPropagation();
    this.props.showMore();
    this.setState({ showMore: true });
  };

  getAllOptions() {
    const { trips, headTrips, tailTrips } = this.props;

    return [...(headTrips || []), ...(tailTrips || []), ...(trips || [])];
  }

  getRenderedOptions() {
    const { headTrips } = this.props;
    const { showMore } = this.state;

    if (showMore) {
      return this.getAllOptions();
    }
    if (headTrips?.length > 0) {
      return headTrips;
    }
    return this.getAllOptions().slice(0, 3);
  }

  getBestTailOptionDetails() {
    const trip = this.props.tailTrips[0];
    if (!trip) return null;

    const mainStep = getMainStep(trip.steps);
    return {
      price: trip.price.totalWithoutPlatformFee,
      supplierScore: mainStep.provider.supplierScore,
    };
  }

  render() {
    const { showMore } = this.state;
    const { vehicle_type, className, type, filter, mobile } = this.props;

    const renderedTrips = this.getRenderedOptions();
    const allTrips = this.getAllOptions();
    const bestTailOption = this.getBestTailOptionDetails();
    const viewMoreMessage =
      bestTailOption?.supplierScore > 0
        ? messages.moreWithScore
        : messages.more;

    if (!config.SHOW_VEHICLE_CATEGORY) {
      return (
        <Layout direction="column" className={CN(style.container, className)}>
          <TripList
            trips={allTrips.map((trip) => trip.id)}
            type={type}
            mobile={mobile}
          />
        </Layout>
      );
    }

    return (
      <Layout direction="column" className={CN(style.container, className)}>
        <Layout align="space-between end" className={style.heading}>
          <CategoryTooltip
            categoryName={
              <FormattedMessage id={`VEHICLES.ID_${vehicle_type}.NAME`} />
            }
            tooltipText={
              <FormattedMessage
                id={`VEHICLES.ID_${vehicle_type}.DESCRIPTION`}
              />
            }
            origin={
              <Layout align="start center">
                {this.getIcon(type, filter)}
                <h2 className={style.categoryName}>
                  <FormattedMessage id={`VEHICLES.ID_${vehicle_type}.NAME`} />
                </h2>
                <Icon
                  inline
                  interactive
                  family="stamp"
                  className={style.iconHelp}
                >
                  ?
                </Icon>
              </Layout>
            }
          />
        </Layout>
        <TripList
          trips={renderedTrips.map((trip) => trip.id)}
          type={type}
          vehicleType={vehicle_type}
          mobile={mobile}
        />
        {!showMore &&
          bestTailOption &&
          allTrips.length - renderedTrips.length > 0 && (
            <Layout align="center center">
              <Button
                clear
                nocaps
                className={style.more}
                onClick={this.handleClick}
              >
                <FormattedMessage
                  {...viewMoreMessage}
                  values={{
                    options: allTrips.length - renderedTrips.length,
                    price: bestTailOption.price,
                    score: bestTailOption.supplierScore,
                  }}
                />
                <Icon name="keyboard_arrow_down" small />
              </Button>
            </Layout>
          )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Category.propTypes = {
//   showMore: PropTypes.func.isRequired,
//   trip_type: PropTypes.string.isRequired,
//   vehicle_type: PropTypes.string,
//   type: PropTypes.string,
//   filter: PropTypes.string,
//   trips: PropTypes.array.isRequired,
//   className: PropTypes.string,
//   mobile: PropTypes.bool
// };

export default connect(null, { showMore })(Category);
