const SEARCH = 'search';

export default [
  {
    name: 'SEARCH_UNIQUE_CLASS_TYPE',
    type: 'bool',
    default: false,
    description: 'Show only unique class+type trips',
    category: SEARCH,
  },
  {
    name: 'SEARCH_RECOMMENDED_TRIP',
    type: 'bool',
    default: true,
    description: 'Show trip recommendation',
    category: SEARCH,
  },
  {
    name: 'SEARCH_ONDEMAND',
    type: 'bool',
    default: true,
    description: 'Show on-demand results',
    category: SEARCH,
  },
  {
    name: 'SHOW_TAGS_IN_SEARCH',
    type: 'bool',
    default: true,
    description: 'Show trip tags',
    category: SEARCH,
  },
  {
    name: 'VEHICLE_CLASS_TOP_RESULTS',
    type: 'bool',
    default: false,
    description: 'Show first options in each category by vehicle class',
    category: SEARCH,
  },
  {
    name: 'MINIMAL_SUPPLIER_SCORE',
    type: 'string',
    default: '',
    description:
      'If set the user will see only the options that have supplier score >= of the given one',
    category: SEARCH,
  },
  {
    name: 'ENABLED_SEARCH_CLASSES',
    type: 'string',
    default: '',
    description:
      'Enabled trip classes (if not empty). Comma or space separated. Available: first, business, economy, standard, green_standard, green_premium, business_van',
    category: SEARCH,
  },
  {
    name: 'DISABLED_SEARCH_CLASSES',
    type: 'string',
    default: '',
    description:
      'Disabled trip classes (if not empty). Comma or space separated. Available: first, business, economy, standard, green_standard, green_premium, business_van',
    category: SEARCH,
  },
  {
    name: 'IGNORE_SEARCH_DISCOUNTS',
    type: 'bool',
    default: false,
    description:
      "Don't show discounts on the search results page and show slashed price as the final one",
    category: SEARCH,
  },
];
