import * as React from 'react';
import CheckIcon from 'mz-icons/check';
import messages from '../../../MozioGroupHomePage/messages.intl';
import { FormattedMessage } from 'mz-intl';
import { Feature, CheckIconWrapper } from './elements';

const FEATURES = [
  { message: messages.SEARCH_FEATURE_FREE_CANCELLATION },
  { message: messages.SEARCH_FEATURE_FLIGHT_DELAYED },
  { message: messages.SEARCH_FEATURE_SUPPORT },
  { message: messages.CAPABILITIES_SUPPORT_TITLE },
];

const FeaturesList = () =>
  FEATURES.map((feature, index) => (
    <Feature key={index}>
      <CheckIconWrapper>
        <CheckIcon />
      </CheckIconWrapper>
      <FormattedMessage {...feature} />
    </Feature>
  ));

export default FeaturesList;
