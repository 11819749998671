import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { BOOK_TRIP_FORM, BOOKING_FEE_STATUS } from 'app/constants';
import { getBookingFeeStatus } from 'app/sagas/selectors';

export default function* closeHint() {
  const status = yield select(getBookingFeeStatus);
  yield put(
    change(BOOK_TRIP_FORM, BOOKING_FEE_STATUS, {
      ...status,
      showHint: false,
    })
  );
}
