import React from 'react';
import classnames from 'classnames';
import StaticContainer from 'react-static-container';
import LiftSlideAnimator from './LiftSlideAnimator';
import SimpleSlideAnimator from './SimpleSlideAnimator';
import _ from 'lodash';

import style from './style.css';

// Constants
const ANIMATORS = {
  lift: LiftSlideAnimator,
  slide: SimpleSlideAnimator,
};

/**
 * The component for showing mobile pages that animates all
 * chenges in the pages stack.
 */
export default class MobileScreenAnimator extends React.Component {
  elements = {};

  constructor(props, context) {
    super(props, context);
    this.state = { pages: props.pages };
    this.queue = [];
    this.transitionActive = false;
  }

  componentWillMount() {
    const curPageIdx = this.state.pages.length - 1;
    this.animateBetween = {
      fromIdx: curPageIdx,
      toIdx: curPageIdx,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.queue.push(nextProps);
    this.maybeProcessQueue();
  }

  shouldComponentUpdate() {
    // Component is never updated by new props.
    // Only manually when transition ends
    return false;
  }

  componentDidUpdate() {
    const { fromIdx, toIdx } = this.animateBetween;

    if (fromIdx !== toIdx) {
      const direction = toIdx < fromIdx ? 'pop' : 'push';
      this.runAnimation(direction, toIdx, fromIdx);
    } else {
      this.transitionActive = false;
      this.maybeProcessQueue();
    }
  }

  maybeProcessQueue() {
    if (!this.transitionActive && this.queue.length) {
      this.transitionActive = true;
      const nextProps = this.queue.shift();
      const starter = this.startTransition.bind(this, nextProps);
      setTimeout(starter, 1000 / 60);
    }
  }

  runAnimation(direction, toIdx, fromIdx) {
    const { onAnimationStart } = this.props;

    // Prepare constants for animation
    const optsPageIdx = direction === 'push' ? toIdx : fromIdx;
    const animator = this.createAnimatorForPage(optsPageIdx);
    const toDomNode = this.elements[`wrp_${toIdx}`];
    const fromDomNode = this.elements[`wrp_${fromIdx}`];

    // Hide target page, because it will be in higher
    // layer than previous page (when pushing new page)
    // It needed only because we use small delay below.
    toDomNode.style.opacity = 0.001;

    // Start animation with a little delay.
    // It gives some time for a browser to apply
    // DOM changes from latest rerendering.
    setTimeout(() => {
      onAnimationStart();
      toDomNode.style.opacity = 1;
      animator[direction](toDomNode, fromDomNode, this.handleEndAnimation);
    }, 1000 / 30);
  }

  createAnimatorForPage(pageIdx) {
    const pageElem = this.state.pages[pageIdx];
    const animation = _.get(pageElem, 'props.animation', 'slide');
    const Animator = ANIMATORS[animation] || ANIMATORS.slide;
    return new Animator();
  }

  startTransition(nextProps) {
    const nextPages = [...nextProps.pages];
    const prevPages = this.state.pages;

    this.animateBetween = {
      fromIdx: prevPages.length - 1,
      toIdx: nextPages.length - 1,
    };

    if (prevPages.length > nextPages.length) {
      Array.prototype.push.apply(nextPages, prevPages.slice(nextPages.length));
    }

    this.setState({ pages: nextPages });
    this.forceUpdate();
  }

  handleEndAnimation = () => {
    const { onAnimationEnd } = this.props;
    const currPageIdx = this.animateBetween.toIdx;
    const newChildren = this.state.pages.slice(0, currPageIdx + 1);

    this.animateBetween = {
      fromIdx: currPageIdx,
      toIdx: currPageIdx,
    };

    this.setState({ pages: newChildren });
    this.forceUpdate(onAnimationEnd);
  };

  isPageVisible(idx) {
    const { fromIdx, toIdx } = this.animateBetween;
    return fromIdx === idx || toIdx === idx;
  }

  shouldPageUpdate(idx) {
    const { fromIdx, toIdx } = this.animateBetween;
    return (fromIdx === toIdx && toIdx === idx) || idx === toIdx;
  }

  render() {
    const { pages } = this.state;
    return (
      <div>
        {pages.map((pageElem, i) => {
          const pageVisible = this.isPageVisible(i);
          const shouldUpdate = this.shouldPageUpdate(i);
          const modifiers = { [style.visible]: pageVisible };

          return (
            <div
              key={i}
              ref={(el) => (this.elements[`wrp_${i}`] = el)}
              id={pageVisible ? 'visible-mobile-page' : undefined}
              className={classnames(style.wrapper, modifiers)}
            >
              <StaticContainer shouldUpdate={shouldUpdate}>
                {pageElem}
              </StaticContainer>
            </div>
          );
        })}
      </div>
    );
  }
}
MobileScreenAnimator.defaultProps = {
  onAnimationStart: _.noop,
  onAnimationEnd: _.noop,
};

// TODO: move to Flow types
// MobileScreenAnimator.propTypes = {
//   pages: PropTypes.array,
//   onAnimationEnd: PropTypes.func,
//   onAnimationStart: PropTypes.func
// };
