import { call, put, select } from 'redux-saga/effects';
import { getFormValues, initialize } from 'redux-form';
import {
  loadBookTrip,
  showPickupTimeChangedNotification,
} from 'app/actions/book-trip';
import { getHistory } from 'app/history';
import { getBookTripForm } from 'app/sagas/selectors';
import {
  BOOK_TRIP_UPDATED_MODAL_FORM,
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
} from 'app/constants';

/**
 * This saga is called when user click on "Update" button in
 * "Trip Updated" modal. It updates url and fires action for reinitialization BTP
 * @param {Object} action        Action fired by user from modal - type: UPDATE_TRIP
 *                               or from handleChangePickupTime saga
 */
export default function* updateTrip(action) {
  const {
    payload: {
      newBookTripUrl,
      updatedTrip,
      forceMeetAndGreet,
      timeChangedProps = {},
    },
  } = action;

  // update URL
  const history = yield call(getHistory);
  yield call(history.replace, newBookTripUrl);

  // now copy values of "TripUpdated" modal form to main "BTP" form
  const oldTripFormValues = yield select(getBookTripForm);
  const newTripFormValues = yield select(
    getFormValues(BOOK_TRIP_UPDATED_MODAL_FORM)
  );

  yield put(
    initialize(BOOK_TRIP_FORM, {
      ...oldTripFormValues,
      ...newTripFormValues,
    })
  );

  const { isTimeChanged, field, originTime, newTime } = timeChangedProps;
  // notify user if provider returned pickup time different from requested
  if (isTimeChanged) {
    yield put(
      showPickupTimeChangedNotification({ field, originTime, newTime })
    );
  }

  // notify saga to reload trip. Basically just rerender.
  yield put(
    loadBookTrip({
      updatedTrip:
        updatedTrip ||
        (!!newTripFormValues && newTripFormValues[DEPARTING_TRIP_FIELD_NAME]),
      reloadTrip: true,
      forceMeetAndGreet,
    })
  );
}
