import React from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'translations';
import { connectSubpages } from 'app/hocs/subpageManagable';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import DesktopLightbox from 'app/components/base/DesktopLightbox';
import MobileScreen from 'app/components/base/MobileScreen';
import { isMobileBrowser } from 'app/utils/browser';

import style from './style.css';

const messages = {
  title: { id: 'SEARCH_RESULTS.PENDING_MESSAGE' },
  confirmAvailability: { id: 'BOOK_TRIP.CONFIRM_PRICING_AND_AVAILABILITY' },
};

const LoadingBox = () => {
  const content = (
    <div className={style.container}>
      <LoadingSpinner size="small" />
      <p>
        <FormattedMessage {...messages.confirmAvailability} />
      </p>
    </div>
  );

  if (isMobileBrowser) {
    return (
      <MobileScreen title={<FormattedMessage {...messages.title} />}>
        {content}
      </MobileScreen>
    );
  }
  return (
    <DesktopLightbox rounded shadow empty>
      {content}
    </DesktopLightbox>
  );
};

export default compose(connectSubpages())(LoadingBox);
