import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { SAVE_BOOKING_FEE, CLOSE_BOOKING_FEE_HINT } from 'app/constants';
import saveBookingFee from './saveBookingFee';
import closeHint from './closeHint';

/**
 * Saga for actions related to the booking fee field in the book-trip page
 */
export default function* watchBookingFee() {
  yield fork(takeLatest, SAVE_BOOKING_FEE, saveBookingFee);
  yield fork(takeLatest, CLOSE_BOOKING_FEE_HINT, closeHint);
}
