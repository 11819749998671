import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { BOOK_TRIP_FORM, GRATUITY_STATUS } from 'app/constants';
import { getGratuityStatus } from 'app/sagas/selectors';

export default function* closeHint() {
  const status = yield select(getGratuityStatus);
  yield put(
    change(BOOK_TRIP_FORM, GRATUITY_STATUS, {
      ...status,
      showHint: false,
    })
  );
}
