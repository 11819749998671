import { call } from 'redux-saga/effects';
import { places } from 'mz-sdk';

/**
 * By given id get the place details and make right object in
 * suggestions format.
 */
export default function* findPlaceByCoords(lat, lng) {
  const pointsOfInterest = yield call(places.getPointsOfInterest, { lat, lng });
  return pointsOfInterest.length > 0 ? pointsOfInterest[0] : {};
}
