import React from 'react';
import CN from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import InlineLabel from 'app/components/base/InlineLabel';
import Icon from 'app/components/base/Icon';
import Amenity from 'app/components/search-results/Amenity';
import BagsAndPassengers from '../../commons/BagsAndPassengers';
import RideshareLabel from '../../commons/RideshareLabel';
import InternetRequiredLabel from '../../commons/InternetRequiredLabel';
import MilesEarnedLabel from '../../commons/MilesEarnedLabel';
import TripCategory from '../../commons/TripCategory';
import config from 'config';

import style from './style.css';

const extendedTripView = config.SRP_EXTENDED_TRIP_DETAILS;

const messages = {
  notIncluded: { id: 'SEARCH_RESULTS.NOT_INCLUDED' },
  departure: { id: 'SEARCH_RESULTS.DEPARTURE' },
  serviceHours: { id: 'SEARCH_RESULTS.SERVICE_HOURS' },
  waitTime: { id: 'SEARCH_RESULTS.WAIT_TIME' },
};

class Details extends React.Component {
  getAmenities() {
    const { amenities } = this.props;

    if (!amenities.length) return null;

    return amenities.map((amenity) => (
      <Amenity key={amenity.id} item={amenity} primary />
    ));
  }

  render() {
    const {
      rewardPoints,
      maxPassengers,
      maxBags,
      isMaxBagsPerPerson,
      isTrain,
      isOndemand,
      departureTime,
      serviceHours,
      waitTime,
      vehicleTypeId,
      categoryName,
      showCategory,
    } = this.props;

    return (
      <Layout direction="column" size="noshrink">
        <Layout
          className={CN(style.details, {
            [style.extendedView]: extendedTripView,
          })}
          align={extendedTripView ? 'start start' : 'start center'}
          direction={extendedTripView ? 'column' : 'row'}
        >
          {/* passangers and bags */}
          <BagsAndPassengers
            className={style.bagsAndPassengers}
            isTrain={isTrain}
            isMaxBagsPerPerson={isMaxBagsPerPerson}
            maxBags={maxBags}
            maxPassengers={maxPassengers}
          />

          {/* departure time if provided */}
          {departureTime && (
            <Layout align="start center" className={style.departure}>
              <Icon light inline family="mozio" name="clock" />
              <b>
                <FormattedMessage {...messages.departure} />
              </b>
              <span>{departureTime}</span>
            </Layout>
          )}

          {/* service hours time if provided */}
          {serviceHours && (
            <Layout align="start center" className={style['service-hours']}>
              <Icon light inline family="mozio" name="clock" />
              <b>
                <FormattedMessage {...messages.serviceHours} />
              </b>
              <span>{serviceHours}</span>
            </Layout>
          )}

          {/* amenities */}
          {extendedTripView && <Layout>{this.getAmenities()}</Layout>}
          {!extendedTripView && this.getAmenities()}
        </Layout>
        {/* waiting time if provided */}
        {config.WAITING_TIME_SHOW && !!waitTime && (
          <Layout direction="row" size="noshrink">
            <InlineLabel
              className={style.waitTime}
              primary
              align="center center"
              icon={{ name: 'clock', family: 'mozio' }}
              text={
                <FormattedMessage
                  {...messages.waitTime}
                  values={{ minutes: waitTime }}
                />
              }
            />
          </Layout>
        )}
        <Layout direction="row" size="noshrink">
          {isOndemand && <InternetRequiredLabel />}
          {isOndemand && <RideshareLabel />}
          {config.LOYALTY_PROGRAM_ENABLED && !!rewardPoints && (
            <MilesEarnedLabel miles={rewardPoints} />
          )}
        </Layout>
        {showCategory && (
          <Layout direction="row" size="noshrink">
            <TripCategory
              vehicleTypeId={vehicleTypeId}
              categoryName={categoryName}
            />
          </Layout>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Details.propTypes = {
//   maxPassengers: PropTypes.number,
//   maxBags: PropTypes.number,
//   isMaxBagsPerPerson: PropTypes.bool,
//   departureTime: PropTypes.string,
//   serviceHours: PropTypes.string,
//   isTrain: PropTypes.bool,
//   amenities: PropTypes.array,
//   waitTime: PropTypes.number,
//   // actions
//   onScheduleDeparture: PropTypes.func,
// };

Details.defaultProps = {
  amenities: [],
};

export default Details;
