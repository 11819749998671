import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import handleLoadMeetAndGreetTrips from './handleLoadMeetAndGreetTrips';
import { GET_SUGGESTED_MEET_AND_GREET_TRIPS } from 'app/constants';

export default function* watchLoadMeetAndGreetTrips() {
  yield fork(
    takeLatest,
    GET_SUGGESTED_MEET_AND_GREET_TRIPS,
    handleLoadMeetAndGreetTrips
  );
}
