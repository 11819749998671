import { createReducer } from 'app/utils/redux';
import VEHICLE_CLASS from 'mz-sdk/constants/vehicleClass';
import {
  getMainStep,
  getAmenities,
  getEnhancedVehicleClass,
} from 'app/utils/trip';
import { setProviders } from 'app/utils/providers';
import _ from 'lodash';
import update from 'immutability-helper';
import { constants } from 'mz-sdk';
import config from 'config';
import {
  DO_SEARCH,
  LOAD_SEARCH_FORM,
  END_SEARCH,
  SET_RECOMMENDED_TRIP,
  ADD_SEARCH_RESULTS,
  TOGGLE_SEARCH_CATEGORY,
  TOGGLE_SEARCH_CATEGORIES_ALL,
  SELECT_SEARCH_SORT_OPTION,
  TOGGLE_SEARCH_AMENITY,
  TOGGLE_SEARCH_TAG,
  TOGGLE_SEARCH_PROVIDER,
  TOGGLE_MEET_AND_GREET_FILTER,
  FILTER_SUITCASE_COUNT,
  UPDATE_SEARCH_FILTERS,
  SAVE_SEARCH,
  SET_SCHEDULED_DEPARTURE,
  UPDATE_SCHEDULED_TRIP_PRICE,
  OUTDATE_SEARCH,
} from 'app/constants';

const { SORTER_ENUM, SORTER_FUNCTIONS, CATEGORIES_FILTERS } = constants;

/**
 * Enum of all available result filters
 * @type {Object}
 */
export const FILTER_ENUM = {
  PROVIDERS: 'PROVIDERS',
  AMENITIES: 'AMENITIES',
  CATEGORIES: 'CATEGORIES',
  TAGS: 'TAGS',
  MEET_AND_GREET: 'MEET_AND_GREET',
  MEET_AND_GREET_INCLUDED: 'MEET_AND_GREET_INCLUDED',
  VEHICLE_CLASS: 'VEHICLE_CLASS',
  FILTER_SUITCASE_COUNT: 'FILTER_SUITCASE_COUNT',
  MINIMAL_SUPPLIER_SCORE: 'MINIMAL_SUPPLIER_SCORE',
};

export const FILTERS = {
  [FILTER_ENUM.PROVIDERS]: (trip, { providers = {} }) => {
    const available = Object.keys(providers).filter(
      (providerName) => providers[providerName]
    );
    if (available.length === 0) {
      return true;
    }

    // TODO: support multimodal filtering, currently all trips with no main steps
    // are public transit and they don't have provider information
    const mainStep = getMainStep(trip);
    if (!mainStep) {
      return false;
    }

    const { provider } = mainStep;
    return available.some((providerName) => providerName === provider.name);
  },

  [FILTER_ENUM.AMENITIES]: (trip, { amenities = {} }) => {
    if (_.isEmpty(amenities)) {
      return true;
    }

    // TODO: support multimodal filtering, currently all trips with no main steps
    // are public transit and they have no amenities information
    const mainStep = getMainStep(trip);
    if (!mainStep) {
      return false;
    }
    return Object.keys(amenities).every((amenityId) => {
      return (
        !amenities[amenityId] ||
        mainStep.amenities.some((amenity) => {
          return amenity.id === amenityId;
        })
      );
    });
  },

  [FILTER_ENUM.TAGS]: (trip, { tags = {} }) => {
    if (_.isEmpty(tags)) {
      return true;
    }
    return Object.keys(tags).every((tagType) => {
      return !tags[tagType] || trip.tags.some((tag) => tag.type === tagType);
    });
  },

  [FILTER_ENUM.CATEGORIES]: (trip, { categories = {} }) => {
    const available = Object.keys(categories).filter((id) => categories[id]);
    if (available.length === 0) {
      return true;
    }

    const main = getMainStep(trip);
    const category = CATEGORIES_FILTERS[main.vehicle.type.key];
    return available.some((id) => id === category);
  },

  [FILTER_ENUM.MEET_AND_GREET]: (trip, { meetAndGreet = false }) => {
    if (!meetAndGreet) return true;
    const amenities = getAmenities(
      getMainStep(trip),
      getMainStep(trip, true),
      'amenities'
    );
    return !!_.find(amenities, { id: 'meet_and_greet' });
  },

  [FILTER_ENUM.MEET_AND_GREET_INCLUDED]: (
    trip,
    { meetAndGreetIncluded = false }
  ) => {
    if (!meetAndGreetIncluded) return true;
    const amenities = getAmenities(
      getMainStep(trip),
      getMainStep(trip, true),
      'amenities'
    );
    return !!_.find(amenities, { id: 'meet_and_greet', included: true });
  },

  [FILTER_ENUM.VEHICLE_CLASS]: (trip, { vehicleClass }) => {
    if (!vehicleClass) return true;
    const tripVehicleClass = getEnhancedVehicleClass(trip);
    return vehicleClass === tripVehicleClass;
  },

  [FILTER_ENUM.FILTER_SUITCASE_COUNT]: (trip, { suitcaseCount = 0 }) => {
    const { vehicle } = getMainStep(trip);
    if (!vehicle) {
      return false;
    }
    return vehicle.totalBags >= suitcaseCount;
  },

  [FILTER_ENUM.MINIMAL_SUPPLIER_SCORE]: (trip, { supplierScore = 0 }) => {
    if (!supplierScore) return true;
    const { provider } = getMainStep(trip);
    if (!provider) {
      return false;
    }
    return provider.supplierScore >= supplierScore;
  },
};

/**
 * Emptry active filters object. Can be used to
 * reset filters.
 * @type {Object}
 */
export const EMPTY_FILTER = {
  categories: {},
  providers: {},
  amenities: {},
  tags: {},
  meetAndGreet: false,
  sort: SORTER_ENUM.RECOMMENDED,
  suitcaseCount: 0,
  meetAndGreetIncluded: false,
  vehicleClass: null,
  supplierScore: 0,
};

// Initial state of the reducer with empty filters
export const initialState = {
  alerts: [],
  activeFilters: EMPTY_FILTER,
  search_id: null,
  search_expiration: null,
  linked: false,
  searching: true,
  errored: null,
  outdated: false,
  recommendedTripIds: [],
  search: {},
  available: [],
  amenities: {},
  tags: {},
  providers: {},
  trips: {},
};

/**
 * Fitler one trip for each vehicle class, mainly for Hertz
 * @param  {Object} trips
 * @return {Object}
 */
export const filterUniqueClassTypes = (trips) => {
  const result = {};

  Object.keys(trips).forEach((tripId) => {
    // Get vehicle class and ignore if no class defined
    const vehicleClass = getEnhancedVehicleClass(trips[tripId]);
    if (!vehicleClass) return;

    // Get some additional trip data
    const totalPrice = trips[tripId].totalPrice;

    // Make unique cheapest trip by each category
    const currentTotalPrice =
      result[vehicleClass] && trips[result[vehicleClass]].totalPrice;
    if (!result[vehicleClass] || totalPrice < currentTotalPrice) {
      result[vehicleClass] = tripId;
    }
  });

  return [
    result[VEHICLE_CLASS.ECONOMY],
    result[VEHICLE_CLASS.STANDARD],
    result[VEHICLE_CLASS.BUSINESS],
    result[VEHICLE_CLASS.FIRST],
    result[VEHICLE_CLASS.BUSINESS_VAN],
    result[VEHICLE_CLASS.GREEN_STANDARD],
    result[VEHICLE_CLASS.GREEN_PREMIUM],
  ].filter(Boolean);
};

/**
 * Remain only the trips within enabled classes or only the trips with classes
 * that are not disabled
 * @param  {Object} trips
 * @return {Object}
 */
export const filterTripClasses = (trips) => {
  if (!config.ENABLED_SEARCH_CLASSES && !config.DISABLED_SEARCH_CLASSES) {
    return trips;
  }

  const result = {};
  const isEnabled = !!config.ENABLED_SEARCH_CLASSES;
  const filterClasses = (
    config.ENABLED_SEARCH_CLASSES || config.DISABLED_SEARCH_CLASSES
  )
    .split(/,|\s+/)
    .map((x) => VEHICLE_CLASS[x.trim().toUpperCase()])
    .reduce((acc, k) => ({ ...acc, [k]: true }), {});

  Object.keys(trips).forEach((tripId) => {
    const vehicleClass = getEnhancedVehicleClass(trips[tripId]);
    if (!vehicleClass) return;
    if (
      (isEnabled && filterClasses[vehicleClass]) ||
      (!isEnabled && !filterClasses[vehicleClass])
    ) {
      result[tripId] = trips[tripId];
    }
  });

  return result;
};

/**
 * Filter trips by given `activeFilters` object
 * @param  {Object} filters - current active filters
 * @param  {Object} trips - normalized trips map
 * @return {Array} an array of sorted trip ids
 */
export const doFilterResults = (filters, trips, recommendedTripIds = []) => {
  const filteredTrips = filterTripClasses(trips);

  // HERTZ results filter
  if (config.SEARCH_UNIQUE_CLASS_TYPE) {
    return filterUniqueClassTypes(filteredTrips);
  }

  // Mozio general filter
  const tripIds = Object.keys(filteredTrips).filter((tripId) => {
    return (
      !recommendedTripIds.includes(tripId) &&
      Object.keys(FILTERS).every((filterId) => {
        return FILTERS[filterId](filteredTrips[tripId], filters);
      })
    );
  });

  if (filters.sort == null) {
    return tripIds;
  }

  return tripIds.sort((id1, id2) => {
    return SORTER_FUNCTIONS[filters.sort](
      filteredTrips[id1],
      filteredTrips[id2]
    );
  });
};

/**
 * By given sate and filters update command create
 * an object with new `activeFilters` and refiltered
 * reulsts list using new active filters.
 * @param  {Object} state        current reducer state
 * @param  {object} filterUpdate
 * @return {Object}
 */
const updateFilterResult = (state, filterUpdate, isUpdate = true) => {
  const activeFilters = isUpdate
    ? update(state.activeFilters, filterUpdate)
    : filterUpdate;

  const available = doFilterResults(
    activeFilters,
    state.trips,
    state.recommendedTripIds
  );

  return {
    activeFilters,
    available,
  };
};

const toggleFilter = (values, value) => {
  return { [value]: { $set: !values[value] } };
};

/**
 * It merges normalized results together, concatenating both trips arrays
 * @param {Object} prev - prev state
 * @param {Object} next - next state
 * @return {Object} new state
 */
export const mergeResults = (prev, next) => {
  const result = Object.assign({}, prev);
  Object.keys(next).forEach((key) => {
    result[key] = result[key]
      ? update(result[key], {
          trips: { $apply: (x) => _.union(x, next[key].trips) },
        })
      : next[key];
  });
  return result;
};

export const replaceTripId = (list, oldTripId, newTripId) => {
  return list.map((id) => (id === oldTripId ? newTripId : id));
};

export const updateTripIn = (collection, oldTripId, newTripId) => {
  const result = Object.assign({}, collection);
  Object.keys(collection).forEach((key) => {
    const { trips } = collection[key];
    if (!trips) return;
    result[key] = {
      ...result[key],
      trips: replaceTripId(trips, oldTripId, newTripId),
    };
  });
  return result;
};

export const actionHandlers = {
  [ADD_SEARCH_RESULTS]: (state, results = {}) => {
    const trips = {
      ...state.trips,
      ...results.trips,
    };
    return {
      trips,
      alerts: results.alerts || [],
      categories: mergeResults(state.categories, results.categories),
      providers: setProviders(mergeResults(state.providers, results.providers)),
      tags: mergeResults(state.tags, results.tags),
      amenities: mergeResults(state.amenities, results.amenities),
      available: doFilterResults(state.activeFilters, trips),
      search_id: state.search_id || results.searchId,
      // ^ protect the overwrite of the id by public transit search request
      search_expiration: results.expiresAt,
    };
  },

  [DO_SEARCH]: () => initialState,
  [LOAD_SEARCH_FORM]: () => initialState,

  [END_SEARCH]: (state, { errored }) => ({
    searching: false,
    available: doFilterResults(
      state.activeFilters,
      state.trips,
      state.recommendedTripIds
    ),
    errored,
  }),
  [OUTDATE_SEARCH]: (state, outdated = true) => ({
    ...state,
    outdated: !!outdated,
  }),
  [TOGGLE_SEARCH_CATEGORY]: (state, category) => {
    return updateFilterResult(state, {
      categories: toggleFilter(state.activeFilters.categories, category),
    });
  },

  [TOGGLE_SEARCH_CATEGORIES_ALL]: (state) => {
    return updateFilterResult(state, {
      categories: { $set: {} },
    });
  },

  [SELECT_SEARCH_SORT_OPTION]: (state, sortId) => {
    return updateFilterResult(state, {
      sort: { $set: sortId },
    });
  },

  [TOGGLE_SEARCH_AMENITY]: (state, amenityId) => {
    return updateFilterResult(state, {
      amenities: toggleFilter(state.activeFilters.amenities, amenityId),
    });
  },

  [TOGGLE_SEARCH_TAG]: (state, tagType) => {
    return updateFilterResult(state, {
      tags: toggleFilter(state.activeFilters.tags, tagType),
    });
  },

  [TOGGLE_SEARCH_PROVIDER]: (state, providerId) => {
    return updateFilterResult(state, {
      providers: toggleFilter(state.activeFilters.providers, providerId),
    });
  },

  [TOGGLE_MEET_AND_GREET_FILTER]: (state, active) => {
    return updateFilterResult(
      state,
      {
        ...state.activeFilters,
        meetAndGreet: active,
      },
      false
    );
  },

  [FILTER_SUITCASE_COUNT]: (state, count) => {
    return updateFilterResult(
      state,
      {
        ...state.activeFilters,
        suitcaseCount: count,
      },
      false
    );
  },

  [UPDATE_SEARCH_FILTERS]: (state, nextFilters) => {
    return updateFilterResult(
      state,
      {
        ...state.activeFilters,
        ...nextFilters,
      },
      false
    );
  },

  [SET_RECOMMENDED_TRIP]: (state, recommendations = []) => {
    const recommendedIds = recommendations.map((trip) => trip.trip_id);

    const updatedState = {
      ...state,
      tags: {
        ...state.tags,
        recommended: {
          type: 'recommended',
          trips: recommendedIds,
        },
      },
      recommendedTripIds: recommendedIds,
      trips: Object.values(state.trips)
        .map((trip) => {
          if (!recommendedIds.includes(trip.id)) return trip;
          const recommendedResult = recommendations.find(
            ({ trip_id }) => trip.id === trip_id
          );

          return {
            ...trip,
            tags: [
              ...trip.tags,
              ...(recommendedResult.tags && recommendedResult.tags.length > 0
                ? recommendedResult.tags.map((t) => ({
                    type: t.name,
                    text: t.description,
                    priority: 0,
                  }))
                : [{ type: 'recommended', priority: 0 }]),
            ],
            rules_strings: recommendedResult.rules_strings,
          };
        })
        .reduce((res, trip) => ({ ...res, [trip.id]: trip }), {}),
    };
    return updatedState;
  },

  [SAVE_SEARCH]: (state, search) => ({
    search,
    linked: true,
  }),

  [SET_SCHEDULED_DEPARTURE]: (state, action) => {
    const { tripId, departureTicket, returnTicket, updatedTrip, bookTripForm } =
      action;

    if (bookTripForm) return state;

    let { [tripId]: trip, ...trips } = state.trips;
    const result = {};

    if (updatedTrip) {
      const newTripId = updatedTrip.id;
      const oldTripId = trip.id;
      Object.assign(result, {
        categories: updateTripIn(state.categories, oldTripId, newTripId),
        providers: updateTripIn(state.providers, oldTripId, newTripId),
        tags: updateTripIn(state.tags, oldTripId, newTripId),
        amenities: updateTripIn(state.amenities, oldTripId, newTripId),
        available: replaceTripId(state.available, oldTripId, newTripId),
      });
      trip = updatedTrip;
    }

    return Object.assign(result, {
      trips: {
        ...trips,
        [trip.id]: {
          ...trip,
          price: {
            ...trip.price,
            loading: true,
          },
          departureTicket,
          returnTicket,
        },
      },
    });
  },

  [UPDATE_SCHEDULED_TRIP_PRICE]: (state, { tripId, formattedPrice }) => {
    const { [tripId]: trip, ...trips } = state.trips;

    const result = {
      ...state,
      trips: {
        ...trips,
        [trip.id]: {
          ...trip,
          price: {
            ...trip.price,
            total: formattedPrice ? formattedPrice : trip.price.total,
            loading: false,
          },
        },
      },
    };

    return result;
  },
};

export default createReducer(initialState, actionHandlers);
