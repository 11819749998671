import { GET_USER_SAGA_HOOK } from 'app/constants';
import { select, call } from 'redux-saga/effects';
import { isTravelAgent, getUser } from 'app/sagas/selectors';
import { doGetTravelAgent } from '../watchTravelAgentTool/getTravelAgentProfile';
import { hook } from 'app/utils/hook';
import _ from 'lodash';

export default function* getUserData() {
  const user = yield select(getUser);
  const isTA = yield select(isTravelAgent);

  if (_.isEmpty(user)) {
    return;
  }
  if (isTA) {
    yield call(doGetTravelAgent);
  }

  yield hook(GET_USER_SAGA_HOOK);
}
