import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { ADD_ACCOUNT_ID, REMOVE_ACCOUNT_ID } from 'app/constants';
import addAccountId from './addAccountId';
import removeAccountId from './removeAccountId';

/**
 * Saga for actions related to `book for someone else` field in the BTP
 */
export default function* watchAccountId() {
  yield fork(takeLatest, ADD_ACCOUNT_ID, addAccountId);
  yield fork(takeLatest, REMOVE_ACCOUNT_ID, removeAccountId);
}
