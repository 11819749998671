// @flow
import { styled } from 'mz-ui-kit';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
});

export const ProviderHeader = styled('h2', {
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
  fontSize: '24px',
  marginRight: '10px',
});

export const Name = styled('div', {
  marginBottom: '5px',
  ':after': {
    content: '"•"',
    marginLeft: '10px',
    marginRight: '10px',
  },
  ':first-child:last-child': {
    ':after': { display: 'none' },
  },
  ':last-child': {
    ':after': { display: 'none' },
  },
});

export const Badges = styled('div', {
  display: 'flex',
  flexFlow: 'row wrap',
  alignItems: 'center',
});

export const Badge = styled('div', {
  marginRight: '5px',
  marginBottom: '5px',
});
