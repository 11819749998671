import {
  UPDATE_RIDE_POSITION,
  TRACK_RIDE,
  SET_RESERVATION_DATA,
  SET_RIDE_DATA,
  SET_TRACKING_MAP_LOADING,
} from '../constants';

import { createAction } from '../utils/redux';

export const updateRidePosition = createAction(UPDATE_RIDE_POSITION);

export const trackRide = createAction(TRACK_RIDE);

export const setReservationData = createAction(SET_RESERVATION_DATA);

export const setRideData = createAction(SET_RIDE_DATA);

export const setMapLoading = createAction(SET_TRACKING_MAP_LOADING);
