import React from 'react';

import { ROUTES_CONFIG_HOOK } from 'app/constants';
import { hook } from 'app/utils/hook';
import AppPage from 'app/pages/AppPage';
import LocaleSwitch from './LocaleSwitch';

import mainRoutes from './mainRoutes';
import userRoutes from './userRoutes';
import travelAgentRoutes from './travelAgentToolRoutes';
import travelAgentSplashRoutes from './travelAgentSplashRoutes';

const Routes = () => {
  const defaultRoutes = [
    ...userRoutes(),
    ...travelAgentRoutes(),
    ...travelAgentSplashRoutes(),
    ...mainRoutes(),
  ];

  const renderedPage = (
    <AppPage>
      <LocaleSwitch routes={defaultRoutes} />
    </AppPage>
  );

  return hook({
    name: ROUTES_CONFIG_HOOK,
    args: [defaultRoutes],
    defaultHandler: () => renderedPage,
  });
};

export default Routes;
