const LOYALTY = 'loyalty';

export default [
  {
    name: 'LOYALTY_PROGRAM_ENABLED',
    type: 'bool',
    default: false,
    description: 'Enable loyalty program',
    category: LOYALTY,
  },
  {
    name: 'LOYALTY_PROGRAM_COLOR',
    type: 'color',
    default: '#0700b9',
    description: 'Loyalty program color',
    category: LOYALTY,
  },
  {
    name: 'LOYALTY_PROGRAM_NAME',
    type: 'string',
    default: '',
    description: 'Name of the loyalty program',
    category: LOYALTY,
  },
  {
    name: 'LOYALTY_PROGRAM_NUMBER_MAX_LEN',
    type: 'string',
    default: '',
    description: 'Maximum length for loyalty program membership number',
    category: LOYALTY,
  },
  {
    name: 'LOYALTY_PROGRAM_NUMBER_ONLY_DIGITS',
    type: 'bool',
    default: false,
    description: 'Allow to type only digits in the membership number field',
    category: LOYALTY,
  },
  {
    name: 'LOYALTY_PROGRAM_NUMBER_REQUIRED',
    type: 'bool',
    default: false,
    description: 'Make the membership number field to be required',
    category: LOYALTY,
  },
];
