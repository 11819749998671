import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import { yiqContrast } from 'app/utils/style';

import style from './style.css';

const Step = ({
  icon,
  description,
  color = '#ffffff',
  recommended,
  className,
  publicTransit,
  mobile,
}) => {
  const iconsMap = {
    walk: <Icon family="mozio" name="walk" className={style.walkIcon} />,
    bus: <Icon family="mozio" name="bus" />,
    rail: <Icon family="mozio" name="train" />,
  };
  const stepIcon = iconsMap[icon];

  // Transit description className calculations
  // calculate constrast, only works for HTML color codes, which should be fine
  const bgColor = recommended && color === '#ffffff' ? '#efefef' : color;

  const descStyle = {
    backgroundColor: bgColor,
    color: yiqContrast(bgColor.slice(1), '#444'),
  };
  const useBackgroundColor = !!description && color !== '#ffffff';
  const descriptionClassName = classnames({
    [style.plainDesc]: publicTransit && !useBackgroundColor,
    [style.coloredDesc]: publicTransit && useBackgroundColor,
    [style['coloredDesc--mobile']]:
      publicTransit && useBackgroundColor && !!mobile,
    [style['plainDesc--mobile']]:
      publicTransit && !useBackgroundColor && !!mobile,
  });

  const modifiers = {
    [style['step--mobile']]: !!mobile,
    [style['step--small']]: !publicTransit,
  };

  return (
    <Layout
      className={classnames(style.step, className, modifiers)}
      align="start center"
      nowrap
      direction="row"
    >
      {icon && (!mobile || icon === 'walk') && stepIcon}
      {!!description && (
        <span style={descStyle} className={descriptionClassName}>
          {description}
        </span>
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// Step.propTypes = {
//   description: PropTypes.node,
//   icon: PropTypes.oneOf([
//     'walk',
//     'bus',
//     'car',
//     'ferry',
//     'rail'
//   ]),
//   color: PropTypes.string,
//   recommended: PropTypes.bool,
//   publicTransit: PropTypes.bool,
//   mobile: PropTypes.bool,
//   className: PropTypes.string
// };

export default Step;
