import React from 'react';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';

import style from './style.css';

const Image = ({
  src,
  alt,
  circled,
  bezel,
  bezelClassName,
  className,
  onError,
}) => (
  <Layout className={style.container}>
    <div
      className={classnames({
        [className]: !!className,
        [style['circled-container']]: circled,
      })}
    >
      <img
        className={classnames(
          {
            [style.circled]: circled,
          },
          style.image
        )}
        src={src}
        alt={alt}
        onError={onError}
      ></img>
    </div>
    {bezel && (
      <div
        className={classnames(style.bezel, bezelClassName, {
          [style.circledBezel]: circled,
        })}
      />
    )}
  </Layout>
);

// TODO: move to Flow types
// Image.propTypes = {
//   src: PropTypes.string.isRequired,
//   bezel: PropTypes.bool,
//   bezelClassName: PropTypes.string,
//   alt: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.object
//   ]).isRequired,
//   className: PropTypes.string,
//   circled: PropTypes.bool,
//   onError: PropTypes.func
// };

export default Image;
