import _ from 'lodash';
import { put, call, race, take } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { pickups, APIError } from 'mz-sdk';
import {
  showErrorNotification,
  showWarningNotification,
  handleRefParam,
} from 'app/sagas/utils';
import { isLeaveTrackingPage } from 'app/sagas/patterns';
import { getMainStep } from 'app/utils/trip';
import {
  setMapLoading,
  setReservationData,
  setRideData,
  updateRidePosition,
} from 'app/actions/tracking';

import getReservation from './getReservation';

const breakStatuses = [
  'cancelled-by-user',
  'cancelled-by-driver',
  'payment-auth-failed',
  'completed',
  'failed',
  'lost',
];

const MICRO_POLLING_INTERVAL = 700;
const SHORT_POLLING_INTERVAL = 3000;
const LONG_POLLING_INTERVAL = 7000;

export function* pollRideLocation(id, ondemand) {
  let maxErrorCount = 10;
  while (true) {
    let rideInfo;
    try {
      rideInfo = yield call(pickups.getPosition, { id, ondemand: !!ondemand });
    } catch (e) {
      if (!maxErrorCount--) break;
      yield call(delay, LONG_POLLING_INTERVAL);
      continue;
    }

    if (!rideInfo) {
      yield call(showWarningNotification, { messageId: 'TRACKING.CANT_TRACK' });
      yield call(delay, 15000);
      continue;
    }

    const { location, path } = rideInfo;
    const position = {
      eta: rideInfo.eta,
      status: rideInfo.status,
    };

    let hasSteps = false;
    if (Array.isArray(path) && path.length > 1) {
      hasSteps = true;

      // If we have a path, then smoothen the trajectory.
      for (let i = 0; i < path.length - 1; i++) {
        position.lat = path[i].lat;
        position.lng = path[i].lng;
        yield put.sync(updateRidePosition(position));
        yield call(delay, MICRO_POLLING_INTERVAL);
      }
    }
    // The last point uses the location object anyway.
    position.lat = location ? location.lat : null;
    position.lng = location ? location.lng : null;

    yield put.sync(updateRidePosition(position));

    // We break polling if trip has stopped.
    if (breakStatuses.indexOf(rideInfo.status) !== -1) {
      break;
    }

    yield call(
      delay,
      hasSteps ? MICRO_POLLING_INTERVAL : SHORT_POLLING_INTERVAL
    );
  }
}

export function* pollVehicleInfo(id, ondemand) {
  let maxErrorCount = 10;
  while (true) {
    let vehicleInfo = null;
    try {
      vehicleInfo = yield call(pickups.getInfo, { id, ondemand: !!ondemand });
    } catch (e) {
      if (!maxErrorCount--) break;
      yield call(delay, LONG_POLLING_INTERVAL);
      continue;
    }

    if (vehicleInfo) {
      yield put(setRideData(vehicleInfo));
    }

    // We break polling if trip has stopped.
    if (vehicleInfo && breakStatuses.indexOf(vehicleInfo.status) !== -1) {
      break;
    }

    yield call(delay, LONG_POLLING_INTERVAL);
  }
}

/**
 * Saga for searching trips
 */
export default function* trackRide(action) {
  const { trackingId, ondemand } = action.payload;

  yield call(handleRefParam);
  yield put(setMapLoading());
  // Get reservations in parallel
  const reservation = yield !!trackingId &&
    call(getReservation, trackingId, !!ondemand);

  // When no reservation received, just redirect to home page
  if (_.isEmpty(reservation)) {
    // Show error tracking page.
    yield call(showErrorNotification, {
      messageId: 'TRACKING.BOOKING_NOT_FOUND',
    });
    yield put(setReservationData({}));
    return;
  }

  const { trip, pickupTime } = reservation;

  // Defaulting to main step for now.
  const mainStep = getMainStep(trip);

  // Extract address data.
  const {
    from: { location: startAddress },
    to: { location: endAddress },
  } = mainStep;

  yield put(
    setReservationData({
      startAddress,
      endAddress,
      trackingId,
      trip,
      pickupTime,
    })
  );

  if (trip.cancelled) return;

  try {
    yield race({
      polling: [
        call(pollRideLocation, trackingId, !!ondemand),
        call(pollVehicleInfo, trackingId, !!ondemand),
      ],
      locationChange: take(isLeaveTrackingPage),
    });
  } catch (error) {
    if (!(error instanceof APIError)) {
      console.error(error); // eslint-disable-line no-console
    }
  }
}
