import React, { Component } from 'react';
import Button from 'app/components/base/Button';
import moment from 'moment';
import classnames from 'classnames';
import style from './style.css';

class Tile extends Component {
  handleClick = (event) => {
    const { value, onClick } = this.props;
    if (!moment.isMoment(value)) return;
    event.preventDefault();
    onClick(value);
  };

  render() {
    const { value, disabled, active, target } = this.props;
    return (
      <Button
        round
        clear
        alt
        disabled={disabled}
        align="center center"
        className={classnames(style.tile, {
          [style.label]: !moment.isMoment(value),
          [style.active]: active,
          [style.target]: target,
        })}
        onClick={this.handleClick}
      >
        {moment.isMoment(value) ? value.date() : value}
      </Button>
    );
  }
}

// TODO: move to Flow types
// Tile.propTypes = {
//   onClick: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   active: PropTypes.bool,
//   target: PropTypes.bool,
//   value: PropTypes.oneOfType([
//     PropTypes.object,
//     PropTypes.string
//   ]).isRequired
// };

export default Tile;
