import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { isFlightValidation } from 'app/sagas/patterns';
import { adjustPickupTimeByFlight } from './adjustPickupTimeByFlight';

/**
 * Watch changes in the flight information
 */
export default function* watchFlightInformation() {
  yield fork(takeLatest, isFlightValidation, adjustPickupTimeByFlight);
}
