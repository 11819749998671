// @flow
import React from 'react';
import ArrowRight from 'mz-icons/chevron-right';
import { Button } from '../Navbar';
import config from 'config';
import { ArrowWrapper, ButtonContainer } from './elements';

import type { LanguageDescriptor } from 'mz-intl/core/languages';

type Props = {
  activeLanguage: LanguageDescriptor,
  appearance: 'desktop' | 'mobile',
  open: () => {},
};

const LanguageButton = ({ activeLanguage, appearance, open }: Props) => {
  const isDarkMode = config.COLORS_DARK_MODE;
  const isDesktop = appearance === 'desktop';

  return (
    <ButtonContainer $desktop={isDesktop}>
      <Button onClick={open}>
        {activeLanguage.text}
        <ArrowWrapper $darkMode={isDarkMode} $desktop={isDesktop}>
          <ArrowRight size="xs" rotate={isDesktop && '90deg'} />
        </ArrowWrapper>
      </Button>
    </ButtonContainer>
  );
};

export default LanguageButton;
