import React, { Component } from 'react';
import { Layout } from 'app/components/base/Layout';
import { findDOMNode } from 'react-dom';
import Item from './Item';
import style from './style.css';
import CN from 'classnames';
import makeScrollableNavigation from 'app/hocs/makeScrollableNavigation';

class OptionList extends Component {
  listRef = (c) => {
    this.scrollableListDOM = c;
  };

  itemRef = (isSelected, isFocused) => (c) => {
    if (isSelected) {
      this.selectedItemDOM = findDOMNode(c);
    }
    if (isFocused) {
      this.focusedItemDOM = findDOMNode(c);
    }
  };

  renderItem = (item, index) => {
    const { value, focused, template, clear } = this.props;
    const isActive = value === item;
    const isFocused = index === focused;
    return (
      <Item
        option={item}
        onClick={this.props.onChange}
        ref={this.itemRef(isActive, isFocused)}
        template={template}
        active={isActive}
        focused={isFocused}
        clear={clear}
        key={index}
      />
    );
  };

  render() {
    const { options, centered, wrap } = this.props;
    return (
      <Layout
        direction="column"
        nowrap
        className={CN(style.list, {
          [style.centered]: centered,
          [style.wrap]: wrap,
        })}
        ref={this.listRef}
      >
        {options.map(this.renderItem)}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// OptionList.propTypes = {
//   options: PropTypes.array.isRequired,
//   className: PropTypes.string,
//   template: PropTypes.func,
//   focused: PropTypes.number,
//   centered: PropTypes.bool,
//   wrap: PropTypes.bool,
//   clear: PropTypes.bool,
//   value: PropTypes.any,
//   onChange: PropTypes.func.isRequired
// };

export default makeScrollableNavigation(OptionList);
