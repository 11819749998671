import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { show3DSFrame, hide3DSFrame } from 'app/actions/creditcards';
import { showErrorNotification, ThirdPartyError } from 'app/sagas/utils';

/**
 * Create 3DS validation process.
 * Creates 3DS source and polls the source until is chargeable.
 * Otherwise, returns the corresponding error.
 */
export default function* authorize3DSCard(stripe, three_d_secure, price) {
  let result;
  try {
    result = yield call(stripe.createSource, {
      type: 'three_d_secure',
      three_d_secure,
      amount: price.amount,
      currency: price.currency,
      redirect: { return_url: 'https://www.mozio.com/3ds_success.html' },
    });
  } catch (e) {
    yield call(showErrorNotification, { error: e });
    return null;
  }

  const { source, error: srcError } = result;

  if (srcError) {
    yield call(showErrorNotification, {
      error: new ThirdPartyError(
        srcError.message,
        srcError.code,
        srcError.type
      ),
    });
    return null;
  }

  // Show the 3DS Popup.
  yield put(show3DSFrame(source.redirect.url));

  const { id, client_secret } = source;
  while (true) {
    const { source: retrieved, error } = yield call(stripe.retrieveSource, {
      id,
      client_secret,
    });
    if (error) {
      yield call(showErrorNotification, {
        error: new ThirdPartyError(error.message, error.code, error.type),
      });
      return null;
    }

    switch (retrieved.status) {
      case 'pending':
        break;
      case 'chargeable':
        // Hide 3DS popup.
        yield put(hide3DSFrame(retrieved.redirect.url));
        return retrieved;
      case 'failed':
      default:
        // Hide 3DS popup.
        yield put(hide3DSFrame(retrieved.redirect.url));
        yield call(showErrorNotification, {
          messageId: 'THREE_D_SECURE.DECLINED',
        });
        return null;
    }

    yield call(delay, 500);
  }
}
