import React from 'react';
import classnames from 'classnames';
import NavbarButton from './NavbarButton';
import NavbarButtonsGroup from './NavbarButtonsGroup';
import Icon from '../Icon';

import style from './style.css';

/**
 * Hamburger toggle button for openning/closing collapsible navigation bar
 * @return {Component}
 */
export const NavbarHamburger = ({ className, opened, ...props }) => (
  <NavbarButtonsGroup position="right">
    <NavbarButton
      {...props}
      className={classnames(className, style.hamburger, {
        [style['hamburger--opened']]: opened,
      })}
    >
      <Icon className={style.hamburger__icon}>menu</Icon>
    </NavbarButton>
  </NavbarButtonsGroup>
);

// TODO: move to Flow types
// NavbarHamburger.propTypes = {
//   className: PropTypes.string,
//   opened: PropTypes.bool
// };

// A workaround for the issue (impossible to check type of element
// in conditional render method, like in NavigationContainer)
// https://github.com/gaearon/react-hot-loader/issues/304
NavbarHamburger.isHamburger = true;

export default NavbarHamburger;
