import React from 'react';
import { connect } from 'react-redux';
import config from 'config';
import { setLocale } from 'app/history';
import { Layout } from 'app/components/base/Layout';
import Header from 'app/components/base/Header';
import * as appActions from 'app/actions/app';
import * as userActions from 'app/actions/user-session';
import {
  getActiveLanguage,
  getActiveCurrency,
  getLocation,
} from 'app/sagas/selectors';

import style from './style.css';

class Navigation extends React.Component {
  state = {
    open: false,
  };

  handleToggleNavbar = () => {
    this.setState({ open: !this.state.open });
  };

  handleSelectCurrency = (currency) => {
    const { setCurrency } = this.props;

    setCurrency({ currency: currency.code, changedByUser: true });
  };

  handleClickLogin = () => {
    const { saveRedirectBackUrl, location } = this.props;
    saveRedirectBackUrl(location);
  };

  render() {
    const { languages, activeLanguage, activeCurrency, user, logout } =
      this.props;

    const { open } = this.state;

    return (
      <Layout align="center center" className={style.container}>
        <Header
          className={style.header}
          loggedIn={!!user}
          loggedInUser={user ? user.first_name : null}
          userType={user ? user.user_type : null}
          userLinks={config.USER_NAVIGATION_SHOW}
          showSigninButton={config.SHOW_SIGNIN_BUTTON}
          opened={open}
          activeCurrency={activeCurrency}
          activeLanguage={activeLanguage}
          activeTab={null}
          onToggleNavbar={this.handleToggleNavbar}
          languages={languages}
          onClickLogin={this.handleClickLogin}
          onSelectLanguage={setLocale}
          onSelectCurrency={this.handleSelectCurrency}
          onLogout={logout}
        />
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Navigation.propTypes = {
//   languages: PropTypes.object,

//   // State
//   user: PropTypes.object,
//   activeLanguage: PropTypes.string,
//   activeCurrency: PropTypes.string,
//   location: PropTypes.object,

//   // Actions
//   setCurrency: PropTypes.func.isRequired,
//   logout: PropTypes.func.isRequired,
//   saveRedirectBackUrl: PropTypes.func.isRequired
// };

const mapStateToProps = (state) => {
  const {
    userSession: { user },
  } = state;

  return {
    user,
    activeLanguage: getActiveLanguage(state),
    activeCurrency: getActiveCurrency(state),
    location: getLocation(state),
  };
};

const mapDispatchToProps = {
  ...appActions,
  ...userActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
