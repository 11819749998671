import { put, call, select } from 'redux-saga/effects';
import formConfig from 'app/forms/search-form';
import { getSearchForm } from 'app/sagas/selectors';
import { initialize } from 'redux-form';
import resolveFormValues from './resolveFormValues';
import { LOAD_SEARCH_FORM_HOOK } from 'app/constants';
import { hook } from 'app/utils/hook';

/**
 * Saga for loading search form data and prepare it to be used
 * in the search form. Provided action should be generated by
 * `app/history` and contain `search` and `pathname` in the payload.
 */
export default function* loadSearchForm() {
  let values = yield select(getSearchForm);
  if (values === formConfig.initialValues) {
    values = yield call(resolveFormValues);
    yield put(
      initialize(formConfig.form, {
        ...formConfig.initialValues,
        ...values,
      })
    );
  }

  yield hook(LOAD_SEARCH_FORM_HOOK);
  return values;
}
