import React from 'react';
import { FormattedMessage } from 'translations';
import Tooltip from './Tooltip';

import style from './style.css';

const messages = {
  start: { id: 'SEARCH_RESULTS.MAP.START_MARKER' },
};

const StartPoint = () => (
  <div className={style.startPoint}>
    <Tooltip text={<FormattedMessage {...messages.start} />} />
  </div>
);

export default StartPoint;
