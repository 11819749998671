import { styled } from 'mz-ui-kit';

export const Container = styled('div', {});

export const Title = styled('div', {
  marginBottom: '10px',
});

export const TitleContent = styled('span', ({ $palette }) => ({
  marginLeft: '5px',
  color: `${$palette('TEXT')}`,
}));

export const PickupLocation = styled('div', ({ $palette }) => ({
  paddingBottom: '14px',
  fontSize: '14px',
  color: `${$palette('TEXT')}`,
}));

export const Section = styled('div', ({ $palette }) => ({
  display: 'flex',
  padding: '14px 0',
  borderTop: `1px solid ${$palette('GRAY')}`,
  borderBottom: `1px solid ${$palette('GRAY')}`,
}));

export const Column = styled('div', {
  width: '50%',
});

export const Content = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

export const Icon = styled('div', ({ $palette }) => ({
  color: `${$palette('GREEN')}`,
  fontSize: '22px',
  marginRight: '5px',
}));

export const Label = styled('div', ({ $palette }) => ({
  fontSize: '14px',
  fontWeight: 'bold',
  color: `${$palette('GREEN')}`,
  marginBottom: '4px',
}));
