// App
export const SET_CURRENCY = 'SET_CURRENCY';
export const INIT_APP = 'INIT_APP';
export const CRITICAL_ERROR = 'CRITICAL_ERROR';

// Subpages
export const REMOVE_ALL_SUBPAGES = 'REMOVE_ALL_SUBPAGES';

// Home traveler
export const SET_CURRENT_TRAVELER = 'SET_CURRENT_TRAVELER';
export const SEARCH_FOR_PARKING_SPOT = 'SEARCH_FOR_PARKING_SPOT';

// User
export const GET_LOGGED_STATE = 'GET_LOGGED_STATE';
export const SET_USER = 'SET_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SOCIAL = 'LOGIN_USER_SOCIAL';
export const REGISTER_USER = 'REGISTER_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const RESET_PASSWORD_USER = 'RESET_PASSWORD_USER';
export const REACTIVATE_ACCOUNT = 'REACTIVATE_ACCOUNT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const SUSPEND_ACCOUNT = 'SUSPEND_ACCOUNT';
export const SET_REDIRECT_BACK_URL = 'SET_REDIRECT_BACK_URL';
export const CONFIRM_EMAIL_ADDRESS = 'CONFIRM_EMAIL_ADDRESS';
export const UPDATE_USER_INFORMATION = 'UPDATE_USER_INFORMATION';
export const SET_USER_DATA_READY = 'SET_USER_DATA_READY';
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const SET_RESERVATIONS_FILTER = 'SET_RESERVATIONS_FILTER';
export const SET_ACTIVE_RESERVATIONS_TAB = 'SET_ACTIVE_RESERVATIONS_TAB';

// Reservations
export const GET_RESERVATIONS = 'GET_RESERVATIONS';
export const SET_RESERVATIONS = 'SET_RESERVATIONS';
export const GET_PAST_RESERVATIONS = 'GET_PAST_RESERVATIONS';
export const SET_PAST_RESERVATIONS = 'SET_PAST_RESERVATIONS';
export const ADD_RESERVATIONS = 'ADD_RESERVATIONS';
export const ADD_PAST_RESERVATIONS = 'ADD_PAST_RESERVATIONS';

// CreditCard Form
export const SELECT_CREDIT_CARD = 'SELECT_CREDIT_CARD';
export const ADD_CREDIT_CARD = 'ADD_CREDIT_CARD';
export const DELETE_CREDIT_CARD = 'DELETE_CREDIT_CARD';
export const UPDATE_CREDIT_CARD = 'UPDATE_CREDIT_CARD';
export const GET_CREDIT_CARDS = 'GET_CREDIT_CARDS';
export const SET_CREDIT_CARDS = 'SET_CREDIT_CARDS';
export const TOGGLE_THREE_D_SECURE_LIGHTBOX = 'TOGGLE_THREE_D_SECURE_LIGHTBOX';
export const THREE_D_SECURE_SHOW_IFRAME = 'THREE_D_SECURE_SHOW_IFRAME';
export const THREE_D_SECURE_HIDE_IFRAME = 'THREE_D_SECURE_HIDE_IFRAME';

// Search Results Constants
export const DO_SEARCH = 'DO_SEARCH';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SEARCH_RESULTS_SHOW_MORE = 'SEARCH_RESULTS_SHOW_MORE';
export const LOAD_SEARCH_RESULTS = 'LOAD_SEARCH_RESULTS';
export const LOAD_SEARCH_FORM = 'LOAD_SEARCH_FORM';
export const END_SEARCH = 'END_SEARCH';
export const SAVE_SEARCH = 'SAVE_SEARCH';
export const SET_RECOMMENDED_TRIP = 'SET_RECOMMENDED_TRIP';
export const ADD_SEARCH_RESULTS = 'ADD_SEARCH_RESULTS';
export const TOGGLE_SEARCH_CATEGORY = 'TOGGLE_SEARCH_CATEGORY';
export const SELECT_SEARCH_SORT_OPTION = 'SELECT_SEARCH_SORT_OPTION';
export const TOGGLE_SEARCH_AMENITY = 'TOGGLE_SEARCH_AMENITY';
export const TOGGLE_SEARCH_TAG = 'TOGGLE_SEARCH_TAG';
export const TOGGLE_SEARCH_PROVIDER = 'TOGGLE_SEARCH_PROVIDER';
export const TOGGLE_SEARCH_CATEGORIES_ALL = 'TOGGLE_SEARCH_CATEGORIES_ALL';
export const TOGGLE_MEET_AND_GREET_FILTER = 'TOGGLE_MEET_AND_GREET_FILTER';
export const UPDATE_SEARCH_FILTERS = 'UPDATE_SEARCH_FILTERS';
export const CANCEL_SEARCH = 'CANCEL_SEARCH';
export const SWAP_LOCATIONS = 'SWAP_LOCATIONS';
export const OUTDATE_SEARCH = 'OUTDATE_SEARCH';
export const SHARE_RESULT_EMAIL = 'SHARE_RESULT_EMAIL';
export const COPY_RESULT_URL = 'COPY_RESULT_URL';
export const FILTER_SUITCASE_COUNT = 'FILTER_SUITCASE_COUNT';

// Scheduled rides actions
export const SET_SCHEDULED_DEPARTURE = 'SET_SCHEDULED_DEPARTURE';
export const VIEW_EARLIER_TIMES = 'VIEW_EARLIER_TIMES';
export const VIEW_LATER_TIMES = 'VIEW_LATER_TIMES';
export const START_SCHEDULE_RESEARCH = 'START_SCHEDULE_RESEARCH';
export const END_SCHEDULE_RESEARCH = 'END_SCHEDULE_RESEARCH';
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE';
export const SET_SCHEDULE_UPDATE_ERROR = 'SET_SCHEDULE_UPDATE_ERROR';
export const UPDATE_SCHEDULED_TRIP_PRICE = 'UPDATE_SCHEDULED_TRIP_PRICE';

// SRP map
export const SELECT_TRIP_FOR_SRP_MAP = 'SELECT_TRIP_FOR_SRP_MAP';
export const TOGGLE_SRP_MAP = 'TOGGLE_SRP_MAP';
export const SET_SRP_MAP_DIRECTIONS = 'SET_SRP_MAP_DIRECTIONS';
export const SET_CACHED_DIRECTION = 'SET_CACHED_DIRECTION';
export const CLEAR_CACHED_DIRECTIONS = 'CLEAR_CACHED_DIRECTIONS';
export const CLEAR_DIRECTIONS = 'CLEAR_DIRECTIONS';

// Autocomplete
export const AUTOCOMPLETE_LOADING = 'AUTOCOMPLETE_LOADING';
export const AUTOCOMPLETE_RECEIVED = 'AUTOCOMPLETE_RECEIVED';
export const AUTOCOMPLETE_FAILED = 'AUTOCOMPLETE_FAILED';
export const AUTOCOMPLETE_TEXTING = 'AUTOCOMPLETE_TEXTING';
export const AUTOCOMPLETE_CLEANUP = 'AUTOCOMPLETE_CLEANUP';
export const AUTOCOMPLETE_CLIENT_FILTERED = 'AUTOCOMPLETE_CLIENT_FILTERED';
export const AUTOCOMPLETE_VALIDATE_COMPLETE = 'AUTOCOMPLETE_VALIDATE_COMPLETE';
export const AUTOCOMPLETE_VALIDATE_CLEAN = 'AUTOCOMPLETE_VALIDATE_CLEAN';

// Notifications
export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

// Book trip page
export const GET_CURRENT_TRIP = 'GET_CURRENT_TRIP';
export const REQUEST_DEPARTING_TRIP = 'REQUEST_DEPARTING_TRIP';
export const CHANGE_TRIP_MODE = 'CHANGE_TRIP_MODE';
export const SET_CHANGED_MODE_TRIP = 'SET_CHANGED_MODE_TRIP';
export const BOOK_CHANGED_MODE_TRIP = 'BOOK_CHANGED_MODE_TRIP';
export const ADD_COUPON = 'ADD_COUPON';
export const CLOSE_COUPON_HINT = 'CLOSE_COUPON_HINT';
export const CLOSE_BOOKING_FEE_HINT = 'CLOSE_BOOKING_FEE_HINT';
export const CLOSE_GRATUITY_HINT = 'CLOSE_GRATUITY_HINT';
export const TOGGLE_OPTIONAL_AMENITY = 'TOGGLE_OPTIONAL_AMENITY';
export const UPDATE_PRICE = 'UPDATE_PRICE';
export const UPDATE_PRICE_SUCCESS = 'UPDATE_PRICE_SUCCESS';
export const UPDATE_PRICE_FAIL = 'UPDATE_PRICE_FAIL';
export const SAVE_BOOKING_FEE = 'SAVE_BOOKING_FEE';
export const CONFIRM_BOOKING = 'CONFIRM_BOOKING';
export const SET_TRIP_ERROR = 'SET_TRIP_ERROR';
export const CLEAR_TRIP_ERROR = 'CLEAR_TRIP_ERROR';
export const DO_BOOK_TRIP = 'DO_BOOK_TRIP';
export const LOAD_BOOK_TRIP = 'LOAD_BOOK_TRIP';
export const UPDATE_TRIP = 'UPDATE_TRIP';
export const KEEP_ORIGINAL_PICKUP_TIME = 'KEEP_ORIGINAL_PICKUP_TIME';
export const SET_BOOK_TRIP_INIT_FLAG = 'SET_BOOK_TRIP_INIT_FLAG';
export const VALIDATE_FLIGHT_NUMBER = 'VALIDATE_FLIGHT_NUMBER';
export const CLEAR_FLIGHT_VALIDATION = 'CLEAR_FLIGHT_VALIDATION';
export const KEEP_FLIGHT = 'KEEP_FLIGHT';
export const CHANGE_FLIGHT = 'CHANGE_FLIGHT';
export const CHANGE_FLIGHT_AIRPORT = 'CHANGE_FLIGHT_AIRPORT';
export const CHANGE_FLIGHT_DATE = 'CHANGE_FLIGHT_DATE';
export const START_UPDATE_REQUEST = 'START_UPDATE_REQUEST';
export const END_UPDATE_REQUEST = 'END_UPDATE_REQUEST';
export const SHOW_PICKUP_TIME_CHANGED_NOTIFICATION =
  'SHOW_PICKUP_TIME_CHANGED_NOTIFICATION';
export const HIDE_PICKUP_TIME_CHANGED_NOTIFICATION =
  'HIDE_PICKUP_TIME_CHANGED_NOTIFICATION';
export const SET_CHARGE_IN_USD_MESSAGE = 'SET_CHARGE_IN_USD_MESSAGE';
export const ADD_ACCOUNT_ID = 'ADD_ACCOUNT_ID'; // Allows to book on behalf of another user
export const REMOVE_ACCOUNT_ID = 'REMOVE_ACCOUNT_ID';
export const BOOKING_EMAIL_TAKEN = 'BOOKING_EMAIL_TAKEN';
export const DESELECT_PROFILE = 'DESELECT_PROFILE';
export const GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS';
export const SET_TERMS_AND_CONDITIONS = 'SET_TERMS_AND_CONDITIONS';
export const GET_SUGGESTED_MEET_AND_GREET_TRIPS =
  'GET_SUGGESTED_MEET_AND_GREET_TRIPS';
export const SET_SUGGESTED_MEET_AND_GREET_TRIPS =
  'SET_SUGGESTED_MEET_AND_GREET_TRIPS';
export const UPDATE_MEET_AND_GREET_TRIP = 'UPDATE_MEET_AND_GREET_TRIP';
export const INCREMENT_BOOKING_ATTEMPTS = 'INCREMENT_BOOKING_ATTEMPTS';

// Favourite locations
export const SAVE_FAVOURITE_LOCATION = 'SAVE_FAVOURITE_LOCATION';
export const GET_FAVOURITE_LOCATIONS = 'GET_FAVOURITE_LOCATIONS';
export const SET_FAVOURITE_LOCATIONS = 'SET_FAVOURITE_LOCATIONS';
export const DELETE_FAVOURITE_LOCATION = 'DELETE_FAVOURITE_LOCATION';

// Confirmation page
export const INIT_CONFIRMATION_PAGE = 'INIT_CONFIRMATION_PAGE';
export const SET_RESERVATION = 'SET_RESERVATION';
export const CP_SET_LOADING = 'CP_SET_LOADING';
export const CLICK_PROMO = 'CLICK_PROMO';
export const SEND_APP_LINK = 'SEND_APP_LINK';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';

// Travel Agent Tool
export const GET_TA_ACCOUNT = 'GET_TA_ACCOUNT';
export const SET_TA_ACCOUNT = 'SET_TA_ACCOUNT';
export const GET_REPORT = 'GET_REPORT';
export const SUBMIT_TA_PROFILE = 'SUBMIT_TA_PROFILE';
export const TA_DELETE_PAYMENT_ACCOUNT = 'TA_DELETE_PAYMENT_ACCOUNT';
export const TA_ADD_PAYMENT_ACCOUNT = 'TA_ADD_PAYMENT_ACCOUNT';
export const TA_SELECT_PAYMENT_ACCOUNT = 'TA_SELECT_PAYMENT_ACCOUNT';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const TA_SELECT_CUSTOMER_PROFILE = 'TA_SELECT_CUSTOMER_PROFILE';
export const TA_GET_CUSTOMER_PROFILE_LIST = 'TA_GET_CUSTOMER_PROFILE_LIST';
export const TA_SET_CUSTOMER_PROFILE_LIST = 'TA_SET_CUSTOMER_PROFILE_LIST';
export const TA_GET_CUSTOMER_PROFILE = 'TA_GET_CUSTOMER_PROFILE';
export const TA_SET_CUSTOMER_PROFILE = 'TA_SET_CUSTOMER_PROFILE';
export const TA_CREATE_CUSTOMER_PROFILE = 'TA_CREATE_CUSTOMER_PROFILE';
export const TA_UPDATE_CUSTOMER_PROFILE = 'TA_UPDATE_CUSTOMER_PROFILE';
export const TA_DELETE_CUSTOMER_PROFILE = 'TA_DELETE_CUSTOMER_PROFILE';
export const TA_GET_RESERVATIONS = 'TA_GET_RESERVATIONS';
export const TA_SET_RESERVATIONS = 'TA_SET_RESERVATIONS';
export const TA_WITHDRAW_COMMISSIONS = 'TA_WITHDRAW_COMMISSIONS';
export const TA_GET_WITHDRAWALS = 'TA_GET_WITHDRAWALS';
export const TA_SET_WITHDRAWALS = 'TA_SET_WITHDRAWALS';

// Ride Tracking
export const TRACK_RIDE = 'TRACK_RIDE';
export const UPDATE_RIDE_POSITION = 'UPDATE_RIDE_POSITION';
export const SET_RESERVATION_DATA = 'SET_RESERVATION_DATA';
export const SET_RIDE_DATA = 'SET_RIDE_DATA';
export const SET_TRACKING_MAP_LOADING = 'SET_TRACKING_MAP_LOADING';

// Customer feedback
export const CUSTOMER_FEEDBACK_SUBMIT = 'CUSTOMER_FEEDBACK_SUBMIT';

// No Results
export const NO_RESULTS_SUBMIT_EMAIL = 'NO_RESULTS_SUBMIT_EMAIL';
