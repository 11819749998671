import React from 'react';
import { FormattedMessage } from 'mz-intl';
import HourlyLabel from 'app/components/common/HourlyLabel';
import DataField from './DataField';
import messages from 'app/components/search-results/Trip/messages.intl';
import {
  ModeTitle,
  RouteSection,
  Column,
  AddressWrapper,
  Circle,
} from './elements';

const HourlySummary = ({
  fromAddress,
  departureDatetime,
  hourlyDetails: { hoursRequested },
}) => (
  <div>
    <ModeTitle>
      <FormattedMessage message={messages.HOURLY} />
    </ModeTitle>
    <RouteSection>
      <Column>
        <DataField
          renderValue={() => departureDatetime}
          renderLabel={() => (
            <FormattedMessage message={messages.PICKUP_TIME} />
          )}
        />
        <DataField
          renderValue={() => <HourlyLabel hours={hoursRequested} />}
          renderLabel={() => (
            <FormattedMessage message={messages.RIDE_DURATION} />
          )}
        />
      </Column>
      <Column>
        <DataField
          renderValue={() => <AddressWrapper>{fromAddress}</AddressWrapper>}
          renderLabel={() => (
            <FormattedMessage message={messages.PICKUP_LOCATION} />
          )}
          renderLabelPrefix={() => <Circle />}
        />
      </Column>
    </RouteSection>
  </div>
);

export default HourlySummary;
