import createMessages from 'mz-intl/create';

export default createMessages('SEARCH_RESULTS', {
  ATTENTION: 'Attention',
  HOURLY_NOTICE: 'All prices are for entire duration of the trip.',
  SEARCH_BUTTON_TOOLTIP: 'Click search to update the results',
  SEARCH_NO_START_LOCATION: 'Search must have a start location',
  SEARCH_NO_END_LOCATION: 'Search must have an end location',
  INCOMPLETE_ADDRESS_TITLE: 'Incomplete Address',
  INCOMPLETE_ADDRESS_PARAGRAPH:
    'One of the addresses you provided is not specific enough. In order to give you the correct prices and availability please update the address below by selecting some specific building or hotel.',
  SEARCH_FORM_FROM_TITLE: 'From',
  SEARCH_FORM_TO_TITLE: 'To',
  INCORRECT_ADDRESS_SUBMIT_BUTTON: 'Update',
});
