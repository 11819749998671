import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { CLOSE_GRATUITY_HINT } from 'app/constants';
import setGratuity from './setGratuity';
import closeHint from './closeHint';
import { isBookTripGratuityChange } from 'app/sagas/patterns';

/**
 * Saga for actions related to gratuity in the book-trip page.
 */
export default function* watchGratuity() {
  yield fork(takeLatest, isBookTripGratuityChange, setGratuity);
  yield fork(takeLatest, CLOSE_GRATUITY_HINT, closeHint);
}
