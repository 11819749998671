import { cmd } from 'mangojuice-core';

// Constants
let updateHandlers = [];
let activeStore = null;

/**
 * Set active store in all instances of ReduxShared blocks
 * @param  {Store} store
 */
export const setActiveStore = (store) => {
  activeStore = store;
  updateHandlers.forEach((x) => x());
};

/**
 * Return the active store object. For tests
 * @return {Store}
 */
export const getActiveStore = () => activeStore;

export default class ReduxState {
  config(reduxState) {
    return { meta: { reduxState } };
  }

  port(exec, destroyed) {
    // Subscribe to active store changes
    let storeSub = null;
    const handleStoreReset = () => {
      const currState = this.meta.reduxState || activeStore;
      if (!currState) return;
      if (storeSub) storeSub();
      exec(this.SetInitState(currState.getState()));
      storeSub = currState.subscribe(() =>
        exec(this.SetNewState(currState.getState()))
      );
    };

    // Subscribe to change of the global store
    if (!this.meta.reduxState) {
      updateHandlers.push(handleStoreReset);
    }

    // Unsubscribe from store when block destroyed
    destroyed.then(() => {
      updateHandlers = updateHandlers.filter((x) => x !== handleStoreReset);
      if (storeSub) storeSub();
    });

    // Attach to already active store
    handleStoreReset();
  }

  @cmd SetInitState(initState) {
    return initState;
  }

  @cmd SetNewState(newState) {
    return newState;
  }
}
