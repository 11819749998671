import { call, put } from 'redux-saga/effects';
import { favouritePlaces } from 'mz-sdk';
import { setFavouriteLocations } from 'app/actions/favourite-locations';
import _ from 'lodash';

/**
 * Load favourite locations
 */
export default function* loadLocations(action) {
  try {
    const places = yield call(favouritePlaces.getAll);
    const sortedPlaces = _.sortBy(places, 'id');
    yield call(action.resolvePromise, sortedPlaces);
    yield put(setFavouriteLocations({ places: sortedPlaces }));
  } catch (error) {
    yield call(action.rejectPromise, error);
  }
}
