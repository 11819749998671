// @flow
import React from 'react';
import { FormattedMessage } from 'translations';
import SelectorButton from 'mz-ui-kit/core/SelectorButton';
import LanguageSelector from './LanguageSelector';
import LanguageButton from './LanguageButton';

import type { LanguageDescriptor } from 'mz-intl/core/languages';

type Props = {
  activeLanguage: string,
  languages: object,
  onSelect: (selectedLanguage: LanguageDescriptor) => {},
};

// Constants
const messages = {
  language: { id: 'LANGUAGE' },
};

const LanguageDropdown = ({ activeLanguage, languages, onSelect }: Props) => {
  const lang = languages[activeLanguage] || languages.en_US;

  return (
    <SelectorButton
      mobileTitle={<FormattedMessage {...messages.language} />}
      width="270px"
      renderSelector={(props) => (
        <LanguageSelector
          {...props}
          activeLanguage={lang}
          onSelect={onSelect}
          availableLanguages={Object.keys(languages)}
        />
      )}
      renderButton={(props) => (
        <LanguageButton {...props} activeLanguage={lang} />
      )}
    />
  );
};

LanguageDropdown.defaultProps = {
  activeLanguage: 'en_US',
};

export default LanguageDropdown;
