// @flow
import * as React from 'react';
import { LightboxBaseField, Footer } from 'mz-ui-kit/core/LightboxBaseField';
import { FormattedMessage } from 'mz-intl';
import { Button } from 'mz-ui-kit/core/Button';
import Alert from 'mz-ui-kit/core/Alert';
import Form from 'mz-ui-kit/form/Form';
import EmailField from 'mz-ui-kit/form/EmailField/field';
import * as Navbar from 'app/components/base/Navbar';
import resendConfirmationEmail from 'mz-sdk/services/user/resendConfirmationEmail';
import {
  Container,
  Text,
  Title,
  Success,
  Error,
  ButtonWrapper,
} from './elements';
import SuccessImage from './assets/success-image';
import messages from '../messages.intl';
import commonMessages from 'mz-ui-kit/messages.intl';
import generalMessages from 'translations/messages/next-messages.intl';

const LighboxContent = ({ cancel: closeDialog, setFocusableRef }: *) => {
  const [emailSent, setEmailStatus] = React.useState(false);

  const handleSubmit = ({ email }) => {
    return resendConfirmationEmail({ email }).then(() => setEmailStatus(true));
  };

  return (
    <Form
      onSubmit={handleSubmit}
      render={({ submit, meta }, nest) => (
        <form onSubmit={submit}>
          <Container>
            {!emailSent && (
              <>
                <FormattedMessage
                  tagName={Text}
                  message={messages.RESEND_BOOKING_TEXT}
                />
                <EmailField
                  {...nest('email')}
                  innerRef={setFocusableRef}
                  label={<FormattedMessage message={messages.EMAIL_LABEL} />}
                  required
                />
              </>
            )}
            {emailSent && (
              <>
                <Success>
                  <SuccessImage />
                </Success>
                <Text $centerText>
                  <FormattedMessage message={messages.RESEND_DONE} />
                </Text>
              </>
            )}
            {meta.error && (
              <Error>
                <Alert color="red">
                  <FormattedMessage
                    message={generalMessages['ERRORS.GENERAL_ERROR']}
                  />
                </Alert>
              </Error>
            )}
          </Container>
          <Footer>
            <ButtonWrapper>
              {!emailSent && (
                <Button
                  col={4}
                  type="submit"
                  loading={meta.loading}
                  onClick={submit}
                  fullWidth
                >
                  <FormattedMessage message={messages.SEND} />
                </Button>
              )}
              {emailSent && (
                <Button onClick={closeDialog} fullWidth>
                  <FormattedMessage message={commonMessages.OK} />
                </Button>
              )}
            </ButtonWrapper>
          </Footer>
        </form>
      )}
    />
  );
};

const ResendBookingButton = () => (
  <LightboxBaseField
    renderContainer={({ openLightbox }) => (
      <Navbar.Button onClick={openLightbox}>
        <FormattedMessage message={messages.RESEND_BOOKING} />
      </Navbar.Button>
    )}
    renderLightbox={(props) => <LighboxContent {...props} />}
    lightboxTitle={
      <FormattedMessage
        tagName={Title}
        message={messages.RESEND_CONFIRMATION_EMAIL}
      />
    }
  />
);

export default ResendBookingButton;
