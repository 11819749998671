import { createReducer } from '../utils/redux';
import {
  GET_RESERVATIONS,
  GET_PAST_RESERVATIONS,
  SET_RESERVATIONS,
  SET_PAST_RESERVATIONS,
  ADD_RESERVATIONS,
  ADD_PAST_RESERVATIONS,
  SET_RESERVATIONS_FILTER,
  PRESENT_RESERVATIONS_TAB,
  SET_ACTIVE_RESERVATIONS_TAB,
} from '../constants';

const initialState = {
  currentReservationsTab: 0,
  fetchingFuture: false,
  fetchingPast: false,
  moreComingFuture: true,
  moreComingPast: true,
  futureReservations: [],
  pastReservations: [],
  filters: new Map(),
  activeTab: PRESENT_RESERVATIONS_TAB,
};

const actionHandlers = {
  [GET_RESERVATIONS]: () => ({
    fetchingFuture: true,
  }),
  [GET_PAST_RESERVATIONS]: () => ({
    fetchingPast: true,
  }),
  [SET_RESERVATIONS]: (_, reservations) => ({
    futureReservations: reservations,
    fetchingFuture: false,
  }),
  [SET_PAST_RESERVATIONS]: (_, reservations) => ({
    pastReservations: reservations,
    fetchingPast: false,
  }),
  [ADD_RESERVATIONS]: ({ futureReservations }, { results, next }) => ({
    futureReservations: [...futureReservations, ...results],
    fetchingFuture: false,
    moreComingFuture: !!next,
  }),
  [ADD_PAST_RESERVATIONS]: ({ pastReservations }, { results, next }) => ({
    pastReservations: [...pastReservations, ...results],
    fetchingPast: false,
    moreComingPast: !!next,
  }),
  [SET_RESERVATIONS_FILTER]: (_, filters) => ({
    filters,
    futureReservations: [],
    pastReservations: [],
    fetchingFuture: true,
    fetchingPast: true,
  }),
  [SET_ACTIVE_RESERVATIONS_TAB]: (_, activeTab) => ({
    activeTab,
  }),
};

export default createReducer(initialState, actionHandlers);
