import React from 'react';
import getIcon from 'app/utils/icons';
import Icon from 'app/components/base/Icon';
import { compose } from 'redux';
import _ from 'lodash';
import { Layout } from 'app/components/base/Layout';
import { eventToKey } from 'app/utils/listeners';
import StandardInput from 'app/components/base/StandardInput';
import classnames from 'classnames';
import EmptySuggestions from '../commons/EmptySuggestions';
import SuggestionsList from '../commons/SuggestionsList';
import makeFoldable from 'app/hocs/makeFoldable';
import builder from '../builder';

import style from '../style.css';

/**
 * Stateless component for using in desktop autocomplete.
 * It uses an interface to be used in `Field` component from `redux-form`.
 * With `Field` you will controll search input field.
 *
 * It provides a prop for working with found items: `items` and `onSelect`.
 * `items` should be an array of objects `{ display, icon: { name, family }, ... }`.
 * `onSelect` called when some item from result is clicked with the item object.
 *
 * You can show loading spinner by passing `loading` prop to the component.
 * @return {Component}
 */
export class BaseAutocomplete extends React.Component {
  state = {
    focusedSuggestionIndex: 0,
  };

  componentDidMount() {
    this.focusCurrentValue(this.props);
  }

  componentWillReceiveProps(newProps) {
    const { suggestions, selectedSuggestion } = newProps;
    if (
      suggestions !== this.props.suggestions &&
      !selectedSuggestion.uncomplete
    ) {
      this.focusCurrentValue(newProps);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.validated && this.props.validated) {
      this.props.fold();
    }
  }

  focusCurrentValue({ suggestions, selectedSuggestion }) {
    if (!suggestions.length) return;
    const focusedSuggestionIndex = _.findIndex(suggestions, (item) => {
      return item.value === selectedSuggestion.value;
    });
    this.setState({ focusedSuggestionIndex });
  }

  focusSuggestion(index) {
    this.props.unfold();
    this.setState({ focusedSuggestionIndex: index });
  }

  handleSelect = (item) => {
    const { fold, onSelect } = this.props;
    onSelect(item);
    fold();
  };

  handleInputChange = (e) => {
    this.setState({ focusedSuggestionIndex: 0 });
    this.props.unfold();
    this.props.input.onChange(e);
  };

  handleKeyDown = (event) => {
    const key = eventToKey(event);
    const index = this.state.focusedSuggestionIndex;
    const { suggestions } = this.props;

    if (suggestions.length) {
      // handle navigation arrows and Enter
      if (key === 'ArrowUp') {
        const newIndex = index - 1 < 0 ? suggestions.length - 1 : index - 1;
        this.focusSuggestion(newIndex);
        event.preventDefault();
      } else if (key === 'ArrowDown') {
        const newIndex = index + 1 > suggestions.length - 1 ? 0 : index + 1;
        this.focusSuggestion(newIndex);
        event.preventDefault();
      } else if (key === 'Enter' && suggestions[index]) {
        this.handleSelect(suggestions[index]);

        event.stopPropagation();
        event.preventDefault();
      }
    }
  };

  handleBlur = () => {
    const {
      input: { onBlur },
      suggestions,
      unsafe,
      open,
      selectedSuggestion,
    } = this.props;

    if (unsafe) {
      onBlur();
    } else {
      const focusedSuggestion =
        open && suggestions[this.state.focusedSuggestionIndex];
      const defaultVal =
        !selectedSuggestion || selectedSuggestion.uncomplete
          ? { display: '', uncomplete: true }
          : selectedSuggestion;
      onBlur(focusedSuggestion || defaultVal);
    }
  };

  render() {
    const {
      size, // eslint-disable-line no-unused-vars
      className,
      loading,
      validated,
      suggestions,
      inputIcon,
      unsafe,
      uncomplete,
      readOnly,
      resultsTitle,
      emptySuggestions,
      selectedSuggestion,
      emptySuggestionsText,
      onSelect, // eslint-disable-line no-unused-vars
      open,
      type,
      ...props
    } = this.props;

    const showResults =
      (suggestions && !!suggestions.length) || emptySuggestions || unsafe;

    const modifiers = {
      [style.desktop]: true,
    };

    const icon =
      selectedSuggestion &&
      !selectedSuggestion.uncomplete &&
      getIcon(selectedSuggestion, type);
    const prefix = icon && (
      <Icon {...icon} small className={style.item__icon} />
    );

    return (
      <Layout
        nowrap
        size="noshrink"
        direction="column"
        className={classnames(className, style.container, modifiers)}
      >
        <StandardInput
          {...props}
          input={{
            ...props.input,
            onChange: !readOnly && this.handleInputChange,
            onKeyDown: this.handleKeyDown,
            onBlur: this.handleBlur,
          }}
          prefix={prefix}
          autoClear={!uncomplete}
          readOnly={readOnly}
          className={style.input}
          icon={inputIcon}
          type="text"
          cleanable
          loading={loading}
          validated={validated}
        />

        {open && showResults && !readOnly && (
          <div className={style.list_wrapper}>
            <div className={style.list_wrapper__triangle} />
            {emptySuggestions && !unsafe && (
              <EmptySuggestions
                className={style.emptySuggestions}
                text={emptySuggestionsText}
              />
            )}
            {((suggestions && !!suggestions.length) || unsafe) && (
              <SuggestionsList
                type={type}
                selected={selectedSuggestion}
                title={resultsTitle}
                suggestions={suggestions}
                focusedSuggestionIndex={this.state.focusedSuggestionIndex}
                onSelect={this.handleSelect}
                value={props.input.value}
                unsafe={unsafe}
              />
            )}
          </div>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// BaseAutocomplete.propTypes = {
//   selectedSuggestion: PropTypes.any,
//   className: PropTypes.string,
//   size: PropTypes.string,
//   type: PropTypes.string,
//   loading: PropTypes.bool,
//   validated: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   unsafe: PropTypes.bool,
//   suggestions: PropTypes.array,
//   emptySuggestions: PropTypes.bool,
//   uncomplete: PropTypes.bool,
//   onSelect: PropTypes.func,
//   inputIcon: PropTypes.any,
//   emptySuggestionsText: PropTypes.string,
//   resultsTitle: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.string
//   ]),
//   // redux-form Field props
//   input: PropTypes.object.isRequired,
//   // makeFoldable props
//   open: PropTypes.bool.isRequired,
//   fold: PropTypes.func.isRequired,
//   unfold: PropTypes.func.isRequired
// };

export default compose(builder, makeFoldable)(BaseAutocomplete);
