import _ from 'lodash';

// Internal constants (exported for testing)
let hooksRegistry = {};
const reqPlugin = require.context(
  'app/plugins',
  true,
  /\.\/[^/]+\/(.+?)\-hooks\.js$/,
  'lazy'
);

/**
 * Register a hook handler for give hook name. It also checking that the hook
 * is not registered before. Otherwise it throws an error message.
 * @param  {string} name
 * @param  {function} handler
 */
export const registerHookHandler = (name, handler) => {
  if (hooksRegistry[name] && process.env !== 'development') {
    throw new Error(`Hook handler for "${name}" already registered`);
  }
  hooksRegistry[name] = handler;
};

/**
 * By given name remove hook handler if exists
 */
export const unregisterHookHandler = (name) => {
  delete hooksRegistry[name];
};

/**
 * Remove all hooks handlers (for testing)
 */
export const unregisterAllHooks = () => {
  hooksRegistry = {};
};

/**
 * Call a hook with given name and arguments. First argument could be
 * an object with { name, args, defaultHandler } fields to be able to
 * specify default hook handler.
 * Examples of usage:
 *   - `hook(MY_HOOK_NAME, 1, 2, 3)`
 *   - `hook({ name: MY_HOOK_NAME, args: [1,2,3], defaultHandler: () => { ... } })`
 * @param  {string}  name
 * @param  {...any}  args
 * @return {any}
 */
export const hook = (name, ...args) => {
  let options;
  if (_.isPlainObject(name)) {
    options = name;
    options.args = options.args || [];
  } else {
    options = { name, args };
  }

  const handler = hooksRegistry[options.name] || options.defaultHandler;
  return handler ? handler(...options.args) : undefined;
};

/**
 * Load a set of plugins from `app/plugins/{pluginName}/index.js`
 * if exists, and register all hooks exported from the module
 * (in default object or as module level export variables).
 * Returns a Promise that will be resolved when plugin loaded
 * and all hooks registered.
 *
 * @param  {string} pluginName
 * @return {Promise}
 */
export const loadPlugin = (pluginName, pkgResolver = reqPlugin) => {
  const pkg = `./${pluginName}/${pluginName}-hooks.js`;
  if (pkgResolver.keys().indexOf(pkg) >= 0) {
    return new Promise((resolve) => {
      pkgResolver(pkg).then((pluginObj) => {
        const hooks = pluginObj.default || pluginObj;
        Object.keys(hooks).forEach((k) => {
          registerHookHandler(k, hooks[k]);
        });
        resolve(true);
      });
    });
  }
  return Promise.resolve(false);
};
