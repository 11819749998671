// @flow
import React from 'react';
import { FormattedMessage } from 'mz-intl';
import SectionHeader from 'app/components/book-trip/commons/SectionHeader';
import messages from 'app/components/search-results/Trip/messages.intl';
import btpMessages from 'app/components/book-trip/messages.intl';
import HourlyLabel from 'app/components/common/HourlyLabel';
import ReservationActions from '../ReservationActions';
import {
  Container,
  Content,
  Section,
  Column,
  PickupLocation,
  Icon,
  Label,
} from './elements';
import { CONFIRMATION_FORM, RIDE_DURATION_FIELD_NAME } from 'app/constants';
// new ui-kit components
import TranslatedField from 'app/components/common/TranslatedField';
import RideDurationField from 'mz-ui-kit/form/RideDurationField';
import HourlyIcon from 'mz-icons/hourly';
import ClockIcon from 'mz-icons/clock';

// types
type Props = {
  fromAddress: string,
  readOnly: boolean,
  hoursRequested: number,
  minimumHours?: number,
  pickupDateTime: string,
  includedKms: number,
};

const HourlyRide = ({
  fromAddress,
  readOnly,
  pickupDateTime,
  hoursRequested,
  minimumHours,
  includedKms,
}: Props) => {
  return (
    <Container>
      <SectionHeader
        icon={{ family: 'mozio', name: 'trip-detail' }}
        title={<FormattedMessage message={btpMessages.YOUR_TRIP} />}
        subtitle={<FormattedMessage message={btpMessages.HOURLY_RIDE} />}
      />
      <PickupLocation>
        <Label>
          <FormattedMessage message={messages.PICKUP_LOCATION} />
        </Label>
        {fromAddress}
      </PickupLocation>
      {!readOnly && (
        <TranslatedField
          component={RideDurationField}
          name={RIDE_DURATION_FIELD_NAME}
          appearance="btp-field-outlined"
          label={<FormattedMessage message={messages.RIDE_DURATION} />}
          minHours={minimumHours}
        />
      )}
      {readOnly && (
        <Section>
          <Column>
            <Label>
              <FormattedMessage message={messages.PICKUP_TIME} />
            </Label>
            <Content>
              <Icon>
                <ClockIcon />
              </Icon>
              {pickupDateTime}
            </Content>
          </Column>
          <Column>
            <Label>
              <FormattedMessage message={messages.RIDE_DURATION} />
            </Label>
            <Content flexible>
              <Icon>
                <HourlyIcon />
              </Icon>
              <HourlyLabel noIcon hours={hoursRequested} />
            </Content>
          </Column>
        </Section>
      )}
      <div>
        <FormattedMessage
          message={messages.INCLUDED_KMS}
          values={{ distance: includedKms }}
        />
      </div>
      {readOnly && (
        <ReservationActions
          formName={CONFIRMATION_FORM}
          direction="departing"
        />
      )}
    </Container>
  );
};

export default HourlyRide;
