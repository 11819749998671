import React from 'react';

export const Arrow = () => (
  /* eslint-disable */
  <svg width="3.484px" height="5.72px" viewBox="0 0 3.484 5.72">
    <g>
      <path d="M0.686,5.72L0,4.99l2.436-2.116L0.035,0.785l0.287-0.414L0.657,0l2.518,2.192c0.197,0.175,0.309,0.423,0.309,0.683 c0,0.263-0.112,0.511-0.308,0.682L0.686,5.72z" />
    </g>
  </svg>
  /* eslint-enable */
);

export const InfoIcon = () => (
  /* eslint-disable */
  <svg width="10px" height="10px" viewBox="0 0 10 10">
    <g>
      <circle cx="5" cy="2.832" r="0.447" />
      <polygon points="5.983,7.389 4.017,7.389 4.017,7.031 4.553,6.853 4.553,4.351 4.017,4.172 4.017,3.815 4.553,3.815 5.447,3.815 5.447,6.853 5.983,7.031" />
      <path d="M5,10c-2.757,0-5-2.243-5-5s2.243-5,5-5s5,2.243,5,5S7.757,10,5,10z M5,0.732C2.647,0.732,0.732,2.647,0.732,5S2.647,9.268,5,9.268S9.268,7.353,9.268,5S7.353,0.732,5,0.732z" />
    </g>
  </svg>
  /* eslint-enable */
);

export const ClockIcon = () => (
  /* eslint-disable */
  <svg width="12px" height="12px" viewBox="0 0 12 12">
    <g>
      <path d="M5.999,0C2.691,0,0,2.692,0,6c0,1.035,0.27,2.055,0.78,2.951c0.497,0.878,1.218,1.624,2.085,2.158c0.944,0.582,2.029,0.89,3.135,0.89c3.309,0,6-2.691,6-5.999C11.999,2.692,9.307,0,5.999,0z M5.999,11.145c-0.949,0-1.878-0.264-2.687-0.763c-0.746-0.46-1.365-1.101-1.79-1.854C1.085,7.761,0.854,6.885,0.854,6c0-2.838,2.309-5.147,5.146-5.147c2.838,0,5.147,2.309,5.147,5.147C11.146,8.837,8.837,11.145,5.999,11.145z" />
      <path d="M6.215,6.483l0.001-2.835c0-0.235-0.191-0.427-0.427-0.427c-0.235,0-0.426,0.191-0.426,0.427L5.362,6.661l0.035,0.165c0.021,0.051,0.052,0.098,0.093,0.138l1.688,1.687c0.161,0.162,0.442,0.162,0.604-0.001c0.166-0.166,0.166-0.437,0-0.603L6.215,6.483z" />
    </g>
  </svg>
  /* eslint-enable */
);

export const LocationIcon = () => (
  /* eslint-disable */
  <svg width="9.75px" height="13px" viewBox="0 0 9.75 13">
    <g>
      <path d="M4.875,0C2.183,0,0,2.183,0,4.875c0,3.131,3.511,7.039,4.062,7.636C4.291,12.759,4.482,13,4.875,13s0.584-0.241,0.812-0.489C6.239,11.914,9.75,8.006,9.75,4.875C9.75,2.183,7.567,0,4.875,0z M4.875,8.125c-1.795,0-3.25-1.455-3.25-3.25s1.455-3.25,3.25-3.25s3.25,1.455,3.25,3.25S6.67,8.125,4.875,8.125z M4.875,3.25c-0.897,0-1.625,0.728-1.625,1.625S3.978,6.5,4.875,6.5S6.5,5.772,6.5,4.875S5.772,3.25,4.875,3.25z" />
    </g>
  </svg>
  /* eslint-enable */
);

export const PreferredIcon = () => (
  /* eslint-disable */
  <svg width="11.145px" height="11.26px" viewBox="0 0 11.145 11.26">
    <g>
      <path d="M8.419,5.498c2.727-0.64,2.727-2.36,2.727-3.502v-0.1c0-0.314-0.251-0.565-0.565-0.565H9.675C9.713,0.891,9.738,0.452,9.738,0H1.445c0,0.452,0.025,0.904,0.063,1.331H0.565C0.251,1.331,0,1.582,0,1.895v0.1C0,3.151,0,4.87,2.764,5.511c0.591,0.992,1.332,1.682,2.161,1.921v2.573H3.305c-0.352,0-0.628,0.276-0.628,0.628c0,0.351,0.276,0.628,0.628,0.628h4.398c0.352,0,0.628-0.276,0.628-0.628c0-0.351-0.276-0.628-0.628-0.628h-1.52V7.444C7.037,7.218,7.816,6.515,8.419,5.498z M10.002,2.46C9.977,3.138,9.839,3.703,9.06,4.092C9.235,3.59,9.386,3.038,9.499,2.46H10.002z M1.131,2.46h0.54c0.113,0.59,0.264,1.142,0.44,1.644C1.294,3.716,1.156,3.138,1.131,2.46z M2.752,1.243h5.654c-0.302,2.95-1.621,5.021-2.827,5.021C4.385,6.264,3.053,4.193,2.752,1.243z" />
    </g>
  </svg>
  /* eslint-enable */
);

export const RecommendedIcon = () => (
  /* eslint-disable */
  <svg width="12.644px" height="12px" viewBox="0 0 12.644 12">
    <g>
      <path d="M6.322,9.646L10.229 12 9.196 7.561 12.644 4.575 8.098 4.185 6.322 0 4.546 4.185 0 4.575 3.449 7.561 2.415 12 z" />
    </g>
  </svg>
  /* eslint-enable */
);
