import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { preprocessTripObject } from 'app/utils/trip';
import { changeTripMode } from 'app/actions/book-trip';
import {
  BOOK_TRIP_FORM,
  RETURN_TRIP_FIELD_NAME,
  ONE_WAY_MODE_VALUE,
  ROUND_TRIP_MODE_VALUE,
} from 'app/constants';

export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);
  const trip = selector(state, RETURN_TRIP_FIELD_NAME);
  const preparedTrip = preprocessTripObject(trip, ROUND_TRIP_MODE_VALUE);

  if (preparedTrip) {
    return {
      ...preparedTrip.heading,
      ...preparedTrip.routing,
      ...preparedTrip.reservationDetails,
      direction: 'returning',
      cancelled: trip.cancelled,
    };
  }
  return {};
};

export const builder = (ComposedComponent) => {
  class WrapperComponent extends React.Component {
    handleClickRemove = () => {
      const {
        input: { onChange },
        setOneWay,
      } = this.props;
      onChange(null);
      setOneWay();
    };
    render() {
      const { direction } = this.props;
      return !direction ? null : (
        <ComposedComponent
          direction={direction}
          onClickRemove={this.handleClickRemove}
          {...this.props}
        />
      );
    }
  }

  // TODO: move to Flow types
  // WrapperComponent.propTypes = {
  //   formName: PropTypes.string,
  //   input: PropTypes.object,
  //   direction: PropTypes.string,
  //   setOneWay: PropTypes.func.isRequired
  // };

  return compose(
    connect(mapStateToProps, {
      setOneWay: () => changeTripMode(ONE_WAY_MODE_VALUE),
    })
  )(WrapperComponent);
};

export default builder;
