import { places } from 'mz-sdk';
import { call } from 'redux-saga/effects';

/**
 * By given id get the place details and make right object in
 * suggestions format.
 */
export default function* findPlaceById(place_id) {
  return yield call(places.getPlaceDetails, place_id);
}
