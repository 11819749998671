import React, { Component } from 'react';
import classnames from 'classnames';
import Dropdown from 'app/components/base/Dropdown';
import Button from 'app/components/base/Button';
import { Layout } from 'app/components/base/Layout';
import makeFoldable from 'app/hocs/makeFoldable';
import Icon from 'app/components/base/Icon';
import _ from 'lodash';
import style from './style.css';

class FoldableDropdown extends Component {
  handleClick = (event) => {
    if (this.props.disabled) {
      event.stopPropagation();
      return;
    }
    if (this.props.open) {
      this.props.fold();
    }
    this.props.onClick(event);
  };

  renderButton() {
    const align = this.props.label ? 'space-between center' : 'end center';
    const modifiers = {
      [style.raiseHandle]: this.props.raiseHandle,
      [style.hasarrow]: this.props.showDropDownArrow,
    };

    return (
      <Button
        clear
        alt
        tight
        nocaps
        disabled={this.props.disabled}
        onClick={this.handleClick}
        className={classnames(style.input, modifiers)}
        active={this.props.open}
      >
        <Layout align={align} size="noshrink" nowrap>
          <div className={style.label}>{this.props.label}</div>
          {this.props.showDropDownArrow && (
            <Icon className={style.arrow} primary={this.props.open}>
              arrow_drop_down
            </Icon>
          )}
        </Layout>
      </Button>
    );
  }

  renderOrigin() {
    return React.cloneElement(this.props.origin, {
      onClick: this.handleClick,
      active: this.props.open,
    });
  }

  render() {
    const { open, origin, children, ...props } = this.props;
    return (
      <Dropdown
        {..._.omit(props, 'id', 'className')}
        onClick={this.handleClick}
        origin={origin ? this.renderOrigin() : this.renderButton()}
        open={open}
      >
        {children}
      </Dropdown>
    );
  }
}

// TODO: move to Flow types
// FoldableDropdown.propTypes = {
//   id: PropTypes.string,
//   className: PropTypes.string,
//   label: PropTypes.node,
//   origin: PropTypes.node,
//   children: PropTypes.node,
//   onClick: PropTypes.func,
//   open: PropTypes.bool.isRequired,
//   fold: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   raiseHandle: PropTypes.bool,
//   showDropDownArrow: PropTypes.bool
// };

FoldableDropdown.defaultProps = {
  showDropDownArrow: true,
  onClick: _.noop,
};

export default makeFoldable(FoldableDropdown);
