import React from 'react';
import { FormattedMessage } from 'translations';
import Image from 'app/components/base/Image';
import RatingStars from 'app/components/base/RatingStars';
import { Layout } from 'app/components/base/Layout';
import Heading from './Heading';
import config from 'config';
import Tags from '../../../commons/Tags';

import style from '../style.css';

const messages = {
  tripImageAlt: { id: 'SEARCH_RESULTS.TRIP_IMAGE_ALT' },
};

export const TripHeader = ({
  duration,
  tags,
  numVehicles,
  vehicleImageUrl,
  rating,
  ...props
}) => (
  <Layout align="start start" nowrap className={style.header}>
    {/* Logo & stars */}
    <Layout
      className={style['picture-column']}
      direction="column"
      align="start center"
    >
      <Image
        circled
        bezel={numVehicles > 1}
        bezelClassName={style.imageBezel}
        className={style.image}
        src={vehicleImageUrl}
        alt={<FormattedMessage {...messages.tripImageAlt} />}
      />
      {config.SHOW_PROVIDER_RATING && (
        <Layout className={style.rating}>
          <RatingStars rating={rating} />
        </Layout>
      )}
    </Layout>

    {/* Title & Tags */}
    <Layout
      className={style['details-container']}
      size="grow"
      direction="column"
      align="start start"
    >
      <Heading {...props} duration={duration} />
      {config.SHOW_TAGS_IN_SEARCH && (
        <Tags tags={tags} className={style.tags} details />
      )}
    </Layout>
  </Layout>
);

// TODO: move to Flow types
// TripHeader.propTypes = {
//   duration: PropTypes.object,
//   tags: PropTypes.array,
//   vehicleImageUrl: PropTypes.string,
//   numVehicles: PropTypes.number,
//   rating: PropTypes.number,
//   steps: PropTypes.array,
//   className: PropTypes.string
// };

export default TripHeader;
