import { styled } from 'mz-ui-kit';

export const TooltipContainer = styled('div', {
  fontSize: '12px',
  marginLeft: '4px',
  marginTop: '-10px',
});

export const TooltipContent = styled('div', {
  whiteSpace: 'normal',
  fontWeight: 'normal',
  fontSize: '12px',
});
