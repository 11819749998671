import React from 'react';
import { Layout } from 'app/components/base/Layout';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';

import style from './style.css';

class CreditCardInput extends React.Component {
  render() {
    const {
      type,
      elementRef,
      /* eslint-disable */
      meta,
      input: { value, ...input },
      className,
      size = 'noshrink',
      /* eslint-enable */
    } = this.props;
    const typeToStripeElement = {
      creditCardNumber: CardNumberElement,
      creditCardExpiry: CardExpiryElement,
      creditCardCVC: CardCVCElement,
    };
    const Element = typeToStripeElement[type];
    const baseStyle = {
      base: {
        '::placeholder': {
          color: '#888888', // value of $SYS_INPUT_PLACEHOLDER_COLOR from theme.css
        },
      },
      invalid: {
        color: 'inherit',
      },
    };

    return (
      <Layout size={size} className={className} id={type}>
        <Element
          {...input}
          elementRef={elementRef}
          className={style.input}
          style={baseStyle}
        />
      </Layout>
    );
  }
}

// TODO: move to Flow types
// CreditCardInput.propTypes = {
//   input: PropTypes.object,
//   elementRef: PropTypes.node,
//   type: PropTypes.string.isRequired
// };

export default CreditCardInput;
