import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import getCustomerProfileList from './getCustomerProfileList';
import getCustomerProfile from './getCustomerProfile';
import createCustomerProfile from './createCustomerProfile';
import updateCustomerProfile from './updateCustomerProfile';
import deleteCustomeProfile from './deleteCustomerProfile';
import {
  TA_GET_CUSTOMER_PROFILE_LIST,
  TA_GET_CUSTOMER_PROFILE,
  TA_CREATE_CUSTOMER_PROFILE,
  TA_UPDATE_CUSTOMER_PROFILE,
  TA_DELETE_CUSTOMER_PROFILE,
} from 'app/constants';

export default function* watchCustomerProfile() {
  yield fork(takeLatest, TA_GET_CUSTOMER_PROFILE_LIST, getCustomerProfileList);
  yield fork(takeLatest, TA_GET_CUSTOMER_PROFILE, getCustomerProfile);
  yield fork(takeLatest, TA_CREATE_CUSTOMER_PROFILE, createCustomerProfile);
  yield fork(takeLatest, TA_UPDATE_CUSTOMER_PROFILE, updateCustomerProfile);
  yield fork(takeLatest, TA_DELETE_CUSTOMER_PROFILE, deleteCustomeProfile);
}
