const TIME_MEASURES = {};

/**
 * Start measuring of some type of measure. By default it only start
 * measuring if measure with given type is not exists or complete (have end time).
 * @param  {string}  type
 * @param  {Boolean} [reset=false] When true it will start measure even if mesure
 *                                 is not complete
 * @param  {Date}    [time=null]   If passed will be used instead current time
 * @return {null|Date}
 */
export const startMeasuring = (type, reset = false, time = null) => {
  const currTime = time || new Date();
  const measure = TIME_MEASURES[type];

  if (!measure || measure.end || reset) {
    TIME_MEASURES[type] = { start: currTime };
    return currTime;
  }
  return null;
};

/**
 * Stop measuring of given type. Set end time only if
 * measure is not stopped yet.
 * @param  {string} type
 * @return {null|Date}
 */
export const stopMeasuring = (type) => {
  const currTime = new Date();
  const measure = TIME_MEASURES[type];

  if (measure && !measure.end) {
    measure.end = currTime;
    return currTime;
  }
  return null;
};

/**
 * Return complete measure (with start and end) or null if measure not
 * exists of is not complete (do not have end time)
 * @param  {string} type
 * @return {null|Object}
 */
export const getMeasure = (type) => {
  const measure = TIME_MEASURES[type];
  if (measure && measure.end) {
    return measure;
  }
  return null;
};
