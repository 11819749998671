// @flow
import { styled } from 'mz-ui-kit';

export const DenyContainer = styled('div', {
  fontSize: '40px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  textAlign: 'center',
  padding: '40px',
});
