import VEHICLE_CLASS from 'mz-sdk/constants/vehicleClass';
import config from 'config';

const CLASS_TO_IMAGE = {
  [VEHICLE_CLASS.BUSINESS]: require('img/vehicle-class/business.png'),
  [VEHICLE_CLASS.FIRST]: require('img/vehicle-class/first-class.png'),
  [VEHICLE_CLASS.STANDARD]: require('img/vehicle-class/standard.png'),
  [VEHICLE_CLASS.BUSINESS_VAN]: require('img/vehicle-class/business-van.png'),
  [VEHICLE_CLASS.GREEN_STANDARD]: require('img/vehicle-class/green-standard.png'),
  [VEHICLE_CLASS.GREEN_PREMIUM]: require('img/vehicle-class/green-premium.png'),
};

export const getImageFromClass = (vehicleClass) => {
  if (!config.STATIC_VEHICLE_IMAGES_BASED_IN_VEHICLE_CLASS) {
    return null;
  }
  return (vehicleClass && CLASS_TO_IMAGE[vehicleClass]) || CLASS_TO_IMAGE[2];
};
