import React from 'react';
import { FormattedMessage } from 'translations';
import { styled } from 'mz-ui-kit';
import config from 'config';

const Container = styled('div', {
  fontSize: '12px',
  color: '#727272',
});

const messages = {
  loyalty: { id: 'FOOTER.LOYALTY_PROGRAM' },
};

const LoyaltyText = () => {
  if (!config.LOYALTY_PROGRAM_ENABLED) return null;
  return (
    <Container>
      <FormattedMessage
        {...messages.loyalty}
        values={{
          program: config.LOYALTY_PROGRAM_NAME,
          company: config.EMAIL_SITE_NAME,
        }}
      />
    </Container>
  );
};

export default LoyaltyText;
