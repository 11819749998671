import { getFormValues } from 'redux-form';
import { BOOK_TRIP_FORM, PRICE_FIELD_NAME } from 'app/constants';
import _ from 'lodash';

const getOriginalTripPrice = (state, formName = BOOK_TRIP_FORM) => {
  const price = getFormValues(formName)(state)[PRICE_FIELD_NAME];

  const priceValue = _.get(price, 'originalPriceRaw');

  return priceValue;
};

export default getOriginalTripPrice;
