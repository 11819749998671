import React from 'react';
import { Link } from 'react-router-dom';
import { Layout } from '../Layout';
import { formatString } from 'app/utils/format';
import { hook } from 'app/utils/hook';
import { APP_LOGO_IMAGE_HOOK } from 'app/constants';
import config from 'config';

import style from './style.css';

// Utils
const getDefaultLogoUrl = (inverse, customUrl) => {
  const isDefaultLogo = !customUrl;
  const defaultLogo = require(`img/logo${inverse ? '_white' : ''}.svg`);
  return isDefaultLogo ? defaultLogo : customUrl;
};

/**
 * White-label specific logo link. If custom url provided
 * if returns a regular "a", otherwise returns react-router's "Link"
 */
const LogoLink = ({ children, ...props }) =>
  config.CUSTOM_LOGO_LINK ? (
    <a {...props} href={formatString(config.CUSTOM_LOGO_LINK)}>
      {children}
    </a>
  ) : (
    <Link {...props} to="/">
      {children}
    </Link>
  );

// TODO: move to Flow types
// LogoLink.propTypes = {
//   children: PropTypes.any
// };

/**
 * This is a special version of Logo component which makes it
 * responsive.
 * It is not using `<img>` tag for showing the image because it
 * have issues in IE11 (Windows 7). `max-width` is not working
 * properly in the system. Fortunatelly `background-size: contain` works
 * as expected
 */
const NavbarLogo = (props) => {
  const customUrl = config.CUSTOM_LOGO;
  const white = config.COLORS_DARK_MODE;
  const layoutAlign = `start ${config.HEADER_LOGO_POSITION}`;
  const logoUrl = hook({
    name: APP_LOGO_IMAGE_HOOK,
    args: [white, customUrl],
    defaultHandler: getDefaultLogoUrl,
  });

  return (
    <Layout align={layoutAlign} className={style.logo}>
      <LogoLink {...props} className={style.logo__link}>
        <div
          style={{ backgroundImage: `url("${formatString(logoUrl)}")` }}
          className={style.logo__img}
        />
      </LogoLink>
    </Layout>
  );
};

export default NavbarLogo;
