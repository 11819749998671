import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import RatingStars from 'app/components/base/RatingStars';
import { FormattedMessage } from 'translations';
import Image from 'app/components/base/Image';
import Icon from 'app/components/base/Icon';
import VehicleModel from 'app/components/common/VehicleModel';
import config from 'config';

import style from './style.css';

const messages = {
  tripImageAlt: { id: 'SEARCH_RESULTS.TRIP_IMAGE_ALT' },
};

/**
 * Component that shows the logo and rating of a given provider,
 * to be used inside the Trip card components.
 */
const TripProviderLogo = ({
  className,
  imageUrl,
  numVehicles,
  make,
  model,
  rating,
  recommended,
  mobile,
  medium,
  compact,
  norating,
}) => {
  const modifiers = {
    [style['logo--mobile']]: mobile,
    [style['logo--recommended']]: recommended,
    [style['logo--medium']]: medium,
    [style['logo--compact']]: compact,
  };

  const alignSizes = mobile ? 'start center' : 'space-between center';
  const multiVehicle = numVehicles > 1;

  const showRating =
    !_.isNil(rating) && !norating && config.SHOW_PROVIDER_RATING;

  if (!imageUrl) return null;
  return (
    <Layout className={style.relativeWrapper}>
      {multiVehicle && !mobile && !medium && (
        <Layout align="center center" className={style.badge}>
          <Icon family="mozio" name="mv-multiplier" />
          {numVehicles}
        </Layout>
      )}
      <Layout
        nowrap
        className={classnames(style.logo, className, modifiers)}
        direction="column"
        align={alignSizes}
        size={mobile || medium ? null : 'noshrink'}
      >
        <FormattedMessage {...messages.tripImageAlt}>
          {(alt) => (
            <Image
              circled
              bezel={multiVehicle}
              bezelClassName={style.imageBezel}
              className={style.image}
              src={imageUrl}
              alt={alt}
            />
          )}
        </FormattedMessage>
        {config.SHOW_VEHICLE_DETAILS && (
          <Layout
            className={style.details}
            direction="column"
            align="start center"
            nowrap
          >
            <VehicleModel
              className={style.model}
              make={make}
              model={model}
              showSimilarOnNewLine
            />
            {showRating && (
              <RatingStars className={style.rating} rating={rating} />
            )}
          </Layout>
        )}
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// TripProviderLogo.propTypes = {
//   className: PropTypes.string,
//   imageUrl: PropTypes.string,
//   numVehicles: PropTypes.number,
//   rating: PropTypes.number,
//   recommended: PropTypes.bool,
//   make: PropTypes.string,
//   model: PropTypes.string,
//   mobile: PropTypes.bool,
//   medium: PropTypes.bool,
//   compact: PropTypes.bool,
//   norating: PropTypes.bool
// };

export default TripProviderLogo;
