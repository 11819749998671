import { compose } from 'redux';
import { connect } from 'react-redux';
import { ROUND_TRIP_MODE_VALUE } from 'app/constants';
import * as bookTripActions from 'app/actions/book-trip';
import { getBookTripForm } from 'app/sagas/selectors';

const mapStateToProps = (state) => {
  const { departing_pickup_datetime } = getBookTripForm(state);
  return { minDate: departing_pickup_datetime };
};

/**
 * Connect to i18n and redux
 * @param  {Component} ComposedComponent
 * @return {Component}
 */
export const builder = (ComposedComponent) => {
  return compose(
    connect(mapStateToProps, {
      onDateSelected: () =>
        bookTripActions.changeTripMode(ROUND_TRIP_MODE_VALUE),
    })
  )(ComposedComponent);
};

export default builder;
