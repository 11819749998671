import React from 'react';
import CN from 'classnames';
import { FormattedMessage } from 'translations';
import InlineLabel from 'app/components/base/InlineLabel';
import HoverableDropdown from 'app/components/base/HoverableDropdown';
import config from 'config';

import style from './style.css';

const extendedTripView = config.SRP_EXTENDED_TRIP_DETAILS;

const messages = {
  bagsPerVehicle: { id: 'SEARCH_RESULTS.BAGS_PER_VEHICLE' },
  bagsPerPerson: { id: 'SEARCH_RESULTS.BAGS_PER_PERSON' },
  // extended texts
  bagsPerVehicleExtended: { id: 'SEARCH_RESULTS.BAGS_PER_VEHICLE_EXTENDED' },
  bagsPerPersonExtended: { id: 'SEARCH_RESULTS.BAGS_PER_PERSON_EXTENDED' },
};

const Bags = ({ isTrain, isMaxBagsPerPerson, maxBags }) => {
  if (isTrain || !maxBags) return null;

  const bagsMessage = isMaxBagsPerPerson
    ? messages.bagsPerPersonExtended
    : messages.bagsPerVehicleExtended;

  const labelMessage = extendedTripView ? (
    <FormattedMessage {...bagsMessage} values={{ numBags: maxBags }} />
  ) : (
    maxBags
  );

  const label = (
    <InlineLabel
      className={CN(style.label, { [style.extendedView]: extendedTripView })}
      icon={{ name: 'srp-bags', family: 'mozio' }}
      dark
      text={labelMessage}
    />
  );

  if (!extendedTripView) {
    const bagsTooltip = isMaxBagsPerPerson
      ? messages.bagsPerPerson
      : messages.bagsPerVehicle;

    return (
      <HoverableDropdown
        axis="end"
        direction="top"
        negative
        rounded
        className={style.bags}
        origin={label}
      >
        <div className={style.tooltip}>
          <FormattedMessage {...bagsTooltip} />
        </div>
      </HoverableDropdown>
    );
  }

  return label;
};

// TODO: move to Flow types
// Bags.propTypes = {
//   isTrain: PropTypes.bool,
//   isMaxBagsPerPerson: PropTypes.bool,
//   maxBags: PropTypes.number
// };

export default Bags;
