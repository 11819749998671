/* eslint-disable max-len */
import createMessages from 'mz-intl/create';

export default createMessages('BOOK_TRIP_PAGE', {
  YOUR_TRIP: 'Your Trip:',
  HOURLY_RIDE: 'Hourly Ride',
  RIDE_DURATION: 'Ride duration',
  HOURLY_PICKUP_INSTRUCTIONS:
    'Total ride duration is {numHours}. {providerName} will arrive 15 minutes before your scheduled pickup time at the designated address.',
  ON_DEMAND_DISCLAIMER:
    'When you book this ride, we will put a hold on your credit card for {holdPercentage} of the ride. Upon completion of the ride only the final price will be billed.',
  ON_DEMAND_HOLD_PERCENTAGE: '125% of the estimated price',
  NO_MEET_AND_GREET_RESULTS:
    "We are sorry, but we weren't able to find any meet and greet trips that fit your requirements.",
  MILES_EARNED: 'Total {company} Miles:',
  SEND_BOOKING_LINK: 'Send booking link',
  BOOKING_LINK_SENT_HEADER: 'Link sent!',
  BOOKING_LINK_SENT_BODY:
    'If the customer will book a transfer with your link you will receive a booking confirmation email.',
  BOOKING_LINK_BACK: 'Return to home page',
  SUBMIT_FAILED:
    'We are sorry, but the form submission has failed. Please try again and if the problem persists contact our support.',
  MARKETING_CONSENT: 'I agree to receive personalized marketing emails',
  AGREE_WITH_TERMS: 'I agree with {mozio_link}',
  BY_CLICKING_TERMS: 'By clicking "Book" you agree with {mozio_link}',
  TERMS_AND_CONDITIONS: 'Terms & Conditions',
  PROVIDER_TERMS_AND_CONDITIONS: "the provider's {provider_link}",
  SMS_NOTIFICATIONS_DESCRIPTION:
    'Receive an SMS message with a service confirmation 48hr before your ride.',
  CARBON_OFFSET_NAME: 'Carbon Emission Offset',
  CARBON_OFFSET_DESCRIPTION:
    'We will offset your carbon emissions for this trip by investing in sustainable reforestation projects.',
  PRICE_PER_RIDE: 'per ride',
  ESIM_TITLE: 'Virtual SIM Card',
  ESIM_DESCRIPTION:
    'Virtual SIM Card added to your device through a QR Code allowing you to use 1Gb of Data valid for 7 Days. {learnMoreLink}',
  ESIM_LEARN_MORE: 'Learn more',
  ESIM_DETAILS_TITLE: 'eSIM Details',
  ESIM_DETAILS_TEXT:
    'Use data while roaming without the need of a physical SIM Card. Our Virtual SIM Card or eSIM is added to your compatible device through a simple configuration through a QR Code. You get 1Gb of Data which is valid for 7 days from the moment you activate it. Service is subject to {phoneCompatLink} and coverage availability. Virtual SIM Card installation instructions are sent to your email 24 business hours after your purchase. Your Digital SIM Card may not come with its own phone number. Service is sold by Mozio and provided by Airalo.',
  ESIM_DETAILS_PHONE_COMPAT: 'device compatibility',
  STANDARD_CANCEL_TITLE: 'Standard Cancellation Policy',
  STANDARD_CANCEL_DESCRIPTION:
    'Cancel your reservation {hours} hours before the scheduled pickup time to receive a credit voucher for the value paid for each ride. The credit voucher can be used to book any other ride within a year from the date of cancellation.',
  FLEXIBLE_CANCEL_TITLE: 'Enhanced Cancellation Policy',
  FLEXIBLE_CANCEL_DESCRIPTION:
    'Receive a full refund for the paid value of a reservation that has been cancelled in line with the cancellation policy minus the value of the feature. {learnMoreLink}',
  FLEXIBLE_CANCEL_LEARN_MORE: 'Learn more',
  FLEXIBLE_CANCEL_DETAILS_TITLE: 'Flexible Cancel Policy',
  FLEXIBLE_CANCEL_DETAILS_RULE_1:
    '"Mozio\'s Flexible Cancel Policy" (from now on referred to as "the feature") is a feature that allows customers who opt in to receive a full refund for the paid value of a reservation that has been cancelled in line with the cancellation policy minus the value of the feature (from now on referred to as "prime") which will remain non-refundable in all instances.',
  FLEXIBLE_CANCEL_DETAILS_RULE_2:
    'Customers who do not opt in on the feature will receive a voucher for the value paid for the ride cancelled in line with the cancellation policy valid for use within a year of issuance on any service booked through Mozio.',
  FLEXIBLE_CANCEL_DETAILS_RULE_3:
    'The feature will need to be purchased at the same time of payment of the reservation. Once a reservation has been processed and paid, the feature cannot be added on.',
  FLEXIBLE_CANCEL_DETAILS_RULE_4:
    'Cancellation of a reservation outside the cancellation policy will not result in a refund in any case for the value of the ride nor the value of the feature.',
  FLEXIBLE_CANCEL_DETAILS_RULE_5:
    'Use of the service implies acceptance of the same regardless of the conditions the service was provided in and resignation of claim for a refund.',
  FLEXIBLE_CANCEL_DETAILS_RULE_6:
    'The paid value of the feature will remain non-refundable regardless of any other type of refund offered.',
  FLEXIBLE_CANCEL_DETAILS_RULE_7:
    'The value of the refund will match the full amount paid for the reservation and not the full amount of the ride. Coupons and discounts applied will not be taken into consideration for the refunded amount.',
  FLIGHT_LANDING_PICKUP_NOTICE:
    "Please bear in mind, this option does not allow you to select your own pickup time. Instead, the driver goes by your plane's landing time and waits for you only after your plane has landed.",
});
/* eslint-enable max-len */
