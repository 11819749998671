import { user } from 'mz-sdk';
import { call } from 'redux-saga/effects';
import {
  showSuccessNotification,
  normalizeErrorMessage,
} from 'app/sagas/utils';
import { SubmissionError } from 'redux-form';

export default function* changePassword(action) {
  try {
    yield call(user.changePassword, action.payload);
    yield call(showSuccessNotification, {
      messageId: 'USER.CHANGE_PASSWORD.SUCCESS_MESSAGE',
    });
    yield call(action.resolvePromise);
  } catch (err) {
    const errorMessage = normalizeErrorMessage(
      (err && err.response && err.response.msg) || 'Unknown error'
    );
    yield call(
      action.rejectPromise,
      new SubmissionError({
        _error: errorMessage,
      })
    );
  }
}
