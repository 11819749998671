import moment from 'moment';
import _ from 'lodash';
import config from 'config';
import {
  ON_DEMAND_CATEGORY,
  ROUND_TRIP_MODE_VALUE,
  TRANSPORT_RAIL,
} from 'app/constants';
import { MONTH_DAY_HOUR_FORMAT } from 'app/utils/types';
import { overwriteAirportAddress } from 'app/utils/togglers';
import { getImageFromClass } from 'app/utils/vehicleClass';

import PRICE_TYPE from 'mz-sdk/constants/priceType';
import VEHICLE_CLASS from 'mz-sdk/constants/vehicleClass';

export const isMainStep = (step) => step.main;
export const findMainStep = (steps) => steps.find(isMainStep);
export const getMainStep = (trip, isRoundTrip) => {
  return trip && findMainStep(isRoundTrip ? trip.returnSteps : trip.steps);
};

export const getSteps = (trip, isRoundTrip) => {
  return isRoundTrip ? trip.returnSteps : trip.steps;
};

export const isRoundTrip = (trip) =>
  trip.returnSteps && trip.returnSteps.length > 0;

export const isScheduledTrip = (trip) => {
  const { ticketTypes } = getMainStep(trip);
  return ticketTypes && ticketTypes.length > 0;
};

// Get from distance from steps before mainStep
const getFromDistance = (steps) => {
  let fromDistance = 0;
  if (steps.length > 1) {
    for (let i = 0; i < steps.length; i++) {
      if (steps[i].main || !steps[i].distance) break;
      fromDistance += steps[i].distance.miles;
    }
  }
  return fromDistance;
};

// Get to distance from steps after mainStep
const getToDistance = (steps) => {
  let toDistance = 0;
  if (steps.length > 1) {
    for (let i = steps.length - 1; i > 0; i--) {
      if (steps[i].main || !steps[i].distance) break;
      toDistance += steps[i].distance.miles;
    }
  }
  return toDistance;
};

// Get waiting time with informatino about start place
const getWaitingTime = (steps = []) => {
  const mainStep = findMainStep(steps);
  if (!mainStep) return null;

  const minutes = mainStep.bookingDetails.waitTime;
  const fromAirport = !!mainStep.from.location.iataCode;

  return !minutes
    ? null
    : {
        minutes,
        fromType: mainStep.from.location.type,
        fromAirport,
      };
};

// utility for transforming directions request to string used for caching responses
export const getDirectionsRequestHash = (request) => {
  const hash = request
    .map(({ category, start, end }) => {
      return `${category}_${start.lat}_${start.lng}_${end.lat}_${end.lng}`;
    })
    .reduce((result, key) => result + key, '');
  return hash;
};

export const GREEN_CLASS_PAIRS = {
  [VEHICLE_CLASS.ECONOMY]: VEHICLE_CLASS.GREEN_STANDARD,
  [VEHICLE_CLASS.STANDARD]: VEHICLE_CLASS.GREEN_STANDARD,
  [VEHICLE_CLASS.BUSINESS]: VEHICLE_CLASS.GREEN_PREMIUM,
  [VEHICLE_CLASS.FIRST]: VEHICLE_CLASS.GREEN_PREMIUM,
  [VEHICLE_CLASS.BUSINESS_VAN]: VEHICLE_CLASS.GREEN_PREMIUM,
};

export const getEnhancedVehicleClass = (trip) => {
  // Get vehicle class and ignore if no class defined
  const mainStep = trip.steps.find((x) => x.main);
  const vehicleObj = mainStep && mainStep.vehicle;
  if (!vehicleObj || !vehicleObj.vehicleClass) return null;

  return vehicleObj.vehicleClass;
};

/**
 * By given trip object create the object with structure suitable
 * for rendering via any trip related component. This object should
 * return the same structure to provide uniform interface for
 * any component.
 *
 * So, if Trip object changed **DO NOT** change the interface of
 * returned object. Try to adapt new trip structure to existing interface.
 *
 * IT IS THE PLACE FOR TRIP NORMALIZATION LOGIC
 */
export const preprocessTripObject = (trip, tripMode) => {
  if (!trip || _.isEmpty(trip)) return null;

  const isRoundTripMode = tripMode === ROUND_TRIP_MODE_VALUE;
  const steps = getSteps(trip, isRoundTripMode);
  const mainStep = getMainStep(trip, isRoundTripMode);
  if (!mainStep) return null;

  const {
    freeCancellation,
    taxesAndFeesIncluded,
    slashPrice,
    price,
    serviceClass,
    departureTime,
    timetable,
    type,
    tags,
    rewardPoints,
    detailedRoute,
    priceTagline,
    owner,
  } = trip;

  const {
    type: mainStepType,
    from,
    to,
    provider,
    amenities,
    description,
    departureDatetime,
    arrivalDatetime,
    passengersCount,
    accurateTime,
    vehicle: {
      imageUrl,
      vehicleClass,
      vehicleClassName,
      maxPassengers,
      maxBags,
      make,
      model,
      isMaxBagsPerPerson,
      type: category = {},
      numVehicles,
      totalBags,
    },
    bookingDetails: {
      showFlightInfo,
      tollsIncluded,
      areTermsInternal,
      termsOfServiceURL,
      gratuityIncluded,
      acceptsGratuity,
      startTime,
      endTime,
      waitTime,
      meetAndGreetAvailable,
      meetAndGreetDefault,
      meetAndGreetPrice,
      meetAndGreetRawPrice,
      cancellationPolicy,
      cancellableOffline,
      cancellableOnline,
      extraPaxRequired,
      showTollsAndGratuity,
      isBookable,
      specialInstructionsPlaceholder,
      hourlyDetails,
    },
  } = mainStep;

  const isTrain = mainStepType === TRANSPORT_RAIL;
  const isHourlyRide = !!(hourlyDetails && hourlyDetails.hoursRequested);

  const detailedWaitingTime = {
    waitingPrice: null, // TODO: add value from trip when it will be available,
    direct: showTollsAndGratuity ? getWaitingTime(trip.steps) : null,
    return: showTollsAndGratuity ? getWaitingTime(trip.returnSteps) : null,
  };

  const providersArray = Array.isArray(provider.name)
    ? provider.name
    : [provider.name];
  const enhancedVehicleClass = getEnhancedVehicleClass(trip);

  return {
    // Everything you need to show trip header
    heading: {
      vehicleImageUrl: getImageFromClass(enhancedVehicleClass) || imageUrl,
      numVehicles: numVehicles || 1,
      providerNames: providersArray,
      rating: provider.rating,
      vehicleClass,
      vehicleClassName,
      description,
      category,
      tags,
      type,
      make,
      model,
    },

    // Information about trip owner
    owner,

    // Details about the trip
    details: {
      rewardPoints,
      customDepartureTime: departureTime,
      waitTime,
      detailedWaitingTime,
      serviceHours: startTime && endTime && `${startTime} - ${endTime}`,
      recommendedTrip: tags.some(
        (tag) => tag.type && tag.type.includes('recommended')
      ),
      onDemand: type === ON_DEMAND_CATEGORY,
      tollsIncluded: !!tollsIncluded,
      ignoreFlightInfo: !showFlightInfo,
      gratuityIncluded: !!gratuityIncluded,
      acceptsGratuity: !!acceptsGratuity,
      meetAndGreetAvailable: !!meetAndGreetAvailable,
      meetAndGreetDefault: !!meetAndGreetDefault,
      meetAndGreetPrice,
      meetAndGreetRawPrice,
      cancellationPolicy,
      cancellableOffline,
      cancellableOnline,
      serviceClass,
      amenities,
      tags,
      maxPassengers,
      maxBags,
      isMaxBagsPerPerson,
      totalBags,
      numVehicles: numVehicles || 1,
      isTrain,
      timetable,
      areTermsInternal,
      termsOfServiceURL,
      passengersCount,
      extraPaxRequired,
      isBookable,
      showTollsAndGratuity,
      specialInstructionsPlaceholder,
      scheduledTrip: isScheduledTrip(trip),
      isHourlyRide,
      hourlyDetails,
    },

    // Everything about route of the trip
    routing: {
      fromLocation: from.location,
      toLocation: to.location,
      departureDatetime: moment
        .parseZone(departureDatetime)
        .format(MONTH_DAY_HOUR_FORMAT),
      arrivalDatetime: moment
        .parseZone(arrivalDatetime)
        .format(MONTH_DAY_HOUR_FORMAT),
      plainDepartureDatetime: departureDatetime,
      plainArrivalDatetime: arrivalDatetime,
      fromAddress: overwriteAirportAddress(from.location),
      toAddress: to.location && overwriteAirportAddress(to.location),
      fromDistance:
        (from.distance && from.distance.miles) || getFromDistance(steps),
      toDistance: (to.distance && to.distance.miles) || getToDistance(steps),
      fromStation: !!from.station && {
        name: from.station.customName || from.station.name,
        address: from.station.fullAddress,
      },
      toStation: !!to.station && {
        name: to.station.customName || to.station.name,
        address: to.station.fullAddress,
      },
      trainStationName:
        from.location?.trainStationName || to.location?.trainStationName || '',
      fromIataCode: from.location.iataCode,
      toIataCode: to.location && to.location.iataCode,
      fromPort: (from.location.isCruisePort && from.location.name) || null,
      toPort:
        (to.location && to.location.isCruisePort && to.location.name) || null,
      tripMode,
      detailedRoute,
    },

    // Printable duration of the main step (one way)
    duration: !!accurateTime && {
      raw: accurateTime,
      hours: Math.floor(accurateTime / 60),
      minutes: `0${accurateTime % 60}`.slice(-2),
    },

    // Everything about price
    pricing: {
      type: price.type,
      currencySymbol: price.currencySymbol,
      currencyCode: price.currencyCode,
      tagline: priceTagline,
      price: price.currencySymbol ? price.raw : price.totalWithoutPlatformFee,
      ridePrice: price.totalWithoutPlatformFee,
      slashPrice,
      freeCancellation,
      taxesAndFeesIncluded,
    },

    // Extra offer - i.e. "extra trip":
    offers: {
      extraTrip: trip.extraTripOffer,
    },

    reservationDetails: {
      flightNumber: mainStep.flight,
      airline: mainStep.airline,
      fltSupport: trip.fltSupport,
    },
  };
};

export const isPriceEstimated = (priceType) => {
  return (
    priceType === PRICE_TYPE.ESTIMATE_PRICE ||
    priceType === PRICE_TYPE.PRICE_RANGE ||
    priceType === PRICE_TYPE.BASE_PRICE
  );
};

function getSupplierScore(trip) {
  const mainStep = getMainStep(trip);
  return mainStep.provider.supplierScore ?? 0;
}

function riseFirstOptions(trips, sortedTripIds) {
  if (!config.VEHICLE_CLASS_TOP_RESULTS || !sortedTripIds.length) {
    return {
      differentOptions: [],
      otherOptions: sortedTripIds,
    };
  }

  const firstTripPerClass = {};
  const passedTripPerClass = {};
  const minimalSupplierScore = parseInt(config.MINIMAL_SUPPLIER_SCORE || '0');

  for (let i = 0; i < sortedTripIds.length; i++) {
    const trip = trips[sortedTripIds[i]];
    const vehicleClass = getEnhancedVehicleClass(trip);
    const passSupplierScore = getSupplierScore(trip) >= minimalSupplierScore;

    if (vehicleClass) {
      if (!firstTripPerClass[vehicleClass]) {
        firstTripPerClass[vehicleClass] = sortedTripIds[i];
      }
      if (!passedTripPerClass[vehicleClass] && passSupplierScore) {
        passedTripPerClass[vehicleClass] = sortedTripIds[i];
      }
    }
  }

  const differentOptions = _.uniq([
    sortedTripIds[0],
    firstTripPerClass[VEHICLE_CLASS.ECONOMY],
    passedTripPerClass[VEHICLE_CLASS.ECONOMY],
    firstTripPerClass[VEHICLE_CLASS.STANDARD],
    passedTripPerClass[VEHICLE_CLASS.STANDARD],
    firstTripPerClass[VEHICLE_CLASS.BUSINESS],
    passedTripPerClass[VEHICLE_CLASS.BUSINESS],
    firstTripPerClass[VEHICLE_CLASS.FIRST],
    passedTripPerClass[VEHICLE_CLASS.FIRST],
    firstTripPerClass[VEHICLE_CLASS.GREEN_STANDARD],
    passedTripPerClass[VEHICLE_CLASS.GREEN_STANDARD],
  ]).filter(Boolean);

  return {
    differentOptions,
    otherOptions: _.difference(sortedTripIds, differentOptions),
  };
}

/**
 * By given list of available trip ids and an object with categories
 * returns a list of categories with trips.
 */
export const getCategorizedTrips = (available, trips, categories) => {
  const categorizedTrips = Object.keys(categories).reduce((result, index) => {
    const category = categories[index];
    const { differentOptions, otherOptions } = riseFirstOptions(
      trips,
      category.trips
        .filter((trip) => available.indexOf(trip) !== -1)
        .sort(
          (tripA, tripB) => available.indexOf(tripA) - available.indexOf(tripB)
        ),
      category
    );

    if (!differentOptions.length && !otherOptions.length) {
      return result;
    }

    const firstId = differentOptions[0] || otherOptions[0];
    const mainStep = getMainStep(trips[firstId]);

    if (mainStep) {
      return {
        ...result,
        [index]: {
          ...category,
          filter: mainStep.vehicle.type.key,
          type: mainStep.type,
          headTrips: differentOptions.map((trip) => trips[trip]),
          tailTrips: otherOptions.map((trip) => trips[trip]),
          trips: undefined,
        },
      };
    }

    return result;
  }, {});

  return categorizedTrips;
};

export const getAmenities = (steps, returnSteps, path) => {
  return _.compact(
    _.unionBy(
      [..._.get(steps, path, []), ..._.get(returnSteps, path, [])],
      'id'
    )
  );
};
