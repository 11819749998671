import { call, put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  SELECTED_TICKET_FIELD_NAME,
  SELECTED_RETURN_TICKET_FIELD_NAME,
} from 'app/constants';
import { updatePrice, updateTrip } from 'app/actions/book-trip';
import { parseUrlParams } from 'app/sagas/utils';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { trips } from 'mz-sdk';
import {
  getBookTripFormValue,
  isBookTripPage,
  getActiveCurrency,
} from 'app/sagas/selectors';
import { updateScheduledTripPrice } from 'app/actions/scheduled-rides';
import waitForPriceUpdateFinished from 'app/sagas/watchBookTrip/watchUpdatePrice/waitForPriceUpdateFinished';
import _ from 'lodash';

/**
 * This saga is called when user changes departure or return ticket of scheduled ride
 * It updates selected ticket values in form and calls API to get updated price
 */
export default function* (action) {
  const {
    payload: {
      updatedTrip,
      departureTicket,
      returnTicket,
      tripId: originTripId,
      searchId: originSearchId,
    },
  } = action;

  const priceScheduleParams = _.omitBy(
    {
      ticket_id: departureTicket && departureTicket.ticketId,
      alternative_time_index: departureTicket && departureTicket.scheduleId,
      return_ticket_id: returnTicket && returnTicket.ticketId,
      return_alternative_time_index: returnTicket && returnTicket.scheduleId,
    },
    _.isNil
  );

  const isOnBookTripPage = yield select(isBookTripPage);

  // skip handling ticket selection on SRP.
  // Selected values will be stored in state for choosed trip
  if (!isOnBookTripPage) {
    const tripId = updatedTrip ? updatedTrip.id : originTripId;
    const searchId = updatedTrip ? updatedTrip.searchId : originSearchId;

    const priceParams = {
      trip_id: tripId,
      session_id: searchId,
      ...priceScheduleParams,
      currency: yield select(getActiveCurrency),
    };

    try {
      const { finalPriceCompact } = yield call(trips.getPrice, priceParams);
      yield put(
        updateScheduledTripPrice({ tripId, formattedPrice: finalPriceCompact })
      );
    } catch (err) {
      console.log(err); //eslint-disable-line
      // set no price. update failed
      yield put(updateScheduledTripPrice({ tripId, formattedPrice: null }));
    }

    return;
  }

  const departureTicketBeforeChange = yield select(
    getBookTripFormValue,
    SELECTED_TICKET_FIELD_NAME
  );
  const returnTicketBeforeChange = yield select(
    getBookTripFormValue,
    SELECTED_RETURN_TICKET_FIELD_NAME
  );

  if (departureTicket) {
    yield put(
      change(BOOK_TRIP_FORM, SELECTED_TICKET_FIELD_NAME, departureTicket)
    );
  }

  if (returnTicket) {
    yield put(
      change(BOOK_TRIP_FORM, SELECTED_RETURN_TICKET_FIELD_NAME, returnTicket)
    );
  }

  try {
    // update whole BTP form if new trip is available after researching schedule
    if (updatedTrip) {
      const urlParams = yield call(parseUrlParams);

      const updatedTripUrlParams = {
        ...urlParams,
        session_id: updatedTrip.searchId,
        trip_id: updatedTrip.id,
      };

      const newBookTripUrl = `/book${objectToQueryString(
        updatedTripUrlParams
      )}`;

      yield put(updateTrip({ newBookTripUrl, updatedTrip }));
    } else {
      // update only price if changed ticket was without research
      const sourceActionId = action.type;
      yield put(
        updatePrice({ priceParams: priceScheduleParams, sourceActionId })
      );

      // here we wait for update fail or success. Fail throws error
      yield call(waitForPriceUpdateFinished, sourceActionId);
    }
  } catch (err) {
    yield put(
      change(
        BOOK_TRIP_FORM,
        SELECTED_TICKET_FIELD_NAME,
        departureTicketBeforeChange
      )
    );
    yield put(
      change(
        BOOK_TRIP_FORM,
        SELECTED_RETURN_TICKET_FIELD_NAME,
        returnTicketBeforeChange
      )
    );
  }
}
