import React from 'react';
import classnames from 'classnames';
import Icon from 'app/components/base/Icon';

import style from './style.css';

/**
 * Presentational component to show the image of an upgrade item in the book-trip page
 */
const Image = ({ icon, imageUrl, mobile, green }) => {
  const modifiers = {
    [style['icon--mobile']]: mobile,
    [style['icon--green']]: green,
  };
  return (
    <Icon
      family="mozio"
      className={classnames(style.icon, modifiers)}
      src={imageUrl}
    >
      {icon}
    </Icon>
  );
};

// TODO: move to Flow types
// Image.propTypes = {
//   icon: PropTypes.string,
//   imageUrl: PropTypes.string,
//   mobile: PropTypes.bool
// };

export default Image;
