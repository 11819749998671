import React from 'react';
import classnames from 'classnames';

import style from './style.css';

/**
 * General styled link ("a" element)
 * @return {Component}
 */
export const Link = ({
  dark = false,
  uppercase = false,
  className,
  children,
  component = 'a',
  ...props
}) =>
  React.createElement(
    component,
    {
      ...props,
      className: classnames(style.link, style.default, className, {
        [style.dark]: dark,
        [style.uppercase]: uppercase,
      }),
    },
    children
  );

// TODO: move to Flow types
// Link.propTypes = {
//   dark: PropTypes.bool,
//   uppercase: PropTypes.bool,
//   className: PropTypes.string,
//   children: PropTypes.node,
//   component: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.func
//   ])
// };

export default Link;
