// @flow
/* eslint-disable max-len */
import createMessages from 'mz-intl/create';

export default createMessages('NEW_HOME_PAGE', {
  // Search block
  SEARCH_HEADER: 'Interested in our B2B Solutions?',
  SEARCH_HEADER_LINK: 'Visit {link}',
  SEARCH_TITLE:
    'Airport Shuttles, Taxis, Buses, Transfers & Limousines, anytime and everywhere.',
  SEARCH_HOURLY_TITLE: 'Your own personal car & driver on standby',
  SEARCH_FEATURE_FLIGHT_DELAYED: 'Flight Delayed? We wait.',
  SEARCH_FEATURE_FREE_CANCELLATION: 'Online Cancellation on 99% of rides',
  SEARCH_FEATURE_SUPPORT: '24/7 Customer Service',
  // Capabilities
  CAPABILITIES_SEARCH_TITLE: 'Search and Compare',
  CAPABILITIES_SEARCH_SUBTITLE:
    'Anything from Budget Shuttles and Cheap Bus tickets to Affordable Private Cars and Luxury Limousines easily laid out for you to compare and chose.',
  CAPABILITIES_GETTHERE_TITLE: 'Get There Your Way',
  CAPABILITIES_GETTHERE_SUBTITLE:
    'Taxis, Airport Shuttles, Private and Shared Vans, all from curated and trusted local providers.',
  CAPABILITIES_SUPPORT_TITLE: 'Support from start to finish',
  CAPABILITIES_SUPPORT_SUBTITLE:
    '24/7 Customer Support to help with any question or problem you have instantly.',
  // Partnering
  PARTNERING_TITLE: 'Interested in partnering?',
  PARTNERING_SUBTITLE: 'Large brands trust Mozio to transfer their customers',
  PARTNERING_BUTON_TEXT: 'Learn about our business solutions',
  // Recognised brands
  RECOGNIZED_BRANDS_TITLE: 'Search and compare brands you recognize',
  // Download app
  DOWNLOAD_APP_TITLE: 'Download the Mozio mobile app',
  DOWNLOAD_APP_SUBTITLE:
    'The Mozio app lets you access trip details offline, modify reservations on the go, easily contact our 24/7 customer support, and more!',
  // Coupon banner
  COUPON_TITLE: 'Get up to 20% discount for&nbsp;your&nbsp;future&nbsp;trips!',
  COUPON_SUBTITLE:
    'You will visit that amazing place as soon as all the flights restrictions are being lifted. Why don’t you buy a discounted coupon now for your future travels?',
  COUPON_BUTTON_TITLE: 'Buy a coupon',
});
/* eslint-enable max-len */
