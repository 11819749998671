import { styled } from 'mz-ui-kit';

export const VehicleClassContainer = styled('div', () => ({
  maxWidth: '200px',
  marginTop: '10px',
}));

export const MilesWrapper = styled('div', {
  margin: '10px 0',
});
