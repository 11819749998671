import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';

import style from './style.css';

const messages = {
  numVehicles: { id: 'BOOK_TRIP.NUM_VEHICLES' },
  bagsPerVehicle: { id: 'SEARCH_RESULTS.BAGS_PER_VEHICLE' },
  bagsPerPerson: { id: 'SEARCH_RESULTS.BAGS_PER_PERSON' },
  totalBags: { id: 'BOOK_TRIP.TOTAL_BAGS' },
  passengers: { id: 'MOBILE_SEARCH_FORM.PASSENGERS' },
};

/**
 * Stateless component to show the number of passangers and bags.
 * This component should be included in the main priceBreakdown component.
 */
const Details = ({
  numVehicles,
  passengersCount,
  bagsCount,
  bagsPerPerson,
  mobile,
}) => {
  const multipleVehicle = numVehicles > 1;
  const size = multipleVehicle ? '33' : '50';
  const leftAlign = mobile && !multipleVehicle ? 'center end' : 'center center';
  const rightAlign =
    mobile && !multipleVehicle ? 'center start' : 'center center';
  const middleAlign = multipleVehicle ? 'center center' : leftAlign;

  const bagsTargetMessage = bagsPerPerson
    ? messages.bagsPerPerson
    : messages.bagsPerVehicle;

  const bagsLabel = multipleVehicle ? messages.totalBags : bagsTargetMessage;

  const modifiers = {
    [style.mobile]: mobile,
    [style.stretch]: multipleVehicle,
  };

  return (
    <Layout className={classnames(style.container, modifiers)}>
      {multipleVehicle && (
        <Layout
          className={style.section}
          direction="column"
          align={leftAlign}
          size={size}
        >
          <div>
            <Layout size="auto" align="center center">
              <Icon className={style.icon} family="mozio" name="mv-vehicle" />
              <span className={style.label}>{numVehicles}</span>
            </Layout>
            <div className={style.note}>
              <FormattedMessage {...messages.numVehicles} />
            </div>
          </div>
        </Layout>
      )}
      <Layout
        className={style.section}
        direction="column"
        align={middleAlign}
        size={size}
      >
        <div>
          <Layout size="auto" align="center center">
            <Icon className={style.icon} family="mozio" name="sb-passenger" />
            <span className={style.label}>{passengersCount}</span>
          </Layout>
          <div className={style.note}>
            <FormattedMessage
              {...messages.passengers}
              values={{ value: passengersCount }}
            />
          </div>
        </div>
      </Layout>
      <Layout
        className={style.section}
        direction="column"
        align={rightAlign}
        size={size}
      >
        <div>
          <Layout size="auto" align="center center">
            <Icon className={style.icon} family="mozio" name="sb-bags" />
            <span className={style.label}>{bagsCount}</span>
          </Layout>
          <div className={style.note}>
            <FormattedMessage {...bagsLabel} />
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

// TODO: move to Flow types
// Details.propTypes = {
//   numVehicles: PropTypes.number,
//   passengersCount: PropTypes.number,
//   bagsCount: PropTypes.number,
//   bagsPerPerson: PropTypes.bool,
//   mobile: PropTypes.bool
// };

Details.defaultProps = {
  passengersCount: 0,
  bagsCount: 0,
};

export default Details;
