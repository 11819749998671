import _ from 'lodash';
import config from 'config';
import { call, put } from 'redux-saga/effects';
import { initialize } from 'redux-form';
import { getHistory } from 'app/history';
import { showErrorNotification, handleRefParam } from 'app/sagas/utils';
import { setLoading, setReservation } from 'app/actions/confirmation';
import doSetupUpgrades from 'app/sagas/watchBookTrip/doSetupUpgrades';
import handleReservation from './handleReservation';
import initPromoteAppForm from './initPromoteAppForm';
import setReservationInformationInChat from './setReservationInformationInChat';
import {
  CONFIRMATION_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_DATETIME_FIELD_NAME,
  RETURN_TRIP_BOOKED,
  RETURN_TRIP_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  PRICE_FIELD_NAME,
} from 'app/constants';

function getReservationPrice(res) {
  if (config.USE_TOTAL_PRICE_ON_CP) {
    return parseFloat(res.trip.totalPrice);
  }
  return parseFloat(res.grossRevenue);
}

/**
 * Get reservation object from SDK and trigger an action
 * to set reservations in the reducer
 * @param  {Object} payload   Object with `id` and `return_id` strings
 */
export default function* getReservations({
  payload: { id, return_id, ondemand },
}) {
  try {
    yield put(setLoading(true));
    yield call(handleRefParam);

    // Get reservations in parallel
    let [departing, returning] = yield [
      (!!id && call(handleReservation, id, !!ondemand)) || null,
      (!!return_id && call(handleReservation, return_id, !!ondemand)) || null,
    ];

    // When no reservation received, just redirect to home page
    if (_.isEmpty(departing) && _.isEmpty(returning)) {
      const history = yield call(getHistory);
      yield call(history.push, '/');
      return;
    }

    // if only return reservation left - treat it as departure
    if (_.isEmpty(departing) && !_.isEmpty(returning)) {
      departing = returning;
      returning = null;
    }

    // Reservations endpoint ignores whether it's a return trip,
    // so returnSteps will always be empty. For returning trip,
    // we need to force this.
    if (!_.isEmpty(returning)) {
      departing.trip.returnSteps = returning.trip.steps;
      returning.trip.returnSteps = returning.trip.steps;
    }
    // console.log(departing, returning)
    // SDK could return empty array if reservation not found.
    // Normalize it to be null in this case.
    yield put(setReservation({ departing, returning }));

    // on CP we don't have final price so take it from trip obj
    // and we need to add departing and returning price
    let totalPrice = getReservationPrice(departing);
    if (returning && returning.trip) {
      const departingPrice = getReservationPrice(departing);
      const returnPrice = getReservationPrice(returning);
      totalPrice = (departingPrice + returnPrice).toFixed(2);
    }
    const finalPrice = `${totalPrice} ${departing.trip.price.currencyCode}`;

    // Set trips in book trip form, because many components of CP
    // rely on it
    const returninFields = !returning
      ? {}
      : {
          [RETURN_TRIP_FIELD_NAME]: returning.trip,
          [RETURN_DATETIME_FIELD_NAME]: returning.pickupTime,
          [RETURN_BOOK_STATE_FIELD_NAME]: RETURN_TRIP_BOOKED,
        };

    yield put(
      initialize(CONFIRMATION_FORM, {
        ...returninFields,
        [DEPARTING_TRIP_FIELD_NAME]: departing.trip,
        [PRICE_FIELD_NAME]: { finalPrice },
      })
    );

    yield call(initPromoteAppForm, departing || returning || {});

    yield call(doSetupUpgrades, CONFIRMATION_FORM);
    yield put(setLoading(false));
    yield call(setReservationInformationInChat, departing);
  } catch (error) {
    yield call(showErrorNotification, { error });
  }
}
