import React from 'react';
import classnames from 'classnames';

import style from '../style.css';

/**
 * Component for showing empty suggestions results list.
 * (ehen server returns no suggestions for the request)
 * @return {Component}
 */
export const EmptySuggestions = ({ className, text, ...props }) => (
  <p className={classnames(className, style.emptySuggestions)} {...props}>
    {text}
  </p>
);
EmptySuggestions.defaultProps = {
  text: 'There is no suggestion for your search request',
};

// TODO: move to Flow types
// EmptySuggestions.propTypes = {
//   text: PropTypes.node,
//   className: PropTypes.string
// };

export default EmptySuggestions;
