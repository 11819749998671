import { fork } from 'redux-saga/effects';
import watchTripMode from './watchTripMode';
import watchCoupon from './watchCoupon';
import watchAmenity from './watchAmenity';
import watchUpdatePrice from './watchUpdatePrice';
import watchGratuity from './watchGratuity';
import watchBookingFee from './watchBookingFee';
import watchChangePickupTime from './watchChangePickupTime';
import watchKeepOriginalTime from './watchKeepOriginalTime';
import watchUpdateTrip from './watchUpdateTrip';
import watchConfirmBooking from './watchConfirmBooking';
import watchDoBookTrip from './watchDoBookTrip';
import watchLoadBookTrip from './watchLoadBookTrip';
import watchSelectCustomer from './watchSelectCustomer';
import watchFlightInformation from './watchFlightInformation';
import watchSubmitErrors from './watchSubmitErrors';
import watchLeaveBookTrip from './watchLeaveBookTrip';
import watchAccountId from './watchAccountId';
import watchThreeDSecure from './watchThreeDSecure';
import watchEmailChange from './watchEmailChange';
import watchTermsAndConditions from './watchTermsAndConditions';
import rideDuration from './rideDuration';

/**
 * Book trip page logic
 * @return {Generator}
 */
export default function* watchBookTrip() {
  yield fork(watchLoadBookTrip);
  yield fork(watchLeaveBookTrip);
  yield fork(watchDoBookTrip);
  yield fork(watchTripMode);
  yield fork(rideDuration);
  yield fork(watchCoupon);
  yield fork(watchAmenity);
  yield fork(watchUpdatePrice);
  yield fork(watchGratuity);
  yield fork(watchBookingFee);
  yield fork(watchChangePickupTime);
  yield fork(watchKeepOriginalTime);
  yield fork(watchUpdateTrip);
  yield fork(watchConfirmBooking);
  yield fork(watchSelectCustomer);
  yield fork(watchFlightInformation);
  yield fork(watchSubmitErrors);
  yield fork(watchAccountId);
  yield fork(watchThreeDSecure);
  yield fork(watchEmailChange);
  yield fork(watchTermsAndConditions);
}
