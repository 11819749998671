import { fork } from 'redux-saga/effects';
import watchSaveLocation from './watchSaveLocation';
import watchLoadLocations from './watchLoadLocations';
import watchDeleteLocation from './watchDeleteLocation';

export default function* favouriteLocations() {
  yield fork(watchSaveLocation);
  yield fork(watchLoadLocations);
  yield fork(watchDeleteLocation);
}
