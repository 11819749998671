import _ from 'lodash';
import { getHistory, getLocaleFreePathname } from 'app/history';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { call, select } from 'redux-saga/effects';
import { handleRefParam } from 'app/sagas/utils';
import { getCurrency, getLocation, getSavedSearch } from 'app/sagas/selectors';

const allowedParams = [
  'ta',
  'wl',
  'staging_api',
  'testing_api',
  'gid',
  'guid',
  'airline',
  'airline2',
  'flight_number',
  'flight_number2',
  'customer_special_instructions',
  'partner_tracking_id',
  'coupon_code',
];

export default function* doBookTrip({
  payload: { session_id, trip_id, ondemand },
}) {
  const currency = yield select(getCurrency);
  const location = yield select(getLocation);
  const ref = yield call(handleRefParam);
  const query = _.pick(location.query, allowedParams);

  let params = { session_id, trip_id, currency, ...query, ...ref };
  if (ondemand) params.ondemand = 1;

  const { customer_id } = yield select(getSavedSearch);
  if (customer_id) {
    params = { ...params, customer_id };
  }

  // In our spagetti sagas code we call `doBookTrip` twise
  // when the user click to the "book" button on the search results,
  // which push to history "/book" page two times. In that case, when the
  // user click "back" button, he won't go to the search results page
  // To DIRTY fix that, we use "replace" when the page is already "/book"
  const history = yield call(getHistory);
  const pathname = getLocaleFreePathname(history.location.pathname);
  const updateFunc = pathname === '/book' ? history.replace : history.push;
  yield call(updateFunc, `/book${objectToQueryString(params)}`);
}
