/**
 * Returns flatten messages object from tree messages object
 * @param  {Object} messagesTree
 * @return {Object}
 */
function createMessagesObject(messagesTree, prefix = '') {
  return Object.keys(messagesTree).reduce((acc, k) => {
    const val = messagesTree[k];
    if (typeof val === 'string') {
      return { ...acc, [`${prefix}${k}`]: val };
    }
    return { ...acc, ...createMessagesObject(val, `${prefix}${k}.`) };
  }, {});
}

export default createMessagesObject;
