import React from 'react';
import CN from 'classnames';
import { Layout } from 'app/components/base/Layout';
import Passengers from './Passengers';
import Bags from './Bags';
import config from 'config';

import style from './style.css';

const extendedTripView = config.SRP_EXTENDED_TRIP_DETAILS;

const BagsAndPassengers = ({
  className,
  mobile,
  maxPassengers,
  maxBags,
  isMaxBagsPerPerson,
  isTrain,
}) => (
  <Layout
    className={CN(className, { [style.mobile]: mobile })}
    align={extendedTripView ? 'start start' : 'start center'}
    direction={extendedTripView ? 'column' : 'row'}
  >
    <Passengers
      isTrain={isTrain}
      maxPassengers={maxPassengers}
      mobile={mobile}
    />
    <Bags
      isTrain={isTrain}
      isMaxBagsPerPerson={isMaxBagsPerPerson}
      maxBags={maxBags}
      mobile={mobile}
    />
  </Layout>
);

// TODO: move to Flow types
// BagsAndPassengers.propTypes = {
//   className: PropTypes.string,
//   isTrain: PropTypes.bool,
//   isMaxBagsPerPerson: PropTypes.bool,
//   maxPassengers: PropTypes.number,
//   maxBags: PropTypes.number,
//   mobile: PropTypes.bool
// };

export default BagsAndPassengers;
