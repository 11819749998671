import React from 'react';
import { getLocaleFreePathname } from 'app/history';
import { getDomainConfig } from 'app/utils/browser';
import { findLanguage } from 'mz-intl/core/languages';
import { Route, Redirect } from 'react-router-dom';

const LocaleRoute = ({ children }) => (
  <Route path="/:locale([a-z]{2}|[a-z]{2}-[a-z]{2})">
    {({ match, location }) => {
      const requestLocale = (match?.params.locale || '').toLowerCase().trim();
      const defaultLang = findLanguage(getDomainConfig().locale || '');
      const actualLang = findLanguage(requestLocale, defaultLang.value);

      if (!requestLocale || actualLang.value !== requestLocale) {
        const localeFreePath = getLocaleFreePathname(location.pathname);
        const newPathname = `/${actualLang.value}/${localeFreePath}`.replace(
          /\/{2,}/g,
          '/'
        );

        return <Redirect to={{ ...location, pathname: newPathname }} />;
      }

      return children(actualLang.value);
    }}
  </Route>
);

export default LocaleRoute;
