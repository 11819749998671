import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

/**
 * Shorthand for using 'react-router-dom''s Link or general link
 * when the url is external
 * @return {Component}
 */
export const SmartLink = ({ children, to, ...props }) => {
  if (to && to.startsWith('http')) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    );
  }
  return (
    <RouterLink to={to} {...props}>
      {children}
    </RouterLink>
  );
};

// TODO: move to Flow types
// SmartLink.propTypes = {
//   children: PropTypes.node,
//   to: PropTypes.string
// };

export default SmartLink;
