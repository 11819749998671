import APIError from 'mz-sdk/client/APIError';
import queryStringToObject from 'mz-utils/queryStringToObject';
import { getFullStorySessionURL } from 'app/utils/fullstory';
import { StripeError } from 'mz-ui-kit/form/PaymentButton/StripeError';
import { select, call } from 'redux-saga/effects';
import { pushLoggly } from 'app/utils/analytics';
import { getLocation } from 'app/sagas/selectors';

export function* trackPageView() {
  // FIXME: disabled to save some Loggly resources
  // const location = yield select(getLocation);
  // const query = queryStringToObject(location.search);
  // const sessionId = query.session_id || '';
  // const path = (location && location.pathname) || '';
  //
  // const logData = {
  //   logType: 'pageView',
  //   searchSessionId: sessionId,
  //   path,
  //   query
  // };
  // yield call(pushLoggly, logData);
}

export function* trackSales(reservations, trip, price) {
  yield reservations.map((r) =>
    call(pushLoggly, {
      logType: 'bookingSuccess',
      tripId: trip.id,
      searchId: trip.searchId,
      priceUsd: price.finalPriceUsd,
      mozioProfitUsd: r.mozioProfitUsd,
      partnerProfitUsd: r.partnerProfitUsd,
      reservationId: r.trip.id,
      fullstoryUrl: getFullStorySessionURL(),
    })
  );
}

export function* trackSearches(/* query */) {
  // FIXME: disabled to save some Loggly resources
  // const logData = {
  //   logType: 'searches',
  //   queryData: query
  // };
  // yield call(pushLoggly, logData);
}

export function* trackNoSearchResults(query) {
  const logData = {
    logType: 'emptySearches',
    queryData: query,
  };
  yield call(pushLoggly, logData);
}

export function* trackGeocodeError(errorMsg) {
  const logData = {
    logType: 'geocodeError',
    error: { message: errorMsg },
  };
  yield call(pushLoggly, logData);
}

export function* trackNotification(data) {
  const location = yield select(getLocation);
  const logData = {
    logType: 'userNotification',
    path: location.pathname,
    query: queryStringToObject(location.search),
    userNotification: data,
  };
  yield call(pushLoggly, logData);
}

export function* trackInvalidPhone(err, { code, number }) {
  const logData = {
    logType: 'formValidation',
    error: { message: err.message },
    value: {
      code,
      number,
    },
  };
  yield call(pushLoggly, logData);
}

export function* trackMalformedData(message, value) {
  const logData = {
    logType: 'malformedData',
    error: { message },
    value,
  };
  yield call(pushLoggly, logData);
}

export function* trackFailedBooking(resData, trip, price, error) {
  const normError = error || {};
  const errorOrigin =
    normError instanceof StripeError
      ? 'stripe'
      : normError instanceof APIError
      ? 'api'
      : 'unknown';

  const logData = {
    logType: 'bookingFailed',
    errorCode: error.errorCode || error.code || 'unknown',
    errorOrigin,
    stripeSourceId: normError.source?.id,
    apiResponse: JSON.stringify(normError.response),
    searchId: trip.searchId,
    tripId: trip.id,
    priceUsd: price.finalPriceUsd,
    fullstoryUrl: getFullStorySessionURL(),
  };
  yield call(pushLoggly, logData);
}

export function* trackPriceJump({
  originalSearchId,
  originalTripId,
  newSearchId,
  newTripId,
  oldPriceUsd,
  newPriceUsd,
}) {
  const logData = {
    logType: 'priceJump',
    searchId: originalSearchId,
    tripId: originalTripId,
    priceUsd: oldPriceUsd,
    newPriceUsd: newPriceUsd,
    newSearchId: newSearchId,
    newTripId: newTripId,
  };
  yield call(pushLoggly, logData);
}
