import React from 'react';
import config from 'config';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { hook } from 'app/utils/hook';
import {
  getUser,
  getBookingFee,
  isTravelAgent,
  isMasterTravelAgent,
} from 'app/sagas/selectors';
import { saveBookingFee, closeBookingFeeHint } from 'app/actions/book-trip';
import {
  BOOK_TRIP_FORM,
  BOOKING_FEE_STATUS,
  PRICE_FIELD_NAME,
  ACCOUNT_ID_STATUS,
  SHOW_BOOK_FEE_HOOK,
  ALLOW_CHANGE_FEE_HOOK,
} from 'app/constants';

export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);

  const { bookingFee, bookingFeeValue, bookingFeeOriginal } = selector(
    state,
    PRICE_FIELD_NAME
  );
  const { updating, showHint } = selector(state, BOOKING_FEE_STATUS) || {};
  const accountIdStatus = selector(state, ACCOUNT_ID_STATUS) || {};
  const bookingFeeData = getBookingFee(state);
  const user = getUser(state);
  const isTACredits = user && user.travel_agents_credits;
  const valuePrefix = bookingFeeData.currency;
  const defaultBookingFee =
    (bookingFeeData.percentage
      ? bookingFeeValue
      : bookingFeeData.value || bookingFeeValue) || 0;

  const showFeeDefault =
    config.EDITABLE_BOOKING_FEE &&
    (isTravelAgent(state) || !!(accountIdStatus.savedValue && isTACredits));
  const showFee = hook({
    name: SHOW_BOOK_FEE_HOOK,
    args: [user, showFeeDefault],
    defaultHandler: () => showFeeDefault,
  });

  const allowChangeFeeDefault =
    showFee &&
    (isMasterTravelAgent(state) ||
      !user ||
      !user.company ||
      !user.company.use_master_commission);
  const allowChangeFee = hook({
    name: ALLOW_CHANGE_FEE_HOOK,
    args: [user, allowChangeFeeDefault],
    defaultHandler: () => allowChangeFeeDefault,
  });

  return {
    showFee,
    allowChangeFee,
    updating,
    showHint,
    bookingFee,
    bookingFeeOriginal,
    user,
    valuePrefix,
    defaultBookingFee,
  };
};

export const builder = (ComposedComponent) => {
  const WrappedComponent = ({ showFee, ...props }) =>
    showFee && <ComposedComponent {...props} />;

  // TODO: move to Flow types
  // WrappedComponent.propTypes = {
  //   formName: PropTypes.string,
  //   showFee: PropTypes.bool.isRequired
  // };

  return connect(mapStateToProps, {
    saveBookingFee,
    closeBookingFeeHint,
  })(WrappedComponent);
};

export default builder;
