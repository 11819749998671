export const SEARCH_FORM_SESSION_STORAGE = 'mz-searchform';
export const REF_PARAM_SESSION_STORAGE = 'mz-ref-param';

// Errors
export const REQUIRED_ERROR_TYPE = 'REQUIRED_ERROR_TYPE';
export const INVALID_ERROR_TYPE = 'INVALID_ERROR_TYPE';
export const INVALID_EMAIL_ERROR_TYPE = 'INVALID_EMAIL_ERROR_TYPE';
export const INVALID_PHONE_NUMBER_ERROR_TYPE =
  'INVALID_PHONE_NUMBER_ERROR_TYPE';
export const NOT_SAME_EMAIL_ERROR_TYPE = 'NOT_SAME_EMAIL_ERROR_TYPE';
export const MUST_AGREE_TNC_ERROR_TYPE = 'MUST_AGREE_TNC_ERROR_TYPE';
export const NOT_SAME_PASSWORD_ERROR_TYPE = 'NOT_SAME_PASSWORD_ERROR_TYPE';
export const FLIGHT_REQUIRED_ERROR_TYPE = 'VALIDATION.FLIGHT_REQUIRED';
export const PHONE_CODE_REQUIRED = 'VALIDATION.PHONE_CODE_REQUIRED';
export const PHONE_NUMBER_REQUIRED = 'VALIDATION.PHONE_NUMBER_REQUIRED';

// State values
export const ONE_WAY_MODE_VALUE = 'one_way';
export const ROUND_TRIP_MODE_VALUE = 'round_trip';
export const HOURLY_MODE_VALUE = 'hourly';
export const PARKING_MODE_VALUE = 'parking';

// Notifications
export const NOTIFICATION_SUCCESS = 'success';
export const NOTIFICATION_INFO = 'info';
export const NOTIFICATION_WARNING = 'warning';
export const NOTIFICATION_ERROR = 'error';

// Transport Types
export const TRANSPORT_CAR = 'car';
export const TRANSPORT_TAXI = 'taxi';
export const TRANSPORT_RAIL = 'rail';
export const TRANSPORT_BUS = 'bus';
export const TRANSPORT_FERRY = 'ferry';

// Search results category types
export const ON_DEMAND_CATEGORY = 'ondemand';

// Confirmation page
export const CONFIRMATION_FORM = 'confirmationForm';

// Search form field names
export const SEARCH_FORM_NAME = 'searchForm';
export const IS_FLIGHT_TIME_FIELD_NAME = 'is_flight_time';
export const IS_RETURN_FLIGHT_TIME_FIELD_NAME = 'is_return_flight_time';

// Book trip form and fields
export const BOOK_TRIP_FORM = 'bookTrip';
export const CONSENT_EMAIL_FIELD_NAME = 'consent_for_contact';
export const AGREE_WITH_TERMS_FIELD_NAME = 'agree_with_terms';
export const BOOK_TRIP_UPDATED_MODAL_FORM = 'bookTripUpdatedModal';
export const RETURN_TRIP_FIELD_NAME = 'return_trip';
export const DEPARTING_TRIP_FIELD_NAME = 'departing_trip';
export const RETURN_BOOK_STATE_FIELD_NAME = 'return_book_state';

export const DEPARTING_FLIGHT_FIELD_NAME = 'departing_flight';
export const RETURNING_FLIGHT_FIELD_NAME = 'returning_flight';

export const DEPARTING_CRUISE_FIELD_NAME = 'departing_cruise';
export const DEPARTING_SHIP_FIELD_NAME = 'departing_ship';

export const RETURN_DATETIME_FIELD_NAME = 'return_datetime';

export const DEPARTING_PICKUP_FIELD_NAME = 'departing_pickup_datetime';
export const RETURNING_PICKUP_FIELD_NAME = 'returning_pickup_datetime';
export const ALTERNATIVE_TIME_INDEX = 'alternative_time_index';
export const RETURN_ALTERNATIVE_TIME_INDEX = 'return_alternative_time_index';
export const TRIP_PICKUP_TIMES_ORIGIN_TZ_FIELD_NAME =
  'trip_pickup_times_origin_tz';
export const SELECTED_TICKET_FIELD_NAME = 'selectedTicket';
export const SELECTED_RETURN_TICKET_FIELD_NAME = 'selectedReturnTicket';
export const RIDE_DURATION_FIELD_NAME = 'hourly_booking_duration';

export const COUPON_CODE_FIELD_NAME = 'coupon_code';
export const COUPON_STATUS = 'coupon_status';
export const START_RETURN_BOOKING = 'START_RETURN_BOOKING';
export const RETURN_TRIP_BOOKED = 'RETURN_TRIP_BOOKED';
export const RETURN_DATE_SELECTED = 'RETURN_DATE_SELECTED';

export const UPGRADES_FIELD_NAME = 'upgrades';
export const PRICE_FIELD_NAME = 'price';
export const BOOKING_FEE_FIELD_NAME = 'booking_fee';
export const BOOKING_FEE_STATUS = 'booking_fee_status';

export const LOYALTY_MEMBER_NUMBER_FIELD_NAME = 'loyalty_number';
export const FIRST_NAME_FIELD_NAME = 'ci__first_name';
export const LAST_NAME_FIELD_NAME = 'ci__last_name';
export const EMAIL_FIELD_NAME = 'ci__email';
export const EMAIL_CONFIRMATION_FIELD_NAME = 'ci__email_confirmation';
export const PHONE_CODE_FIELD_NAME = 'ci__phone_code';
export const PHONE_NUMBER_FIELD_NAME = 'ci__phone_number';
export const NO_PHONE_SERVICE_UPON_LANDING_FIELD_NAME =
  'ci__no_phone_service_upon_landing';
export const SPECIAL_INSTRUCTIONS_FIELD_NAME = 'ci__special_instructions';
export const PASSWORD_FIELD_NAME = 'ci__password';
export const TRAVELER_FIELD_NAME = 'ci__traveler';
export const TRAVELER_FIRST_NAME_FIELD_NAME = 'ci__traveler_first_name';
export const TRAVELER_LAST_NAME_FIELD_NAME = 'ci__traveler_last_name';
export const TRAVELER_PHONE_CODE_FIELD_NAME = 'ci__traveler_phone_code';
export const TRAVELER_PHONE_NUMBER_FIELD_NAME = 'ci__traveler_phone_number';
export const TRAVELER_EMAIL_FIELD_NAME = 'ci__traveler_email';
export const MEET_AND_GREET_BOX_CLOSED = 'MEET_AND_GREET_BOX_CLOSED';
export const TA_CONFIRMATION_EMAIL_FIELD_NAME =
  'ci__travel_agent_confirmation_email';
export const DEFAULT_CURRENCY_FIELD_NAME = 'currency';
export const DEFAULT_LANGUAGE_FIELD_NAME = 'language';
export const CURRENCY_OBJECT_FIELD_NAME = 'currency';
export const COUNTRY_CODE_OBJECT_FIELD_NAME = 'country_code_object';
export const LANGUAGE_OBJECT_FIELD_NAME = 'langauge_object';
export const PHONE_FIELD_NAME = 'phone_number';
export const USER_INFORMATION_FORM = 'user_information_form';

export const SELECTED_CREDIT_CARD_ID = 'selected_credit_card_ID';
export const GRATUITY_FIELD_NAME = 'gratuity_amount';
export const GRATUITY_STATUS = 'gratuity_status';

export const SELECTED_CUSTOMER_PROFILE = 'SELECTED_CUSTOMER_PROFILE';

export const SESSION_ID_FIELD_NAME = 'session_id';

export const ACCOUNT_ID_FIELD_NAME = 'account_id';
export const ACCOUNT_ID_STATUS = 'account_id_status';

export const SAVE_TA_PROFILE_FIELD_NAME = 'save_ta_profile';
export const SAVE_TA_CC_FIELD_NAME = 'save_ta_cc';

export const DEPARTING_RELATIVE_PICKUP_FIELD_NAME =
  'departing_rel_pickup_datetime';
export const RETURNING_RELATIVE_PICKUP_FIELD_NAME =
  'returning_rel_pickup_datetime';

export const TRAIN_INFO_FIELD_NAME = 'train_info';
export const RETURN_TRAIN_INFO_FIELD_NAME = 'return_train_info';

// TODO Move to the SDK
export const UPGRADE_IDS = {
  // used same value as key in amenities from API
  // https://api-staging-internal.mozio.com/v2/docs/#!/pricing/Pricing_get_pricing
  MEET_AND_GREET: 'meet_and_greet',
  // other upgrades
  INSURANCE_COVERAGE: 'insuranceCoverage',
  TOLLS: 'tolls',
  GRATUITY: 'gratuity',
  TOLLS_AND_GRATUITY: 'tollsAndGratuity',
  FLEXIBLE_CANCEL_POLICY: 'flexible_cancel',
  SMS_NOTIFICATION: 'sms_notifications',
  ESIM: 'e_sim',
  CARBON_OFFSET: 'carbon_offset',
};

// Credit card form fields
export const CC_ADD_NEW_OPENED = 'CC_ADD_NEW_OPENED';
export const CC_FIRST_NAME_FIELD = 'first_name';
export const CC_LAST_NAME_FIELD = 'last_name';
export const CC_NUMBER_FIELD = 'number';
export const CC_EXPIRY_FIELD = 'expiry';
export const CC_CVC_FIELD = 'cvc';
export const CC_DESCRIPTION_FIELD = 'description';

// Update agent profile form and fields
export const TA_AGENT_PROFILE_FORM = 'TA_AGENT_PROFILE_FORM';
export const TA_PROFILE_COMPANY_NAME = 'tap__company_name';
export const TA_PROFILE_TAX_ID = 'tap__tax_id';
export const TA_PROFILE_COMMISSION = 'tap__commission';
export const TA_PROFILE_COMMISSION_PERCENTS = 'tap__commission_percents';
export const TA_PROFILE_CURRENCY = 'tap__currency';

// Update TA company info form and fields
export const TA_COMPANY_INFO_FORM = 'taCompanyInfoForm';
export const TA_CI_COMPANY_NAME_FIELD_NAME = 'taci__company_name';
export const TA_CI_PAYOUT_TO_MASTER_FIELD_NAME = 'taci__payout_to_master';
export const TA_CI_ALLOW_CHANGE_FEE_FIELD_NAME = 'taci__allow_change_fee';

// Travel Agent register form
export const TA_EMAIL_FIELD_NAME = 'email';
export const TA_CONFIRM_EMAIL_FIELD_NAME = 'confirm_email';
export const TA_FIRST_NAME_FIELD_NAME = 'first_name';
export const TA_LAST_NAME_FIELD_NAME = 'last_name';
export const TA_CALLING_CODE_FIELD_NAME = 'country_code_name';
export const TA_PHONE_FIELD_NAME = 'phone_number_national';
export const TA_PASSWORD_FIELD_NAME = 'password';
export const TA_CONFIRM_PASSWORD_FIELD_NAME = 'confirm_password';
export const TA_COUNTRY_FIELD_NAME = 'country';
export const TA_CURRENCY_FIELD_NAME = 'currency';
export const TA_COMPANY_FIELD_NAME = 'company';
export const TA_COMPANY_NAME_FIELD_NAME = 'company_name';
export const TA_COMPANY_CODE_FIELD_NAME = 'company_code';
export const TA_IATA_FIELD_NAME = 'iata_code';
export const TA_AGENT_ID_FIELD_NAME = 'external_agent_id';

// TA Dashboard booking report form
export const BOOKING_REPORT_FORM = 'bookingReportForm';
export const REPORT_START_MONTH = 'ta__report__start_month';
export const REPORT_START_YEAR = 'ta__report__start_year';
export const REPORT_END_MONTH = 'ta__report__end_month';
export const REPORT_END_YEAR = 'ta__report__end_year';
export const REPORT_IS_TRAVEL_DATE = 'ta__report__travel_date';
export const REPORT_INCLUDE_WITHDRAWN = 'ta__report__include_withdrawn';
export const REPORT_DATES_ERROR = 'REPORT_DATES_ERROR';

// TA Customer profile form
export const TA_CUSTOMER_PROFILE_FORM = 'TA_CUSTOMER_PROFILE_FORM';
export const TA_CUSTOMER_PROFILE_NAME = 'tacp_profile_name';
export const TA_CUSTOMER_FIRST_NAME = 'tacp_first_name';
export const TA_CUSTOMER_LAST_NAME = 'tacp_last_name';
export const TA_CUSTOMER_EMAIL = 'tacp_email';
export const TA_CUSTOMER_CONFIRM_EMAIL = 'tacp_confirm_email';
export const TA_CUSTOMER_PHONE_CODE = 'tacp_phone_code';
export const TA_CUSTOMER_PHONE_NUMBER = 'tacp_phone_number';

// TA Payment settings form
export const TA_PAYMENTS_ACCOUNT_FORM = 'TA_PAYMENT_ACCOUNT_FORM,';
export const TA_PAYMENTS_ACCOUNT_TYPE = 'tapf_account_type';
export const TA_PAYMENTS_PAYPAL_EMAIL = 'tapf_paypal_email';

// TA Sales Balance form
export const TA_SALES_BALANCE_FORM = 'taSalesBalanceForm';
export const TA_SALES_BALANCE_MONTH = 'tasb__month';

// TA Withdrawals form
export const TA_WITHDRAWALS_FORM = 'TA_WITHDRAWALS_FORM';
export const TA_WITHDRAWALS_ACCOUNT_FIELD = 'taw_account';

// Path names
export const SEARCH_RESULTS_PAGE_PATH = '/search';

// Customer feedback form
export const CUSTOMER_FEEDBACK_FORM = 'CUSTOMER_FEEDBACK_FORM';
export const CUSTOMER_FEEDBACK_COMMENT = 'comment';
export const CUSTOMER_FEEDBACK_RATING = 'rating';

// Stripe input names
export const STRIPE_CARDNUMBER_INPUT_NAME = 'cardnumber';
export const STRIPE_CVC_INPUT_NAME = 'cvc';
export const STRIPE_EXPIRY_INPUT_NAME = 'exp-date';

// Reservation filter columns
export const FILTER_FIRST_NAME = 'Customer First Name';
export const FILTER_LAST_NAME = 'Customer Last Name';
export const FILTER_DATE_OF_BOOKING = 'Date Of Booking';
export const FILTER_CUSTOMER_EMAIL = 'Customer Email';
export const FILTER_AGENT_EMAIL = 'Agent Email';
export const FILTER_RESERVATION_ID = 'Reservation ID';
export const FILTER_BOOKING_DATE = 'Booking Date';
export const FILTER_SERVICE_DATE = 'Service Date';

// Reservation tabs
export const PRESENT_RESERVATIONS_TAB = 0;
export const PAST_RESERVATIONS_TAB = 1;
