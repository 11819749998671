import { createReducer } from '../utils/redux';
import {
  SET_RESERVATION_DATA,
  SET_RIDE_DATA,
  UPDATE_RIDE_POSITION,
  SET_TRACKING_MAP_LOADING,
} from '../constants';

const initialState = {
  loading: false,
  rideCoords: null,
  startAddress: null,
  endAddress: null,
  trackingId: null,
  trip: null,
  pickupTime: null,
  vehicle: null,
  driver: null,
  status: null,
};

const actionHandlers = {
  [SET_TRACKING_MAP_LOADING]: () => ({
    loading: true,
  }),
  [UPDATE_RIDE_POSITION]: (_, { lat, lng, eta, status }) => ({
    rideCoords: { lat, lng },
    eta,
    status,
  }),
  [SET_RESERVATION_DATA]: (
    _,
    { trackingId, startAddress, endAddress, trip, pickupTime }
  ) => ({
    startAddress,
    endAddress,
    trackingId,
    trip,
    pickupTime,
    loading: false,
  }),
  [SET_RIDE_DATA]: (state, { vehicle, driver }) => ({
    vehicle,
    driver,
    status: vehicle && vehicle.status ? vehicle.status : state.status,
  }),
};

export default createReducer(initialState, actionHandlers);
