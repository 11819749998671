import { formConfig as bookTripFormConfig } from 'app/pages/BookTripPage/builder';
import { actionTypes } from 'redux-form';
import { RIDE_DURATION_FIELD_NAME } from 'app/constants';
import { CHANGE_RIDE_DURATION_SAGA } from '../restoreOriginalRideDuration';

const iRideDurationChanged = (action) => {
  return (
    action.type === actionTypes.CHANGE &&
    action.meta.form === bookTripFormConfig.form &&
    action.meta.field === RIDE_DURATION_FIELD_NAME &&
    action.source !== CHANGE_RIDE_DURATION_SAGA
  );
};

export default iRideDurationChanged;
