import React from 'react';
import { Layout, Flex } from '../Layout';
import Logo from '../Logo';
import Icon from '../Icon';
import config from 'config';

import style from './style.css';

const LogoAndSocialLinksRow = (props) => (
  <Layout
    className={style.logoAndSocialLinks}
    direction="row"
    align="start center"
    nowrap
    {...props}
  >
    <Flex className={style.logo} size="noshrink">
      <Logo customUrl={config.CUSTOM_FOOTER_LOGO} />
    </Flex>
    {!!config.FOOTER_SOCIALS.length && (
      <Flex size="noshrink">
        {config.FOOTER_SOCIALS.map((l, i) => (
          <a
            key={i}
            className={style.icon}
            href={l[1]}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon family="mozio" inline>{`${l[0]}-footer`}</Icon>
          </a>
        ))}
      </Flex>
    )}
    <Flex size="grow" />
  </Layout>
);

export default LogoAndSocialLinksRow;
