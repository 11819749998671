import React from 'react';
import SmartLink from '../SmartLink';
import NavbarButton from './NavbarButton';

/**
 * Shorthand for using 'react-router-dom''s Link instead "a"
 * component
 * @return {Component}
 */
export const NavbarLink = ({ children, ...props }) => (
  <NavbarButton {...props} component={SmartLink}>
    {children}
  </NavbarButton>
);

// TODO: move to Flow types
// NavbarLink.propTypes = {
//   children: PropTypes.node
// };

export default NavbarLink;
