import React from 'react';
import HoverableDropdown from 'app/components/base/HoverableDropdown';

import style from './style.css';

const CategoryTooltip = ({ origin, categoryName, tooltipText }) => (
  <HoverableDropdown
    axis="end"
    direction="right"
    className={style.tooltipContainer}
    origin={origin}
    childrenClassName={style.tooltip}
  >
    <span className={style.tooltipCategoryName}>{categoryName}</span>
    {tooltipText}
  </HoverableDropdown>
);

// TODO: move to Flow types
// CategoryTooltip.propTypes = {
//   origin: PropTypes.any,
//   categoryName: PropTypes.string,
//   tooltipText: PropTypes.string
// };

export default CategoryTooltip;
