// @flow
import React from 'react';
import LanguageSelectorField from 'mz-ui-kit/form/LanguageSelectorField';
import { FormattedMessage } from 'translations';

import type { LanguageDescriptor } from 'mz-intl/core/languages';

type Props = {
  activeLanguage: LanguageDescriptor,
  appearance: 'desktop' | 'mobile',
  availableLanguages: Array<string>,
  onSelect: (selectedLanguage: LanguageDescriptor) => {},
  close: () => {},
};

const messages = {
  placeholder: { id: 'LANGUAGE_PLACEHOLDER' },
};

const LanguageSelector = ({
  activeLanguage,
  appearance,
  availableLanguages,
  onSelect,
  close,
}: Props) => (
  <LanguageSelectorField
    fieldAppearance={appearance}
    placeholder={<FormattedMessage {...messages.placeholder} />}
    availableLanguages={availableLanguages}
    input={{
      value: activeLanguage,
      onChange: ({ value }) => {
        onSelect(value);
        close();
      },
    }}
    optionComparator={(option: any, selectedOption: any) =>
      option.value === selectedOption.value
    }
  />
);

export default LanguageSelector;
