import {
  SELECT_TRIP_FOR_SRP_MAP,
  TOGGLE_SRP_MAP,
  SET_SRP_MAP_DIRECTIONS,
  SET_CACHED_DIRECTION,
  CLEAR_CACHED_DIRECTIONS,
  CLEAR_DIRECTIONS,
} from 'app/constants';
import { createAction } from '../utils/redux';

export const selectTripForSrpMap = createAction(SELECT_TRIP_FOR_SRP_MAP);

export const toggleSrpMap = createAction(TOGGLE_SRP_MAP);

export const setSrpMapDirections = createAction(SET_SRP_MAP_DIRECTIONS);

export const setCachedDirection = createAction(SET_CACHED_DIRECTION);

export const clearCachedDirections = createAction(CLEAR_CACHED_DIRECTIONS);

export const clearDirections = createAction(CLEAR_DIRECTIONS);
