import { setConfig } from 'mz-sdk';
import config from 'config';
import _ from 'lodash';

// List of config fields, that shouldn't be overrided by WL
// config, retrived from WLF
const nonOverrideConfigs = [
  'BASE_URL',
  'BASE_URL_V2',
  'STRIPE_PUBLISHABLE_KEY',
  'STRIPE_DEVELOPMENT_MODE',
  'PUBLIC_PATH',
  'ENV',
  'SHOW_TICKET_PASSES_SELECTION',
  'HERTZ_V2_BOOKING',
];

// Hardcoded list of domains that should be forced to
// load a whitelabel with name from value
// Required only by staging corporate sites
const domainToWhitelabel = {
  'corporate.app-staging.mozio.com': 'corporate',
  'cwt.app-staging.mozio.com': 'corp-cwt',
  'gbt.app-staging.mozio.com': 'corp-gbt',
  'atpi.app-staging.mozio.com': 'corp-atpi',
  'corporate.app-testing.mozio.com': 'corporate',
  'cwt.app-testing.mozio.com': 'corp-cwt',
  'gbt.app-testing.mozio.com': 'corp-gbt',
  'atpi.app-testing.mozio.com': 'corp-atpi',
};

// Utils
const createElement = (tag, attrs = {}) => {
  const tagElem = document.createElement(tag);
  Object.keys(attrs).forEach((k) => {
    tagElem[k] = attrs[k];
  });
  return tagElem;
};

const injectScript = (src) => {
  return new Promise((resolve) => {
    const element = createElement('script', { src });
    let loaded = false;
    element.onload = element.onreadystatechange = function onReady() {
      if (
        !loaded &&
        (!this.readyState ||
          this.readyState === 'loaded' ||
          this.readyState === 'complete')
      ) {
        loaded = true;
        resolve();
      }
    };
    document.body.appendChild(element);
  });
};

const parseQueryArgs = () => {
  const queryDict = {};
  window.location.search
    .substr(1)
    .split('&')
    .forEach((item) => {
      queryDict[item.split('=')[0]] = item.split('=')[1];
    });
  return queryDict;
};

const updateThemeCss = (newThemeCss) => {
  const previewStyleId = 'whitelabel-style';

  let existingStyle = document.getElementById(previewStyleId);
  if (!existingStyle) {
    existingStyle = createElement('style', {
      type: 'text/css',
      id: previewStyleId,
    });
    document.head.appendChild(existingStyle);
  }

  if (existingStyle.styleSheet) {
    existingStyle.styleSheet.cssText = newThemeCss;
  } else {
    existingStyle.appendChild(document.createTextNode(newThemeCss));
  }
};

const updateAppConfig = (appConfig) => {
  Object.assign(config, appConfig, _.pick(config, nonOverrideConfigs));
  setConfig(Object.assign({}, config));
};

const shouldListenChanges = () => {
  try {
    const referrereUrl = new URL(document.referrer);
    return (
      window.self !== window.top &&
      (referrereUrl.hostname === 'wlf-staging.mozio.com' ||
        referrereUrl.hostname === 'wlf.mozio.com' ||
        referrereUrl.hostname === 'localhost')
    );
  } catch (e) {
    return false;
  }
};

export const listenToChanges = (rerenderApp) => {
  if (!shouldListenChanges()) return;

  window.addEventListener('message', function (event) {
    if (event.data.themeCss) {
      updateThemeCss(event.data.themeCss);
    }
    if (event.data.appConfig) {
      updateAppConfig(event.data.appConfig);
    }
    rerenderApp();
  });
};

/**
 * Check `wl` URL query parameter and if provided – try to load whitelabel
 * configs and inject it (with theme styles) to the page.
 * @return {Promise}
 */
export async function loadWhitelabel() {
  const queryDict = parseQueryArgs();
  const forcedWl = domainToWhitelabel[window.location.host];
  const wl = forcedWl || queryDict.wl;
  if (!wl) return;

  // Load config object
  const stagingSuffix = queryDict.staging_wl === '1' ? '-staging-internal' : '';
  const wlfEndpoint = `https://api${stagingSuffix}.mozio.com/v2/whitelabels/whitelabel/`;
  /* eslint-disable */
  await injectScript(`${wlfEndpoint}bootstrap.js?name=${wl}`);
  /* eslint-enable */

  // Check WL is loaded
  const wlObj = window.WL_OBJ;
  if (!wlObj) return;

  // Inject config with critical properties selected from current config
  updateAppConfig(wlObj.config);
  updateThemeCss(wlObj.theme);
}
