import React from 'react';
import CN from 'classnames';
import { FormattedMessage } from 'translations';
import InlineLabel from 'app/components/base/InlineLabel';
import config from 'config';

import style from './style.css';

const extendedTripView = config.SRP_EXTENDED_TRIP_DETAILS;

const messages = {
  passengersPerVehicle: { id: 'SEARCH_RESULTS.PASSENGERS_PER_VEHICLE_DESKTOP' },
  passengersPerVehicleMobile: {
    id: 'SEARCH_RESULTS.PASSENGERS_PER_VEHICLE_MOBILE',
  },
};

const Passengers = ({ isTrain, maxPassengers, mobile }) => {
  if (isTrain || !maxPassengers) return null;

  const extendedMessage = mobile
    ? messages.passengersPerVehicleMobile
    : messages.passengersPerVehicle;

  const passengersText = extendedTripView ? (
    <FormattedMessage
      {...extendedMessage}
      values={{ numPassengers: maxPassengers }}
    />
  ) : (
    maxPassengers
  );

  return (
    <InlineLabel
      className={CN(style.label, { [style.extendedView]: extendedTripView })}
      align="center center"
      icon={{ name: 'srp-passengers', family: 'mozio' }}
      dark
      text={passengersText}
    />
  );
};

// TODO: move to Flow types
// Passengers.propTypes = {
//   isTrain: PropTypes.bool,
//   maxPassengers: PropTypes.number,
//   mobile: PropTypes.bool
// };

export default Passengers;
