import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { FormattedMessage } from 'translations';
import config from 'config';
import DesktopTrip from 'app/components/search-results/Trip/Desktop';
import MobileTrip from 'app/components/search-results/Trip/Mobile';
import TimetableLightbox from '../TimetableLightbox';
import { findMainStep } from 'app/utils/trip';
import {
  getCurrency,
  getSearchForm,
  getSavedSearch,
  getSearchResultsTrips,
  getActiveTripIdForSrpMap,
  getRecommendedTripIds,
} from 'app/sagas/selectors';
import { ON_DEMAND_CATEGORY } from 'app/constants';
import * as searchResultsActions from 'app/actions/search-results';
import { doBookTrip } from 'app/actions/book-trip';
import * as scheduledRidesActions from 'app/actions/scheduled-rides';
import { selectTripForSrpMap } from 'app/actions/srp-map';
import { pushData } from 'app/utils/analytics';

const messages = {
  priceLabel: { id: 'SEARCH_RESULTS.PRICE_LABEL_MOBILE' },
};

/**
 * Component for displaying trip in list of search results.
 * By passed trip Id it gets rest of trip props from state
 */
export class ConnectedTrip extends Component {
  onScheduleDeparture = () => {
    const {
      subpages,
      returnSteps,
      departureTicket: selectedTicket,
      returnTicket: selectedReturnTicket,
    } = this.props;

    const isRoundTrip = returnSteps && returnSteps.length > 0;

    subpages.pushPage(TimetableLightbox, {
      trip: this.props,
      isRoundTrip,
      selectedTicket,
      selectedReturnTicket,
      onConfirm: ({ departureTicket, returnTicket, updatedTrip }) => {
        subpages.popPage();
        this.props.scheduleDepartureTime({
          tripId: this.props.id,
          searchId: this.props.searchId,
          departureTicket,
          returnTicket,
          updatedTrip,
        });
      },
    });
  };

  onTripHover = () => {
    const { id, steps } = this.props;

    this.props.selectTripForSrpMap({
      tripId: id,
      steps,
    });
  };
  onClickBookTrip = () => {
    const { provider, from, to } = findMainStep(this.props.steps);
    const { type, price } = this.props;
    const { mode } = this.props.search;
    pushData('trip-added-to-basket', {
      currency: price.currencyCode,
      totalPrice: price.total,
    });
    this.props.doBookTrip({
      trip_id: this.props.id,
      session_id: this.props.searchId,
      provider: provider.name,
      from: from.location.name,
      to: (to.location && to.location.name) || null,
      ondemand: type === ON_DEMAND_CATEGORY,
      mode,
    });
  };

  render() {
    if (!this.props.steps) return null;
    const {
      bookingDetails: {
        isBookable,
        startAddressIncomplete,
        endAddressIncomplete,
      },
    } = findMainStep(this.props.steps);
    const isAddressIncomplete = startAddressIncomplete || endAddressIncomplete;
    const Trip = this.props.mobile ? MobileTrip : DesktopTrip;

    return (
      <Trip
        {...this.props}
        onScheduleDeparture={this.onScheduleDeparture}
        onClickBookTrip={this.onClickBookTrip}
        onClick={this.onClickBookTrip}
        onTripHover={this.onTripHover}
        isBookable={isBookable}
        isAddressIncomplete={isAddressIncomplete}
        priceLabel={
          config.SHOW_SRP_PRICE_LABEL && (
            <FormattedMessage {...messages.priceLabel} />
          )
        }
      />
    );
  }
}

// TODO: move to Flow types
// ConnectedTrip.propTypes = {
//   id: PropTypes.string.isRequired,
//   searchId: PropTypes.string.isRequired,
//   preferred: PropTypes.bool,
//   recommended: PropTypes.bool,
//   steps: PropTypes.array.isRequired,
//   returnSteps: PropTypes.array,
//   search: PropTypes.object.isRequired,

//   serviceClass: PropTypes.string,
//   duration: PropTypes.number,
//   serviceHoursInfo: PropTypes.string,
//   price: PropTypes.object.isRequired,
//   priceTagline: PropTypes.string,
//   freeCancellation: PropTypes.bool,
//   taxesAndFeesIncluded: PropTypes.bool,
//   timetable: PropTypes.array,
//   tags: PropTypes.array.isRequired,
//   type: PropTypes.string,

//   subpages: PropTypes.object.isRequired,

//   // TODO: this should be removed when api is ready.
//   departureTime: PropTypes.string,

//   // schedule props
//   departureTicket: PropTypes.object,
//   returnTicket: PropTypes.object,

//   route: PropTypes.array,
//   customerId: PropTypes.string,
//   appCurrency: PropTypes.string.isRequired,

//   // Actions
//   scheduleDepartureTime: PropTypes.func.isRequired,
//   doBookTrip: PropTypes.func.isRequired,
//   selectTripForSrpMap: PropTypes.func.isRequired
// };

const mapStateToProps = (state, { id }) => {
  const { customer_id } = getSearchForm(state);
  const trip = getSearchResultsTrips(state)[id];

  return {
    ...trip,
    search: getSavedSearch(state),
    customerId: customer_id,
    appCurrency: getCurrency(state),
    showedOnMap: getActiveTripIdForSrpMap(state) === id,
    isRecommended: getRecommendedTripIds(state).includes(id),
  };
};

const mapDispatchToProps = {
  ...searchResultsActions,
  doBookTrip,
  scheduleDepartureTime: scheduledRidesActions.scheduleDepartureTime,
  selectTripForSrpMap,
};

export default compose(
  connectSubpages(),
  connect(mapStateToProps, mapDispatchToProps)
)(ConnectedTrip);
