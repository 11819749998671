import { call, put, select } from 'redux-saga/effects';
import { trips } from 'mz-sdk';
import {
  startScheduleResearch,
  endScheduleResearch,
  updateSchedule,
  setScheduleUpdateError,
} from 'app/actions/scheduled-rides';
import { VIEW_EARLIER_TIMES, ON_DEMAND_CATEGORY } from 'app/constants';
import { findMainStep } from 'app/utils/trip';
import { getActiveCurrency } from 'app/sagas/selectors';
import transformTicketTypesToMatrix from 'app/components/search-results/TimeTableSelect/utils/transformTicketTypesToMatrix';
import moment from 'moment';

/**
 * This saga is called when user clicks on "View earlier" or "View later" times
 * inside Timetable dialog
 * @param {Object} VIEW_EARLIER_TIMES or VIEW_LATER_TIMES action
 */
export default function* handleScheduleResearch(action) {
  const {
    payload: { isReturn, trip },
    type,
  } = action;

  yield put(startScheduleResearch());

  // prepare research params
  const pickupTimeProp = isReturn
    ? 'return_pickup_datetime'
    : 'pickup_datetime';

  const tripSteps = isReturn ? trip.returnSteps : trip.steps;

  const { ticketTypes } = findMainStep(tripSteps);

  const departures = transformTicketTypesToMatrix(ticketTypes).map(
    (schedule) => schedule.departureDatetime
  );

  const shiftedPickupDatetime =
    type === VIEW_EARLIER_TIMES
      ? moment.min(departures)
      : moment.max(departures);

  const pickupTimePropName = isReturn
    ? 'returnPickupDatetime'
    : 'pickupDatetime';

  // allow shifting schedule only in range of same day
  if (
    shiftedPickupDatetime.get('date') !== trip[pickupTimePropName].get('date')
  ) {
    yield put(endScheduleResearch());
    return;
  }

  const currency = yield select(getActiveCurrency);

  const researchParams = {
    [pickupTimeProp]: shiftedPickupDatetime,
    currency,
  };

  try {
    const updatedTrip = yield call(trips.research, {
      session_id: trip.searchId,
      trip_id: trip.id,
      ondemand: trip.type === ON_DEMAND_CATEGORY,
      params: researchParams,
    });

    if (updatedTrip) {
      yield put(updateSchedule({ trip: updatedTrip }));
    } else {
      yield put(setScheduleUpdateError({ error: "Can't load schedule" }));
    }

    yield put(endScheduleResearch());
  } catch (err) {
    yield put(
      setScheduleUpdateError({
        error: "Can't load schedule",
        message: err.message,
      })
    );
    yield put(endScheduleResearch());
  }
}
