// @flow
import * as React from 'react';
import InfoLabel from '../InfoLabel';
import HelpTooltip from 'mz-ui-kit/core/HelpTooltip';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';

const RideshareLabel = () => (
  <InfoLabel color="green">
    <b style={{ marginRight: '0.5em' }}>
      <FormattedMessage message={messages.RIDESHARE} />
    </b>
    <HelpTooltip
      width="230px"
      content={() => <FormattedMessage message={messages.RIDESHARE_TOOLTIP} />}
      size="normal"
    />
  </InfoLabel>
);

export default RideshareLabel;
