import { call, put, select } from 'redux-saga/effects';
import { setSrpMapDirections, clearDirections } from 'app/actions/srp-map';
import {
  getSearchResultsTrips,
  getActiveTripIdForSrpMap,
} from 'app/sagas/selectors';
import getTripDirections from '../watchHoverOnTrip/getTripDirections';

export default function* handleToggleMap(action) {
  let trip = action.payload.trip;

  // trip is passed in mobile mode
  // here we clear previous directions to avoid rendering previous result
  if (trip) {
    yield put(clearDirections());
  }

  // get trip from search results when hover was before map is opened
  const tripId = yield select(getActiveTripIdForSrpMap);
  if (tripId) {
    const searchTrips = yield select(getSearchResultsTrips);
    trip = searchTrips[tripId];
  }
  if (trip) {
    const directions = yield call(getTripDirections, trip);

    yield put(setSrpMapDirections({ directions }));
  }
}
