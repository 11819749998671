import { put, call } from 'redux-saga/effects';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { getCustomerProfileList } from 'app/actions/travel-agent';

export default function* deleteCustomerProfile(action) {
  try {
    const { profileId } = action.payload;
    yield call(travelagent.deleteCustomerProfile, {
      profile_id: profileId,
    });
    const getCustomerProfileListAction = yield call(getCustomerProfileList);
    yield put(getCustomerProfileListAction);
    yield call(showSuccessNotification, {
      messageId: 'TA_ACCOUNT.CUSTOMER_PROFILES.PROFILE_DELETED_NOTIFICATION',
      titleId: 'SUCCESS',
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
