// @flow
import React from 'react';
import createIcon from 'mz-icons/utils/createIcon';

export default createIcon({
  iconName: 'success-image',
  icon: [
    102,
    102,
    null,
    null,
    null,
    <g
      key="success-image"
      fill="none"
      fillRule="evenodd"
      transform="translate(.5)"
    >
      <circle cx="51" cy="51" r="51" fill="#B4D7F4" />
      <path
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="9"
        d="M76.456 36.929L43.929 69.456 25.544 51.07"
      />
    </g>,
  ],
});
