import config from 'config';
import React from 'react';
import {
  PRODUCTION_STRIPE_CONFIGS,
  DEVELOPMENT_STRIPE_CONFIGS,
} from 'mz-ui-kit/form/PaymentButton/constants';

export function getCustomStripeConfig() {
  const stripeConfigs = (config.CUSTOM_STRIPE_CONFIG || []).reduce(
    (acc, data) => {
      const [currency, origin = 'USD', country = 'US'] = (data[0] || '')
        .split(/\s*,\s*/)
        .map((x) => x.toUpperCase().trim());

      const [prodKey, devKey] = (data[1] || '')
        .split(/\s*,\s*/)
        .map((x) => x.trim());

      if (!currency || !origin || !country) {
        return acc;
      }

      const activeKey = config.STRIPE_DEVELOPMENT_MODE ? devKey : prodKey;
      if (!activeKey) {
        return acc;
      }

      return [
        ...acc,
        {
          default: acc.length === 0,
          currencies: [currency],
          apiKey: activeKey,
          origin,
          country,
        },
      ];
    },
    []
  );

  const defaultConfigs = config.STRIPE_DEVELOPMENT_MODE
    ? DEVELOPMENT_STRIPE_CONFIGS
    : PRODUCTION_STRIPE_CONFIGS;
  return stripeConfigs.length > 0 ? stripeConfigs : defaultConfigs;
}

export function useStripeConfig() {
  return React.useMemo(
    () => getCustomStripeConfig(),
    [config.CUSTOM_STRIPE_CONFIG, config.STRIPE_DEVELOPMENT_MODE]
  );
}
