import config from 'config';
import _ from 'lodash';
import { isMobileBrowser } from 'mz-ui-kit/utils';
import queryStringToObject from 'mz-utils/queryStringToObject';
import { takeLatest } from 'redux-saga';
import { LOCATION_CHANGE, getLocaleFreePathname } from 'app/history';
import { pushData, pushGa, getVirtualUrl } from 'app/utils/analytics';
import {
  REQUIRED_ERROR_TYPE,
  TRIP_EXPIRED_ERROR_CODE,
  DO_SEARCH,
  DO_BOOK_TRIP,
  SELECT_SEARCH_SORT_OPTION,
  TOGGLE_MEET_AND_GREET_FILTER,
  SEARCH_RESULTS_SHOW_MORE,
  SET_TRIP_ERROR,
  REGISTER_USER,
  CONFIRM_BOOKING,
  CLICK_PROMO,
  SEARCH_FOR_PARKING_SPOT,
  SHARE_RESULT_EMAIL,
  COPY_RESULT_URL,
  FILTER_SUITCASE_COUNT,
} from 'app/constants';
import { select, call, take, race, fork } from 'redux-saga/effects';
import {
  isChangeBookingMode,
  isUserLoginSuccesful,
  isEnterHomepage,
  isEnterSearchResults,
  isEnterBookTrip,
  isEnterConfirmation,
} from 'app/sagas/patterns';
import { getMainStep } from 'app/utils/trip';
import {
  getTripToBook,
  getActiveCurrency,
  getActiveLanguage,
  getSavedBooking,
  getCouponFieldValue,
  getBookTripFormErrors,
  getGratuityFieldValue,
  getLocation,
} from 'app/sagas/selectors';
import { handleRefParam } from 'app/sagas/utils';

export const send = (
  method,
  /* type, category, action, label, value */ ...params
) => {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line
    console.log('GOOGLE ANALYTICS', method, ...params);
  }
  pushGa(method, ...params);
};

export const sendEvent = (...params) => send('send', 'event', ...params);

export const setAction = (step, name) =>
  send('ec:setAction', 'checkout', {
    step,
    option: name,
  });

export const SRP_CATEGORY = 'Booking';
export const BTP_CATEGORY = 'Booking';
export const BOOKING_FLOW_CATEGORY = 'Booking Flow';
export const SHARE_RESULTS = 'Share Search Results';

export const HOME_PAGE_LABEL = 'Homepage';
export const SEARCH_PAGE_LABEL = 'Search Results Page';
export const BOOK_TRIP_PAGE_LABEL = 'Book Trip Page';
export const CONFIRMATION_PAGE_LABEL = 'Confirmation Page';

function* getBookingEventLabel() {
  const { provider, from, to, mode } = yield select(getSavedBooking);
  return `${provider}-${mode}-from-${from}-to-${to}`;
}

export function* reportTripSelected() {
  const label = yield call(getBookingEventLabel);
  yield call(
    sendEvent,
    SRP_CATEGORY,
    'Book Trip Clicked (Search Results Page)',
    label
  );
}

// export function* reportConfirmBooking() {
//   const label = yield call(getBookingEventLabel);
//   yield call(sendEvent, BTP_CATEGORY, 'Confirm Booking', label);
// }

export function* reportFieldsRequired() {
  const errors = yield select(getBookTripFormErrors);
  if (errors) {
    const label = Object.keys(errors)
      .filter((key) => errors[key] === REQUIRED_ERROR_TYPE)
      .join('-');
    yield call(sendEvent, BTP_CATEGORY, 'BTP Missing Fields', label);
  }
}

export function* reportSoldOut() {
  yield call(sendEvent, BTP_CATEGORY, 'Trip Expired');
}

export function* reportAddCoupon() {
  const code = yield select(getCouponFieldValue);
  yield call(sendEvent, BTP_CATEGORY, 'Add Coupon', code);
}

export function* reportInvalidCoupon() {
  const code = yield select(getCouponFieldValue);
  yield call(sendEvent, BTP_CATEGORY, 'Invalid Coupon', code);
}

export function* reportGratuityAdded() {
  const gratuity = yield select(getGratuityFieldValue);
  yield call(sendEvent, BTP_CATEGORY, 'Add Gratuity', gratuity);
}

export function* reportAmenityAdded(amenityId) {
  yield call(sendEvent, BTP_CATEGORY, 'Add amenity', amenityId);
}

export function* reportHomepageSearch() {
  yield call(sendEvent, BOOKING_FLOW_CATEGORY, 'Search', HOME_PAGE_LABEL);
}

export function* reportBookRide() {
  yield call(sendEvent, BOOKING_FLOW_CATEGORY, 'Book Ride', SEARCH_PAGE_LABEL);
}

export function* reportSupplierScore({ payload: { trip_id } }) {
  const trip = yield select(getTripToBook, trip_id);
  const score = getMainStep(trip)?.provider?.supplierScore || -1;
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Supplier Score',
    SEARCH_PAGE_LABEL,
    score
  );
}

export function* reportSearchResultsSearch() {
  yield call(sendEvent, BOOKING_FLOW_CATEGORY, 'Search', SEARCH_PAGE_LABEL);
}

export function* reportSearchResultsShowMore() {
  yield call(sendEvent, BOOKING_FLOW_CATEGORY, 'More Click', SEARCH_PAGE_LABEL);
}

export function* reportSortBy({ payload }) {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    `Sort by: ${payload}`,
    SEARCH_PAGE_LABEL
  );
}

export function* reportMeetAndGreetFilter({ payload }) {
  const sort = payload ? 'meet and greet only' : 'all rides';
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    `Sort by: ${sort}`,
    SEARCH_PAGE_LABEL
  );
}

export function* reportConfirmBooking() {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Confirm booking',
    BOOK_TRIP_PAGE_LABEL
  );
}

export function* reportCopyLinkSPR() {
  yield call(sendEvent, SHARE_RESULTS, 'Copy SRP URL', SEARCH_PAGE_LABEL);
}

export function* reportSendLinkSPR() {
  yield call(sendEvent, SHARE_RESULTS, 'Send SRP URL Email', SEARCH_PAGE_LABEL);
}

export function* reportFilterSuitcase() {
  yield call(
    sendEvent,
    SHARE_RESULTS,
    'Filter Search by Suitcase Number',
    SEARCH_PAGE_LABEL
  );
}

export function* reportClickPromo({ payload }) {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    `Click Promo ${payload}`,
    CONFIRMATION_PAGE_LABEL
  );
}

function* searchForParkingSpot({ payload: callback }) {
  yield call(sendEvent, HOME_PAGE_LABEL, 'parking spot search', 'travelcar', {
    hitCallback: () => callback && callback(),
  });
}

export function* reportCreatedAccount() {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Created account',
    BOOK_TRIP_PAGE_LABEL
  );
}

export function* reportBookingTimeout({ payload }) {
  if (payload.errorCode !== TRIP_EXPIRED_ERROR_CODE) return;
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Time out click',
    BOOK_TRIP_PAGE_LABEL
  );
}

export function* reportSearchStart() {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Search Started',
    SEARCH_PAGE_LABEL
  );
}

export function* reportSearchEnd() {
  yield call(
    sendEvent,
    BOOKING_FLOW_CATEGORY,
    'Search Finished',
    SEARCH_PAGE_LABEL
  );
}

export function* reportSearch(action) {
  if (action && action.payload && action.payload.noTracking) {
    return;
  }
  const location = yield select(getLocation);
  const pathname = getLocaleFreePathname(location.pathname);
  if (pathname === '/') {
    yield call(reportHomepageSearch);
  } else if (pathname.startsWith('/search')) {
    yield call(reportSearchResultsSearch);
  }
}

export function* reportModeChange(action) {
  const mode = action && action.payload;
  yield call(sendEvent, BOOKING_FLOW_CATEGORY, 'Mode', mode);
}

export function* reportBookingSignIn() {
  let routeChange;
  while (true) {
    routeChange = yield take(LOCATION_CHANGE);
    // make sure we're in the book trip page
    if (
      !getLocaleFreePathname(routeChange.payload.location.pathname).startsWith(
        '/book'
      )
    )
      continue;
    routeChange = yield take(LOCATION_CHANGE);
    // make sure we're going from the book trip page to the sign in page
    if (
      !getLocaleFreePathname(routeChange.payload.location.pathname).startsWith(
        '/user/login'
      )
    )
      continue;
    const { login, exit } = yield race({
      exit: take(LOCATION_CHANGE),
      login: take(isUserLoginSuccesful),
    });

    if (login) {
      yield call(
        sendEvent,
        BOOKING_FLOW_CATEGORY,
        'Sign in',
        BOOK_TRIP_PAGE_LABEL
      );
    } else if (
      exit &&
      getLocaleFreePathname(exit.payload.location.pathname).startsWith(
        config.REG_ROUTE_PATH
      )
    ) {
      // if the user exits the sign in page and goes to the register page report that instead
      const { register } = yield race({
        exit: take(LOCATION_CHANGE),
        register: take(REGISTER_USER),
      });

      if (register) {
        yield call(
          sendEvent,
          BOOKING_FLOW_CATEGORY,
          'Register',
          BOOK_TRIP_PAGE_LABEL
        );
      }
    }
  }
}

/**
 * Traack transaction using Google Tag Manager. It shouldn't rely on any booking
 * form because it could be called from any page where some booking happened
 * and reservations produced.
 * @param  {Array}     reservations
 * @param  {String}    [couponCode='']
 * @return {Generator}
 */
export function* reportTransaction(reservations, couponCode = '') {
  const currency = yield select(getActiveCurrency);
  const locale = yield select(getActiveLanguage);
  const dateFormat = 'DD-MM-YYYY';

  const events = reservations.map((r) => {
    const step = getMainStep(r.trip);
    const amountPaid = parseFloat(r.grossRevenue || '0');
    const duration = step.arrivalDatetime.diff(step.departureDatetime, 'days');
    let id = `${step.from.location.fullAddress}`;
    if (step.to.location) {
      id += `-${step.to.location.fullAddress}`;
    }

    return {
      metric8: amountPaid,
      ecommerce: {
        currencyCode: currency,
        purchase: {
          actionField: {
            id: r.trip.confirmationNumber,
            affiliation: 'TF',
            revenue: parseFloat(r.partnerProfit),
            tax: '',
            shipping: '',
            coupon: couponCode,
            metric8: amountPaid,
            dimension8: locale,
          },
          products: [
            {
              id,
              name: 'TRANSFER',
              price: amountPaid,
              brand: step.provider.name,
              category: step.vehicle.type.name,
              variant: '',
              quantity: '1',
              coupon: couponCode,
              dimension2: step.departureDatetime.format(dateFormat),
              dimension3: step.arrivalDatetime.format(dateFormat),
              dimension4: 'Credit Card',
              dimension5: 'TF',
              dimension8: locale,
              metric1: step.passengersCount,
              metric5: duration,
            },
          ],
        },
      },
    };
  });

  events.forEach((e) => pushData('purchase', e));
}

/**
 * The old transaction event, for booking.com pixel conversion tracking.
 * @param {Array<Reservations>} reservations
 */
export function* reportPartnerBookingComplete(reservations) {
  const { ref: partnerName } = yield call(handleRefParam);

  const sum = (acc, x) => acc + x;
  const transactionId = reservations.map((r) => r.trip.id).join(',');
  const transactionTax = reservations
    .map((r) => parseFloat(r.partnerProfitUsd))
    .reduce(sum, 0);
  const transactionTotal = reservations
    .map((r) => parseFloat(r.grossRevenueUsd))
    .reduce(sum, 0);
  const transactionProducts = reservations.map((r) => {
    const step = getMainStep(r.trip);
    const category = 'point-to-point';
    const quantity = step.passengersCount;
    const unitPrice = r.grossRevenueUsd / quantity;

    return {
      name: `${step.provider.name}-${step.vehicle.type.name}-${category}`,
      sku: r.trip.id,
      price: unitPrice,
      category,
      quantity,
    };
  });

  const partnerTransactionData = {
    partnerName,
    transactionId,
    transactionTotal,
    transactionTax,
    transactionProducts,
  };

  if (config.PARTNER_REF === 'airfrance' && window.gtag) {
    window.gtag('event', 'purchase', {
      allow_custom_scripts: true,
      value: transactionTotal,
      transaction_id: transactionId,
      send_to: 'DC-5109792/vente/conve000+transactions',
    });
    window.gtag('event', 'conversion', {
      send_to: 'AW-937753808/t-VkCNKC6uIBEND5k78D',
      value: transactionTotal,
      currency: 'USD',
      transaction_id: transactionId,
    });
  }

  pushData('partner-reservation-completed', partnerTransactionData);
}

export function* checkoutHome() {
  yield take(isEnterHomepage);
  yield call(setAction, 1);
}

export function* checkoutSearchResults() {
  yield take(isEnterSearchResults);
  yield call(setAction, 2);
}

export function* checkoutBookTrip() {
  yield take(isEnterBookTrip);
  yield call(setAction, 3);
}

export function* checkoutConfirmation() {
  yield take(isEnterConfirmation);
  yield call(setAction, 4);
}

export function* reportRouteFunnel() {
  yield fork(checkoutHome);
  yield fork(checkoutSearchResults);
  yield fork(checkoutBookTrip);
  yield fork(checkoutConfirmation);
}

let prevPushedEvent = null;
export function* reportPageView({ payload: { location } }) {
  const params = queryStringToObject(location.search);
  const lang = yield select(getActiveLanguage);
  const eventName = 'virtualPageView';
  const path = getLocaleFreePathname(location.pathname).replace(
    /(.)\/+$/,
    '$1'
  );
  const search = location.search;

  const virtualUrl = getVirtualUrl(
    _.fromPairs(config.TAG_MANAGER_PATHNAME_MAP),
    config.TAG_MANAGER_URL_PREFIX,
    path,
    lang
  );

  const event = Object.assign({}, params, {
    site_type: isMobileBrowser() ? 'M' : 'D',
    cod_lang: lang,
    virtualUrl,
  });

  // Report pageView to SessionCam if it's present:
  if (
    window.sessionCamRecorder &&
    window.sessionCamRecorder.createVirtualPageLoad
  ) {
    window.sessionCamRecorder.createVirtualPageLoad(path);
  }

  if (!_.isEqual(prevPushedEvent, event)) {
    prevPushedEvent = event;
    pushData(eventName, event);
    send('set', {
      page: path + search,
      title: document.title,
    });
    send('send', 'pageview');
  }
}

export default function* watchFunneling() {
  yield fork(takeLatest, LOCATION_CHANGE, reportPageView);
  yield fork(takeLatest, isChangeBookingMode, reportModeChange);
  yield fork(takeLatest, DO_SEARCH, reportSearch);
  yield fork(takeLatest, DO_BOOK_TRIP, reportBookRide);
  yield fork(takeLatest, DO_BOOK_TRIP, reportSupplierScore);
  yield fork(takeLatest, SELECT_SEARCH_SORT_OPTION, reportSortBy);
  yield fork(
    takeLatest,
    TOGGLE_MEET_AND_GREET_FILTER,
    reportMeetAndGreetFilter
  );
  yield fork(takeLatest, SEARCH_RESULTS_SHOW_MORE, reportSearchResultsShowMore);
  yield fork(takeLatest, CONFIRM_BOOKING, reportConfirmBooking);
  yield fork(takeLatest, COPY_RESULT_URL, reportCopyLinkSPR);
  yield fork(takeLatest, SHARE_RESULT_EMAIL, reportSendLinkSPR);
  yield fork(takeLatest, FILTER_SUITCASE_COUNT, reportFilterSuitcase);
  yield fork(takeLatest, SET_TRIP_ERROR, reportBookingTimeout);
  yield fork(takeLatest, CLICK_PROMO, reportClickPromo);
  yield fork(takeLatest, SEARCH_FOR_PARKING_SPOT, searchForParkingSpot);
  yield fork(reportBookingSignIn);
  yield fork(reportRouteFunnel);
}
