import React from 'react';
import { FormattedMessage } from 'translations';
import InteractiveInfoBlock from 'app/components/base/InteractiveInfoBlock';

import style from './style.css';

// Constants
const messages = {
  title: { id: 'BOOK_TRIP.PLANNED_RETURN_TITLE' },
  text: { id: 'BOOK_TRIP.PLANNED_RETURN_TEXT' },
  button: { id: 'BOOK_TRIP.ADD_RETURN_TRIP' },
};

const CommonPlannedReturn = (props) => {
  const { onClickAdd, mobile } = props;

  const text = (
    <span className={style.text}>
      <FormattedMessage {...messages.text} />
    </span>
  );

  const button = [
    {
      title: <FormattedMessage {...messages.button} />,
      onClick: onClickAdd,
    },
  ];

  return (
    <InteractiveInfoBlock
      type="white"
      title={<FormattedMessage {...messages.title} />}
      text={text}
      buttons={button}
      buttonsOnNextLine={mobile}
    />
  );
};

// TODO: move to Flow types
// CommonPlannedReturn.propTypes = {
//   onClickAdd: PropTypes.func,
//   mobile: PropTypes.bool
// };

export default CommonPlannedReturn;
