import { connect } from 'react-redux';
import { BOOK_TRIP_FORM, DEPARTING_TRIP_FIELD_NAME } from 'app/constants';
import { formValueSelector } from 'redux-form';
import { preprocessTripObject } from 'app/utils/trip';

export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);

  const trip = selector(state, DEPARTING_TRIP_FIELD_NAME);
  const preparedTrip = preprocessTripObject(trip);
  if (preparedTrip) {
    return {
      ...preparedTrip.heading,
      ...preparedTrip.routing,
      ...preparedTrip.reservationDetails,
      direction: 'departing',
      cancelled: trip.cancelled,
    };
  }
  return {};
};

export const builder = (ComposedComponent) => {
  return connect(mapStateToProps)(ComposedComponent);
};

export default builder;
