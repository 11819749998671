import React from 'react';
import { getCategorizedTrips, getMainStep } from 'app/utils/trip';
import { connect } from 'react-redux';
import { FormattedMessage } from 'translations';
import CN from 'classnames';
import TripList from 'app/components/search-results/TripList';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import { Layout } from 'app/components/base/Layout';
import Category from 'app/components/search-results/Category';
import RecommendedHintLabel from 'app/components/search-results/RecommendedHintLabel';
import { constants } from 'mz-sdk';
import { isMobileBrowser } from 'app/utils/browser';
import StarIcon from 'mz-icons/star';
import SearchAlert from '../SearchAlert';
import FeaturesList from 'app/pages/HomePage/WhitelabelsHomePage/sections/FeaturesList';
import { getRecommendedTripIds } from 'app/sagas/selectors';
import { hook } from 'app/utils/hook';
import { SHOW_MANY_RECOMMENDATIONS_HOOK } from 'app/constants';
import config from 'config';
import {
  OverlayWrapper,
  OutdatedOverlay,
  RecommendedLabel,
  RecommendedIcon,
  SearchAlertWrapper,
  FeaturesListWrapper,
  StyledAdsense,
} from './elements';

const { SORTER_ENUM } = constants;

import style from '../style.css';

const MAX_SORTED_TRIPS = 10;

/**
 * Component for showing trips by categories.
 */
export const TripsResult = ({
  alerts,
  className,
  available,
  trips,
  categories,
  searching,
  recommendedTripIds,
  activeSort,
  mobile,
  outdated,
}) => {
  let results;

  const showManyRecommendations = hook(SHOW_MANY_RECOMMENDATIONS_HOOK);

  const renderManyRecommendedTrips = () => (
    <>
      <RecommendedLabel>
        <RecommendedIcon>
          <StarIcon />
        </RecommendedIcon>
        <FormattedMessage id="SEARCH_RESULTS.TAGS.RECOMMENDED" />
      </RecommendedLabel>
      <TripList trips={recommendedTripIds} showCategory mobile={mobile} />
    </>
  );

  const renderOneRecommendedTrip = () =>
    recommendedTripIds.map((id) => {
      const recommendedTrip = trips[id];
      const recommendedStep = recommendedTrip && getMainStep(recommendedTrip);

      if (recommendedStep) {
        return (
          <div key={id} className={style.recommended}>
            <Category
              className={style.recommendedCategory}
              trips={[recommendedTrip]}
              type={recommendedStep.type}
              filter={recommendedStep.vehicle.type.name}
              vehicle_type={recommendedStep.vehicle.type.id}
              trip_type={recommendedStep.vehicle.type.name}
              mobile={mobile}
            />
            <RecommendedHintLabel
              mobile={mobile}
              rules_strings={
                (recommendedTrip && recommendedTrip.rules_strings) || []
              }
            />
          </div>
        );
      }
      return null;
    });

  const WrappedRecommendedTrips = showManyRecommendations
    ? renderManyRecommendedTrips()
    : renderOneRecommendedTrip();

  if (available.length) {
    // for desktop show categorized results. For mobile - no because mobile trip
    // contains categoty type icon and name
    if (
      activeSort === SORTER_ENUM.RECOMMENDED &&
      !config.SEARCH_UNIQUE_CLASS_TYPE
    ) {
      const categorizedTrips = getCategorizedTrips(
        available,
        trips,
        categories
      );
      const sortedCategories = Object.keys(categorizedTrips).sort(
        (keyA, keyB) => {
          const catA = categorizedTrips[keyA];
          const catB = categorizedTrips[keyB];
          const tripA = (
            catA.trips?.[0] ||
            catA.headTrips?.[0] ||
            catA.tailTrips?.[0]
          )?.id;
          const tripB = (
            catB.trips?.[0] ||
            catB.headTrips?.[0] ||
            catB.tailTrips?.[0]
          )?.id;
          return available.indexOf(tripA) - available.indexOf(tripB);
        }
      );
      results = sortedCategories.map((key) => (
        <Category
          className={style.section}
          key={key}
          mobile={mobile}
          {...categorizedTrips[key]}
        />
      ));
    } else {
      if (available.length >= MAX_SORTED_TRIPS) {
        results = (
          <TripList
            trips={available.slice(0, MAX_SORTED_TRIPS)}
            mobile={mobile}
          />
        );
      } else {
        results = [
          <TripList key="just-trips" trips={available} mobile={mobile} />,
        ];
      }
    }
  }

  const modifiers = {
    [style['container--mobile']]: mobile,
  };

  const adsProps = !isMobileBrowser
    ? {
        layoutKey: '-g0-15+31-f6+sd',
        slot: '3419781082',
      }
    : {
        layoutKey: '-gh-9+2q-4u+1k',
        slot: '2777617408',
      };

  return (
    <Layout
      direction="column"
      className={CN(style.container, modifiers, className)}
      align="start center"
      nowrap
    >
      {searching && <LoadingSpinner />}
      {alerts.length > 0 && (
        <SearchAlertWrapper>
          <SearchAlert>
            <div dangerouslySetInnerHTML={{ __html: alerts.join(' ') }} />
          </SearchAlert>
        </SearchAlertWrapper>
      )}
      {isMobileBrowser && (
        <FeaturesListWrapper>
          <FeaturesList />
        </FeaturesListWrapper>
      )}
      <OverlayWrapper>
        {outdated && <OutdatedOverlay />}
        {recommendedTripIds.length > 0 && WrappedRecommendedTrips}
        <StyledAdsense
          {...adsProps}
          key={adsProps.slot}
          client="ca-pub-5731973738783459"
          format="fluid"
          responsive="false"
          $mobile={isMobileBrowser}
        />
        {results}
      </OverlayWrapper>
    </Layout>
  );
};

// TODO: move to Flow types
// TripsResult.propTypes = {
//   activeSort: PropTypes.string,
//   recommendedTripIds: PropTypes.array,
//   search: PropTypes.object,
//   categories: PropTypes.object,
//   available: PropTypes.array,
//   searching: PropTypes.bool,
//   trips: PropTypes.object,
//   className: PropTypes.string,
//   mobile: PropTypes.bool,
//   outdated: PropTypes.bool
// };

const mapStateToProps = (state) => {
  const {
    searchResults: { alerts, activeFilters, categories, search, outdated },
  } = state;

  return {
    alerts,
    activeSort: activeFilters.sort,
    categoryFilter: activeFilters.categories,
    recommendedTripIds: getRecommendedTripIds(state),
    categories,
    search,
    outdated,
  };
};

export default connect(mapStateToProps)(TripsResult);
