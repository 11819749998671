import React from 'react';
import LabeledFrame from 'app/components/base/LabeledFrame';
import CN from 'classnames';
import style from './style.css';

const InlineLabel = ({ className, ...props }) => {
  return <LabeledFrame {...props} className={CN(className, style.container)} />;
};

// TODO: move to Flow types
// InlineLabel.propTypes = {
//   className: PropTypes.string
// };

export default InlineLabel;
