import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';

import style from './style.css';

/**
 * Component for showing lightbox with close cross icon and transparent
 * overlay to handle clicks outside of the lightbox.
 * @type {Component}
 */
export const DesktopLightbox = ({
  empty,
  wide,
  shadow,
  rounded,
  primaryTitle,
  clearTitle,
  className,
  children,
  title,
  onClickClose,
  ...props
}) => {
  const modifiers = {
    [style['lightbox--empty']]: empty,
    [style['lightbox--wide']]: wide,
    [style['lightbox--primary']]: primaryTitle,
    [style['lightbox--clear']]: clearTitle,
    [style['lightbox--shadow']]: shadow,
    [style['lightbox--rounded']]: rounded,
  };

  // Need to do because of cssmodule nested composition restriction.
  const headerModifiers = {
    [style['lightbox__header--primary']]: primaryTitle,
    [style['lightbox__header--clear']]: clearTitle,
  };

  const titleModifiers = {
    [style['lightbox__title--clear']]: clearTitle,
  };

  return (
    <div className={classnames(style.lightbox, modifiers)}>
      <div className={style.lightbox__overlay} onClick={onClickClose} />
      <div className={style.lightbox__content}>
        <Layout
          nowrap
          size="noshrink"
          direction="column"
          className={classnames(className, style.lightbox__window)}
          {...props}
        >
          {!empty && (
            <Layout
              nowrap
              direction="row"
              size="noshrink"
              className={classnames(style.lightbox__header, headerModifiers)}
            >
              {title && (
                <Flex>
                  <h2
                    className={classnames(
                      style.lightbox__title,
                      titleModifiers
                    )}
                  >
                    {title}
                  </h2>
                </Flex>
              )}
              <Button
                clear
                transparent
                freeWidth
                onClick={onClickClose}
                className={style.lightbox__close}
              >
                <Icon name="close" />
              </Button>
            </Layout>
          )}
          {children}
        </Layout>
      </div>
    </div>
  );
};

// TODO: move to Flow types
// DesktopLightbox.propTypes = {
//   empty: PropTypes.bool,
//   wide: PropTypes.bool,
//   shadow: PropTypes.bool,
//   rounded: PropTypes.bool,
//   primaryTitle: PropTypes.bool,
//   clearTitle: PropTypes.bool,
//   children: PropTypes.node,
//   className: PropTypes.string,
//   title: PropTypes.node,
//   onClickClose: PropTypes.func
// };

export default DesktopLightbox;
