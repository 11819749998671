import { call, put } from 'redux-saga/effects';
import { creditcard } from 'mz-sdk';
import { showErrorNotification, showInfoNotification } from 'app/sagas/utils';
import { getCreditCards, selectCreditCard } from 'app/actions/creditcards';

export default function* deleteCreditCards(action) {
  const { id } = action.payload;
  const { selected } = action.payload;
  const unselect = selected && selected.id === id;

  try {
    yield call(creditcard.deleteCreditCard, action.payload);
    const getCreditCardsAction = yield call(getCreditCards);
    yield put(getCreditCardsAction);
    if (unselect) {
      yield put(selectCreditCard({}));
    }
    yield call(showInfoNotification, {
      messageId: 'USER.CREDIT_CARD.DELETE_SUCCESS_MESSAGE',
      titleId: 'SUCCESS',
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
