import React from 'react';
import { FormattedMessage } from 'translations';
import Tooltip from './Tooltip';

import style from './style.css';

const messages = {
  end: { id: 'SEARCH_RESULTS.MAP.END_MARKER' },
};

const EndPoint = () => (
  <div className={style.endPoint}>
    <Tooltip text={<FormattedMessage {...messages.end} />} />
  </div>
);

export default EndPoint;
