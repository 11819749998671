/**
 * Create HTML element with given inner html
 * @return {HTMLNode}
 */
export const createElement = (html) => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = html;

  if (wrapper.children.length > 1) {
    throw new Error('"html" must be one wrapper element.');
  }

  return wrapper.children[0];
};
