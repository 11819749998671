import React from 'react';
import CN from 'classnames';
import InlineLabel from 'app/components/base/InlineLabel';
import { FormattedMessage } from 'translations';
import HeartIcon from 'mz-icons/heart';
import StarIcon from 'mz-icons/star';
import style from './style.css';

const messages = {
  recommended: { id: 'SEARCH_RESULTS.TAGS.RECOMMENDED' },
  clientPreferred: { id: 'SEARCH_RESULTS.TAGS.CLIENT_PREFERRED' },
};

const TagIconsMap = {
  recommended: StarIcon,
  recommended_by_gbt: StarIcon,
  most_popular_provider: HeartIcon,
};

const TagLabel = ({ tag, inline, showText }) => {
  const TagIcon = TagIconsMap[tag.type];
  const WrappedIcon = TagIcon && (
    <div className={style.tagIcon}>
      <TagIcon />
    </div>
  );

  return (
    <InlineLabel
      light={false}
      inline={inline}
      key={tag.type}
      className={CN(style[tag.tag_type || tag.type], style.tag)}
      icon={WrappedIcon}
      text={
        showText ? tag.text || <FormattedMessage {...messages[tag.type]} /> : ''
      }
    />
  );
};

// TODO: move to Flow types
// TagLabel.propTypes = {
//   tag: PropTypes.object,
//   inline: PropTypes.bool,
//   showText: PropTypes.bool
// };

export default TagLabel;
