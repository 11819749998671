import { fork, take } from 'redux-saga/effects';
import { takeEvery } from 'redux-saga';
import checkTAUser from './checkTAUser';
import { LOCATION_CHANGE } from 'app/history';
import { SET_USER } from 'app/constants';

export default function* watchCheckTAUser() {
  yield take(SET_USER);
  yield fork(takeEvery, LOCATION_CHANGE, checkTAUser);
}
