import { call } from 'redux-saga/effects';
import restoreOriginalPickupTime from '../watchChangePickupTime/restoreOriginalPickupTime';

/**
 * This saga is called when user click on "Keep Original Time" button in
 * "Trip Updated" modal. It just set's time to original value and doesn't reload anything
 * @param {Object} action        Action fired by user from modal - type: KEEP_ORIGINAL_PICKUP_TIME
 */
export default function* handleKeepOriginalTime(action) {
  const fieldName = action.payload;

  yield call(restoreOriginalPickupTime, fieldName);
}
