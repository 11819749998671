// @flow
import React from 'react';
import CurrencySelectorField from 'mz-ui-kit/form/CurrencySelectorField';
import { FormattedMessage } from 'translations';

import { type Currency } from 'mz-sdk/services/currencies/getAll';

type Props = {
  activeCurrency: string,
  appearance: 'desktop' | 'mobile',
  onSelect: (selectedCurrency: Currency) => {},
  close: () => {},
};

const messages = {
  placeholder: { id: 'CURRENCY_PLACEHOLDER' },
};

const CurrencySelector = ({
  activeCurrency,
  appearance,
  onSelect,
  close,
}: Props) => (
  <CurrencySelectorField
    fieldAppearance={appearance}
    placeholder={<FormattedMessage {...messages.placeholder} />}
    input={{
      value: activeCurrency,
      onChange: (selectedCurrency) => {
        onSelect(selectedCurrency);
        close();
      },
    }}
    optionComparator={(option: any, selectedOption: any) =>
      option.code === selectedOption
    }
  />
);

export default CurrencySelector;
