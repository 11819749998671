import { call } from 'redux-saga/effects';
import { reservations } from 'mz-sdk';

/**
 * Get reservation object from SDK and handle error
 * @param  {string} id   The id to get reservation
 */
export default function* handleReservation(id, ondemand) {
  try {
    const reservation = yield call(reservations.get, { id, ondemand });
    return reservation;
  } catch (error) {
    // SDK throws and error if reservation not found.
    // Normalize it to be null in this case.
    return null;
  }
}
