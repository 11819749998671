const BACKGROUNDS = 'backgrounds';

export default [
  {
    name: 'COVER_OVERLAY_COLOR',
    type: 'select',
    options: [
      { title: 'Gray', value: 'rgba(51,51,51,0.3)' },
      { title: 'White', value: 'rgba(255,255,255,0.3)' },
      { title: 'Transparent', value: 'rgba(0,0,0,0)' },
    ],
    default: 'rgba(51,51,51,0.3)',
    description: 'Cover overlay color',
    category: BACKGROUNDS,
  },
  {
    name: 'HELP_BUTTON_COLOR',
    type: 'select',
    options: [
      { title: 'Primary color', value: 'FIRST_BACKGROUND_COLOR' },
      { title: 'Second color', value: 'SECOND_BACKGROUND_COLOR' },
      { title: 'Third color', value: 'THIRD_BACKGROUND_COLOR' },
      { title: 'Fourth color', value: 'FOURTH_BACKGROUND_COLOR' },
    ],
    default: 'FIRST_BACKGROUND_COLOR',
    description: 'Help button color',
    category: BACKGROUNDS,
  },
  {
    name: 'HELP_CHAT_THEME_COLOR',
    type: 'select',
    options: [
      { title: 'Primary color', value: 'FIRST_BACKGROUND_COLOR' },
      { title: 'Second color', value: 'SECOND_BACKGROUND_COLOR' },
      { title: 'Third color', value: 'THIRD_BACKGROUND_COLOR' },
      { title: 'Fourth color', value: 'FOURTH_BACKGROUND_COLOR' },
    ],
    default: 'FIRST_BACKGROUND_COLOR',
    description: 'Help chat theme color',
    category: BACKGROUNDS,
  },
  {
    name: 'SEARCHFORM_BACKGROUND_COLOR',
    type: 'select',
    options: [
      { title: 'Black', value: 'rgba(0,0,0,0.4)' },
      { title: 'White', value: 'rgba(255,255,255,0.4)' },
      { title: 'Transparent', value: 'rgba(0,0,0,0)' },
    ],
    default: 'rgba(0,0,0,0.4)',
    description: 'Search form background color',
    category: BACKGROUNDS,
  },
  {
    name: 'HOME_HEADER_COLOR',
    type: 'select',
    options: [
      { title: 'Black', value: '#000000' },
      { title: 'White', value: '#FFFFFF' },
    ],
    default: '#FFFFFF',
    description: 'Color of home page header text',
    category: BACKGROUNDS,
  },
  {
    name: 'SEARCH_COVER_POSITION',
    type: 'select',
    options: ['top', 'center', 'bottom'],
    default: 'center',
    description: 'Search form background image position',
    category: BACKGROUNDS,
  },
  {
    name: 'SEARCH_FORM_COVER_IMAGE',
    type: 'image',
    default: 'https://static.mozio.com/assets/hero.jpg',
    description: 'Custom form cover image',
    category: BACKGROUNDS,
  },
  {
    name: 'CUSTOM_APP_CSS',
    type: 'string',
    view: 'textarea',
    default: '',
    description: 'Custom CSS for the app',
    category: BACKGROUNDS,
  },
  {
    name: 'CUSTOM_HTML_PARTS',
    type: 'pairs',
    default: [],
    description: 'Custom HTML elements of the page',
    category: BACKGROUNDS,
  },
  {
    name: 'HOME_FEATURES_ORDERED_LIST',
    type: 'pairs',
    default: [
      ['delay-wait', '10'],
      ['budget-to-premium', '20'],
      ['free-cancellation', '30'],
      ['customer-support', '40'],
      ['miles', '0'],
      ['meet-n-greet', '0'],
    ],
    description: 'List of features on HP',
    category: BACKGROUNDS,
  },
  {
    name: 'REGISTRATION_PAGE_BG',
    type: 'string',
    default: 'primary',
    description: 'Login/Registration page background color',
    category: BACKGROUNDS,
  },
];
