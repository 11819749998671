import React from 'react';
import classnames from 'classnames';
import Logo from './NavbarLogo';
import Collapsible from '../Collapsible';
import { Layout, Flex } from '../Layout';
import config from 'config';
import Button from 'mz-ui-kit/core/Button';
import { FormattedMessage } from 'translations';
import GlobeIcon from 'mz-icons/globe';
import { styled } from 'mz-ui-kit';

import style from './style.css';

// Utils
const decomposeChildren = (children) => {
  const others = [];
  let hamburger;

  React.Children.forEach(children, (elem) => {
    if (elem && elem.type.isHamburger) {
      hamburger = elem;
    } else if (elem) {
      others.push(elem);
    }
  });

  return { hamburger, others };
};

const ENABLE_HIRING_BUTTON = false;

const CONTAINER_DIRECTION = {
  '> mobile': 'row',
  mobile: 'column',
};
const LOGO_SIZE = {
  '> mobile': 'grow',
  mobile: 'noshrink',
};

const messages = {
  hiring: { id: 'HIRING' },
};

const IconWrapper = styled('span', {
  display: 'flex',
  marginRight: '10px',
});

const renderHiringButton = () => (
  <Flex size="noshrink" className={style.hiringContainer}>
    <Button
      size="inherit"
      appearance="outlined"
      $as="a"
      href="https://angel.co/mozio/jobs/"
      target="_blank"
    >
      <IconWrapper>
        <GlobeIcon size="lg" />
      </IconWrapper>
      <FormattedMessage {...messages.hiring} />
    </Button>
  </Flex>
);

/**
 * Basic container for navigation with logo
 * @param  {Function} onClickLogo  handler for click event on logo
 * @return {Component}
 */
export const NavbarContainer = ({
  onClickLogo,
  className,
  children,
  collapsible,
  opened,
  ...props
}) => {
  const { hamburger, others } = decomposeChildren(children);

  return (
    <Layout
      nowrap
      direction={CONTAINER_DIRECTION}
      component="header"
      className={classnames(className, style.container, {
        [style['container--collapsible']]: collapsible,
      })}
      {...props}
    >
      <Layout
        className={style.logoContainer}
        direction="row"
        size={LOGO_SIZE}
        nowrap
      >
        <Flex size="grow" className={style.logoContainer}>
          <Logo onClick={onClickLogo} />
        </Flex>
        {ENABLE_HIRING_BUTTON &&
          config.SHOW_MOZIO_GROUP_HOMEPAGE &&
          renderHiringButton()}
        <Flex size="noshrink">
          {collapsible &&
            hamburger &&
            React.cloneElement(hamburger, { opened })}
        </Flex>
      </Layout>
      <Flex className={style.nav} component="nav" size="noshrink">
        <Collapsible
          className={style.nav__items}
          opened={!collapsible || opened}
        >
          <div className={style.nav__padder}>{others}</div>
        </Collapsible>
      </Flex>
    </Layout>
  );
};
NavbarContainer.defaultProps = {
  collapsible: true,
  opened: false,
};

// TODO: move to Flow types
// NavbarContainer.propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.node,
//   onClickLogo: PropTypes.func,
//   collapsible: PropTypes.bool,
//   opened: PropTypes.bool
// };

export default NavbarContainer;
