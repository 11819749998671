import { createReducer } from '../utils/redux';
import {
  TOGGLE_THREE_D_SECURE_LIGHTBOX,
  GET_CREDIT_CARDS,
  SET_CREDIT_CARDS,
  SELECT_CREDIT_CARD,
} from '../constants';

const initialState = {
  list: [],
  selected: {},
  loading: false,
  threeDSecureUrl: '',
};

const actionHandlers = {
  [TOGGLE_THREE_D_SECURE_LIGHTBOX]: (_, secureUrl) => ({
    threeDSecureUrl: secureUrl || '',
  }),
  [GET_CREDIT_CARDS]: () => ({
    loading: true,
  }),
  [SET_CREDIT_CARDS]: (_, creditcards) => ({
    list: creditcards,
    loading: false,
  }),
  [SELECT_CREDIT_CARD]: (_, selected) => ({
    selected,
  }),
};

export default createReducer(initialState, actionHandlers);
