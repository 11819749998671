import React, { Component } from 'react';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';

import style from './style.css';

class Controllers extends Component {
  normalizeNumber = (n) => {
    const { decimals } = this.props;
    return decimals !== 0 ? parseFloat(n.toFixed(decimals)) : n;
  };

  handleIncrement = () => {
    const { input, max, step } = this.props;
    const { value, onChange } = input;
    if (!max || value < max) {
      onChange(this.normalizeNumber(+value + step));
    }
  };

  handleDecrement = () => {
    const { input, min, step } = this.props;
    const { value, onChange } = input;
    if (value > min) {
      onChange(this.normalizeNumber(+value - step));
    }
  };

  handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  render() {
    const { disabled, mobile } = this.props;

    const controlClassname = classnames(style.control, {
      [style['control--mobile']]: mobile,
    });

    const iconClassname = classnames(style.icon, {
      [style['icon--mobile']]: mobile,
    });

    return (
      <Layout nowrap>
        <Button
          className={controlClassname}
          round
          disabled={disabled}
          onMouseDown={this.handleMouseDown}
          onClick={this.handleDecrement}
        >
          <Icon className={iconClassname} name="remove" />
        </Button>
        <Button
          className={controlClassname}
          round
          onMouseDown={this.handleMouseDown}
          onClick={this.handleIncrement}
          disabled={disabled}
        >
          <Icon className={iconClassname} name="add" />
        </Button>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Controllers.propTypes = {
//   input: PropTypes.object.isRequired,
//   min: PropTypes.number,
//   max: PropTypes.number,
//   step: PropTypes.number,
//   decimals: PropTypes.number,
//   showValue: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   disabled: PropTypes.bool,
//   // modifiers
//   mobile: PropTypes.bool,
//   controlClassname: PropTypes.string
// };

Controllers.defaultProps = {
  min: 1,
  step: 1,
  decimals: 0,
};

export default Controllers;
