import * as React from 'react';
import * as Sentry from '@sentry/browser';

/**
 * Top level error boundary that send the error to Sentry when
 * built in production.
 * @extends React
 */
class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      eventId: null,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (process.env.NODE_ENV !== 'development') {
      Sentry.withScope((scope) => {
        scope.setTag('logger', 'react-boundary');
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            backgroundColor: '#eee',
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            height: '100vh',
          }}
        >
          <div style={{ maxWidth: '500px' }}>
            <div style={{ fontSize: '18px' }}>
              <h1>Something went wrong.</h1>
              <p>
                We are sorry, but while showing the page some critical problem
                happened. We are looking into the problem already and we will
                fix it as soon as possible.
              </p>
              <p>
                You can help us to fix it faster if you will click the button
                below and leave us some additional context
              </p>
            </div>
            <div>
              <button
                style={{
                  appearance: 'none',
                  backgroundColor: '#428BCA',
                  color: '#FFFFFF',
                  padding: '20px',
                  fontSize: '20px',
                  border: 'none',
                  borderRadius: '5px',
                }}
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Report feedback
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default SentryErrorBoundary;
