import { call, select, put } from 'redux-saga/effects';
import { travelagent } from 'mz-sdk';
import { apiCall } from 'app/sagas/utils';
import {
  TA_CI_COMPANY_NAME_FIELD_NAME,
  TA_CI_PAYOUT_TO_MASTER_FIELD_NAME,
  TA_CI_ALLOW_CHANGE_FEE_FIELD_NAME,
} from 'app/constants';
import { getUser as getUserSelector } from 'app/sagas/selectors';
import { getTravelAgent } from 'app/actions/travel-agent';

export const mapPayloadToParams = (user) => (payload) => ({
  payout_to_master: payload[TA_CI_PAYOUT_TO_MASTER_FIELD_NAME],
  use_master_commission: !payload[TA_CI_ALLOW_CHANGE_FEE_FIELD_NAME],
  name:
    payload[TA_CI_COMPANY_NAME_FIELD_NAME] !== user.company.name
      ? payload[TA_CI_COMPANY_NAME_FIELD_NAME]
      : undefined,
});

/**
 * Update TA company info
 */
export default function* updateCompanyInfo(action) {
  const user = yield select(getUserSelector);
  yield call(
    apiCall,
    action,
    travelagent.updateCompany,
    mapPayloadToParams(user)
  );
  yield put(getTravelAgent());
}
