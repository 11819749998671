import React from 'react';
import CN from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { FormattedMessage } from 'translations';
import RoundedHeader from 'app/components/base/RoundedHeader';
import { Layout, Flex } from 'app/components/base/Layout';
import { preprocessTripObject } from 'app/utils/trip';
import HourlyRide from './commons/HourlyRide';
import CarbonOffsetAmenity from './commons/CarbonOffsetAmenity';

import {
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_BOOK_STATE_FIELD_NAME,
  RETURN_TRIP_BOOKED,
} from 'app/constants';

import style from './style.css';

export const mapStateToProps = (state, { formName = BOOK_TRIP_FORM }) => {
  const selector = formValueSelector(formName);
  const returnBookState = selector(state, RETURN_BOOK_STATE_FIELD_NAME);
  const trip = selector(state, DEPARTING_TRIP_FIELD_NAME);
  const preparedTrip = preprocessTripObject(trip) || {
    details: {},
    routing: {},
    heading: {},
  };
  const { routing, details, heading } = preparedTrip;

  return {
    routing,
    heading,
    details,
    returnBookState,
  };
};

export const messages = {
  title: { id: 'BOOK_TRIP.YOUR_TRIP' },
  roundTrip: { id: 'TRIP_DETAILS.MODE_ROUNDTRIP' },
  oneWay: { id: 'TRIP_DETAILS.MODE_ONEWAY' },
};

/**
 * Complete fully functional "Your Trip" block builder that composes
 * all subcomponents together. It is a builder for mobule and desktop
 * versions of the block.
 * @param  {Component} DepartingComponent
 * @param  {Component} ReturningComponent
 * @return {Component}
 */
export const builder = ({ DepartingComponent, ReturningComponent }) => {
  const YourTrip = ({
    returnBookState,
    formName,
    readOnly,
    locked,
    header,
    routing,
    details,
  }) => {
    const { hourlyDetails } = details;
    if (hourlyDetails && hourlyDetails.hoursRequested) {
      const minimumHours = hourlyDetails.minimum || 1;
      return (
        <HourlyRide
          readOnly={readOnly}
          pickupDateTime={routing.departureDatetime}
          fromAddress={routing.fromAddress}
          hoursRequested={hourlyDetails.hoursRequested}
          includedKms={hourlyDetails.totalIncludedKilometers}
          minimumHours={minimumHours}
        />
      );
    }

    const tripTypeMsg =
      returnBookState === RETURN_TRIP_BOOKED
        ? messages.roundTrip
        : messages.oneWay;

    return (
      <Layout className={style.block} direction="column" nowrap>
        {header && (
          <Flex size="noshrink">
            <RoundedHeader
              className={style.title}
              icon={{ family: 'mozio', name: 'trip-detail' }}
            >
              <FormattedMessage {...messages.title} />
              <span className={CN(style.block__direction, style.darkFont)}>
                <FormattedMessage {...tripTypeMsg} />
              </span>
            </RoundedHeader>
          </Flex>
        )}
        <DepartingComponent
          formName={formName}
          readOnly={readOnly}
          locked={locked}
        />
        <ReturningComponent
          formName={formName}
          readOnly={readOnly}
          locked={locked}
        />
        {!readOnly && <CarbonOffsetAmenity />}
      </Layout>
    );
  };

  // TODO: move to Flow types
  // YourTrip.propTypes = {
  //   formName: PropTypes.string,
  //   returnBookState: PropTypes.string,
  //   readOnly: PropTypes.bool,
  //   locked: PropTypes.bool,
  //   header: PropTypes.bool,
  //   details: PropTypes.object,
  //   routing: PropTypes.object
  // };

  YourTrip.defaultProps = {
    readOnly: false,
    header: true,
  };

  return compose(connect(mapStateToProps))(YourTrip);
};

export default builder;
