import config from 'config';
import { getActiveCurrency, getActiveLanguage } from 'app/sagas/selectors';

// We need to have a store link right here to be able
// to use it in processors for getting active currency, for example
let activeStore = null;
export const setActiveStore = (store) => {
  activeStore = store;
};

// Utils
export const removePrefix = (str, prefix) => {
  if (str.startsWith(prefix)) {
    return str.substr(prefix.length);
  }
  return str;
};

// Some special processors
export const PROCESSORS = {
  DOMAIN_WO_PREFIX: (...prefixes) => {
    const str = window.location.hostname;
    for (const prefix of prefixes) {
      if (str.startsWith(prefix)) {
        return removePrefix(str, prefix);
      }
    }
    return str;
  },
  DOMAIN: (removeLevels = 0) => {
    const str = window.location.hostname;
    const parts = str.split('.').slice(parseInt(removeLevels, 10));
    return parts.join('.');
  },
  CURRENCY: () => {
    return getActiveCurrency(activeStore.getState());
  },
  LANGUAGE: () => {
    return getActiveLanguage(activeStore.getState());
  },
  IF_LANG: (lang, value) => {
    const activeLang = getActiveLanguage(activeStore.getState());
    return activeLang === lang ? value : '';
  },
  IF_DOMAIN: (domain, value) => {
    const activeDomain = window.location.hostname;
    return activeDomain === domain ? value : '';
  },
};

/**
 * Replace variables in given string and return the result. It try to find
 * processor by name, and if it not exists it will try to find config variable.
 * Otherwise it will just keep pattern there.
 *
 * You can pass arguments to processor by simple syntax:
 * `{SOME_PROC:arg1,arg2}`
 *
 * @param  {string} str
 * @return {string}
 */
export const formatString = (tpl = '', vars = {}) => {
  return (
    tpl &&
    tpl.replace &&
    tpl.replace(/{([^}]+)}/g, (match, val) => {
      const argsSep = val.indexOf(':');
      const parts =
        argsSep > 0 ? [val.substr(0, argsSep), val.substr(argsSep + 1)] : [val];

      if (PROCESSORS[parts[0]]) {
        const args = parts.length > 1 ? parts[1].split(',') : [];
        return PROCESSORS[parts[0]](...args);
      } else if (vars && Object.prototype.hasOwnProperty.call(vars, parts[0])) {
        return vars[parts[0]];
      } else if (config[parts[0]]) {
        return config[parts[0]];
      }
      return match;
    })
  );
};
