import { ADD_CREDIT_CARD } from 'app/constants';
import { getStripeOrigin } from 'app/sagas/selectors';
import { put, call, select } from 'redux-saga/effects';
import { creditcard } from 'mz-sdk';
import { showErrorNotification, showInfoNotification } from 'app/sagas/utils';
import createStripeSource from 'app/sagas/stripe/createStripeSource';
import { getCreditCards, selectCreditCard } from 'app/actions/creditcards';

// Constants
const ERRORS_MAP = {
  card_declined: 'THREE_D_SECURE.DECLINED',
  card_not_supported: 'THREE_D_SECURE.CARD_NOT_SUPPORTED',
  '3ds_not_supported': 'THREE_D_SECURE.SHOULD_BE_SUPPORTED',
};

export default function* saveCreditCard(action) {
  const newCreditCard = action.type === ADD_CREDIT_CARD;

  const { creditCard, stripe } = action.payload;
  const stripe_origin = yield select(getStripeOrigin);
  const { id, first_name, last_name, description } = creditCard;

  const data = {
    id,
    name: `${first_name} ${last_name}`,
    description,
    stripe_origin,
  };

  // TODO: Validate Stripe CC Form elemts.
  const source = yield call(createStripeSource, stripe, { name: data.name });
  if (!source) return;

  const save = newCreditCard
    ? creditcard.addCreditCard
    : creditcard.updateCreditCard;

  const messageId = newCreditCard
    ? 'USER.CREDIT_CARD.ADD_SUCCESS_MESSAGE'
    : 'USER.CREDIT_CARD.UPDATE_SUCCESS_MESSAGE';

  try {
    yield call(save, data, source);
    const getCreditCardsAction = yield call(getCreditCards);
    yield put(getCreditCardsAction);
    yield put(selectCreditCard(creditCard));
    yield call(showInfoNotification, { messageId, titleId: 'SUCCESS' });
    yield call(action.resolvePromise);
  } catch (error) {
    const errMessageId = ERRORS_MAP[error && error.errorCode];
    yield call(showErrorNotification, {
      error: !errMessageId ? error : null,
      messageId: errMessageId,
    });
    yield call(action.rejectPromise);
  }
}
