import config from 'config';

import {
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_CONFIRMATION_FIELD_NAME,
  CC_FIRST_NAME_FIELD,
  CC_LAST_NAME_FIELD,
} from 'app/constants';

const placeholderHints = {
  [FIRST_NAME_FIELD_NAME]: 'e.g. John',
  [LAST_NAME_FIELD_NAME]: 'e.g. Smith',
  [EMAIL_FIELD_NAME]: 'e.g. john@email.com',
  [EMAIL_CONFIRMATION_FIELD_NAME]: 'e.g. john@email.com',
  [CC_FIRST_NAME_FIELD]: 'e.g. John',
  [CC_LAST_NAME_FIELD]: 'e.g. Smith',
};

export const getPlaceholderHint = (field) => {
  return config.HINT_PLACEHOLDERS_SHOW ? placeholderHints[field] : null;
};

export const overwriteAirportDisplay = (option = {}) => {
  const defaultDisplay = option.display ? option.display : '';
  return option.type === 'airport' && config.OVERWRITE_AIRPORT_DISPLAY
    ? `${option.value} : ${option.name}`
    : defaultDisplay;
};

export const overwriteAirportAddress = (location = {}) => {
  const defaultAddress = location.customName || location.fullAddress;
  // For locations comming from SDK location builder
  if (location.airportName && config.OVERWRITE_AIRPORT_DISPLAY) {
    return `${location.name} : ${location.airportName}`;
  }
  // For locations comming from places endpoints
  return location.iataCode && config.OVERWRITE_AIRPORT_DISPLAY
    ? `${location.iataCode} : ${location.address}`
    : defaultAddress;
};
