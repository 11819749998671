const FOOTER = 'footer';

export default [
  {
    name: 'CUSTOM_FOOTER_LOGO',
    type: 'image',
    default: '',
    description: 'Custom logo for footer',
    category: FOOTER,
  },
  {
    name: 'CUSTOM_FOOTER_HTML',
    type: 'string',
    view: 'textarea',
    default: '',
    description: 'Custom footer HTML',
    category: FOOTER,
  },
  {
    name: 'FOOTER_SOCIALS',
    type: 'pairs',
    default: [
      ['facebook', 'https://www.facebook.com/letsmozio/'],
      ['twitter', 'https://twitter.com/letsmozio'],
      ['instagram', 'https://www.instagram.com/letsmozio/'],
      ['linkedin', 'https://www.linkedin.com/company/mozio/'],
    ],
    description: 'Footer social networks',
    category: FOOTER,
  },
  {
    name: 'FOOTER_CUSTOM_LINKS',
    type: 'pairs',
    default: [
      ['FOOTER.ABOUT_US_LINK', 'https://www.mozio.com/about'],
      ['FOOTER.FAQ_LINK', 'https://www.mygroundbooking.com/hc'],
      ['FOOTER.BLOG_LINK', 'https://blog.mozio.com/'],
      ['PARTNERS_LINK', 'https://www.moziogroup.com/'],
      ['EXECUTIVE_ASSISTANT', 'https://www.mozio.com/executive-assistant'],
      [
        'FOOTER.ONLINE_TRAVEL_LANDSCAPE_LINK',
        'https://www.mozio.com/online-travel-landscape',
      ],
    ],
    description: 'Footer links',
    category: FOOTER,
  },
  {
    name: 'FOOTER_TERMS_LINKS',
    type: 'pairs',
    default: [
      ['FOOTER.PRIVACY_POLICY_LINK', '/privacy-policy'],
      ['FOOTER.TERMS_OF_SERVICE_LINK', '/terms-of-service'],
      ['FOOTER.IMPRINT_LINK', '/imprint'],
    ],
    description: 'Footer terms and privacy links',
    category: FOOTER,
  },
];
