const clickListeners = [];

const handleClick = (event) => {
  clickListeners.forEach(({ element, listener }) => {
    if (!element.contains(event.target)) {
      listener(event);
    }
  });
};

document.addEventListener('click', handleClick, false);

/**
 * Subscribes the given element to click events
 * @param {Element} element
 * @param {function(event)} clickInsideHandler - when the element is clicked
 * @param {function(event)} clickOutsideHandler - when clicking outside the elemnt
 * @returns {function()} unusbscribe function
 */
export const addClickOutsideListener = (element, listener) => {
  const entry = {
    element,
    listener,
  };

  clickListeners.push(entry);

  return () => {
    const index = clickListeners.indexOf(entry);
    clickListeners.splice(index, 1);
  };
};

export const eventToKey = (event) => {
  const keyCode = event.keyCode || event.which || event.charCode;

  const keyMap = {
    38: 'ArrowUp',
    40: 'ArrowDown',
    27: 'Esc',
    13: 'Enter',
    9: 'Tab',
    8: 'Backspace',
  };

  return keyCode ? keyMap[keyCode] : event.key;
};
