import _ from 'lodash';

// helper function used to transform array of ticket types with
// nested schedules array into array of schedules with available ticket types
const transformTicketTypesToMatrix = (ticketTypes) => {
  const mergedSchedules = ticketTypes
    .map((type) => {
      const { schedules, isDefault, defaultScheduleId, ...ticketTypeProps } =
        type;

      const schedulesObject = schedules
        .map((scheduleItem, index) => {
          const { price, ...scheduleParams } = scheduleItem;
          const {
            duration = {},
            arrivalDatetime,
            departureDatetime,
            transfers,
          } = scheduleParams;
          const scheduleHash = `${duration.value}_
             ${arrivalDatetime.format()}_
             ${departureDatetime.format()}_
             ${transfers}`;

          return {
            hash: scheduleHash,
            ...scheduleParams,
            tickets: {
              [ticketTypeProps.ticketId]: {
                ...ticketTypeProps,
                selected: isDefault && defaultScheduleId === index,
                price,
                scheduleId: index,
              },
            },
          };
        })
        .reduce((prevValue, schedule) => {
          const result = {
            ...prevValue,
            [schedule.hash]: schedule,
          };
          return result;
        }, {});

      return schedulesObject;
    })
    .reduce((prevValue, schedulesObject) => {
      const customizer = (objValue, srcValue) => {
        if (objValue && objValue.hash === srcValue.hash) {
          return {
            ...objValue,
            tickets: {
              ...objValue.tickets,
              ...srcValue.tickets,
            },
          };
        }
        return undefined;
      };

      const result = _.isEmpty(prevValue)
        ? _.clone(schedulesObject)
        : _.mergeWith(prevValue, schedulesObject, customizer);

      return result;
    }, {});

  const schedulesArray = Object.values(mergedSchedules);

  return schedulesArray;
};

export default transformTicketTypesToMatrix;
