import {
  AUTOCOMPLETE_TEXTING,
  AUTOCOMPLETE_CLEANUP,
  AUTOCOMPLETE_LOADING,
  AUTOCOMPLETE_RECEIVED,
  AUTOCOMPLETE_FAILED,
  AUTOCOMPLETE_CLIENT_FILTERED,
  AUTOCOMPLETE_VALIDATE_COMPLETE,
  AUTOCOMPLETE_VALIDATE_CLEAN,
} from 'app/constants';
import { createAction } from '../utils/redux';

export const autocompleteRequestItems = createAction(AUTOCOMPLETE_TEXTING);

export const autocompleteCleanupItems = createAction(AUTOCOMPLETE_CLEANUP);

export const autocompleteLoading = createAction(AUTOCOMPLETE_LOADING);

export const autocompleteReceivedItems = createAction(AUTOCOMPLETE_RECEIVED);

export const autocompleteRetrievalFailed = createAction(AUTOCOMPLETE_FAILED);

export const autocompleteClientFiltered = createAction(
  AUTOCOMPLETE_CLIENT_FILTERED
);

export const autocompleteValidationComplete = createAction(
  AUTOCOMPLETE_VALIDATE_COMPLETE
);

export const autocompleteValidationClean = createAction(
  AUTOCOMPLETE_VALIDATE_CLEAN
);
