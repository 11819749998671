import React from 'react';
import { Layout } from '../Layout';
import classnames from 'classnames';
import TermsOfServiceRow from './TermsOfServiceRow';
import CopyrightRow from './CopyrightRow';
import LogoAndSocialLinksRow from './LogoAndSocialLinksRow';
import LinksRow from './LinksRow';
import ContactsRow from './ContactsRow';
import LegalAddress from './LegalAddress';
import LoyaltyText from './LoyaltyText';

import style from './style.css';

const MobileLayout = ({ supportPhones }) => (
  <Layout
    className={classnames(style.container, style['container--mobile'])}
    direction="column"
    align="center center"
  >
    <LogoAndSocialLinksRow align="center center" />
    <LinksRow align="center center" nowrap={false} mobile />
    <ContactsRow align="center center" supportPhones={supportPhones} />
    <TermsOfServiceRow align="center center" />
    <CopyrightRow align="center center" />
    <LegalAddress $mobile />
    <LoyaltyText />
  </Layout>
);

// TODO: move to Flow types
// MobileLayout.propTypes = {
//   supportPhones: PropTypes.array
// };

export default MobileLayout;
