// @flow
import { styled } from 'mz-ui-kit';

export const ArrowWrapper = styled(
  'div',
  ({ $darkMode, $desktop, $palette }) => ({
    color: $darkMode ? `${$palette('white')}` : `${$palette('text')}`,
    marginLeft: '6px',
    ...(!$desktop && {
      position: 'absolute',
      right: '10px',
    }),
  })
);

export const ButtonContainer = styled('div', ({ $desktop }) => ({
  display: $desktop ? 'flex' : 'block',
  width: $desktop ? 'initial' : '100%',
}));
