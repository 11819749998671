import React from 'react';
import { Layout } from 'app/components/base/Layout';
import TripSoldOutError from 'app/components/book-trip/commons/Errors/TripSoldOutError';
import TripUpdatedNotification from 'app/components/book-trip/commons/Errors/TripUpdatedNotification';
import ThreeDSError from 'app/components/book-trip/commons/Errors/ThreeDSError';
import {
  TRIP_UPDATED_ERROR_CODE,
  THREEDS_REQUIRED_ERROR_CODE,
} from 'app/constants';

const ErrorLayout = (props) => {
  const { errorCode } = props;

  const codesMap = {
    9: TripSoldOutError,
    97: TripSoldOutError,
    [TRIP_UPDATED_ERROR_CODE]: TripUpdatedNotification,
    [THREEDS_REQUIRED_ERROR_CODE]: ThreeDSError,
  };

  const TripError = codesMap[errorCode];

  if (TripError) {
    return (
      <Layout align="center center">
        <TripError {...props} />
      </Layout>
    );
  }
  return null;
};

// TODO: move to Flow types
// ErrorLayout.propTypes = {
//   errorCode: PropTypes.number
// };

export default ErrorLayout;
