import React, { Component } from 'react';
import Dropdown from 'app/components/base/Dropdown';

class HoverableDropdown extends Component {
  state = {
    open: false,
  };

  handleMouseEnter = () => {
    this.setState({ open: true });
  };

  handleMouseLeave = () => {
    this.setState({ open: false });
  };

  handleDropdownClick = (event) => {
    event.stopPropagation();
  };

  renderOrigin = () => {
    return React.cloneElement(this.props.origin, {
      onMouseEnter: this.handleMouseEnter,
      onMouseLeave: this.handleMouseLeave,
    });
  };

  render() {
    const { children, className, ...props } = this.props;

    return (
      <Dropdown
        {...props}
        className={className}
        open={this.state.open}
        origin={this.renderOrigin()}
        onClick={this.handleDropdownClick}
      >
        {children}
      </Dropdown>
    );
  }
}

// TODO: move to Flow types
// HoverableDropdown.propTypes = {
//   children: PropTypes.node,
//   className: PropTypes.string,
//   origin: PropTypes.node,
//   axis: PropTypes.string,
//   direction: PropTypes.string
// };

HoverableDropdown.defaultProps = {
  axis: 'end',
  direction: 'bottom',
};

export default HoverableDropdown;
