import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage } from 'translations';
import { connectSubpages } from 'app/hocs/subpageManagable';
import InlineLabel from 'app/components/base/InlineLabel';
import RecommendedHint from './RecommendedHint';
import classnames from 'classnames';

import style from './style.css';

const messages = {
  whyRecommendedLabel: { id: 'SEARCH_RESULTS.WHY_RECOMMENDED_LABEL' },
};

const RecommendedHintLabel = ({ subpages, mobile, rules_strings, hidden }) => {
  if (hidden) return <div />;

  const modifiers = {
    [style.mobile]: mobile,
  };
  const handleClick = () => {
    subpages.pushPage(RecommendedHint, { rules_strings, mobile });
  };
  const align = mobile ? 'center center' : 'end center';
  return (
    <InlineLabel
      align={align}
      icon="info_outline"
      family="material"
      iconClassName={style.label_icon}
      className={classnames(style.label, modifiers)}
      text={<FormattedMessage {...messages.whyRecommendedLabel} />}
      onClick={() => handleClick()}
    />
  );
};

// TODO: move to Flow types
// RecommendedHintLabel.propTypes = {
//   subpages: PropTypes.object.isRequired,
//   rules_strings: PropTypes.array,
//   mobile: PropTypes.bool,
//   hidden: PropTypes.bool
// };

const mapStateToProps = (state) => {
  const {
    app: { locale },
  } = state;
  const allowedLocales = ['en_US', 'en_GB'];
  return { hidden: !allowedLocales.includes(locale) };
};

export default compose(
  connect(mapStateToProps),
  connectSubpages()
)(RecommendedHintLabel);
