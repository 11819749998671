import _ from 'lodash';
import moment from 'moment';
import config from 'config';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import { getMainStep } from 'app/utils/trip';
import {
  getDepartureReservation,
  getReturnReservation,
} from 'app/sagas/selectors';
import {
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
} from 'app/constants';

export const mapStateToProps = (
  state,
  { direction, formName = BOOK_TRIP_FORM }
) => {
  const selector = formValueSelector(formName);

  const tripFields = [DEPARTING_TRIP_FIELD_NAME, RETURN_TRIP_FIELD_NAME];
  if (direction === 'returning') {
    tripFields.reverse();
  }

  const trip = selector(state, tripFields[0]);
  const returnTrip = selector(state, tripFields[1]);

  const reservation =
    direction === 'returning'
      ? getReturnReservation(state)
      : getDepartureReservation(state);

  let isBefore = false;
  if (trip) {
    const mainStep = getMainStep(trip);
    isBefore = mainStep.departureDatetime.isBefore(moment());
  }

  return !_.get(trip, 'confirmationNumber')
    ? {}
    : {
        isAllowedToChange: config.CHANGE_BOOKING_FEATURE && !trip.fltSupport,
        reservationId: trip.id,
        returnReservationId: returnTrip?.id ?? '',
        cancelled: trip.cancelled,
        canCancel: reservation.canCancel,
        isBefore,
      };
};

export const builder = (ComposedComponent) => {
  return compose(withRouter, connect(mapStateToProps))(ComposedComponent);
};

export default builder;
