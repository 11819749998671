const SYSTEM = 'system';

export default [
  {
    name: 'PARTNER_REF',
    type: 'string',
    default: '',
    description: 'Default partner ref name (if domain specific not defined)',
    placeholder: 'e.g. booking',
    category: SYSTEM,
  },
  {
    name: 'TRAVEL_AGENT_COMPANY',
    type: 'string',
    default: '',
    description:
      'Company code for registered TAs. If set then all registered user will be TAs.',
    category: SYSTEM,
  },
  {
    name: 'MOZIO_API_KEY',
    type: 'string',
    default: 'c09cc3d01bbe22cb5d7afab4f82c8f3f',
    description: 'Access key for Mozio APIs for logging requests',
    category: SYSTEM,
  },
  {
    name: 'FIXED_APP_VERSION',
    type: 'string',
    default: '',
    description: 'Fixed application version (for manually upgrade the WL)',
    category: SYSTEM,
  },
  {
    name: 'DEFAULT_CURRENCY',
    type: 'string',
    default: 'USD',
    description: 'Default currency',
    category: SYSTEM,
  },
  {
    name: 'DEFAULT_PHONE_COUNTRY',
    type: 'string',
    default: '',
    description: 'Default phone country. If empty – take from active locale',
    category: SYSTEM,
  },
  {
    name: 'CUSTOM_DOMAINS',
    type: 'pairs',
    default: [],
    description:
      'Custom WL domains. key = domain, value = "<locale>,<currency>,<ref>"',
    category: SYSTEM,
  },
  {
    name: 'PLACE_AUTOCOMPLETE_PARAMS',
    type: 'pairs',
    default: [],
    description: 'Place autocomplete parameters',
    category: SYSTEM,
  },
  {
    name: 'EXCLUDE_FROM_EXPERIMENTS',
    type: 'bool',
    default: false,
    description: 'Exclude whitelabel from AB experiments',
    editable: true,
    category: SYSTEM,
  },
  {
    name: 'EXTRA_REG_FIELDS',
    type: 'pairs',
    default: [],
    description: 'Extra registration form fields',
    category: SYSTEM,
  },
  {
    name: 'SEPARATE_CONFIRMATION_PAGE',
    type: 'string',
    default: '',
    description:
      'Separate confirmation page URL. Can use any parameter passed to the landing page by {some_var}. Reserved {id} and {return_id} for confirmation numbers.',
    category: SYSTEM,
  },
  {
    name: 'CUSTOM_STRIPE_CONFIG',
    type: 'pairs',
    default: [],
    description:
      'Custom Stripe config, key = <currency>,<origin>,<country>; value = <prod_key>,<test_key>. First item is default.',
    category: SYSTEM,
  },
  {
    name: 'CUSTOM_BOOKING_DOMAIN',
    type: 'string',
    default: '',
    description:
      'The domain that will be used for the reservation. All links in the emails will head to that domain',
    category: SYSTEM,
  },
];
