import countries from 'mz-ui-kit/form/CountryField/countries';

/**
 * Set confirmation number and users data in ZenDesk chat
 * @param {[type]} reservation [description]
 */
function setReservationInformationInChat(reservation) {
  const {
    trip: { owner, confirmationNumber },
  } = reservation;
  const phoneCountry = countries[owner.countryCodeName.toLowerCase()];
  const callingCode = (phoneCountry && phoneCountry.callingCode) || '';
  const chatInfo = {
    name: `${owner.firstName} ${owner.lastName}`,
    email: owner.email,
    phone: `${callingCode}${owner.phoneNumber}`,
    confTag: `confirmation_number: ${confirmationNumber}`,
  };

  if (typeof window !== 'undefined' && window.zE) {
    window.zESettings.webWidget.chat.tags.push(chatInfo.confTag);
    window.zE('webWidget', 'prefill', {
      name: { value: chatInfo.name },
      email: { value: chatInfo.email },
      phone: { value: chatInfo.phone },
    });
  } else {
    // For local debugging
    console.log('ZENDESK_CHAT_INFO', chatInfo); // eslint-disable-line
  }
}

export default setReservationInformationInChat;
