import { put, call } from 'redux-saga/effects';
import { showErrorNotification } from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { setCustomerProfile } from 'app/actions/travel-agent';

export default function* getCustomerProfile(action) {
  try {
    const { profileId } = action.payload;
    const profile = yield call(travelagent.getCustomerProfile, {
      profile_id: profileId,
    });
    yield put(setCustomerProfile({ profile }));
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
