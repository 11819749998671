import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'translations';
import * as Navbar from 'app/components/base/Navbar';
import LanguageDropdown from 'app/components/base/LanguageDropdown';
import CurrencyDropdown from 'app/components/base/CurrencyDropdown';
import ResendBookingButton from './ResendBookingButton';
import config from 'config';
import { formatString } from 'app/utils/format';
import { USER_TYPE_TA, USER_TYPE_NORMAL } from 'app/constants';

import style from './style.css';

const messages = {
  myBookings: { id: 'MY_BOOKINGS' },
  sign_in: { id: 'SIGN_IN' },
  sign_out: { id: 'SIGN_OUT' },
  blog: { id: 'BLOG_LINK' },
  travel_agent: { id: 'TRAVEL_AGENT' },
};

const USER_ACCOUNT_URLS = {
  [USER_TYPE_TA]: '/travel-agent/account',
  [USER_TYPE_NORMAL]: '/user/account',
};

class Header extends React.Component {
  renderLoggedInLinks() {
    const { userType, activeTab, onLogout } = this.props;
    const accountLink =
      USER_ACCOUNT_URLS[userType] || USER_ACCOUNT_URLS[USER_TYPE_NORMAL];

    return (
      accountLink && (
        <Navbar.ButtonsGroup>
          <Navbar.Link to={accountLink} active={activeTab === 'user'}>
            <FormattedMessage {...messages.myBookings} />
          </Navbar.Link>
          {!config.TUI_CS_ADJUSTMENTS && (
            <Navbar.Button onClick={onLogout} id="nav-logout-button">
              <FormattedMessage {...messages.sign_out} />
            </Navbar.Button>
          )}
        </Navbar.ButtonsGroup>
      )
    );
  }

  renderGuestLinks() {
    const { activeTab, onClickLogin, showSigninButton } = this.props;
    const loginHref = config.LOGIN_ROUTE_PATH;

    if (!showSigninButton) return null;

    return (
      <Navbar.ButtonsGroup>
        <Navbar.Link
          to={loginHref}
          active={activeTab === 'user'}
          onClick={onClickLogin}
        >
          <FormattedMessage {...messages.sign_in} />
        </Navbar.Link>
      </Navbar.ButtonsGroup>
    );
  }

  renderCommonLinks() {
    return (
      <Navbar.ButtonsGroup>
        <Navbar.Button
          href="http://try.mozio.com/travel-agent/"
          target="_blank"
        >
          <FormattedMessage {...messages.travel_agent} />
        </Navbar.Button>
        <Navbar.Button href="https://blog.mozio.com/" target="_blank">
          <FormattedMessage {...messages.blog} />
        </Navbar.Button>
      </Navbar.ButtonsGroup>
    );
  }

  renderLanguageCurrency() {
    const {
      languages,
      activeLanguage,
      activeCurrency,
      onSelectCurrency,
      onSelectLanguage,
    } = this.props;

    return (
      <Navbar.ButtonsGroup className={style.languageCurrencyGroup}>
        <LanguageDropdown
          languages={languages}
          activeLanguage={activeLanguage}
          onSelect={onSelectLanguage}
        />
        <CurrencyDropdown
          activeCurrency={activeCurrency}
          onSelect={onSelectCurrency}
        />
      </Navbar.ButtonsGroup>
    );
  }

  renderCustomLinks() {
    const links = config.HEADER_CUSTOM_LINKS.map((p) => [
      formatString(p[0]),
      formatString(p[1]),
    ]).filter((p) => p[0] && p[1]);

    return (
      !_.isEmpty(links) && (
        <Navbar.ButtonsGroup>
          {links.map((pair, i) => (
            <Navbar.Button key={i} href={pair[1]} target="_blank">
              <FormattedMessage id={pair[0]} />
            </Navbar.Button>
          ))}
        </Navbar.ButtonsGroup>
      )
    );
  }

  render() {
    const { className, userLinks, loggedIn, opened, onToggleNavbar } =
      this.props;

    return (
      <Navbar.Container className={className} opened={opened}>
        <Navbar.ButtonsGroup position="right">
          {this.renderCustomLinks()}
          {config.SHOW_RESEND_BOOKING && <ResendBookingButton />}
          <Navbar.ButtonsGroup>
            {loggedIn ? this.renderLoggedInLinks() : this.renderGuestLinks()}
            {userLinks && this.renderCommonLinks()}
          </Navbar.ButtonsGroup>
          {this.renderLanguageCurrency()}
        </Navbar.ButtonsGroup>
        <Navbar.Hamburger onClick={onToggleNavbar} />
      </Navbar.Container>
    );
  }
}

Header.defaultProps = {
  loggedIn: false,
  userLinks: true,
  showSigninButton: true,
};

// TODO: move to Flow types
// Header.propTypes = {
//   className: PropTypes.string,
//   activeTab: PropTypes.oneOf(['user']),
//   opened: PropTypes.bool,
//   loggedIn: PropTypes.bool,
//   loggedInUser: PropTypes.string,
//   userType: PropTypes.number,
//   userLinks: PropTypes.bool,
//   showSigninButton: PropTypes.bool,
//   onToggleNavbar: PropTypes.func,
//   languages: PropTypes.object,
//   activeLanguage: PropTypes.string,
//   onSelectLanguage: PropTypes.func,
//   activeCurrency: PropTypes.string,
//   onSelectCurrency: PropTypes.func,
//   onClickLogin: PropTypes.func,
//   onLogout: PropTypes.func
// };

export default Header;
