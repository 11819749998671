import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  UPGRADES_FIELD_NAME,
  UPGRADE_IDS,
} from 'app/constants';
import { getGratuityStatus, getBookTripForm } from 'app/sagas/selectors';

/**
 * Update the upgrades list with the latest saved gratuity
 */
export default function* updateUpgrades() {
  const status = yield select(getGratuityStatus);
  let newGratuityUpgrade;

  if (parseInt(status.savedValue, 10) === 0) {
    newGratuityUpgrade = {
      included: false,
      caption: '',
    };
  } else {
    newGratuityUpgrade = {
      included: true,
      showInUpgradeYourTrip: false,
      caption: `${status.savedValue || 0}%`,
    };
  }

  const form = yield select(getBookTripForm);
  const upgrades = form[UPGRADES_FIELD_NAME];
  const newUpgrades = upgrades.map((upgrade) =>
    upgrade.id === UPGRADE_IDS.GRATUITY
      ? {
          ...upgrade,
          ...newGratuityUpgrade,
        }
      : upgrade
  );
  yield put(change(BOOK_TRIP_FORM, UPGRADES_FIELD_NAME, newUpgrades));
}
