import { put, select } from 'redux-saga/effects';
import { change } from 'redux-form';
import { BOOK_TRIP_FORM, COUPON_STATUS } from 'app/constants';
import { getCouponStatus } from 'app/sagas/selectors';

export default function* closeHint() {
  const status = yield select(getCouponStatus);

  yield put(
    change(BOOK_TRIP_FORM, COUPON_STATUS, {
      ...status,
      showHint: false,
      showRefresh: true,
    })
  );
}
