import {
  LOGIN_USER,
  LOGIN_USER_SOCIAL,
  SET_USER,
  LOGOUT_USER,
  RESET_PASSWORD_USER,
  REGISTER_USER,
  REACTIVATE_ACCOUNT,
  CHANGE_PASSWORD,
  SUSPEND_ACCOUNT,
  SET_REDIRECT_BACK_URL,
  CONFIRM_EMAIL_ADDRESS,
  UPDATE_USER_INFORMATION,
  SET_USER_DATA_READY,
  UPDATE_USER_DATA,
} from '../constants';

import { createAction, createDeferredAction } from '../utils/redux';

export const logout = createAction(LOGOUT_USER);

export const setUser = createAction(SET_USER);

export const setUserDataReady = createAction(SET_USER_DATA_READY);

export const updateUserData = createAction(UPDATE_USER_DATA);

export const register = createDeferredAction(REGISTER_USER);

export const login = createAction(LOGIN_USER);

export const loginSocial = createAction(LOGIN_USER_SOCIAL);

export const resetPassword = createAction(RESET_PASSWORD_USER);

export const changePassword = createDeferredAction(CHANGE_PASSWORD);

export const suspendAccount = createDeferredAction(SUSPEND_ACCOUNT);

export const reactivateAccount = createAction(REACTIVATE_ACCOUNT);

export const saveRedirectBackUrl = createAction(SET_REDIRECT_BACK_URL);

export const confirmVerificationCode = createDeferredAction(
  CONFIRM_EMAIL_ADDRESS
);

export const updateUserInformation = createDeferredAction(
  UPDATE_USER_INFORMATION
);
