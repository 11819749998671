import React from 'react';
import { connect } from 'react-redux';
import * as notificationActions from 'app/actions/notifications';

import NotificationBar from 'app/components/base/NotificationBar';

export const GlobalNotification = ({ clearNotifications, ...props }) => (
  <NotificationBar {...props} onClose={clearNotifications} />
);

// TODO: move to Flow types
// GlobalNotification.propTypes = {
//   // Actions
//   clearNotifications: PropTypes.func.isRequired,
//   message: PropTypes.string
// };

export default connect(
  ({ notifications }) => notifications,
  notificationActions
)(GlobalNotification);
