import createMessages from 'mz-intl/create';
import createMessagesObject from '../createMessagesObject';
import gbtSources from '../sources/corporate-gbt.sources-en_US.json';

export const DOMAIN = 'CORP_GBT';

export default createMessages(
  {
    domain: DOMAIN,
    resource: 'corporate-gbt',
    allowUnreviewed: true,
    resourceWithoutDomainPrefix: true,
  },
  createMessagesObject(gbtSources)
);
