import { take, race } from 'redux-saga/effects';
import { UPDATE_PRICE_SUCCESS, UPDATE_PRICE_FAIL } from 'app/constants';

/**
 * Action pattern generator
 * @param  {String} actionType      Type of action to catch.
 * @param  {String} sourceActionId) Id of action where price update was dispatched
 * @return {Boolean}                True if - action what we need
 */
export const actionPattern =
  (actionType, sourceActionId) =>
  ({ type, payload = {} }) => {
    return type === actionType && payload.sourceActionId === sourceActionId;
  };

/**
 * Helper to create a race between price update fail and success
 * @param {String} sourceActionId Type or other unique identifier or action
 *                                where price update was dispatched
 */
export default function* waitForPriceUpdateFinished(sourceActionId) {
  const { fail } = yield race({
    success: take(actionPattern(UPDATE_PRICE_SUCCESS, sourceActionId)),
    fail: take(actionPattern(UPDATE_PRICE_FAIL, sourceActionId)),
  });

  if (fail) {
    const error = fail.error || new Error();
    throw error;
  }
}
