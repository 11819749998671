import { takeLatest } from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { isBookTripSelectCustomer } from 'app/sagas/patterns';
import updateCustomerInfo from './updateCustomerInfo';
import deselectProfile from './deselectProfile';
import { DESELECT_PROFILE } from 'app/constants';

export default function* watchSelectCustomer() {
  yield fork(takeLatest, DESELECT_PROFILE, deselectProfile);
  yield takeLatest(isBookTripSelectCustomer, updateCustomerInfo);
}
