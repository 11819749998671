import React from 'react';
import { FormattedMessage } from 'translations';
import { Link as RouterLink } from 'react-router-dom';
import { Layout } from '../Layout';
import Link from '../Link';
import { formatString } from 'app/utils/format';
import config from 'config';

import style from './style.css';

/**
 * Renders copyrights of mozio and privacy links
 * @return {Components}
 */
export const TermsOfServiceRow = (props) => {
  const links = (config.FOOTER_TERMS_LINKS || [])
    .map((p) => [formatString(p[0]), formatString(p[1])])
    .filter((p) => p[0] && p[1]);

  return (
    <Layout className={style.privacyAndTermOfService} nowrap {...props}>
      {links.map((l, i) => (
        <Link
          component={RouterLink}
          className={style.copyright__link}
          to={l[1]}
          key={i}
          dark
        >
          <FormattedMessage id={l[0]} />
        </Link>
      ))}
    </Layout>
  );
};

export default TermsOfServiceRow;
