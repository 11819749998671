import React from 'react';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';
import FormInput from 'app/components/base/FormInput';
import { isNull } from 'app/utils/types';
import ValidationMessage from 'app/components/base/ValidationMessage';
import InlineLabel from 'app/components/base/InlineLabel';
import { shouldShowErrors } from 'app/utils/forms';

import style from './style.css';

/**
 * An input wrapper that adds icon, label and clear button
 * to a regular `FormInput` component. Supports only `Field`'s component
 * interface (any component related props should be in `input` prop object)
 */
export class CompactInput extends React.Component {
  isValueEmpty() {
    return isNull(this.props.input.value, this.props.type);
  }

  isLabelHidden() {
    const { expand, type, mobile, nolabel } = this.props;
    const isEmpty = this.isValueEmpty();
    return (
      nolabel ||
      (!expand &&
        isEmpty &&
        !mobile &&
        type !== 'date' &&
        type !== 'number' &&
        type !== 'switch') ||
      (!isEmpty && mobile)
    );
  }

  render() {
    const {
      className,
      errorClassName,
      label,
      size,
      slim,
      placeholder,
      expand,
      underlined,
      withErrors,
      alpha,
      softUnderlined,
      whiteUnderlined,
      success,
      error,
      shake,
      compact,
      rectangled,
      noBottom,
      wide,
      ...props
    } = this.props;

    const labelHidden = this.isLabelHidden();

    const modifiers = {
      [style.active]: props.meta.active,
      [style.empty]: this.isValueEmpty,
      [style.nolabel]: labelHidden,
      [style.alpha]: alpha,
      [style.underlined]: underlined,
      [style.softUnderlined]: softUnderlined,
      [style.whiteUnderlined]: whiteUnderlined,
      [style.expand]: expand,
      [style.compact]: compact,
      [style.slim]: slim,
      [style.success]: success,
      [style.error]: error,
      [style.shake]: shake,
      [style.rectangled]: rectangled,
      [style.nobottom]: noBottom,
      [style.wide]: wide,
    };

    return (
      <Layout direction="column">
        <Layout
          nowrap
          size={size}
          className={classnames(className, style.field, modifiers)}
        >
          {!labelHidden && (
            <label className={style.label}>{label || placeholder}</label>
          )}
          <FormInput
            {...props}
            placeholder={placeholder}
            className={style.input}
          />
        </Layout>
        {withErrors && shouldShowErrors(props.meta) && (
          <InlineLabel className={errorClassName}>
            <ValidationMessage
              plain
              errorType={props.meta.error || props.meta.warning}
            />
          </InlineLabel>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// CompactInput.propTypes = {
//   mobile: PropTypes.bool, // Mobile format of the input (wide and well padded)
//   label: PropTypes.node, // Label above the input (by default `placeholder` used)
//   wide: PropTypes.bool,
//   expand: PropTypes.bool,
//   rectangled: PropTypes.bool,
//   noBottom: PropTypes.bool,
//   compact: PropTypes.bool,
//   alpha: PropTypes.bool,
//   success: PropTypes.bool,
//   error: PropTypes.bool,
//   shake: PropTypes.bool,
//   slim: PropTypes.bool,
//   meta: PropTypes.object,
//   type: PropTypes.string,
//   className: PropTypes.string,
//   errorClassName: PropTypes.string,
//   input: PropTypes.object,
//   size: PropTypes.string,
//   placeholder: PropTypes.node,
//   underlined: PropTypes.bool,
//   softUnderlined: PropTypes.bool,
//   whiteUnderlined: PropTypes.bool,
//   nolabel: PropTypes.bool,
//   withErrors: PropTypes.bool,
//   tight: PropTypes.bool
// };

export default CompactInput;
