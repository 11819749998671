import { createReducer } from '../utils/redux';

import {
  SET_SCHEDULED_DEPARTURE,
  UPDATE_SCHEDULE,
  START_SCHEDULE_RESEARCH,
  END_SCHEDULE_RESEARCH,
  SET_SCHEDULE_UPDATE_ERROR,
} from 'app/constants';

const initialState = {
  loading: false,
  error: null,
  trip: null,
};

const actionHandlers = {
  [SET_SCHEDULED_DEPARTURE]: () => ({
    trip: null,
  }),

  [START_SCHEDULE_RESEARCH]: () => ({
    loading: true,
    error: null,
  }),

  [END_SCHEDULE_RESEARCH]: () => ({
    loading: false,
  }),

  [UPDATE_SCHEDULE]: (_, { trip }) => ({
    loading: false,
    trip,
  }),

  [SET_SCHEDULE_UPDATE_ERROR]: (_, { error }) => ({
    loading: false,
    error,
  }),
};

export default createReducer(initialState, actionHandlers);
