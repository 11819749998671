import { call, put } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { showErrorNotification } from 'app/sagas/utils';

export default function* noResultsSubmitEmails(action) {
  try {
    // @TODO use the new engagment endpoint data
    // remove delay call

    yield call(delay, 3000);
    yield put(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
