import _ from 'lodash';
import {
  searchEnd,
  searchSetRecommendedTrip,
  searchAddResult,
} from 'app/actions/search-results';
import { put, call, select, take, race, fork } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import { trips, APIError } from 'mz-sdk';
import { getSearchId } from 'app/sagas/selectors';
import { startMeasuring, stopMeasuring } from 'app/utils/stats';
import { trackSearches } from 'app/sagas/logging/loggly';
import { reportSearchStart, reportSearchEnd } from 'app/sagas/logging/google';
import storeSearch from './storeSearch';
import { getHistory } from 'app/history';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { isLeaveSearchResults } from 'app/sagas/patterns';
import { normalizeErrorMessage } from 'app/sagas/utils';
import { clearCachedDirections } from 'app/actions/srp-map';
import convertFlightToPickup from './convertFlightToPickup';
import getRequestParams from './getRequestParams';
import sendSearchPerf from './sendSearchPerf';
import { hook } from 'app/utils/hook';
import {
  TRACK_SEARCH_FIRST_RESULT_TIME,
  TRACK_SEARCH_RESULTS_LOAD,
  TRACK_SEARCH_POLL_TIME,
  ROUND_TRIP_MODE_VALUE,
  SHOW_MANY_RECOMMENDATIONS_HOOK,
} from 'app/constants';
import config from 'config';

export function* getRecommended(search_id) {
  const showManyRecomenndations = hook(SHOW_MANY_RECOMMENDATIONS_HOOK);
  let recommendations = [];

  // Get recommended trip
  try {
    if (showManyRecomenndations) {
      recommendations = yield call(trips.getRecommendations, { search_id });
    } else {
      // FIXME: recommendations API is broken, disable it for now
      // const recommended = yield call(trips.getRecommended, { search_id })
      // recommendations = recommended && [recommended]
    }
  } catch (e) {
    // sometimes recommendation API fails and it shouldn't break the whole
    // experience
  }

  if (recommendations && recommendations.length > 0) {
    yield put.sync(searchSetRecommendedTrip(recommendations));
  }
}

function* searchPollingIterator(poller, throttle, hadResults) {
  const payload = yield call(poller, throttle);
  if (payload === null) return 0;

  let nextHadResults = !!hadResults;
  if (!nextHadResults && Object.keys(payload.trips).length > 0) {
    nextHadResults = true;
    stopMeasuring(TRACK_SEARCH_FIRST_RESULT_TIME);
  }

  yield put.sync(searchAddResult(payload));
  const nextResult = yield call(
    searchPollingIterator,
    poller,
    throttle,
    nextHadResults
  );
  return nextResult + payload.trips.length;
}

export function* searchPolling({
  utm_campaign,
  campaign,
  polling_limit,
  ...params
}) {
  startMeasuring(TRACK_SEARCH_POLL_TIME, true);
  startMeasuring(TRACK_SEARCH_FIRST_RESULT_TIME, true);

  yield call(storeSearch, params);
  const poller = yield call(trips.searchPrebook, {
    ...params,
    domain: config.FALLBACK_DOMAIN || window.location.hostname,
    campaign: utm_campaign || campaign,
    include_platform_fee: config.INCLUDE_PLATFORM_FEE,
    include_target_pricing: true,
    use_cached_search: false,
  });

  const actualPollingLimit = parseInt(polling_limit || 120000, 10);
  const pollThrottle = polling_limit ? 0 : 1000;
  yield race({
    foundTripsCount: call(searchPollingIterator, poller, pollThrottle),
    pollingTimerExpired: call(delay, actualPollingLimit),
  });

  yield fork(trackSearches, params);
  stopMeasuring(TRACK_SEARCH_POLL_TIME);
}

const processRequestParams = (params) => {
  const { trip_id, ...otherParams } = params;
  let newParams = _.omitBy(
    {
      ...otherParams,
      corporate_trip_id: trip_id,
    },
    _.isNil
  );

  const normMode = (params.mode || '').toLowerCase();
  if (normMode !== ROUND_TRIP_MODE_VALUE) {
    newParams = _.omit(newParams, [
      'return_pickup_datetime',
      'return_flight_datetime',
    ]);
  }

  return newParams;
};

export function* pollAll(requestParams) {
  const params = processRequestParams(requestParams);
  const sources = ['prebook'];

  // Search for on-demand as well if needed
  // if (config.SEARCH_ONDEMAND) {
  //   sources.push('ondemand');
  // }

  // Get full result for prebook
  yield call(searchPolling, { ...params, sources });
  const searchId = yield select(getSearchId);

  if (searchId && config.SEARCH_RECOMMENDED_TRIP) {
    yield call(getRecommended, searchId);
  }

  stopMeasuring(TRACK_SEARCH_RESULTS_LOAD);
  yield call(sendSearchPerf);

  return true;
}

/**
 * Saga for searching trips
 */
export default function* doSearch(action) {
  const researchParams = action.payload?.searchParams;

  yield call(convertFlightToPickup);
  const urlParams = yield call(getRequestParams);

  const requestParams = {
    ...urlParams,
    ...researchParams,
  };

  // Normalize search mode, because some partners might mess this param
  requestParams.mode = (requestParams.mode || '').toLowerCase() || 'one_way';
  requestParams.mode = ['one_way', 'round_trip', 'hourly'].includes(
    requestParams.mode
  )
    ? requestParams.mode
    : 'one_way';

  const history = yield call(getHistory);
  if (!requestParams) {
    yield call(history.push, '/');
    return;
  }

  yield put(clearCachedDirections());

  startMeasuring(TRACK_SEARCH_RESULTS_LOAD);

  const { start_address, end_address } = requestParams;

  // skip empty end_address to avoid it from adding to url when it's hourly booking
  const search = yield call(
    objectToQueryString,
    _.omitBy(
      {
        ...requestParams,
        start_address: _.isObject(start_address)
          ? start_address.value || start_address.display
          : start_address,
        end_address: _.isObject(end_address)
          ? end_address.value || end_address.display
          : end_address,
      },
      _.isNil
    )
  );

  yield call(history.push, {
    pathname: '/search',
    search,
  });

  try {
    yield call(reportSearchStart);
    const pollResult = yield race({
      polling: call(pollAll, requestParams),
      locationChange: take(isLeaveSearchResults),
    });
    if (pollResult.polling) {
      yield call(reportSearchEnd);
    }
    yield put.sync(searchEnd({}));
  } catch (error) {
    if (error instanceof APIError) {
      const message = normalizeErrorMessage(error.response, true);
      const errorCodeMessage =
        error.response?.non_field_errors?.[0]?.code || null;

      yield put(
        searchEnd({
          errored: { code: error.errorCode, errorCodeMessage, message },
        })
      );
    } else {
      console.error(error); // eslint-disable-line no-console
    }
  }
}
