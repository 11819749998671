import _ from 'lodash';
import { put, call, select } from 'redux-saga/effects';
import { getStripeOrigin } from 'app/sagas/selectors';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'app/sagas/utils';
import { travelagent } from 'mz-sdk';
import { getCustomerProfileList } from 'app/actions/travel-agent';
import getCustomerProfileData from './common/getCustomerProfileData';
import createStripeSource from 'app/sagas/stripe/createStripeSource';

export const isEmpty = (field) => {
  return _.isNil(field) || field === '';
};

export default function* updateCustomerProfile(action) {
  const { payload: stripe } = action;

  const { cardData, profileData, isCardDataEmpty } = yield call(
    getCustomerProfileData
  );
  const stripe_origin = yield select(getStripeOrigin);

  try {
    const source = !isCardDataEmpty
      ? yield call(createStripeSource, stripe, cardData)
      : null;

    yield call(
      travelagent.updateCustomerProfile,
      _.omitBy({ ...profileData, stripe_origin }, isEmpty),
      source
    );
    const getCustomerProfileListAction = yield call(getCustomerProfileList);
    yield put(getCustomerProfileListAction);
    yield call(showSuccessNotification, {
      messageId: 'TA_ACCOUNT.CUSTOMER_PROFILES.PROFILE_UPDATED_NOTIFICATION',
      titleId: 'SUCCESS',
    });
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
