import { call, put, select } from 'redux-saga/effects';
import { setUser } from 'app/actions/user-session';
import { auth } from 'mz-sdk';
import { getHistory } from 'app/history';
import { getLocation } from '../selectors';
import { redirectBack, showErrorNotification } from 'app/sagas/utils';
import getUserData from './getUserData';
import doSetLocaleCurrency from './doSetLocaleCurrency';
import { REDIRECT_AFTER_LOGIN_HOOK } from 'app/constants';
import { hook } from 'app/utils/hook';
import config from 'config';

export default function* doLogin(action, redirect = true) {
  try {
    const { data } = yield call(auth.login, action.payload);
    yield put(setUser(data));
    yield call(getUserData);

    // On login, we identify the user with the Chameleon api
    if (typeof chmln !== 'undefined') {
      // eslint-disable-line
      chmln.identify(window.mozioUser); // eslint-disable-line
    }

    // Redirect user to the previous point
    // or to the point requested by `next` URL parameter
    const { query } = yield select(getLocation);
    const history = yield select(getHistory);
    const nextUrl = query.next;

    if (nextUrl) {
      if (nextUrl.match(/^https?:\/\//)) {
        window.location.href = nextUrl; // eslint-disable-line require-atomic-updates
      } else {
        history.push(nextUrl);
      }
    } else if (redirect) {
      yield call(redirectBack);
    } else {
      const redirectUrl = hook({
        name: REDIRECT_AFTER_LOGIN_HOOK,
        defaultHandler: () => '/',
      });
      history.push(redirectUrl);
    }

    yield call(doSetLocaleCurrency);
  } catch (error) {
    yield put(setUser());
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.LOGIN_ERROR_TITLE',
    });

    if (
      config.PASSWORD_RESET_ABILITY &&
      error.errorCode &&
      error.errorCode === 'user_password_expired'
    ) {
      const history = yield select(getHistory);
      history.push('/user/forgot-password');
    }
  }
}
