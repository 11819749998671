import React from 'react';
import { Layout } from 'app/components/base/Layout';
import MobileTripDirection from 'app/components/common/TripDirection/Mobile';
import ReservationActions from '../../ReservationActions';
import ReservationConfirmationNumber from '../../ReservationConfirmationNumber';
import builder from '../builder';

import style from './style.css';

export const MobileReturnDirection = ({ readOnly, formName, ...props }) => (
  <Layout
    className={style.block}
    direction="row"
    size="noshrink"
    align="start start"
    nowrap
  >
    <Layout className={style.block__direction} direction="column" size="grow">
      <ReservationConfirmationNumber formName={formName} {...props} />
      <MobileTripDirection {...props} showDateTimes={readOnly} />
      <ReservationActions formName={formName} {...props} mobile />
    </Layout>
  </Layout>
);

// TODO: move to Flow types
// MobileReturnDirection.propTypes = {
//   formName: PropTypes.string,
//   onClickRemove: PropTypes.func,
//   readOnly: PropTypes.bool
// };

MobileReturnDirection.defaultProps = {
  readOnly: false,
};

export default builder(MobileReturnDirection);
