import React, { Component } from 'react';
import { FormattedMessage } from 'translations';
import MobileScreen from 'app/components/base/MobileScreen';
import LoadingSpinner from 'app/components/base/LoadingSpinner';
import TripCard from '../commons/TripCard';
import builder from '../commons/builder';

import style from './style.css';

// Message constants.
const messages = {
  cancel: { id: 'CANCEL' },
  bookReturn: { id: 'BOOK_TRIP.BOOK_ROUNDTRIP' },
};

/**
 * Stateless component for a mobile return trip card.
 *
 * If no trip object is provided, it will show a loading spinner.
 * @return {Component}
 */
export class MobileReturnTrip extends Component {
  handleCancel = () => {
    const { subpages } = this.props;
    subpages.popPage();
  };

  handleBookTrip = () => {
    const { subpages, bookChangedModeTrip } = this.props;
    bookChangedModeTrip();
    subpages.removeAll();
  };

  render() {
    const { trip } = this.props;
    return (
      <MobileScreen
        className={style.container}
        title={<FormattedMessage {...messages.bookReturn} />}
        titleDone={<FormattedMessage {...messages.cancel} />}
        onClickDone={this.handleCancel}
      >
        {trip && (
          <TripCard onClickBook={this.handleBookTrip} trip={trip} mobile />
        )}
        {!trip && (
          <div className={style.loading}>
            <LoadingSpinner size="small" />
          </div>
        )}
      </MobileScreen>
    );
  }
}

// TODO: move to Flow types
// MobileReturnTrip.propTypes = {
//   trip: PropTypes.object,
//   subpages: PropTypes.object.isRequired,
//   bookChangedModeTrip: PropTypes.func.isRequired
// };

export default builder(MobileReturnTrip);
