import getAllVehicles from 'mz-sdk/services/vehicles/getAllVehicles';
import createMessages from 'mz-intl/create';
import englishVehicles from '../sources/vehicles.source-en_US.json';

export const DOMAIN = 'VEHICLES';

function createMessagesFromVehicles(vehicles, prefix = '') {
  return vehicles.reduce(
    (acc, v) => ({
      ...acc,
      [`${prefix}VEHICLES.ID_${v.vehicle_id}.NAME`]: v.display_name,
      [`${prefix}VEHICLES.ID_${v.vehicle_id}.DESCRIPTION`]: v.description,
    }),
    {}
  );
}

async function vehicleMessagesLoader() {
  const vehicles = await getAllVehicles();
  return createMessagesFromVehicles(vehicles, `${DOMAIN}.`);
}

export default createMessages(
  {
    domain: DOMAIN,
    disablePull: true,
    disablePush: true,
    domainLoader: vehicleMessagesLoader,
  },
  createMessagesFromVehicles(englishVehicles)
);
