import React from 'react';
import classnames from 'classnames';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import DataField from '../commons/DataField';
import InlineLabel from 'app/components/base/InlineLabel';
import Step from 'app/components/search-results/Trip/commons/Step';
import { Arrow } from 'app/components/base/TripIcons';
import { FormattedMessage } from 'translations';

import style from './style.css';

// Constants
const messages = {
  fromLabel: { id: 'TRIP_DETAILS.FROM' },
  toLabel: { id: 'TRIP_DETAILS.TO' },
  departing: { id: 'TRIP_DETAILS.DEPARTING' },
  returning: { id: 'TRIP_DETAILS.RETURNING' },
  routeDetailsLabel: { id: 'TRIP_DETAILS.MOBILE.ROUTE_DETAILS' },
  milesDistance: { id: 'SEARCH_RESULTS.MILES_DISTANCE' },
};

const getDistance = (distance) => {
  return <FormattedMessage {...messages.milesDistance} values={{ distance }} />;
};

/**
 * Renders the route details section that specifies dates and locations for
 * the trip. It can be used both for normal and return trip.
 * TODO: Add callback for routeDetails button when connecting.
 */
const MobileTripDirection = ({
  showDateTimes,
  fltSupport,
  fromIataCode,
  toIataCode,
  flightNumber,
  airline,
  fromAddress,
  toAddress,
  fromDistance,
  fromStation,
  toDistance,
  toStation,
  arrivalDatetime,
  departureDatetime,
  direction,
  cancelled,
  // onClickRouteDetails
}) => {
  const labelPrefixColorMap = {
    departing: '#009245',
    returning: '#ff9c00',
  };
  const modifiers = {
    [style['trip--cancelled']]: cancelled,
  };
  // display either station (train, bus) or just address entered by user
  const pickupLocation = (fromStation && fromStation.name) || fromAddress;

  const dropoffLocation = (toStation && toStation.name) || toAddress;

  const showWalkingSteps = false;

  const flightCode =
    airline && flightNumber ? `${airline} ${flightNumber}` : null;

  return (
    <Layout
      className={classnames(style.container, modifiers)}
      direction="column"
      nowrap
    >
      {/* Vertical route line */}
      <Layout size="noshrink" direction="row" nowrap>
        <Layout
          nowrap
          size="noshrink"
          direction="column"
          align="start start"
          className={style['route-line']}
        >
          <Icon small className={style.icon}>
            radio_button_unchecked
          </Icon>
          <Flex className={style['vertical-line']} />
          <Icon small className={style.icon}>
            location_on
          </Icon>
        </Layout>

        <Layout direction="column" size="grow">
          <DataField
            value={pickupLocation}
            label={messages.fromLabel}
            labelPrefix={direction && messages[direction]}
            labelPrefixColor={labelPrefixColorMap[direction]}
          />
          {showWalkingSteps && !!fromDistance && !!fromStation && (
            <Layout align="start center" className={style['step-wrapper']}>
              <Step
                icon="walk"
                description={getDistance(fromDistance)}
                className={style.step}
              />
              <span className={style.arrow}>
                {' '}
                <Arrow />{' '}
              </span>
              {fromStation.name}
            </Layout>
          )}
          {showDateTimes && !fltSupport && (
            <InlineLabel
              icon={{ name: 'clock', family: 'mozio' }}
              className={style.label}
              text={departureDatetime}
              iconColor={direction && labelPrefixColorMap[direction]}
            />
          )}
          {!!flightCode && !!fromIataCode && (
            <InlineLabel
              icon={{ name: 'airport', family: 'mozio' }}
              className={style.label}
              text={flightCode}
              iconColor={direction && labelPrefixColorMap[direction]}
            />
          )}
          <DataField
            value={dropoffLocation}
            label={messages.toLabel}
            className={style.from}
          />
          {showWalkingSteps && !!toDistance && !!toStation && (
            <Layout align="start center" className={style['step-wrapper']}>
              {toStation.name}
              <span className={style.arrow}>
                {' '}
                <Arrow />{' '}
              </span>
              <Step
                icon="walk"
                description={getDistance(toDistance)}
                className={style.step}
              />
            </Layout>
          )}
          {showDateTimes && !fltSupport && (
            <InlineLabel
              icon={{ name: 'clock', family: 'mozio' }}
              className={style.label}
              text={arrivalDatetime}
              iconColor={direction && labelPrefixColorMap[direction]}
            />
          )}
          {!!flightCode && !!toIataCode && (
            <InlineLabel
              icon={{ name: 'airport', family: 'mozio' }}
              className={style.label}
              text={flightCode}
              iconColor={direction && labelPrefixColorMap[direction]}
            />
          )}
        </Layout>
      </Layout>

      {/* TODO: add route details screen <Layout>
        <Button bordered small nocaps onClick={onClickRouteDetails}>
          <FormattedMessage {...messages.routeDetailsLabel} />
        </Button>
      </Layout>*/}
    </Layout>
  );
};

// TODO: move to Flow types
// MobileTripDirection.propTypes = {
//   showDateTimes: PropTypes.bool,
//   fromAddress: PropTypes.string.isRequired,
//   toAddress: PropTypes.string.isRequired,
//   fromDistance: PropTypes.string,
//   fromStation: PropTypes.object,
//   toDistance: PropTypes.string,
//   toStation: PropTypes.object,
//   departureDatetime: PropTypes.string,
//   arrivalDatetime: PropTypes.string,
//   direction: PropTypes.oneOf(['departing', 'returning']),
//   onClickRouteDetails: PropTypes.func,
//   cancelled: PropTypes.bool
// };

MobileTripDirection.defaultProps = {
  showDateTimes: false,
};

export default MobileTripDirection;
