import React from 'react';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';
import {
  getTimeVariants,
  getDateFromTimeValue,
  getTimeSelectValue,
  isDateSameDay,
} from './utils';

import style from './style.css';

// Utils
function getVariantsFromProps({ value, timeStep, minDate }) {
  return getTimeVariants(value, timeStep, minDate);
}

/**
 * Time picker row for mobile screens
 */
export class TimePicker extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = { variants: getVariantsFromProps(props) };
  }

  componentWillReceiveProps(nextProps) {
    if (
      !isDateSameDay(nextProps.value, this.props.value) ||
      nextProps.timeStep !== this.props.timeStep
    ) {
      this.updateTimeVariants(nextProps);
    }
  }

  updateTimeVariants(props) {
    this.setState({ variants: getVariantsFromProps(props) });
  }

  handleChangeOption = (e) => {
    const { onChange, value } = this.props;
    const date = getDateFromTimeValue(value, e.target.value);

    if (onChange) {
      onChange(date);
    }
  };

  render() {
    const {
      className,
      value,
      title,
      onChange, // eslint-disable-line
      ...props
    } = this.props;
    const { variants } = this.state;
    const selectValue = getTimeSelectValue(value);

    return (
      <Layout
        nowrap
        align="center center"
        className={classnames(className, style.time)}
        direction="column"
        {...props}
      >
        <h3 className={style.time__header}>{title}</h3>
        <div className={style.time__wrapper}>
          <select
            className={style.time__select}
            value={selectValue}
            onChange={this.handleChangeOption}
          >
            {variants.map((v, i) => (
              <option value={v.time} key={i}>
                {v.name}
              </option>
            ))}
          </select>
        </div>
      </Layout>
    );
  }
}
TimePicker.defaultProps = {
  timeStep: 15,
  title: 'Set Time',
};

// TODO: move to Flow types
// TimePicker.propTypes = {
//   minDate: PropTypes.object,
//   title: PropTypes.string,
//   className: PropTypes.string,
//   value: PropTypes.any,
//   onChange: PropTypes.func,
//   timeStep: PropTypes.number
// };

export default TimePicker;
