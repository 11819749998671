import React from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';

import style from './style.css';

/**
 * Presentational component to show the description of an upgrade item
 */
const Description = ({ description }) => {
  const isArray = Array.isArray(description);
  const modifiers = {
    // [style.mobile]: mobile,
    // [style.horizontal]: horizontal,
    // [style['description--list']]: isArray
  };

  return (
    <Layout className={classnames(style.description, modifiers)}>
      {!isArray && description}
      {isArray && (
        <ul>
          {description.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// Description.propTypes = {
//   mobile: PropTypes.bool,
//   horizontal: PropTypes.bool,
//   description: PropTypes.oneOfType([
//     PropTypes.array,
//     PropTypes.string
//   ])
// };

export default Description;
