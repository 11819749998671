import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import getReservations from './getReservations';
import withdrawCommissions from './withdrawCommissions';
import { TA_GET_RESERVATIONS, TA_WITHDRAW_COMMISSIONS } from 'app/constants';

export default function* watchSalesBalance() {
  yield fork(takeLatest, TA_GET_RESERVATIONS, getReservations);
  yield fork(takeLatest, TA_WITHDRAW_COMMISSIONS, withdrawCommissions);
}
