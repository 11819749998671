import * as React from 'react';
import Badge from '../Badge';
import config from 'config';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';
import useGoogleOptimize from '@react-hook/google-optimize';

function getScoreId(score) {
  if (score >= 9.5) return '9';
  if (score >= 8.5) return '8';
  if (score >= 8) return '7';
  if (score >= 7) return '6';
  if (score >= 5) return '5';
  if (score > 0) return '4';
  return 'NEW';
}

function GenericSupplierScore({ score }) {
  const scoreId = getScoreId(score);
  const minimalScore = config.MINIMAL_SUPPLIER_SCORE
    ? parseFloat(config.MINIMAL_SUPPLIER_SCORE)
    : 7;
  const scoreNumber =
    !score || score <= 0 ? null : `${Math.round(score * 10) / 10}`;

  return (
    <Badge
      leftPart={scoreNumber}
      rightPart={
        <FormattedMessage message={messages[`SUPPLIER_SCORE_${scoreId}`]} />
      }
      color={scoreNumber > 0 && scoreNumber < minimalScore ? 'gray' : 'primary'}
      tooltipContent={() => (
        <FormattedMessage
          message={messages[`SUPPLIER_SCORE_${scoreId}_TOOLTIP`]}
        />
      )}
    />
  );
}

function ABTestNewSupplierVariations({ score }) {
  const scoreVariant = useGoogleOptimize('yQB_9imFQl-sM8DsOQ-Raw', [
    true,
    'Not Tested',
    'Not Enough Ratings',
    'No Score Yet',
    'In Evaluation',
    'Newly Added',
    'New Supplier',
  ]);

  const actualScore = !scoreVariant || scoreVariant === true ? score : -1;
  const scoreId = getScoreId(actualScore);
  const minimalScore = config.MINIMAL_SUPPLIER_SCORE
    ? parseFloat(config.MINIMAL_SUPPLIER_SCORE)
    : 7;
  const scoreNumber =
    !actualScore || actualScore <= 0
      ? null
      : `${Math.round(actualScore * 10) / 10}`;

  const rightPart =
    scoreVariant && scoreVariant !== true ? (
      scoreVariant
    ) : (
      <FormattedMessage message={messages[`SUPPLIER_SCORE_${scoreId}`]} />
    );

  return (
    <Badge
      leftPart={scoreNumber}
      rightPart={rightPart}
      color={scoreNumber > 0 && scoreNumber < minimalScore ? 'gray' : 'primary'}
      tooltipContent={() => (
        <FormattedMessage
          message={messages[`SUPPLIER_SCORE_${scoreId}_TOOLTIP`]}
        />
      )}
    />
  );
}

export default function SupplierScore({ score, experimentNewSupplierLabel }) {
  if (
    experimentNewSupplierLabel &&
    score > 0 &&
    !config.MINIMAL_SUPPLIER_SCORE
  ) {
    return <ABTestNewSupplierVariations score={score} />;
  }
  return <GenericSupplierScore score={score} />;
}
