import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { doSearch } from 'app/actions/search-results';
import classnames from 'classnames';

import style from './style.css';

const ErrorIcon = require('-!babel-loader!@mozioinc/svg-react-loader!./assets/sold-out.svg');
const messages = {
  title: { id: 'BOOK_TRIP.SOLD_OUT_ERROR.TITLE' },
  message: { id: 'BOOK_TRIP.SOLD_OUT_ERROR.MESSAGE' },
  returnToResults: { id: 'BOOK_TRIP.SOLD_OUT_ERROR.RETURN_TO_RESULTS' },
};

class TripSoldOutError extends Component {
  handleClick = () => {
    const {
      onClick,
      errorPopup,
      errorProps: { searchParams },
      subpages,
    } = this.props;

    if (errorPopup) {
      subpages.popPage();
    }
    onClick({ searchParams });
  };

  render() {
    const { errorMessage } = this.props;

    return (
      <Layout
        className={classnames(style.container, style.shrinked)}
        direction="column"
        align="start center"
      >
        <ErrorIcon className={style.icon} />
        <span className={classnames(style.title, style.shrinked)}>
          <FormattedMessage {...messages.title} />
        </span>
        <span className={classnames(style.message, style.shrinked)}>
          {errorMessage || <FormattedMessage {...messages.message} />}
        </span>
        <Button
          className={style.button}
          onClick={this.handleClick}
          nocaps
          rounded
        >
          <FormattedMessage {...messages.returnToResults} />
        </Button>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// TripSoldOutError.propTypes = {
//   linkLocation: PropTypes.object,
//   errorCode: PropTypes.any,
//   errorPopup: PropTypes.bool,
//   errorMessage: PropTypes.string,
//   errorProps: PropTypes.object,
//   subpages: PropTypes.object,
//   onClick: PropTypes.func.isRequired
// };

export default compose(
  connectSubpages(),
  connect(null, { onClick: doSearch })
)(TripSoldOutError);
