const CORPORATE = 'corporate';

export default [
  {
    name: 'LOGIN_ROUTE_PATH',
    type: 'string',
    default: '/user/login',
    description: 'Login page URL',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'SEARCH_REDIRECT_PATH',
    type: 'string',
    default: '/',
    description: 'Search page URL',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'REG_ROUTE_PATH',
    type: 'string',
    default: '/user/register',
    description: 'Registration page URL',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'CORPORATE_ID',
    type: 'string',
    default: '',
    description: 'Id of the corporate',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'CORPORATE_REG_ENABLED',
    type: 'bool',
    default: true,
    description: 'Is registration enabled',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'SHOW_FEATURES_CTA',
    type: 'bool',
    default: true,
    description: 'Is CTA on HP features section enabled',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'IS_EMAIL_CHANGABLE',
    type: 'bool',
    default: true,
    description: 'Can the user change the email',
    editable: false,
    category: CORPORATE,
  },
  {
    name: 'CORPORATE_INVITE_COVER_IMAGE',
    type: 'image',
    default: '',
    description: 'Custom cover image for corporate signup',
    editable: false,
    category: CORPORATE,
  },
];
