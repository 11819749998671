import moment from 'moment';
import countries from 'mz-ui-kit/form/CountryField/countries';
import has from 'lodash/has';
import padStart from 'lodash/padStart';
import isEqual from 'lodash/isEqual';

export const SEARCH_PARAM_DATE_FORMAT = 'MM/DD/Y hh:mm A';

export const formatDateTime = (date) => date.format(SEARCH_PARAM_DATE_FORMAT);

export const createOption = (value, display = value) => ({
  value,
  display: String(display),
});

export const HOUR_FORMAT = 'HH:mm (hh:mm A)';
export const DAY_FORMAT = 'dddd, D';
export const MONTH_DAY_FORMAT = 'MMM DD';
export const FULL_DATE_FORMAT = 'YYYYMMDD HHmmss ZZ';
export const FULL_DATE_FORMAT_WO_TZ = 'YYYYMMDD HHmmss';
export const TRIP_DATE_FORMAT = 'MM-DD-YYYY HH:mm ZZ';
export const YEAR_FORMAT = 'YYYY';
export const DATETIME_FORMAT = 'DD MMM YYYY hh:mm A';
export const DATE_FORMAT = 'MMMM DD, YYYY';
export const MONTH_DAY_HOUR_FORMAT = `${MONTH_DAY_FORMAT} - ${HOUR_FORMAT}`;

export const copyDateTimeFromTripTZ = (date) => {
  const newDate = moment({
    year: date.year(),
    month: date.month(),
    date: date.date(),
    hours: date.hours(),
    minutes: date.minutes(),
    seconds: date.seconds(),
  });
  return newDate;
};

/**
 * This function returns a moment object that doesn't keeps TZ info. So the
 * result will be in the browser TZ. This is made to avoid TZ interpretations
 * when we just need to work with the date, like in `intl.formatDate`.
 * @param  {moment} date     [moment with TZ info]
 * @return {moment} newDate  [moment with Browser TZ]
 */
export const getTZLessDate = (date) => {
  return moment(date.format('DD.MM.YYYY HH:mm'), 'DD.MM.YYYY HH:mm');
};

const getHoursOfDay = (currentDate = moment()) => {
  const hoursOfDay = [];
  const iterableDate = currentDate.clone().hours(0).minutes(0).seconds(0);

  while (iterableDate.isSame(currentDate, 'day')) {
    hoursOfDay.push(iterableDate.clone());
    iterableDate.add(15, 'minutes');
  }
  return hoursOfDay;
};

export const getMinDate = (context = moment()) => {
  const minDate = moment(
    context.utcOffset(-12).format(FULL_DATE_FORMAT),
    FULL_DATE_FORMAT_WO_TZ
  );
  const minutes = minDate.minutes();
  if (minutes > 45) {
    return minDate.add(1, 'hours').minutes(0);
  }
  if (minutes > 30) {
    return minDate.minutes(45);
  }
  if (minutes > 15) {
    return minDate.minutes(30);
  }
  if (minutes > 0) {
    return minDate.minutes(15);
  }
  return minDate;
};

export const getTimeOptions = (context = moment(), minDate = getMinDate()) => {
  const hoursOfDay = getHoursOfDay(context);
  let values = hoursOfDay.slice();
  if (context.isSame(minDate, 'day')) {
    values = hoursOfDay.filter((hour) => hour.isAfter(minDate, 'hour'));
  } else if (context.isBefore(minDate, 'day')) {
    values = [];
  }
  return values;
};

export const setTime = (date, time, minDate = getMinDate()) => {
  const parsed = moment(time, HOUR_FORMAT);
  const result = moment(date)
    .hours(parsed.hours())
    .minutes(parsed.minutes())
    .startOf('minute');
  if (result.isBefore(minDate)) {
    return minDate;
  }
  return result;
};

export const getMonthOptions = () => {
  return moment
    .months()
    .map((mName, index) =>
      createOption(index + 1, `${padStart(index + 1, 2, '0')} ${mName}`)
    );
};

export const getYearOptions = (
  count,
  yearFrom = moment().format(YEAR_FORMAT)
) => {
  return Array(count)
    .fill()
    .map((_, i) => createOption(parseInt(yearFrom, 10) + i));
};

const nullValues = {
  select: {},
  options: {},
};

export const getNull = (type) => {
  return has(nullValues, type) ? nullValues[type] : '';
};

export const isNull = (val, type) => {
  if (val === undefined || val === null) {
    return true;
  }
  switch (type) {
    case 'phone_code':
      return !val || val.uncomplete;
    default:
      return val === '' || isEqual(val, getNull(type));
  }
};

/* eslint-disable max-len */
const EMAIL_REGEXP =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PASSWORD_REGEXP = /^.{2,}$/;
const PHONE_REGEXP = /^\d{8,}$/;
/* eslint-enable max-len */

export const isInvalid = (val, type) => {
  switch (type) {
    case 'select':
      return val && !val.value;
    case 'email':
      return !EMAIL_REGEXP.test(val);
    case 'password':
      return !PASSWORD_REGEXP.test(val);
    case 'phone':
      return !PHONE_REGEXP.test(val);
    case 'creditCardNumber':
    case 'creditCardExpiry':
    case 'creditCardCVC':
      return val && !!val.error;
    case 'date':
      return !val || !val.isValid();
    case 'number':
      return isNaN(val);
    default:
      return !val;
  }
};

export const parse = (val, type) => {
  switch (type) {
    case 'number':
      return parseInt(val, 10);
    case 'date':
      return moment(val);
    default:
      return val;
  }
};

export const cloneDate = (date) => {
  return moment(date.format(FULL_DATE_FORMAT), FULL_DATE_FORMAT);
};

export const isSameDate = (date1, date2) => date1.isSame(date2, 'minute');
export const isSameOption = (option1, option2) =>
  option1.value === option2.value;

export const getOptionDisplay = (option) => option.display;
export const getOptionValue = (option) => option.value;

const normalizeCountryCode = (code) => (code || '').toLowerCase();

export const countryCodeToCountryObject = (code, noFallback) => {
  const normalized = normalizeCountryCode(code);
  if (!countries[normalized]) {
    return noFallback ? null : countryCodeToCountryObject('us');
  }
  return { code: normalized, ...countries[normalized] };
};

export const countryCodeToRawSuggestion = (code, noFallback) => {
  const countryObject = countryCodeToCountryObject(code, noFallback);
  if (!countryObject) return null;
  const { callingCode, name } = countryObject;
  return {
    display: `${name} (${callingCode})`,
    name,
    callingCode,
    code: countryObject.code,
  };
};

export const createStripeValue = () => ({
  brand: 'unknown',
  complete: false,
  empty: true,
});

export const normalizePhone = (value) => {
  if (!value) return value;
  return value.replace(/[^\d]/g, '');
};
