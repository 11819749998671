import { call, put } from 'redux-saga/effects';
import { favouritePlaces } from 'mz-sdk';
import { showErrorNotification } from 'app/sagas/utils';
import { getFavouriteLocations } from 'app/actions/favourite-locations';

/**
 * Delete favourite location
 */
export default function* deleteLocation(action) {
  const place = action.payload;
  try {
    yield call(favouritePlaces.delete, { id: place.id });
    yield call(action.resolvePromise);
    yield put(getFavouriteLocations({ clear: false }));
  } catch (error) {
    yield call(showErrorNotification, { messageId: 'ERRORS.GENERAL_ERROR' });
    yield call(action.rejectPromise, error);
  }
}
