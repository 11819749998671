import React from 'react';
import { findMainStep, getAmenities } from 'app/utils/trip';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import Amenity from 'app/components/search-results/Amenity';
import InlineLabel from 'app/components/base/InlineLabel';
import { ClockIcon } from 'app/components/base/TripIcons';
import Icon from 'app/components/base/Icon';
import { constants } from 'mz-sdk';
import { TRANSPORT_RAIL, ON_DEMAND_CATEGORY } from 'app/constants';
import Button from 'app/components/base/Button';
import { getImageFromClass } from 'app/utils/vehicleClass';
import config from 'config';
import HourlyDuration from '../../commons/HourlyDuration';
import IncompleteAddressLightbox from '../../../IncompleteAddressLightbox';

// import subcomponents
import Heading from './Heading';
import Tags from '../../commons/Tags';
import TripPrice from '../../commons/TripPrice';
import TripProviderLogo from '../../commons/TripProviderLogo';
import BagsAndPassengers from '../../commons/BagsAndPassengers';
import RideshareLabel from '../../commons/RideshareLabel';
import InternetRequiredLabel from '../../commons/InternetRequiredLabel';
import MilesEarnedLabel from '../../commons/MilesEarnedLabel';

import style from './style.css';

const { CATEGORIES_FILTERS } = constants;

const messages = {
  leavesFrom: { id: 'SEARCH_RESULTS.LEAVES_FROM' },
  rideDuration: { id: 'SEARCH_RESULTS.RIDE_DURATION_SHORT' },
  moreAmenities: { id: 'SEARCH_RESULTS.MORE_AMENITIES' },
  notIncluded: { id: 'SEARCH_RESULTS.NOT_INCLUDED' },
  waitTime: { id: 'SEARCH_RESULTS.WAIT_TIME' },
  bookRide: { id: 'SEARCH_RESULTS.BOOK_RIDE' },
};

class MobileTrip extends React.Component {
  state = { detailed: false };

  onClickShowMore = (e) => {
    e.stopPropagation();
    this.setState({ detailed: true });
  };

  render() {
    const {
      id: tripId,
      type: tripType,
      price,
      priceLabel,
      departureTime,
      departingLocation,
      tags,
      startTime,
      endTime,
      steps,
      rewardPoints,
      returnSteps,
      showDescription,
      minimal,
      onClick,
      isRecommended,
      isAddressIncomplete,
      experimentNewSupplierLabel,
    } = this.props;

    const {
      provider,
      description,
      accurateTime: duration,
      vehicle: {
        imageUrl,
        isMaxBagsPerPerson,
        vehicleClass,
        vehicleClassName,
        maxPassengers,
        maxBags,
        make,
        model,
        type: category = {},
        numVehicles,
      },
      bookingDetails: { waitTime, hourlyDetails },
    } = findMainStep(steps);

    // Trip amenities
    const amenities = getAmenities(
      findMainStep(steps),
      findMainStep(returnSteps),
      'amenities'
    );

    const isOndemand = tripType === ON_DEMAND_CATEGORY;
    const isTrain = findMainStep(steps).type === TRANSPORT_RAIL;
    const isHourlyRide = !!(hourlyDetails && hourlyDetails.hoursRequested);
    const vehicleIcon = category.key
      ? CATEGORIES_FILTERS[category.key]
      : 'sedan';

    const headingProps = {
      experimentNewSupplierLabel,
      providerName: provider.name,
      description: showDescription ? description : null,
      vehicleType: category.id,
      score: provider.supplierScore,
      vehicleClass,
      vehicleClassName,
      vehicleIcon,
      make,
      model,
    };

    const providerLogoProps = {
      imageUrl: getImageFromClass(headingProps.vehicleClass) || imageUrl,
      numVehicles,
      recommended: isRecommended || minimal,
      mobile: true,
      norating: minimal,
    };

    const amenitiesInView =
      minimal || this.state.detailed ? amenities : amenities.slice(0, 1);

    const modifiers = {
      [style['container--recommended']]: isRecommended,
      [style['container--minimal']]: minimal,
    };

    const detailsModifiers = {
      [style['no-details']]: !(
        amenitiesInView.length ||
        (startTime && endTime) ||
        departureTime
      ),
    };

    const hours = Math.floor(duration / 60);
    const minutes = `0${duration % 60}`.slice(-2);

    return (
      <IncompleteAddressLightbox tripId={tripId}>
        {({ showForm }) => (
          <Layout
            direction="row"
            className={classnames(style.container, modifiers)}
            onClick={isAddressIncomplete ? showForm : onClick}
            nowrap
          >
            {!minimal && config.SHOW_TAGS_IN_SEARCH && isRecommended && (
              <Tags
                direction="column"
                showText={false}
                tags={tags}
                className={style.tags}
                mobile
              />
            )}
            <TripProviderLogo className={style.logo} {...providerLogoProps} />
            <Flex className={style.tripdata} size="grow">
              <Heading {...headingProps} />

              {config.WAITING_TIME_SHOW && !!waitTime && (
                <InlineLabel
                  className={style.waitTime}
                  align="center center"
                  icon={{ name: 'clock', family: 'mozio' }}
                  text={
                    <FormattedMessage
                      {...messages.waitTime}
                      values={{ minutes: waitTime }}
                    />
                  }
                  primary
                />
              )}

              {/* Second row */}
              <Layout align="space-between start" nowrap>
                {/* Details */}
                <Layout className={style.details}>
                  {!!amenitiesInView.length && (
                    <Layout
                      align="start center"
                      direction="row"
                      className={style.detail}
                    >
                      {amenitiesInView.map((amenity, index) => (
                        <div key={index} className={style.amenityItem}>
                          <Amenity
                            size="noshrink"
                            item={amenity}
                            iconClassName={classnames(
                              style.amenityIcon,
                              style.detailIcon
                            )}
                            compact
                            wrap
                          />
                        </div>
                      ))}
                      {!minimal &&
                        amenitiesInView.length !== amenities.length &&
                        !this.state.detailed && (
                          <span
                            className={style.andmore}
                            onClick={this.onClickShowMore}
                          >
                            <FormattedMessage
                              {...messages.moreAmenities}
                              values={{
                                count:
                                  amenities.length - amenitiesInView.length,
                              }}
                            />
                          </span>
                        )}
                    </Layout>
                  )}
                  {startTime && endTime && (
                    <Layout align="start center" className={style.detail}>
                      <span>
                        {startTime} - {endTime}
                      </span>
                    </Layout>
                  )}

                  {departureTime && (
                    <Layout align="start center" className={style.detail}>
                      <span>Departs</span>
                      <span>{departureTime}</span>
                    </Layout>
                  )}
                </Layout>
              </Layout>

              {!minimal && (
                <Layout
                  className={classnames(style.extra, detailsModifiers)}
                  direction="column"
                >
                  <Layout align="start center" nowrap>
                    {departingLocation && (
                      <FormattedMessage
                        id={messages.leavesFrom.id}
                        values={{
                          location: departingLocation,
                        }}
                      />
                    )}
                    {/* multiVehicles, passengers and bags */}
                    {numVehicles > 1 && (
                      <Layout
                        nowrap
                        align="start center"
                        className={classnames(
                          style['vehicle-spec'],
                          style['multi-vehicle']
                        )}
                      >
                        <Icon family="mozio" name={vehicleIcon} />
                        <span>{numVehicles}</span>
                      </Layout>
                    )}
                    <BagsAndPassengers
                      isTrain={isTrain}
                      isMaxBagsPerPerson={isMaxBagsPerPerson}
                      maxBags={maxBags}
                      maxPassengers={maxPassengers}
                      mobile
                    />
                  </Layout>
                </Layout>
              )}
              <Layout direction="row" size="noshrink">
                {isOndemand && <InternetRequiredLabel />}
                {isOndemand && <RideshareLabel />}
                {config.LOYALTY_PROGRAM_ENABLED && !!rewardPoints && (
                  <MilesEarnedLabel miles={rewardPoints} />
                )}
              </Layout>
            </Flex>

            {/* Pricing */}
            <Layout
              align="end end"
              className={style.rightColumn}
              direction="column"
              size="noshrink"
              nowrap
            >
              <div>
                <TripPrice {...price} priceLabel={priceLabel} mobile />
              </div>
              {isHourlyRide && (
                <Flex size="grow">
                  <HourlyDuration
                    mobile
                    originalRequested={hourlyDetails.originalRequested}
                    hoursRequested={hourlyDetails.hoursRequested}
                    minimum={hourlyDetails.minimum}
                    includedKms={hourlyDetails.totalIncludedKilometers}
                  />
                </Flex>
              )}
              {!isHourlyRide && !!duration && config.RIDE_DURATION_SHOW && (
                <Flex size="grow">
                  <Layout
                    className={classnames(style.duration, style.icon)}
                    align="end center"
                    nowrap
                  >
                    <ClockIcon />
                    <FormattedMessage
                      {...messages.rideDuration}
                      values={{ hours, minutes }}
                    />
                  </Layout>
                </Flex>
              )}
              <Button className={style.button} nocaps rounded>
                <FormattedMessage {...messages.bookRide} />
              </Button>
            </Layout>
          </Layout>
        )}
      </IncompleteAddressLightbox>
    );
  }
}

// TODO: move to Flow types
// MobileTrip.propTypes = {
//   price: PropTypes.object.isRequired,
//   priceLabel: PropTypes.node,
//   departureTime: PropTypes.string,
//   departingLocation: PropTypes.string,
//   startTime: PropTypes.string,
//   endTime: PropTypes.string,
//   duration: PropTypes.number,
//   tags: PropTypes.array.isRequired,
//   steps: PropTypes.array.isRequired,
//   returnSteps: PropTypes.array.isRequired,
//   showDescription: PropTypes.bool,
//   minimal: PropTypes.bool,

//   // Callbacks
//   onClick: PropTypes.func
// };

export default MobileTrip;
