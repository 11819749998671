import _ from 'lodash';
import { put, call, select } from 'redux-saga/effects';
import {
  getSearchId,
  getSavedSearch,
  getLocation,
  getSearchExpirationTime,
} from 'app/sagas/selectors';
import loadSearchForm from './loadSearchForm';
import {
  doSearch,
  toggleProvider,
  searchEnd,
} from 'app/actions/search-results';
import { HOURLY_MODE_VALUE } from 'app/constants';
import { formatMessage } from 'app/translationsManager';
import messages from 'app/components/search-results/messages.intl';

export function* shouldRefreshSearch() {
  // Failsafe comparison that search params are the same. Otherwise we should trigger a new search.
  const prevSearch = yield select(getSavedSearch);
  const { query } = yield select(getLocation);

  if (
    _.isEqual(
      // Need to coerce to strings here.
      Object.keys(prevSearch).map((key) => `${prevSearch[key]}`),
      Object.keys(query).map((key) => query[key])
    )
  ) {
    // Check if search has expired.
    const searchExpiration = yield select(getSearchExpirationTime);
    if (searchExpiration * 1000 < Date.now()) return true;

    return false;
  }

  return true;
}
/**
 * Saga for loading search form data and prepare it to be used
 * in the search form. Provided action should be generated by
 * `app/history` and contain `search` and `pathname` in the payload.
 */
export default function* loadSearchResults() {
  const { start_address, end_address, provider, booking_mode } = yield call(
    loadSearchForm
  );

  // Initiate the search after the form is loaded
  if (
    _.isEmpty(start_address) ||
    (booking_mode !== HOURLY_MODE_VALUE && _.isEmpty(end_address))
  ) {
    const message = _.isEmpty(start_address)
      ? messages.SEARCH_NO_START_LOCATION
      : messages.SEARCH_NO_END_LOCATION;

    yield put(
      searchEnd({
        errored: {
          code: 0,
          message: formatMessage(message),
          skipTryAgain: true,
        },
      })
    );

    return;
  }

  // If when we load SRP there are results, we don't search again.
  const searchId = yield select(getSearchId);

  if (searchId) {
    const refresh = yield call(shouldRefreshSearch);
    if (!refresh) return;
  }

  yield put(doSearch({ noTracking: true }));

  if (!_.isEmpty(provider)) {
    yield put(toggleProvider(provider));
  }
}
