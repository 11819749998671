import React from 'react';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import { constants } from 'mz-sdk';
import StepsSummary from '../../../commons/StepsSummary';
import config from 'config';
import style from '../style.css';
import HourlyLabel from 'app/components/common/HourlyLabel';
import getTimeString from 'mz-utils/getTimeString';
import { FormattedMessage } from 'mz-intl';
import messages from 'app/components/book-trip/PriceBreakdown/messages.intl';

const { CATEGORIES_FILTERS } = constants;

const Heading = ({
  providerNames,
  duration,
  category,
  steps,
  isHourlyRide,
  hourlyDetails,
}) => {
  const time = getTimeString({
    hourDiff: Number(duration.hours),
    minDiff: Number(duration.minutes),
  });
  return (
    <Layout
      align="start start"
      direction="column"
      className={style.heading}
      nowrap
    >
      <h2 className={style.provider}>
        <Icon
          inline
          family="mozio"
          name={category ? CATEGORIES_FILTERS[category.key] : 'sedan'}
        />
        {providerNames.map((name, index) => (
          <span key={index}>
            <span>{name}</span>
            {index !== providerNames.length - 1 && (
              <span className={style.separator} />
            )}
          </span>
        ))}
      </h2>
      <Layout align="start end">
        {/* FIXME: Temporal steps fix for mobile. Should be in Route Details when available. */}
        <StepsSummary steps={steps} className={style.summary} />
      </Layout>
      {isHourlyRide && hourlyDetails && (
        <span className={style.duration}>
          <HourlyLabel hours={hourlyDetails.hoursRequested} />
        </span>
      )}
      {!isHourlyRide && !!duration && config.RIDE_DURATION_SHOW && (
        <span className={style.duration}>
          <FormattedMessage message={messages.ESTIMATED_RIDE_TIME} />
          <span>{time}</span>
        </span>
      )}
    </Layout>
  );
};

// TODO: move to Flow types
// Heading.propTypes = {
//   providerNames: PropTypes.array,
//   vehicleClass: PropTypes.string,
//   duration: PropTypes.object,
//   category: PropTypes.object,
//   steps: PropTypes.array,
//   isHourlyRide: PropTypes.bool,
//   hourlyDetails: PropTypes.shape({
//     originalRequested: PropTypes.number,
//     hoursRequested: PropTypes.number,
//     minimum: PropTypes.number
//   })
// };

export default Heading;
