import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import style from './style.css';

const HeaderInfo = ({ caption, icon, info, className }) => (
  <Layout className={className} direction="column" align="start start">
    <Flex>
      <span className={style.header_info_caption}> {caption} </span>
    </Flex>
    <Flex className={style.header_info}>
      <Layout direction="row">
        <Icon className={style.header_icon}>{icon}</Icon>
        <span className={style.header_info_text}> {info} </span>
      </Layout>
    </Flex>
  </Layout>
);

// TODO: move to Flow types
// HeaderInfo.propTypes = {
//   caption: PropTypes.string,
//   icon: PropTypes.string,
//   className: PropTypes.string,
//   info: PropTypes.string
// };

export default HeaderInfo;
