const brandsMap = {
  Visa: 'Verified by Visa',
  Mastercard: 'MasterCard SecureCode',
  JCB: 'J/Secure',
  AMEX: 'American Express SafeKey',
  Discover: 'ProtectBuy',
  'Diners Club': 'ProtectBuy',
};

const get3DSTechnology = (brand) => {
  return brandsMap[brand] || '3D Secure';
};

export default get3DSTechnology;
