import React from 'react';
import config from 'config';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import MobileScreen from 'app/components/base/MobileScreen';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import MapScreen from '../MapScreen';
import ChangeScheduleScreen from '../ChangeScheduleScreen';
import { doBookTrip } from 'app/actions/book-trip';
import * as srpMapActions from 'app/actions/srp-map';
import * as scheduledRidesActions from 'app/actions/scheduled-rides';
import { preprocessTripObject } from 'app/utils/trip';
import { MONTH_DAY_HOUR_FORMAT } from 'app/utils/types';
import {
  ON_DEMAND_CATEGORY,
  ONE_WAY_MODE_VALUE,
  ROUND_TRIP_MODE_VALUE,
} from 'app/constants';
import moment from 'moment';
import _ from 'lodash';

import {
  getTicketInfo,
  getSelectedTicket,
} from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';

import Header from './Header';
import Body from './Body';
import Bottom from './Bottom';
import style from './style.css';

const messages = {
  title: { id: 'SEARCH_RESULTS.TRIP_SCREEN_TITLE' },
  done: { id: 'DONE' },
  milesDistance: { id: 'SEARCH_RESULTS.MILES_DISTANCE' },
  showMap: { id: 'SEARCH_RESULTS.MAP.SHOW_MAP' },
};

// Utils
const getTripTimes = (preparedTrip, ticket) => {
  const { departureDatetime, arrivalDatetime } = ticket || {};

  const scheduledDepartureDatetime =
    departureDatetime &&
    moment(departureDatetime).format(MONTH_DAY_HOUR_FORMAT);

  const scheduledArrivalDatetime =
    arrivalDatetime && moment(arrivalDatetime).format(MONTH_DAY_HOUR_FORMAT);

  return {
    departureTime:
      scheduledDepartureDatetime || preparedTrip.routing.departureDatetime,
    arrivalTime:
      scheduledArrivalDatetime || preparedTrip.routing.arrivalDatetime,
  };
};

// Component
export class DetailsScreen extends React.Component {
  handleClickBack = () => {
    this.props.subpages.popPage();
  };

  handleClickMap = () => {
    const { trip, toggleSrpMap, subpages } = this.props;

    toggleSrpMap({ trip });
    subpages.pushPage(MapScreen);
  };

  handleClickBook = () => {
    this.props.doBookTrip({
      trip_id: this.props.trip.id,
      session_id: this.props.searchId,
      ondemand: this.props.trip.type === ON_DEMAND_CATEGORY,
    });
    this.props.subpages.removeAll();
  };

  handleClickChangeSchedule = () => {
    const {
      returnSteps,
      departureTicket: selectedTicket,
      returnTicket: selectedReturnTicket,
    } = this.props.trip;

    this.props.subpages.pushPage(ChangeScheduleScreen, {
      isRoundTrip: returnSteps && returnSteps.length > 0,
      selectedTicket,
      selectedReturnTicket,
      trip: this.props.trip,
      onConfirm: ({ departureTicket, returnTicket, updatedTrip }) => {
        this.props.scheduleDepartureTime({
          tripId: this.props.trip.id,
          searchId: this.props.searchId,
          departureTicket,
          returnTicket,
          updatedTrip,
        });
      },
      onClose: () => this.props.subpages.popPage(),
    });
  };

  render() {
    const { trip, mode } = this.props;
    const isRoundTrip = mode === ROUND_TRIP_MODE_VALUE;
    const preparedTrip = preprocessTripObject(trip, ONE_WAY_MODE_VALUE);
    const { isHourlyRide, hourlyDetails } = preparedTrip.details;

    const ticketProps = {};
    const departureTicket = getSelectedTicket(trip, false);
    ticketProps.departureTicketSchedule = getTicketInfo(
      { steps: trip.steps },
      departureTicket
    );

    if (trip.returnSteps.length > 0) {
      const returnTicket = getSelectedTicket(trip, true);
      ticketProps.returnTicketSchedule = getTicketInfo(
        { steps: trip.returnSteps },
        returnTicket
      );
    }

    const scheduledTicket = _.get(ticketProps, 'departureTicketSchedule', null);

    const { departureTime, arrivalTime } = getTripTimes(
      preparedTrip,
      scheduledTicket
    );

    let returnDepartureTime = null;
    let returnArrivalTime = null;

    if (isRoundTrip) {
      const returnPreparedTrip = preprocessTripObject(
        trip,
        ROUND_TRIP_MODE_VALUE
      );

      const returnScheduledTicket = _.get(
        ticketProps,
        'returnTicketSchedule',
        null
      );

      const { departureTime: departure, arrivalTime: arrival } = getTripTimes(
        returnPreparedTrip,
        returnScheduledTicket
      );

      returnDepartureTime = departure;
      returnArrivalTime = arrival;
    }

    return (
      <MobileScreen
        className={style.screen}
        title={<FormattedMessage {...messages.title} />}
        onClickBack={this.handleClickBack}
      >
        <Layout
          direction="column"
          align="start stretch"
          className={style.wrapper}
        >
          <Header
            {...preparedTrip.heading}
            isHourlyRide={isHourlyRide}
            hourlyDetails={hourlyDetails}
            duration={preparedTrip.duration}
            steps={this.props.trip.steps}
          />
          <Body
            tripMode={mode}
            trip={preparedTrip}
            departureTime={departureTime}
            arrivalTime={arrivalTime}
            returnDepartureTime={returnDepartureTime}
            returnArrivalTime={returnArrivalTime}
          />
          <Bottom
            {...ticketProps}
            isHourlyRide={isHourlyRide}
            hourlyDetails={hourlyDetails}
            isBookable={preparedTrip.details.isBookable}
            onDemand={preparedTrip.details.onDemand}
            onClickBookTrip={this.handleClickBook}
            onClickChangeSchedule={this.handleClickChangeSchedule}
          />
          {!isHourlyRide && config.SHOW_SRP_MAP && (
            <Button
              className={style.showMapButton}
              onClick={this.handleClickMap}
              mapToggler
            >
              <FormattedMessage {...messages.showMap} />
              <Icon family="mozio" name="map" small />
            </Button>
          )}
        </Layout>
      </MobileScreen>
    );
  }
}

// TODO: move to Flow types
// DetailsScreen.propTypes = {
//   mode: PropTypes.string,
//   searchId: PropTypes.string,
//   customerId: PropTypes.string,
//   trip: PropTypes.object.isRequired,
//   subpages: PropTypes.object.isRequired,
//   doBookTrip: PropTypes.func.isRequired,
//   scheduleDepartureTime: PropTypes.func.isRequired,
//   toggleSrpMap: PropTypes.func.isRequired
// };

const mapStateToProps = (state, { tripId }) => {
  const {
    form: {
      searchForm: {
        values: { customer_id, mode },
      },
    },
  } = state;
  const {
    searchResults: { trips },
  } = state;
  return {
    searchId: trips[tripId].searchId,
    customerId: customer_id,
    trip: trips[tripId],
    mode,
  };
};

const mapDispatchToProps = {
  doBookTrip,
  scheduleDepartureTime: scheduledRidesActions.scheduleDepartureTime,
  toggleSrpMap: srpMapActions.toggleSrpMap,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  connectSubpages()
)(DetailsScreen);
