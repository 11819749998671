// @flow
import * as React from 'react';
import InfoLabel from '../InfoLabel';
import HelpTooltip from 'mz-ui-kit/core/HelpTooltip';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';

const InternetRequiredLabel = () => (
  <InfoLabel color="green">
    <b style={{ marginRight: '0.5em' }}>
      <FormattedMessage message={messages.MOBILE_DATA_REQUIRED} />
    </b>
    <HelpTooltip
      width="230px"
      content={() => (
        <FormattedMessage message={messages.MOBILE_DATA_REQUIRED_TOOLTIP} />
      )}
      size="normal"
    />
  </InfoLabel>
);

export default InternetRequiredLabel;
