import _ from 'lodash';
import { SEARCH_PARAM_DATE_FORMAT } from 'app/utils/types';
import { call } from 'redux-saga/effects';
import findPlaceById from '../../../watchSearchForm/findPlaceById';
import findPlaceByName from '../../../watchSearchForm/findPlaceByName';
import findPlaceByCoords from '../../../watchSearchForm/findPlaceByCoords';

// helper to get pickup time or flight time
export const getTripTimeParam = (trip) => {
  return trip.pickupDatetime
    ? { paramName: 'pickup_datetime', value: trip.pickupDatetime }
    : { paramName: 'flight_datetime', value: trip.flightDatetime };
};

// helper to get return pickup time or return flight time
export const getTripReturnTimeParam = (trip) => {
  return trip.returnPickupDatetime
    ? { paramName: 'return_pickup_datetime', value: trip.returnPickupDatetime }
    : { paramName: 'return_flight_datetime', value: trip.returnFlightDatetime };
};

/**
 * Converts given address from trip object to address object
 * suitable to be in search request
 * @param {Object} tripAddress
 * @yield {Object}
 */
function* tripAddressToRequestAddress(tripAddress) {
  if (!tripAddress) {
    return {};
  } else if (_.isString(tripAddress)) {
    return yield findPlaceByName(tripAddress);
  } else if (tripAddress.iataCode) {
    return yield findPlaceByName(tripAddress.iataCode);
  } else if (tripAddress.place_id) {
    return yield findPlaceById(tripAddress.place_id);
  } else if (tripAddress.lat && tripAddress.lng) {
    return yield findPlaceByCoords(tripAddress.lat, tripAddress.lng);
  } else if (tripAddress.address) {
    return yield findPlaceByName(tripAddress.address);
  }
  return null;
}

/**
 * This is a function to prepare params for search (deeplink format)
 * @param  {Object} trip Trip object (result of sdk method `trips.getTrip`)
 * @return {Object}      Result params. Set of key-values
 */
function* getSearchParamsFromTrip(trip) {
  const timeParam = getTripTimeParam(trip);
  const [searchStartAddress, searchEndAddress] = yield [
    call(tripAddressToRequestAddress, trip.startLocation),
    call(tripAddressToRequestAddress, trip.endLocation),
  ];
  const searchParams = {
    mode: 'one_way',
    num_passengers: trip.numPassengers,
    start_address: searchStartAddress,
    end_address: searchEndAddress,
    [timeParam.paramName]: timeParam.value
      .locale('en')
      .format(SEARCH_PARAM_DATE_FORMAT),
  };

  const isRoundTrip = trip.returnSteps && trip.returnSteps.length > 0;

  if (isRoundTrip) {
    const returnTimeParam = getTripReturnTimeParam(trip);
    searchParams.mode = 'round_trip';
    searchParams[returnTimeParam.paramName] = returnTimeParam.value
      .locale('en')
      .format(SEARCH_PARAM_DATE_FORMAT);
  }

  return searchParams;
}

export default getSearchParamsFromTrip;
