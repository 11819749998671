import languages from 'app/languages';
import { hook } from 'app/utils/hook';
import { LANGUAGES_GETTER_HOOK } from 'app/constants';

export const getLanguages = () => {
  return hook({
    name: LANGUAGES_GETTER_HOOK,
    defaultHandler: () => languages,
  });
};

const reduceToValue = (acumulator, currentValue) => {
  return [...acumulator, getLanguages()[currentValue].value];
};

export const getAvailableLanguagesKeys = () => {
  const availableLanguages = getLanguages();
  return Object.keys(availableLanguages)
    .filter((key) => availableLanguages[key].code)
    .reduce(reduceToValue, []);
};
