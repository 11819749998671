import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import { connect } from 'react-redux';
import { getSearchArguments } from 'app/sagas/selectors';
import MapContainer from './MapContainer';
import TripsResult from './TripsResult';
import SrpMap from './SrpMap';

import style from './style.css';

export const TripResultsWithMap = ({
  showMap,
  startLocation,
  endLocation,
  directions,
  ...props
}) => (
  <Layout nowrap size="grow" className={style.resultsLayout}>
    <Flex size="grow" className={style.resultsList}>
      <TripsResult {...props} />
    </Flex>
    {showMap && (
      <Flex size="grow" className={style.resultsMap}>
        <MapContainer>
          {(mapSizeChanged) => (
            <SrpMap
              mapSizeChanged={mapSizeChanged}
              startCoords={startLocation}
              endCoords={endLocation}
              directions={directions}
            />
          )}
        </MapContainer>
      </Flex>
    )}
  </Layout>
);

// TODO: move to Flow types
// TripResultsWithMap.propTypes = {
//   showMap: PropTypes.bool,
//   startLocation: PropTypes.object,
//   endLocation: PropTypes.object,
//   directions: PropTypes.array,
// };

export const mapStateToProps = (state) => {
  const {
    srpMap: { showMap, directions = [] },
  } = state;

  const { start_address, end_address } = getSearchArguments(state);

  return {
    showMap,
    startLocation: start_address
      ? { lat: start_address.lat, lng: start_address.lng }
      : null,
    endLocation: end_address
      ? { lat: end_address.lat, lng: end_address.lng }
      : null,
    directions,
  };
};

export default connect(mapStateToProps)(TripResultsWithMap);
