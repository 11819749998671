import createMessages from 'mz-intl/create';

export default createMessages('MZ_HEADER', {
  RESEND_BOOKING: 'Resend Booking',
  RESEND_CONFIRMATION_EMAIL: 'Resend Confirmation Email',
  RESEND_BOOKING_TEXT:
    "Tell us your email and we'll send you copies of all your upcoming bookings.",
  RESEND_DONE: "Thanks, if any reservations are found we'll send them to you.",
  EMAIL_LABEL: 'Email address',
  SEND: 'Send',
});
