import configurableJson from './configurable';

const configObj = {};
configurableJson.fields.forEach((f) => {
  if (!Object.prototype.hasOwnProperty.call(f, 'default')) {
    throw new Error(
      `You must specify production default for field "${f.name}" ` +
        'in "configurable.json"'
    );
  }

  configObj[f.name] = f.default;
});

export default configObj;
