import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { Layout } from '../Layout';
import { hook } from 'app/utils/hook';
import { APP_LOGO_IMAGE_HOOK } from 'app/constants';
import config from 'config';

import style from './style.css';

const getDefaultLogoUrl = (inverse, customUrl) => {
  const isDefaultLogo = !customUrl;
  const defaultLogo = require(`img/logo${inverse ? '_white' : ''}.svg`);
  return isDefaultLogo ? defaultLogo : customUrl;
};

const Logo = ({ className, white, customUrl }) => {
  const logoUrl = hook({
    name: APP_LOGO_IMAGE_HOOK,
    args: [white, customUrl],
    defaultHandler: getDefaultLogoUrl,
  });

  return (
    <Layout
      align="center center"
      className={classnames(style.container, className)}
    >
      <Link to="/" className={style.link}>
        <img
          src={logoUrl}
          className={classnames(style.img)}
          alt={config.EMAIL_SITE_NAME}
        />
      </Link>
    </Layout>
  );
};

// TODO: move to Flow types
// Logo.propTypes = {
//   className: PropTypes.string,
//   white: PropTypes.bool,
//   customUrl: PropTypes.string
// };

export default Logo;
