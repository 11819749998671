import {
  GET_SUGGESTED_MEET_AND_GREET_TRIPS,
  SET_SUGGESTED_MEET_AND_GREET_TRIPS,
  UPDATE_MEET_AND_GREET_TRIP,
} from 'app/constants';
import { createAction, createDeferredAction } from '../utils/redux';

export const getSuggestedMeetAndGreetTrips = createDeferredAction(
  GET_SUGGESTED_MEET_AND_GREET_TRIPS
);

export const setSuggestedMeetAndGreetTrips = createAction(
  SET_SUGGESTED_MEET_AND_GREET_TRIPS
);

export const updateMeetAndGreetTrip = createAction(UPDATE_MEET_AND_GREET_TRIP);
