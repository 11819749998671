import { createReducer } from '../utils/redux';
import _ from 'lodash';
import {
  AUTOCOMPLETE_LOADING,
  AUTOCOMPLETE_RECEIVED,
  AUTOCOMPLETE_FAILED,
  AUTOCOMPLETE_CLEANUP,
  AUTOCOMPLETE_CLIENT_FILTERED,
  AUTOCOMPLETE_VALIDATE_COMPLETE,
  AUTOCOMPLETE_VALIDATE_CLEAN,
} from 'app/constants';

const initialState = {};

const actionHandlers = {
  [AUTOCOMPLETE_LOADING]: (state, { id, loading = true }) => ({
    [id]: {
      ...state[id],
      loading,
    },
  }),
  [AUTOCOMPLETE_RECEIVED]: (state, { id, suggestions, requestContext }) => ({
    [id]: {
      ...state[id],
      suggestions,
      requestContext,
      filteredSuggestions: null,
      loading: false,
      emptySuggestions: !suggestions || !suggestions.length,
      loadedOnce: true,
    },
  }),
  [AUTOCOMPLETE_FAILED]: (state, { id, error }) => ({
    [id]: {
      ...state[id],
      error,
      suggestions: [],
      filteredSuggestions: null,
      loading: false,
      emptySuggestions: false,
      loadedOnce: true,
    },
  }),
  [AUTOCOMPLETE_CLIENT_FILTERED]: (state, { id, filteredSuggestions }) => ({
    [id]: {
      ...state[id],
      filteredSuggestions,
      loading: false,
      validated: false,
      emptySuggestions: _.isNull(filteredSuggestions)
        ? _.isEmpty(state[id].suggestions)
        : _.isEmpty(filteredSuggestions),
    },
  }),
  [AUTOCOMPLETE_CLEANUP]: (state, { id }) => ({
    [id]: undefined,
  }),
  [AUTOCOMPLETE_VALIDATE_CLEAN]: (state, { id }) => ({
    [id]: {
      ...state[id],
      error: null,
      loading: false,
      validated: false,
      lastValue: null,
    },
  }),
  // When async validating completes, we set the result.
  [AUTOCOMPLETE_VALIDATE_COMPLETE]: (state, { id, valid, error, query }) => ({
    [id]: {
      ...state[id],
      error,
      loading: false,
      validated: !!valid,
      loadedOnce: true,
      lastValue: query,
    },
  }),
};

export default createReducer(initialState, actionHandlers);
