import { call } from 'redux-saga/effects';
import { getHistory } from 'app/history';
import objectToQueryString from 'mz-utils/objectToQueryString';
import getRequestParams from './getRequestParams';

/**
 * Saga for searching trips with different arguments.
 * Redirects to Home page form, offering to fill selected values.
 */
export default function* resetSearch({ payload: resetParams }) {
  const requestParams = yield call(getRequestParams);
  const history = yield call(getHistory);
  if (!requestParams) {
    yield call(history.push, '/');
    return;
  }

  Object.keys(resetParams).forEach((key) => {
    if (resetParams[key] === '') {
      delete requestParams[key];
    } else {
      requestParams[key] = resetParams[key];
    }
  });

  const { start_address, end_address } = requestParams;

  const search = yield call(objectToQueryString, {
    ...requestParams,
    start_address: start_address.value || start_address.display,
    end_address: end_address.value || end_address.display,
  });

  yield call(history.push, {
    pathname: '/',
    search,
  });
}
