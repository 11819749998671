// @flow
import React from 'react';
import { FormattedMessage } from 'translations';
import SelectorButton from 'mz-ui-kit/core/SelectorButton';
import CurrencySelector from './CurrencySelector';
import CurrencyButton from './CurrencyButton';

import { type Currency } from 'mz-sdk/services/currencies/getAll';

type Props = {
  activeCurrency: string,
  onSelect: (selectedCurrency: Currency) => {},
};

// Constants
const messages = {
  currency: { id: 'CURRENCY' },
};

const CurrencyDropdown = ({ activeCurrency, onSelect }: Props) => (
  <SelectorButton
    mobileTitle={<FormattedMessage {...messages.currency} />}
    width="270px"
    renderSelector={(props) => (
      <CurrencySelector
        {...props}
        activeCurrency={activeCurrency}
        onSelect={onSelect}
      />
    )}
    renderButton={(props) => (
      <CurrencyButton {...props} activeCurrency={activeCurrency} />
    )}
  />
);

export const formConfig = {
  form: 'currencyDropdownForm',
};

export default CurrencyDropdown;
