// @flow
import * as React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

// Types
type Props = {
  fallback: React.ElementType,
  routes: Array<{
    path?: string,
    wrapper?: React.ComponentType<any> | string,
    allowNestedRoutes?: boolean,
    component: string,
    from?: string,
    to?: string,
  }>,
};

// Utils
function appendPrefixIfDefined(prefix, value) {
  if (!value) return null;
  return `${prefix}${value}`
    .replace(/\/{2,}/, '/') // remove double-slashes
    .replace(/(.+)\/$/, '$1'); // remove ending slash to have `/en-gb` insted of `/en-gb/`
}

/**
 * Switch wrapper that renders a list of routes provided in the props.
 * It also adds an ability to render some wrapper around a component.
 * @param {Array<Route>} routes
 */
const LocaleSwitch = ({ routes, fallback }: Props) => (
  <React.Suspense fallback={fallback}>
    <Route
      render={({ match, location }) =>
        React.createElement(
          Switch,
          null,
          ...routes.map((route) => {
            if (route.from) {
              return (
                <Redirect
                  from={appendPrefixIfDefined(match.url, route.from)}
                  to={{
                    ...location,
                    pathname: appendPrefixIfDefined(match.url, route.to),
                  }}
                />
              );
            }

            return (
              <Route
                key={route.path}
                exact={!route.allowNestedRoutes}
                path={appendPrefixIfDefined(match.url, route.path)}
              >
                {(context) => {
                  if (route.wrapper) {
                    return (
                      <route.wrapper {...context}>
                        <route.component {...context} />
                      </route.wrapper>
                    );
                  }
                  return <route.component {...context} />;
                }}
              </Route>
            );
          })
        )
      }
    />
  </React.Suspense>
);

LocaleSwitch.defaultProps = {
  fallback: <div />,
};

export default LocaleSwitch;
