import { select, call, put } from 'redux-saga/effects';
import { change } from 'redux-form';
import { getAccountIdStatus } from 'app/sagas/selectors';
import getPrice from 'app/sagas/watchBookTrip/watchUpdatePrice/getPrice';
import { showErrorNotification } from 'app/sagas/utils';
import {
  BOOK_TRIP_FORM,
  ACCOUNT_ID_STATUS,
  TA_CONFIRMATION_EMAIL_FIELD_NAME,
} from 'app/constants';
import updateBookingFee from './updateBookingFee';

/**
 * Change the account ID a trip is associated with to book in its behalf
 */
export default function* changeAccountId(accountId) {
  const accountIdStatus = yield select(getAccountIdStatus);

  yield put(
    change(BOOK_TRIP_FORM, ACCOUNT_ID_STATUS, {
      ...accountIdStatus,
      updating: true,
    })
  );

  let newAccountIdStatus = accountIdStatus;

  try {
    // Update price
    const priceParams = {
      ta_email: accountId || undefined,
    };
    yield call(getPrice, { priceParams });
    newAccountIdStatus = {
      ...accountIdStatus,
      savedValue: accountId,
    };
    yield call(updateBookingFee);
    yield put(
      change(BOOK_TRIP_FORM, TA_CONFIRMATION_EMAIL_FIELD_NAME, accountId)
    );
  } catch (error) {
    yield call(showErrorNotification, { error });
  } finally {
    yield put(
      change(BOOK_TRIP_FORM, ACCOUNT_ID_STATUS, {
        ...newAccountIdStatus,
        updating: false,
      })
    );
  }
}
