import React, { Component } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import InlineLabel from 'app/components/base/InlineLabel';
import { Layout, Flex } from 'app/components/base/Layout';
import Amenity from 'app/components/search-results/Amenity';
import Icon from 'app/components/base/Icon';
import BagsAndPassengers from 'app/components/search-results/Trip/commons/BagsAndPassengers';
import { constants } from 'mz-sdk';
import config from 'config';

import style from '../style.css';

// Constants
const { CATEGORIES_FILTERS } = constants;
const messages = {
  waitTime: { id: 'SEARCH_RESULTS.WAIT_TIME' },
  serviceHours: { id: 'SEARCH_RESULTS.SERVICE_HOURS' },
};

// TODO: This needs to be extracted to a common place to be used from everywhere.
const SERVICE_CLASSES = {
  standard: {
    id: 'standard',
    icon: 'SC',
    name: 'Standard Class',
  },
  first: {
    id: 'first',
    icon: 'FC',
    name: 'First Class',
    features: ['You will be teleported', 'No one will know where you are'],
  },
  business: {
    id: 'business',
    icon: 'BC',
    name: 'Business Class',
    features: [
      'Extra Leg Room',
      'Wide Seat',
      'Complimentary Light Meal',
      'Drinks Included',
    ],
  },
};

class Details extends Component {
  chooseSchedule = () => {
    if (this.props.onScheduleDeparture) {
      this.props.onScheduleDeparture();
    }
  };

  get isServiceClassRenderable() {
    const { serviceClass } = this.props;
    return serviceClass && serviceClass in SERVICE_CLASSES;
  }

  renderServiceHours() {
    const { serviceHours } = this.props;
    return (
      <span>
        <b>
          <FormattedMessage {...messages.serviceHours} />{' '}
        </b>
        <span>{serviceHours}</span>
      </span>
    );
  }

  renderWaitTime() {
    const { waitTime } = this.props;
    return (
      <FormattedMessage {...messages.waitTime} values={{ minutes: waitTime }} />
    );
  }

  render() {
    const {
      category,
      serviceClass,
      isTrain,
      isMaxBagsPerPerson,
      maxPassengers,
      maxBags,
      numVehicles,
      amenities,
      waitTime,
      serviceHours,
    } = this.props;
    const vehicleIcon = category ? CATEGORIES_FILTERS[category.key] : 'sedan';

    return (
      <Layout
        className={classnames(style.section, style.details)}
        direction="column"
        align="start start"
        nowrap
      >
        {/* Amenities */}
        {amenities.length > 0 &&
          amenities.map((amenity) => (
            <Amenity
              className={style.amenity}
              key={amenity.id}
              size="noshrink"
              item={amenity}
              iconClassName={classnames(style.icon, style.amenityIcon)}
              compact
            />
          ))}

        {/* waiting time if provided */}
        {config.WAITING_TIME_SHOW && !!waitTime && (
          <InlineLabel
            className={style.amenity}
            icon={{ name: 'clock', family: 'mozio' }}
            text={this.renderWaitTime()}
            compact
            wrap
          />
        )}

        {/* service hours time if provided */}
        {!!serviceHours && (
          <InlineLabel
            className={style.amenity}
            icon={{ name: 'clock', family: 'mozio' }}
            text={this.renderServiceHours()}
            compact
            wrap
          />
        )}

        {/* Service class */}
        {this.isServiceClassRenderable && (
          <Layout
            nowrap
            className={style['service-class']}
            align="center center"
          >
            <span className={style['class-icon']}>
              {SERVICE_CLASSES[serviceClass].icon}
            </span>
            <Flex size="grow">
              <span className={style.item__text}>
                {SERVICE_CLASSES[serviceClass].name}
              </span>
            </Flex>
            <span
              className={style['class-change']}
              onClick={this.chooseSchedule}
            >
              Change
            </span>
          </Layout>
        )}

        {/* multipleVehicles, passangers and bags */}
        <Layout className={style.capacity}>
          {numVehicles > 1 && (
            <Layout
              nowrap
              align="start center"
              className={classnames(
                style['vehicle-spec'],
                style['multi-vehicle']
              )}
            >
              <Icon family="mozio" name={vehicleIcon} />
              <span>{numVehicles}</span>
            </Layout>
          )}
          <BagsAndPassengers
            isTrain={isTrain}
            isMaxBagsPerPerson={isMaxBagsPerPerson}
            maxBags={maxBags}
            maxPassengers={maxPassengers}
          />
        </Layout>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Details.propTypes = {
//   category: PropTypes.object,
//   isTrain: PropTypes.bool,
//   isMaxBagsPerPerson: PropTypes.bool,
//   maxPassengers: PropTypes.number,
//   maxBags: PropTypes.number,
//   numVehicles: PropTypes.number,
//   serviceClass: PropTypes.string,
//   serviceHours: PropTypes.string,
//   timetable: PropTypes.array,
//   amenities: PropTypes.array,
//   waitTime: PropTypes.number,
//   // actions
//   onScheduleDeparture: PropTypes.func
// };

Details.defaultProps = {
  amenities: [],
};

export default Details;
