const HEADER = 'header';

export default [
  {
    name: 'COLORS_DARK_MODE',
    type: 'bool',
    default: false,
    description: 'Dark mode for navigation bar',
    category: HEADER,
  },
  {
    name: 'CUSTOM_LOGO',
    type: 'image',
    default: '',
    description: 'Custom logo for header',
    category: HEADER,
  },
  {
    name: 'CUSTOM_SOCIAL_LOGO',
    type: 'image',
    default: '',
    description: 'Custom logo for social preview',
    category: HEADER,
  },
  {
    name: 'CUSTOM_FAVICON',
    type: 'image',
    default: '',
    description: 'Custom favicon of the page',
    category: HEADER,
  },
  {
    name: 'CUSTOM_LOGO_LINK',
    type: 'string',
    default: '',
    description: 'Custom link for logo',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_HEIGHT',
    type: 'string',
    default: '50px',
    description: 'Custom header height',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_LOGO_HEIGHT',
    type: 'string',
    default: '35px',
    description: 'Custom header logo height',
    category: HEADER,
  },
  {
    name: 'HEADER_LOGO_POSITION',
    type: 'select',
    options: [
      { title: 'Center', value: 'center' },
      { title: 'Top', value: 'start' },
      { title: 'Bottom', value: 'end' },
    ],
    default: 'center',
    description: 'Logo position',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_LINKS',
    type: 'pairs',
    default: [],
    description: 'Custom header links',
    category: HEADER,
  },
  {
    name: 'PROMOTE_APP_LINK',
    type: 'string',
    default: '',
    description: 'The link to the whitelabels mobile app to be promoted',
    category: HEADER,
  },
  {
    name: 'PROMOTE_APP_IMG',
    type: 'string',
    default: '',
    description:
      'The link to the whitelabels img to be use in promote app header',
    category: HEADER,
  },
  {
    name: 'HEADER_CUSTOM_CONTENT',
    type: 'string',
    default: '',
    description: 'Custom content injected to the page before any script',
    category: HEADER,
  },
];
