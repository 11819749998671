import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import config from 'config';
import { isMobileBrowser } from 'app/utils/browser';
import { formatString } from 'app/utils/format';
import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

import style from './style.css';

const FooterCustomHtml = () => (
  <div
    className={classnames(style.container, style['container--html'])}
    dangerouslySetInnerHTML={{
      __html: formatString(config.CUSTOM_FOOTER_HTML),
    }}
  />
);

/**
 * Renred the footer with links and copyright
 * @return {Component}
 */
export const Footer = (props) => {
  if (config.TUI_CS_ADJUSTMENTS) return null;
  if (window.location.pathname.includes('/tracking')) return null;

  if (!_.isEmpty(config.CUSTOM_FOOTER_HTML)) {
    return <FooterCustomHtml />;
  }

  return isMobileBrowser ? (
    <MobileLayout {...props} />
  ) : (
    <DesktopLayout {...props} />
  );
};
Footer.defaultProps = {
  supportPhones: [],
};

// TODO: move to Flow types
// Footer.propTypes = {
//   supportPhones: PropTypes.array
// };

export default Footer;
