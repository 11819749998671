import { call, put } from 'redux-saga/effects';
import { setUser } from 'app/actions/user-session';
import { redirectBack, showErrorNotification } from 'app/sagas/utils';
import getUserData from './getUserData';

export default function* doLoginSocial(action) {
  try {
    yield put(setUser(action.payload.data));
    yield call(getUserData);

    // On login, we identify the user with the Chameleon api
    if (typeof chmln !== 'undefined') {
      // eslint-disable-line
      chmln.identify(window.mozioUser); // eslint-disable-line
    }

    yield call(redirectBack);
  } catch (error) {
    yield put(setUser());
    yield call(showErrorNotification, {
      error,
      titleId: 'USER.LOGIN_ERROR_TITLE',
    });
  }
}
