import React, { Component } from 'react';
import CN from 'classnames';
import { findDOMNode } from 'react-dom';
import { Layout } from 'app/components/base/Layout';
import style from './style.css';
import { DROPDOWN_DIRECTIONS } from 'app/constants';
import _ from 'lodash';

class Dropdown extends Component {
  state = {
    directions: DROPDOWN_DIRECTIONS,
    axises: ['start', 'center', 'end'],
  };

  componentDidMount() {
    window.addEventListener('scroll', this.updatePosition);
    this.updatePosition();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updatePosition);
  }

  updatePosition = _.debounce(() => {
    if (!this.node || this.props.dumb || this.props.strict) return;
    const { top, left, right } = this.node.getBoundingClientRect();
    const width = window.innerWidth;
    const height = window.innerHeight;
    const directions = [];

    if (top > 0 && top > height / 2) {
      directions.push('top');
    } else {
      directions.push('bottom');
    }
    if (left >= 0 && left > width / 2) {
      directions.push('left');
    }
    if (right <= width && right < width / 2) {
      directions.push('right');
    }
    this.setState({ directions });
  }, 100);

  updateAxis = (node) => {
    if (!node) return;
    const width = window.innerWidth;
    const axises = [];
    const { left, right } = node.getBoundingClientRect();
    if (right <= width && left >= 0) {
      axises.push('center');
    }
    if (left < 0) {
      axises.push('start');
    }
    if (right > width) {
      axises.push('end');
    }
    this.setState({ axises });
  };

  render() {
    const {
      align = 'center center',
      origin,
      className,
      primary,
      error,
      inline,
      children,
      childrenClassName,
      noarrow,
      dumb,
      axis,
      negative,
      rounded,
      direction,
      open,
      size,
      onClick,
    } = this.props;
    const { directions, axises } = this.state;
    let finalDirection =
      directions.indexOf(direction) === -1 ? directions[0] : direction;
    let finalAxis = axises.indexOf(axis) === -1 ? axises[0] : axis;

    if (inline) {
      finalDirection = 'down';
      finalAxis = 'start';
    }

    const containedClassnames = CN(
      style.contained,
      style[finalDirection],
      style[finalAxis],
      {
        [style.noarrow]: noarrow,
        [style.nomargin]: noarrow || inline,
        [style.inline]: inline,
        [style.dumb]: dumb,
        [style.negative]: negative,
        [style.rounded]: rounded,
      },
      childrenClassName
    );

    const modifiers = {
      [style.primary]: primary,
      [style.error]: error,
    };

    return (
      <Layout
        size={size}
        align={align}
        className={CN(style.container, className, modifiers)}
        onClick={onClick}
      >
        {React.cloneElement(origin, {
          ref: (node) => (this.node = node && findDOMNode(node)),
        })}
        {open && (
          <div className={containedClassnames} ref={this.updateAxis}>
            <div className={style.triangle} />
            {children}
          </div>
        )}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Dropdown.propTypes = {
//   children: PropTypes.node,
//   direction: PropTypes.string,
//   axis: PropTypes.string,
//   origin: PropTypes.node.isRequired,
//   dumb: PropTypes.bool,
//   strict: PropTypes.bool,
//   inline: PropTypes.bool,
//   primary: PropTypes.bool,
//   error: PropTypes.bool,
//   noarrow: PropTypes.bool,
//   rounded: PropTypes.bool,
//   negative: PropTypes.bool,
//   open: PropTypes.bool.isRequired,
//   className: PropTypes.string,
//   childrenClassName: PropTypes.string
// };

Dropdown.defaultProps = {
  axis: 'center',
  direction: 'bottom',
};

export default Dropdown;
