import { select, call } from 'redux-saga/effects';
import { getAccountIdFieldValue } from 'app/sagas/selectors';
import changeAccountId from './changeAccountId';

/**
 * Associate an account ID to the trip to book on its behalf
 */
export default function* addAccountId() {
  const accountId = yield select(getAccountIdFieldValue);
  yield call(changeAccountId, accountId);
}
