import { call } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import onChangeForm from './onChangeForm';
import { isSearchFormChange } from 'app/sagas/patterns';

/**
 * Wait for redux-form CHANGE action from `searchForm` from.
 */
export default function* watchSearchFormChange() {
  yield call(takeLatest, isSearchFormChange, onChangeForm);
}
