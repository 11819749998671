import React from 'react';
import { Layout } from 'app/components/base/Layout';
import classnames from 'classnames';
import Isvg from 'react-inlinesvg';
import NewFlagIcon from 'mz-icons/flag';
import style from 'style/modules/icons.css';

const MaterialIcon = ({ component = 'i', children, name, ...props }) =>
  React.createElement(component, props, name || children);

// TODO: move to Flow types
// MaterialIcon.propTypes = {
//   component: PropTypes.any,
//   children: PropTypes.string,
//   name: PropTypes.string
// };

const iconContext = require.context(
  '!!babel-loader!@mozioinc/svg-react-loader!svgo-loader?{"plugins":[{"removeViewBox":false}]}!img/icons',
  true,
  /\.svg$/
);

export const mozioIcons = Object.assign(
  {},
  ...iconContext.keys().map((pkg) => {
    const name = pkg.match(/([\w-]+)\.svg/)[1];
    const module = iconContext(pkg);
    return { [name]: module };
  })
);

const MozioIcon = ({ children, name, ...props }) => {
  const IconComponent = mozioIcons[name || children] || mozioIcons.missing;
  if (!IconComponent) {
    if (process.env.NODE_ENV === 'production') return null;
    throw new Error(`No icon for ${name || children}`);
  }
  return <IconComponent {...props} />;
};

// TODO: move to Flow types
// MozioIcon.propTypes = {
//   children: PropTypes.string,
//   name: PropTypes.string
// };

const StampIcon = ({ className, ...props }) => (
  <Layout
    {...props}
    align="center center"
    className={classnames(style.stamp, className)}
  />
);

// TODO: move to Flow types
// StampIcon.propTypes = {
//   className: PropTypes.string
// };

const FlagIcon = ({ name, className }) => (
  <span className={className}>
    <NewFlagIcon country={name} />
  </span>
);

// TODO: move to Flow types
// FlagIcon.propTypes = {
//   className: PropTypes.string,
//   name: PropTypes.string.isRequired
// };

/**
 * Used to render icons
 * @param {string} family - the icon font family from `style/modules/icons.css`
 * @param {boolean} tilt - tilt the icon 45 degrees to the right
 * @param {boolean} interactive - the icon becomes clickable
 * @param {string} children - the icon charcode or font ligature
 * @return {Component}
 */
const Icon = ({
  src,
  family = 'material',
  className,
  primary,
  secondary,
  error,
  small,
  large,
  label,
  light,
  largest,
  dark,
  inline,
  tilt,
  interactive,
  ...rest
}) => {
  if (!style[family]) throw new Error(`Invalid Icon family ${family}`);
  const props = {
    ...rest,
    className: classnames(className, {
      [style[family]]: !src,
      [style.svgIcon]: src,
      [style.small]: small,
      [style.large]: large,
      [style.largest]: largest,
      [style.label]: label || inline,
      [style.primary]: primary,
      [style.secondary]: secondary,
      [style.error]: error,
      [style.dark]: dark,
      [style.tilt]: tilt,
      [style.light]: light,
      [style.inline]: inline,
      [style.interactive]: interactive,
    }),
  };
  if (src) {
    return <Isvg src={src} cacheGetRequests {...props} />;
  }
  switch (family) {
    case 'mozio':
      return MozioIcon(props);
    case 'stamp':
      return StampIcon(props);
    case 'flag':
      return FlagIcon(props);
    default:
      return MaterialIcon(props);
  }
};

// TODO: move to Flow types
// Icon.propTypes = {
//   children: PropTypes.string,
//   tilt: PropTypes.bool,
//   primary: PropTypes.bool,
//   secondary: PropTypes.bool,
//   small: PropTypes.bool,
//   large: PropTypes.bool,
//   label: PropTypes.bool,
//   dark: PropTypes.bool,
//   largest: PropTypes.bool,
//   light: PropTypes.bool,
//   error: PropTypes.bool,
//   inline: PropTypes.bool,
//   family: PropTypes.string,
//   interactive: PropTypes.bool,
//   src: PropTypes.string,
//   className: PropTypes.string
// };

export default Icon;
