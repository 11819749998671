// @flow
import * as React from 'react';
import config from 'config';
import { FormattedMessage } from 'mz-intl';
import messages from '../messages.intl';
import { Container, Miles } from './elements';

const MilesEarnedLabel = ({ miles }) => (
  <Container $color={config.LOYALTY_PROGRAM_COLOR}>
    <FormattedMessage
      message={messages.MILES_EARNED}
      values={{
        company: config.LOYALTY_PROGRAM_NAME,
      }}
    />
    <Miles>{miles}</Miles>
  </Container>
);

export default MilesEarnedLabel;
