// @flow
import { styled } from 'mz-ui-kit';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'row nowrap',
});

export const Content = styled('div', {
  flexGrow: 1,
  marginLeft: '8px',
});

export const IconWrapper = styled('div', {
  flexShrink: 0,
});
