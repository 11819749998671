import { createAction, createDeferredAction } from '../utils/redux';
import {
  GET_TA_ACCOUNT,
  SET_TA_ACCOUNT,
  GET_REPORT,
  SUBMIT_TA_PROFILE,
  TA_ADD_PAYMENT_ACCOUNT,
  TA_SELECT_PAYMENT_ACCOUNT,
  TA_DELETE_PAYMENT_ACCOUNT,
  UPDATE_COMPANY_INFO,
  TA_GET_CUSTOMER_PROFILE_LIST,
  TA_SET_CUSTOMER_PROFILE_LIST,
  TA_SELECT_CUSTOMER_PROFILE,
  TA_GET_CUSTOMER_PROFILE,
  TA_SET_CUSTOMER_PROFILE,
  TA_CREATE_CUSTOMER_PROFILE,
  TA_UPDATE_CUSTOMER_PROFILE,
  TA_DELETE_CUSTOMER_PROFILE,
  TA_GET_RESERVATIONS,
  TA_SET_RESERVATIONS,
  TA_WITHDRAW_COMMISSIONS,
  TA_GET_WITHDRAWALS,
  TA_SET_WITHDRAWALS,
} from '../constants';

export const getReport = createDeferredAction(GET_REPORT);

export const getTravelAgent = createDeferredAction(GET_TA_ACCOUNT);

export const setTravelAgent = createAction(SET_TA_ACCOUNT);

export const submitTaProfile = createDeferredAction(SUBMIT_TA_PROFILE);

export const createPaymentAccount = createDeferredAction(
  TA_ADD_PAYMENT_ACCOUNT
);

export const selectPaymentAccount = createAction(TA_SELECT_PAYMENT_ACCOUNT);

export const updateCompanyInfo = createDeferredAction(UPDATE_COMPANY_INFO);

export const deletePaymentAccount = createDeferredAction(
  TA_DELETE_PAYMENT_ACCOUNT
);

export const getCustomerProfileList = createDeferredAction(
  TA_GET_CUSTOMER_PROFILE_LIST
);

export const setCustomerProfileList = createAction(
  TA_SET_CUSTOMER_PROFILE_LIST
);

export const selectCustomerProfile = createAction(TA_SELECT_CUSTOMER_PROFILE);

export const getCustomerProfile = createDeferredAction(TA_GET_CUSTOMER_PROFILE);

export const setCustomerProfile = createAction(TA_SET_CUSTOMER_PROFILE);

export const createCustomerProfile = createDeferredAction(
  TA_CREATE_CUSTOMER_PROFILE
);

export const updateCustomerProfile = createDeferredAction(
  TA_UPDATE_CUSTOMER_PROFILE
);

export const deleteCustomeProfile = createDeferredAction(
  TA_DELETE_CUSTOMER_PROFILE
);

export const getReservations = createDeferredAction(TA_GET_RESERVATIONS);

export const setReservations = createAction(TA_SET_RESERVATIONS);

export const withdrawCommissions = createDeferredAction(
  TA_WITHDRAW_COMMISSIONS
);

export const getWithdrawals = createDeferredAction(TA_GET_WITHDRAWALS);

export const setWithdrawals = createAction(TA_SET_WITHDRAWALS);
