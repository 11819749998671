import { fork } from 'redux-saga/effects';
import { ROOT_SAGA_HOOK } from 'app/constants';
import { hook } from 'app/utils/hook';
import watchAutocomplete from './watchAutocomplete';
import watchSearchForm from './watchSearchForm';
import watchUserAccount from './watchUserAccount';
import watchUserSession from './watchUserSession';
import watchBookTrip from './watchBookTrip';
import watchConfirmation from './watchConfirmation';
import watchCurrency from './watchCurrency';
import watchTravelAgentTool from './watchTravelAgentTool';
import watchCheckTAUser from './watchCheckTAUser';
import watchRideTracking from './watchRideTracking';
import watchCustomerFeedback from './watchCustomerFeedback';
import scheduledRides from './scheduledRides';
import srpMap from './srpMap';
import suggestedMeetAndGreetTrips from './suggestedMeetAndGreetTrips';
import favouriteLocations from './favouriteLocations';
import logging from './logging';
import doInit from './doInit';

export default function* rootSaga() {
  yield fork(logging);
  yield fork(doInit);
  yield fork(watchConfirmation);
  yield fork(watchUserAccount);
  yield fork(watchAutocomplete);
  yield fork(watchCurrency);
  yield fork(watchUserSession);
  yield fork(watchCheckTAUser);
  yield fork(watchCustomerFeedback);
  yield fork(watchSearchForm);
  yield fork(watchBookTrip);
  yield fork(watchTravelAgentTool);
  yield fork(scheduledRides);
  yield fork(srpMap);
  yield fork(suggestedMeetAndGreetTrips);
  yield fork(favouriteLocations);
  yield fork(watchRideTracking);

  yield hook(ROOT_SAGA_HOOK);
}
