import React from 'react';
import classnames from 'classnames';
import style from './style.css';

export default class MapContainer extends React.Component {
  state = {
    fixed: 'nofix',
    width: 0,
    height: 0,
    iteration: 1,
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleScroll();
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  getMapHeight(bounds) {
    let mapHeight = '';
    if (bounds.top > 0) {
      mapHeight = window.innerHeight - bounds.top;
    } else if (bounds.bottom < window.innerHeight) {
      mapHeight = bounds.bottom;
    }

    return mapHeight;
  }

  handleScroll = () => {
    const bounds = this.node.getBoundingClientRect();
    const mapHeight = this.getMapHeight(bounds);

    let nextFix = 'nofix';
    if (bounds.bottom < window.innerHeight) {
      nextFix = 'fix-bottom';
    } else if (bounds.top < 0) {
      nextFix = 'fix-top';
    }

    if (nextFix !== this.state.fixed || mapHeight) {
      this.setState({
        fixed: nextFix,
        height: mapHeight,
        iteration: this.state.iteration + 1,
      });
    }
  };

  handleResize = () => {
    const bounds = this.node.getBoundingClientRect();
    const mapHeight = this.getMapHeight(bounds);

    this.setState({
      width: bounds.width,
      height: mapHeight,
      iteration: this.state.iteration + 1,
    });
  };

  render() {
    const mapStyle = { width: `${this.state.width}px`, height: '100vh' };
    const modifires = { [style[`map--${this.state.fixed}`]]: true };

    return (
      <div
        className={classnames(style.map, modifires)}
        ref={(el) => (this.node = el)}
      >
        <div className={style.map__wrapper} style={mapStyle}>
          {this.props.children(this.state.iteration)}
        </div>
      </div>
    );
  }
}

// TODO: move to Flow types
// MapContainer.propTypes = {
//   children: PropTypes.any,
//   onMapHeightChanged: PropTypes.func
// };
