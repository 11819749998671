import React from 'react';
import classnames from 'classnames';

import style from './style.css';

/**
 * Container for showing collapsible content. Opening
 * and colsing of the content (changing `opened` prop)
 * is animated.
 */
export class Collapsible extends React.Component {
  componentDidMount() {
    this.styles = {};
    this.className = '';
    this._recalculateHeight(false);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.opened !== this.props.opened) {
      this._recalculateHeight();
    }
  }

  _getCSSTransition() {
    const { speed } = this.props;
    const cssAnimationSpeed = `${speed / 1000.0}s`;
    return `all ${cssAnimationSpeed} ease`;
  }

  _recalculateHeight(animate = true) {
    const measureElem = this._measure;
    const contElem = this._cont;
    const { opened, className, endClassName } = this.props;
    const oldStyles = Object.assign({}, this.styles);
    const newStyles = { ...this.styles };

    // Set styles in object for rerendering
    if (opened) {
      newStyles.height = `${measureElem.offsetHeight}px`;
      this.className = classnames(className, style.wrapper);
    } else {
      newStyles.height = '0px';
      this.className = classnames(className, style.wrapper, endClassName);
    }

    const cssTransition = this._getCSSTransition();
    const shouldAnimate = animate && oldStyles.height !== newStyles.height;
    newStyles.overflow = shouldAnimate || !opened ? 'hidden' : 'visible';
    newStyles.transition = shouldAnimate ? cssTransition : 'none';
    this.styles = newStyles;

    // Clear all animation related timers
    clearTimeout(this.rerenderTimeout);
    clearTimeout(this.overflowTimeout);

    // Set actual height to animate
    if (shouldAnimate && !opened) {
      contElem.style.transition = 'none';
      contElem.style.height = `${measureElem.offsetHeight}px`;
      this.rerenderTimeout = setTimeout(
        this._animateContentHeight.bind(this, contElem),
        10
      );
    } else {
      this._animateContentHeight(contElem);
    }
  }

  _animateContentHeight(currContElem) {
    const contElem = currContElem;
    const animationEndTime = this.props.speed + 10;

    const manuallyUpdateStyles = () => {
      if (contElem && contElem.style) {
        contElem.style.transition = this.styles.transition;
        contElem.style.overflow = this.styles.overflow;
        contElem.style.height = this.styles.height;
        contElem.className = this.className;
      }
    };

    // Remove 'overflow: hidden' after end of open animation
    // and set height to auto
    manuallyUpdateStyles();
    this.overflowTimeout = setTimeout(() => {
      if (this.props.opened) {
        this.styles = {
          ...this.styles,
          overflow: 'visible',
          height: 'auto',
          transition: 'none',
        };
        manuallyUpdateStyles();
      }
    }, animationEndTime);
  }

  render() {
    const { children, className, contentClassName } = this.props;

    return (
      <div
        ref={(e) => (this._cont = e)}
        style={this.styles}
        className={classnames(className, style.wrapper)}
      >
        <div ref={(e) => (this._measure = e)}>
          <div className={contentClassName}>{children}</div>
        </div>
      </div>
    );
  }
}
Collapsible.defaultProps = {
  speed: 300,
};

// TODO: move to Flow types
// Collapsible.propTypes = {
//   speed: PropTypes.number,
//   opened: PropTypes.bool,
//   children: PropTypes.node,
//   className: PropTypes.string,
//   endClassName: PropTypes.string,
//   contentClassName: PropTypes.string
// };

export default Collapsible;
