// @flow
import React from 'react';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';
import getTimeString from 'mz-utils/getTimeString';
import { Container, Title, Subtitle, Duration } from './elements';

// types
type Props = {
  duration: { hours: string, minutes: string },
};

const EstimatedTimeNotice = ({ duration }: Props) => {
  const time = getTimeString({
    hourDiff: Number(duration.hours),
    minDiff: Number(duration.minutes),
  });
  return (
    <Container>
      <div>
        <Title>
          <FormattedMessage message={messages.ESTIMATED_RIDE_TIME} />
        </Title>
        <Subtitle>
          <FormattedMessage message={messages.TIMES_MAY_VARY} />
        </Subtitle>
      </div>
      <Duration>{time}</Duration>
    </Container>
  );
};

export default EstimatedTimeNotice;
