import React from 'react';
import config from 'config';
import { FormattedMessage, FormattedHTMLMessage } from 'translations';
import classnames from 'classnames';
import {
  INVALID_ERROR_TYPE,
  INVALID_EMAIL_ERROR_TYPE,
  INVALID_PHONE_NUMBER_ERROR_TYPE,
  REQUIRED_ERROR_TYPE,
  NOT_SAME_EMAIL_ERROR_TYPE,
  MUST_AGREE_TNC_ERROR_TYPE,
  NOT_SAME_PASSWORD_ERROR_TYPE,
  REPORT_DATES_ERROR,
  FLIGHT_REQUIRED_ERROR_TYPE,
  PHONE_CODE_REQUIRED,
  PHONE_NUMBER_REQUIRED,
  FIRST_NAME_FIELD_NAME,
  LAST_NAME_FIELD_NAME,
  EMAIL_FIELD_NAME,
  EMAIL_CONFIRMATION_FIELD_NAME,
  PHONE_NUMBER_FIELD_NAME,
  PHONE_CODE_FIELD_NAME,
  TRAVELER_FIRST_NAME_FIELD_NAME,
  TRAVELER_LAST_NAME_FIELD_NAME,
  TRAVELER_PHONE_NUMBER_FIELD_NAME,
  TRAVELER_EMAIL_FIELD_NAME,
  CC_FIRST_NAME_FIELD,
  CC_LAST_NAME_FIELD,
  CC_NUMBER_FIELD,
  CC_EXPIRY_FIELD,
  CC_CVC_FIELD,
  DEPARTING_FLIGHT_FIELD_NAME,
  RETURNING_FLIGHT_FIELD_NAME,
  TRAIN_INFO_FIELD_NAME,
  RETURN_TRAIN_INFO_FIELD_NAME,
  LOYALTY_MEMBER_NUMBER_FIELD_NAME,
} from 'app/constants';

import style from './style.css';

const DEFAULT_ERROR_MESSAGES = {
  [INVALID_ERROR_TYPE]: 'VALIDATION.INVALID',
  [REQUIRED_ERROR_TYPE]: 'VALIDATION.REQUIRED',
  [INVALID_EMAIL_ERROR_TYPE]: 'VALIDATION.INVALID_EMAIL',
  [INVALID_PHONE_NUMBER_ERROR_TYPE]: 'VALIDATION.INVALID_PHONE_NUMBER',
  [PHONE_CODE_REQUIRED]: 'VALIDATION.REQUIRED_LABELED',
  [PHONE_NUMBER_REQUIRED]: 'VALIDATION.REQUIRED_LABELED',
  [NOT_SAME_EMAIL_ERROR_TYPE]: 'VALIDATION.MATCH_EMAIL',
  [MUST_AGREE_TNC_ERROR_TYPE]: 'VALIDATION.NOT_AGREED_TNC',
  [NOT_SAME_PASSWORD_ERROR_TYPE]: 'VALIDATION.MATCH_PASSWORD',
  [REPORT_DATES_ERROR]: 'VALIDATION.INVALID_DATES',
};

const LABELED_ERROR_MESSAGES = {
  ...DEFAULT_ERROR_MESSAGES,
  [FLIGHT_REQUIRED_ERROR_TYPE]: 'VALIDATION.REQUIRED_LABELED',
  [REQUIRED_ERROR_TYPE]: 'VALIDATION.REQUIRED_LABELED',
  [INVALID_ERROR_TYPE]: 'VALIDATION.INVALID_LABELED',
  'VALIDATION.TRAIN_INFO_REQUIRED': 'VALIDATION.REQUIRED_LABELED',
};

const FIELD_NAME_LABEL = {
  [FIRST_NAME_FIELD_NAME]: 'USER.FIRST_NAME',
  [LOYALTY_MEMBER_NUMBER_FIELD_NAME]: {
    id: 'BOOK_TRIP.LOYALTY_MEMBER_NUMBER',
    values: { company: config.LOYALTY_PROGRAM_NAME },
  },
  [LAST_NAME_FIELD_NAME]: 'USER.LAST_NAME',
  [EMAIL_FIELD_NAME]: 'USER.EMAIL',
  [EMAIL_CONFIRMATION_FIELD_NAME]: 'USER.EMAIL_AGAIN',
  [PHONE_NUMBER_FIELD_NAME]: 'BOOK_TRIP.CONTACT_INFO.PHONE_NUMBER',
  [PHONE_CODE_FIELD_NAME]: 'BOOK_TRIP.CONTACT_INFO.COUNTRY_CODE',
  [TRAVELER_FIRST_NAME_FIELD_NAME]:
    'BOOK_TRIP.CONTACT_INFO.TRAVELER_FIRST_NAME',
  [TRAVELER_LAST_NAME_FIELD_NAME]: 'BOOK_TRIP.CONTACT_INFO.TRAVELER_LAST_NAME',
  [TRAVELER_PHONE_NUMBER_FIELD_NAME]: 'BOOK_TRIP.CONTACT_INFO.PHONE_NUMBER',
  [TRAVELER_EMAIL_FIELD_NAME]: 'BOOK_TRIP.CONTACT_INFO.TRAVELER_EMAIL',
  [DEPARTING_FLIGHT_FIELD_NAME]: 'BOOK_TRIP.FLIGHT.TITLE',
  [RETURNING_FLIGHT_FIELD_NAME]: 'BOOK_TRIP.FLIGHT.TITLE',
  [CC_FIRST_NAME_FIELD]: 'USER.FIRST_NAME',
  [CC_LAST_NAME_FIELD]: 'USER.LAST_NAME',
  [CC_NUMBER_FIELD]: 'USER.CREDIT_CARD.NUMBER',
  [CC_EXPIRY_FIELD]: 'USER.CREDIT_CARD.EXPIRATION_DATE',
  [CC_CVC_FIELD]: 'USER.CREDIT_CARD.CVV',
  [TRAIN_INFO_FIELD_NAME]: 'BOOK_TRIP.TRAIN_INFO_FIELD',
  [RETURN_TRAIN_INFO_FIELD_NAME]: 'BOOK_TRIP.RETURN_TRAIN_INFO_FIELD',
};

const CC_FIELD_NAMES = [CC_NUMBER_FIELD, CC_EXPIRY_FIELD, CC_CVC_FIELD];

const ValidationMessage = ({
  errorType,
  fieldName,
  component,
  plain,
  className,
  ...props
}) => {
  if (!errorType) return null;
  const classNameProp = classnames(className, style.validationMessage, {
    [style.invalid]: !plain && errorType !== REQUIRED_ERROR_TYPE,
    [style.required]: !plain && errorType === REQUIRED_ERROR_TYPE,
  });
  let children;

  if (
    fieldName &&
    FIELD_NAME_LABEL[fieldName] &&
    !CC_FIELD_NAMES.includes(fieldName)
  ) {
    const labelProps =
      typeof FIELD_NAME_LABEL[fieldName] === 'string'
        ? { id: FIELD_NAME_LABEL[fieldName] }
        : FIELD_NAME_LABEL[fieldName];
    children = (
      <FormattedHTMLMessage
        id={LABELED_ERROR_MESSAGES[errorType]}
        values={{
          FIELD_NAME: <FormattedMessage {...labelProps} />,
        }}
      />
    );
  } else if (DEFAULT_ERROR_MESSAGES[errorType]) {
    // does this when its rendering a translated error message that has no label
    children = <FormattedHTMLMessage id={DEFAULT_ERROR_MESSAGES[errorType]} />;
  } else {
    // does this when its rendering an error message that has **no translation** I.E. stripe errors
    children = <span dangerouslySetInnerHTML={{ __html: errorType }} />;
  }

  return React.createElement(
    component,
    {
      ...props,
      className: classNameProp,
    },
    children
  );
};

// TODO: move to Flow types
// ValidationMessage.propTypes = {
//   plain: PropTypes.bool,
//   component: PropTypes.string,
//   errorType: PropTypes.string,
//   fieldName: PropTypes.string,
//   className: PropTypes.string
// };

ValidationMessage.defaultProps = {
  component: 'span',
};

export default ValidationMessage;
