import React, { Component } from 'react';
import Label from './Label';
import classnames from 'classnames';
import { Layout, Flex } from 'app/components/base/Layout';

import style from './style.css';

class Tabs extends Component {
  state = {
    active: 0,
  };

  handleSelect = (index) => {
    const { onChange } = this.props;
    onChange && onChange(index);
    this.setState({
      active: index,
    });
  };

  render() {
    const {
      children,
      active,
      className,
      primary,
      compact,
      simple,
      onSelect,
      ...props
    } = this.props;
    const current = active === undefined ? this.state.active : active;
    return (
      <Layout
        {...props}
        direction="column"
        className={classnames(className, {
          [style['tabs--primary']]: primary,
          [style['tabs--default']]: !primary && !simple,
          [style['tabs--simple']]: simple,
        })}
      >
        <Layout
          className={classnames(style.bar, {
            [style['bar--bordered']]: compact,
          })}
          nowrap
        >
          {React.Children.map(children, (child, index) => (
            <Label
              component={compact ? 'div' : Flex}
              key={index}
              index={index}
              onSelect={onSelect || this.handleSelect}
              className={style.label}
              label={child.props.label}
              icon={child.props.icon}
              isActive={parseInt(current, 10) === parseInt(index, 10)}
            />
          ))}
        </Layout>
        <div className={style.content}>{children[current]}</div>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Tabs.propTypes = {
//   children: PropTypes.node,
//   active: PropTypes.node,
//   primary: PropTypes.bool,
//   simple: PropTypes.bool,
//   compact: PropTypes.bool,
//   onSelect: PropTypes.func,
//   className: PropTypes.string
// };

export default Tabs;
