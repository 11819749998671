import React, { Component } from 'react';
import classnames from 'classnames';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';

import style from './style.css';

class CheckBox extends Component {
  handleClick = (e) => {
    e.stopPropagation();
    const { onChange, value } = this.props;
    if (onChange) {
      onChange(!value);
    }
  };

  render() {
    const {
      value,
      children,
      className,
      interactive,
      reversedOrder,
      mobile,
      size,
    } = this.props;

    const modifiers = {
      [style['field--interactive']]: interactive,
      [style['field--active']]: value,
      [style['field--mobile']]: mobile,
    };

    return (
      <Layout
        size={size}
        reverse={reversedOrder}
        nowrap
        className={classnames(style.field, modifiers, className)}
        onClick={this.handleClick}
        align="space-between center"
      >
        <Layout nowrap align="start center" size="auto">
          {children}
        </Layout>
        <Icon inline className={style.icon}>
          {value ? 'check_box' : 'check_box_outline_blank'}
        </Icon>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// CheckBox.propTypes = {
//   mobile: PropTypes.bool,
//   reversedOrder: PropTypes.bool,
//   className: PropTypes.string,
//   value: PropTypes.bool,
//   interactive: PropTypes.bool,
//   children: PropTypes.node,
//   onChange: PropTypes.func
// };

export default CheckBox;
