import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import { FormattedMessage } from 'translations';
import LazyListRenderer from 'mz-ui-kit/core/Dropdown/LazyListRenderer';
import SuggestsionItem from './SuggestionItem';
import classnames from 'classnames';

import styles from '../style.css';

const messages = {
  cantFind: { id: 'AUTOCOMPLETE.CANT_FIND' },
  typeHere: { id: 'AUTOCOMPLETE.TYPE_IN_THE_FIELD' },
  select: { id: 'AUTOCOMPLETE.SELECT' },
};

/**
 * Wrapper compinent to show results list
 * @return {Component}
 */
export class SuggestionsList extends React.Component {
  rowRenderer = (item, { focused, index }) => {
    const { type, suggestions } = this.props;

    // The last item is unsafe message if enabled by the prop
    if (index === suggestions.length) {
      return this.renderUnsafeMessage();
    }

    // Regular item
    return (
      <SuggestsionItem
        type={type}
        key={`${index}`}
        item={item}
        focused={focused}
      />
    );
  };

  renderUnsafeMessage() {
    const { onSelect, value } = this.props;
    return (
      <div className={styles.list__noitem}>
        <FormattedMessage {...messages.cantFind} />
        {!value && (
          <span>
            &nbsp;
            <FormattedMessage {...messages.typeHere} />
          </span>
        )}
        {value && (
          <a onClick={onSelect} className={styles.list__selectLink}>
            <FormattedMessage {...messages.select} values={{ value }} />
          </a>
        )}
      </div>
    );
  }

  render() {
    const { suggestions, title, onSelect, focusedSuggestionIndex } = this.props;

    const modifiers = {
      [styles['list--empty']]: !suggestions || !suggestions.length,
    };

    return (
      <Layout size="auto" direction="column" className={classnames(modifiers)}>
        {title && <h3 className={styles.list__header}>{title}</h3>}
        <Flex size="auto">
          <LazyListRenderer
            options={suggestions}
            optionSize={31}
            renderOption={this.rowRenderer}
            focusedIndex={focusedSuggestionIndex}
            onOptionClick={onSelect}
          />
        </Flex>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// SuggestionsList.propTypes = {
//   title: PropTypes.oneOfType([
//     PropTypes.node,
//     PropTypes.string
//   ]),
//   unsafe: PropTypes.bool,
//   showAll: PropTypes.bool,
//   onSelect: PropTypes.func,
//   type: PropTypes.string,
//   suggestions: PropTypes.array,
//   selected: PropTypes.any,
//   focusedSuggestionIndex: PropTypes.number,
//   value: PropTypes.string
// };

export default SuggestionsList;
