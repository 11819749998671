import React from 'react';
import { compose } from 'redux';
import { FormattedMessage } from 'translations';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { Layout } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import DesktopLightbox from 'app/components/base/DesktopLightbox';
import MobileScreen from 'app/components/base/MobileScreen';
import classnames from 'classnames';

import style from './style.css';

const Image = require('-!babel-loader!@mozioinc/svg-react-loader!./assets/recommended-image.svg');

const messages = {
  whyRecommendedLabel: { id: 'SEARCH_RESULTS.WHY_RECOMMENDED_LABEL' },
  whyRecommendedHint: { id: 'SEARCH_RESULTS.WHY_RECOMMENDED_HINT' },
  closeThis: { id: 'CLOSE_THIS' },
  close: { id: 'CLOSE' },
};

const RecommendedHint = ({ rules_strings, subpages, mobile }) => {
  const handleClick = () => {
    subpages.popPage();
  };
  const modifiers = {
    [style.mobile]: mobile,
  };
  const content = (
    <Layout
      direction="column"
      align="start center"
      className={classnames(style.container, modifiers)}
    >
      <Image />
      <h1 className={style.title}>
        <FormattedMessage {...messages.whyRecommendedLabel} />
      </h1>
      <p className={style.subtitle}>
        <FormattedMessage {...messages.whyRecommendedHint} />
      </p>
      <div className={style.content_wrapper}>
        <ol className={style.content}>
          {rules_strings.map((rules_string, index) => {
            if (!rules_string) return null;
            return (
              <li key={index} className={style.rules}>
                {rules_string}
              </li>
            );
          })}
        </ol>
      </div>
      <Layout align="center center">
        <Button rounded onClick={handleClick} className={style.button}>
          <FormattedMessage {...messages.closeThis} />
        </Button>
      </Layout>
    </Layout>
  );

  if (mobile) {
    return (
      <MobileScreen
        title={<FormattedMessage {...messages.whyRecommendedLabel} />}
        titleDone={<FormattedMessage {...messages.close} />}
        onClickDone={handleClick}
      >
        {content}
      </MobileScreen>
    );
  }
  return (
    <DesktopLightbox rounded shadow empty onClickClose={handleClick}>
      {content}
    </DesktopLightbox>
  );
};

// TODO: move to Flow types
// RecommendedHint.propTypes = {
//   rules_strings: PropTypes.array,
//   subpages: PropTypes.object.isRequired,
//   mobile: PropTypes.bool
// };

export default compose(connectSubpages())(RecommendedHint);
