import _ from 'lodash';
import config from 'config';
import { call, select, fork, put } from 'redux-saga/effects';
import { hook } from 'app/utils/hook';
import { reservations } from 'mz-sdk';
import { getHistory } from 'app/history';
import { formatString } from 'app/utils/format';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { setTripError, incrementBookingAttempts } from 'app/actions/book-trip';
import { createPaymentReservationData } from 'mz-ui-kit/form/PaymentButton';
import {
  reportTransaction,
  reportPartnerBookingComplete,
} from 'app/sagas/logging/google';
import { showErrorNotification, handleRefParam } from 'app/sagas/utils';
import { pushDelighted } from 'app/utils/analytics';

import { trackSales, trackFailedBooking } from 'app/sagas/logging/loggly';
import {
  AFTER_CONFIRM_BOOKING_HOOK,
  TRIP_UPDATED_ERROR_CODE,
  DEPARTING_TRIP_FIELD_NAME,
  PRICE_FIELD_NAME,
} from 'app/constants';
import {
  getBookTripForm,
  getLocation,
  getUser,
  behavesAsTravelAgent,
} from 'app/sagas/selectors';
import getReservationData from './getReservationData';
import prepareTripUpdatedDialog from '../../watchChangePickupTime/prepareTripUpdatedDialog';
import getSearchParamsFromTrip from '../../watchLoadBookTrip/processResearchTrip/getSearchParamsFromTrip'; // eslint-disable-line
import processResearchTrip from '../../watchLoadBookTrip/processResearchTrip';

const allowedParams = [
  'ta',
  'wl',
  'gid',
  'guid',
  'partner_tracking_id',
  'staging_api',
  'testing_api',
];

/**
 * Build confirmation page URL and redirect the user
 * @return {Generator}          [description]
 */
function* redirectToConfirmation({ ondemand, id, returnId }) {
  const history = yield call(getHistory);
  const location = yield select(getLocation);
  const query = _.pick(location.query, allowedParams);
  const ref = yield call(handleRefParam);

  const params = { ...ref, ...query, id };
  if (ondemand) params.ondemand = 1;
  if (returnId) params.return_id = returnId;

  let finalUrl = `/confirmation${objectToQueryString(params)}`;

  if (config.SEPARATE_CONFIRMATION_PAGE) {
    const finalParams = {
      ondemand: 0,
      return_id: '', // to replace {return_id} to empty string for one way trip
      ...params,
    };
    const separtePageUrl = formatString(
      config.SEPARATE_CONFIRMATION_PAGE,
      finalParams
    );

    // Set final URL to the custom page URL only if there was no un-used
    // variables in the formatter string (all needed variables was provided
    // to create a final URL)
    if (separtePageUrl.indexOf('{') < 0) {
      finalUrl = separtePageUrl;
    }
  }

  if (finalUrl.startsWith('/')) {
    yield call(history.push, finalUrl);
  } else {
    window.location.href = finalUrl;
  }
}

/**
 * Show trip price changed error lightbox
 * @param {object} form
 */
export function* showPriceUpdatedError(trip) {
  const searchParams = yield call(getSearchParamsFromTrip, trip);
  const newBookTripUrl = `/book${window.location.search}`;

  yield call(prepareTripUpdatedDialog, { updatedTrip: trip });
  yield put(
    setTripError({
      errorCode: TRIP_UPDATED_ERROR_CODE,
      errorPopup: true,
      errorProps: { newBookTripUrl, searchParams },
    })
  );
}

function* doPaidBooking(action, reservationData) {
  const form = yield select(getBookTripForm);
  const couponCode = reservationData.coupon_code;
  const trip = form[DEPARTING_TRIP_FIELD_NAME];
  const price = form[PRICE_FIELD_NAME];

  const { createPayment } = action.payload;
  const paymentObj = yield call(createPayment);
  Object.assign(reservationData, createPaymentReservationData(paymentObj));

  const res = yield call(reservations.create, reservationData);

  // For some unkonwn errors when server returns nothing
  if (!res || !res.length) {
    throw new Error(
      'Sorry, we did not make your reservation for some unknown reason. ' +
        'Please try again or contact support.'
    );
  }

  yield call(action.resolvePromise);
  yield fork(trackSales, res, trip, price);
  yield fork(reportTransaction, res, couponCode);
  yield fork(reportPartnerBookingComplete, res);
  yield hook(AFTER_CONFIRM_BOOKING_HOOK, res);

  yield call(redirectToConfirmation, {
    ondemand: reservationData.ondemand,
    id: res[0].trip.id,
    returnId: res.length > 1 ? res[1].trip.id : null,
  });
}

function* doSendBookingLink(action, reservationData) {
  yield call(reservations.sendLink, reservationData);
  yield call(action.resolvePromise);
}

function* openFeedbackCollectionSurvey(reservationData) {
  const user = yield select(getUser);
  const isTA = yield select(behavesAsTravelAgent);
  const paxName = `${reservationData.first_name} ${reservationData.last_name}`;
  const userName = user ? `${user.first_name} ${user.last_name}` : null;

  pushDelighted('survey', {
    email: user?.email || reservationData.email,
    name: user ? userName : paxName,
    properties: {
      paxName,
      paxEmail: reservationData.email,
      userEmail: user?.email ?? '',
      userType: user?.user_type ?? 0,
      userIsTravelAgent: !!isTA,
      consentForContact: reservationData.consent_for_contact,
      searchId: reservationData.search_id,
      ref: config.PARTNER_REF,
    },
  });
}

/**
 * Saga for confirmation booking
 */
export default function* confirmBooking(action) {
  const form = yield select(getBookTripForm);
  const trip = form[DEPARTING_TRIP_FIELD_NAME];
  const price = form[PRICE_FIELD_NAME];
  const reservationData = yield call(getReservationData);

  try {
    if (action.payload.createPayment) {
      yield call(doPaidBooking, action, reservationData);
    } else {
      yield call(doSendBookingLink, action, reservationData);
    }
    yield call(openFeedbackCollectionSurvey, reservationData);
  } catch (error) {
    yield put(incrementBookingAttempts());
    yield call(trackFailedBooking, reservationData, trip, price, error);

    if (error && error.errorCode === 'trip_price_changed') {
      yield call(showPriceUpdatedError, form[DEPARTING_TRIP_FIELD_NAME]);
    } else {
      yield call(showErrorNotification, { error });
    }
    yield call(action.rejectPromise);

    // FIXME: re-search until we do not have a safe way to check tht the
    // trip was expired
    if (Date.now() > trip.expiresAt * 1000) {
      yield call(processResearchTrip, trip);
    }
  }
}
