import _ from 'lodash';
import { select } from 'redux-saga/effects';
import { getAutocomplete } from '../selectors';

/**
 * Try to find suggestion object from suggestions list by suggestion object –
 * value of the form. If suggestion with the same value will be found
 * in suggestions list, then the form value will be replaced with suggestion
 * from suggestions list.
 * @param  {Object} action
 */
export default function* normalizeSuggestionValue({
  payload: { id, value, changer },
}) {
  // Accept only uncomplete values
  if (!value || !changer || !value.value || !value.uncomplete) {
    return;
  }

  // Get suggestions and try to find suggestion with the same value
  const { suggestions, loadedOnce } = yield select(getAutocomplete, id);
  if (suggestions && loadedOnce) {
    const correctSuggestion = _.find(
      suggestions,
      (x) => String(x.value).toLowerCase() === String(value.value).toLowerCase()
    );
    if (correctSuggestion) {
      changer(correctSuggestion);
    }
  }
}
