import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { formValueSelector } from 'redux-form';
import {
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  RETURN_TRIP_FIELD_NAME,
} from 'app/constants';

export const mapStateToProps = (
  state,
  { direction, formName = BOOK_TRIP_FORM }
) => {
  const selector = formValueSelector(formName);

  const tripField =
    direction === 'returning'
      ? RETURN_TRIP_FIELD_NAME
      : DEPARTING_TRIP_FIELD_NAME;

  const trip = selector(state, tripField);

  return {
    confirmationNumber: trip?.confirmationNumber,
  };
};

export const builder = (ComposedComponent) => {
  function WrapperComponent(props) {
    if (!props.confirmationNumber) return null;
    return <ComposedComponent {...props} />;
  }

  return compose(withRouter, connect(mapStateToProps))(WrapperComponent);
};

export default builder;
