import {
  REGISTER_USER,
  LOGIN_USER,
  LOGIN_USER_SOCIAL,
  LOGOUT_USER,
  REACTIVATE_ACCOUNT,
  SET_USER,
  CONFIRM_EMAIL_ADDRESS,
} from 'app/constants';
import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import doRegister from './doRegister';
import doReactivate from './doReactivate';
import doLogin from './doLogin';
import doLoginSocial from './doLoginSocial';
import doLogout from './doLogout';
import setUser from './setUser';
import doConfirmVerificationCode from './doConfirmVerificationCode';

export default function* watchUserSession() {
  yield fork(takeLatest, REGISTER_USER, doRegister);
  yield fork(takeLatest, LOGIN_USER, doLogin);
  yield fork(takeLatest, LOGIN_USER_SOCIAL, doLoginSocial);
  yield fork(takeLatest, LOGOUT_USER, doLogout);
  yield fork(takeLatest, REACTIVATE_ACCOUNT, doReactivate);
  yield fork(takeLatest, SET_USER, setUser);
  yield fork(takeLatest, CONFIRM_EMAIL_ADDRESS, doConfirmVerificationCode);
}
