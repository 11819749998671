import React from 'react';
import config from 'config';
import { findMainStep, getAmenities } from 'app/utils/trip';
import CN from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout, Flex } from 'app/components/base/Layout';
import CheckedText from 'app/components/base/CheckedText';
import { InfoIcon, ClockIcon } from 'app/components/base/TripIcons';
import Button from 'app/components/base/Button';
import { getImageFromClass } from 'app/utils/vehicleClass';
import { ON_DEMAND_CATEGORY, TRANSPORT_RAIL } from 'app/constants';
import IncompleteAddressLightbox from '../../IncompleteAddressLightbox';
import {
  getTicketInfo,
  getSelectedTicket,
} from 'app/components/search-results/TimeTableSelect/utils/getTripScheduleProps';
// import subcomponents
import Heading from './Heading';
import StepsSummary from '../commons/StepsSummary';
import Details from './Details';
import TripPrice from '../commons/TripPrice';
import Tags from '../commons/Tags';
import TripProviderLogo from '../commons/TripProviderLogo';
import EstimatedTimeTooltip from './EstimatedTimeTooltip';
import ScheduleBtn from './ScheduleBtn';
import HourlyDuration from '../commons/HourlyDuration';

import style from './style.css';

const messages = {
  rideDuration: { id: 'SEARCH_RESULTS.RIDE_DURATION' },
  bookRide: { id: 'SEARCH_RESULTS.BOOK_RIDE' },
  freeCancellation: { id: 'SEARCH_RESULTS.FREE_CANCELLATION' },
  taxesIncluded: { id: 'SEARCH_RESULTS.TAXES_INCLUDED' },
  chooseScheduledDeparture: { id: 'SEARCH_RESULTS.CHOOSE_SCHEDULED_DEPARTURE' },
  priceLabel: { id: 'SEARCH_RESULTS.PRICE_LABEL' },
};

class Trip extends React.Component {
  chooseSchedule = () => {
    if (this.props.onScheduleDeparture) {
      this.props.onScheduleDeparture();
    }
  };

  render() {
    const {
      id: tripId,
      className,
      showedOnMap,
      price,
      priceTagline,
      freeCancellation,
      taxesAndFeesIncluded,
      steps,
      rewardPoints,
      type: tripType,
      returnSteps,
      departureTime,
      tags,
      onScheduleDeparture,
      departureTicket,
      returnTicket,
      onTripHover,
      isBookable,
      isAddressIncomplete,
      isRecommended,
      showCategory = false,
      experimentNewSupplierLabel,
    } = this.props;

    const {
      provider,
      accurateTime: duration,
      type: stepType,
      vehicle: {
        type: category = {},
        imageUrl,
        vehicleClass,
        vehicleClassName,
        maxPassengers,
        maxBags,
        isMaxBagsPerPerson,
        numVehicles,
        make,
        model,
      },
      ticketTypes,
      bookingDetails: {
        showTollsAndGratuity,
        tollsIncluded,
        gratuityIncluded,
        meetAndGreetAvailable,
        meetAndGreetDefault,
        startTime,
        endTime,
        waitTime,
        hourlyDetails,
      },
    } = findMainStep(steps);

    // Trip amenities
    const amenities = getAmenities(
      findMainStep(steps),
      findMainStep(returnSteps),
      'amenities'
    );

    const isHourlyRide = !!(hourlyDetails && hourlyDetails.hoursRequested);

    const headingProps = {
      experimentNewSupplierLabel,
      realProviderName: provider.realName,
      score: provider.supplierScore,
      providerName: provider.name,
      vehicleType: category.id,
      vehicleClass,
      vehicleClassName,
    };

    const isTrain = stepType === TRANSPORT_RAIL;
    const isOndemand = tripType === ON_DEMAND_CATEGORY;

    const tripDetails = {
      isTrain,
      rewardPoints,
      isOndemand,
      maxPassengers,
      maxBags,
      isMaxBagsPerPerson,
      departureTime,
      serviceHours: startTime && endTime && `${startTime} - ${endTime}`,
      showTollsAndGratuity,
      tollsIncluded,
      gratuityIncluded,
      meetAndGreetAvailable,
      meetAndGreetDefault,
      amenities,
      showCategory,
      categoryName: category.name,
      vehicleTypeId: category.id,
      // only pass wait time in trips from airport
      waitTime,
      onScheduleDeparture,
    };

    const providerLogoProps = {
      imageUrl: getImageFromClass(headingProps.vehicleClass) || imageUrl,
      numVehicles,
      recommended: isRecommended,
      make,
      model,
    };

    const hours = Math.floor(duration / 60);
    const minutes = `0${duration % 60}`.slice(-2);

    const modifiers = {
      [style['container--recommended']]: isRecommended,
      [style.active]: showedOnMap,
    };

    const hasTicketTypes = ticketTypes && ticketTypes.length > 0;

    let scheduleProps = null;
    if (hasTicketTypes) {
      const trip = { steps, returnSteps, departureTicket, returnTicket };
      const selectedDepartureTicket = getSelectedTicket(trip, false);
      const selectedReturnTicket = getSelectedTicket(trip, true);
      scheduleProps = {
        departureTicketInfo: getTicketInfo({ steps }, selectedDepartureTicket),
        returnTicketInfo: getTicketInfo(
          { steps: returnSteps },
          selectedReturnTicket
        ),
      };
    }

    return (
      <Layout
        className={CN(style.container, modifiers, className)}
        direction="column"
        onMouseEnter={onTripHover}
      >
        <Layout align="start start" direction="row" nowrap>
          <TripProviderLogo {...providerLogoProps} />

          <Layout
            nowrap
            className={style['details-container']}
            size="grow"
            direction="column"
            align="start start"
          >
            <Heading {...headingProps} />
            <StepsSummary
              steps={steps}
              recommendedTrip={isRecommended}
              className={style.route}
            />
            {config.SHOW_TAGS_IN_SEARCH && <Tags tags={tags} />}
            <Details {...tripDetails} />
          </Layout>

          <Layout
            nowrap
            size="noshrink"
            className={style['price-column']}
            direction="column"
            align="start end"
          >
            <TripPrice
              priceLabel={
                config.SHOW_SRP_PRICE_LABEL && (
                  <FormattedMessage {...messages.priceLabel} />
                )
              }
              {...price}
            />

            {isHourlyRide && (
              <HourlyDuration
                originalRequested={hourlyDetails.originalRequested}
                hoursRequested={hourlyDetails.hoursRequested}
                minimum={hourlyDetails.minimum}
                includedKms={hourlyDetails.totalIncludedKilometers}
              />
            )}
            {!isHourlyRide && !!duration && config.RIDE_DURATION_SHOW && (
              <Layout align="end center" className={style.duration} nowrap>
                <ClockIcon />
                <FormattedMessage
                  {...messages.rideDuration}
                  values={{ hours, minutes }}
                />
                <EstimatedTimeTooltip />
              </Layout>
            )}
            <Layout
              direction="column"
              nowrap
              className={style['price-details']}
              align="end end"
            >
              {priceTagline && (
                <Layout
                  direction="row"
                  align="start center"
                  className={style['icon-container']}
                >
                  <InfoIcon />
                  <span>{priceTagline}</span>
                </Layout>
              )}

              {freeCancellation && (
                <CheckedText
                  className={style['cancellation-text']}
                  checked
                  text={<FormattedMessage {...messages.freeCancellation} />}
                />
              )}
              {taxesAndFeesIncluded && (
                <span className={style['price-notice']}>
                  <FormattedMessage {...messages.taxesIncluded} />
                </span>
              )}
            </Layout>

            {/* book trip and timetable buttons*/}
            {isBookable && !isAddressIncomplete && (
              <Layout
                className={style.actionsContainer}
                nowrap
                align="start stretch"
              >
                {hasTicketTypes && (
                  <Flex size="noshrink" className={style.changeDepartureBtn}>
                    <ScheduleBtn
                      onClick={this.chooseSchedule}
                      {...scheduleProps}
                    />
                  </Flex>
                )}
                <Flex size="noshrink">
                  <Button
                    className={style.button}
                    onClick={this.props.onClickBookTrip}
                    nocaps
                    rounded
                    large
                  >
                    {<FormattedMessage {...messages.bookRide} />}
                  </Button>
                </Flex>
              </Layout>
            )}
            {isAddressIncomplete && (
              <IncompleteAddressLightbox tripId={tripId}>
                {({ showForm }) => (
                  <Layout
                    className={style.actionsContainer}
                    nowrap
                    align="start stretch"
                  >
                    <Flex size="noshrink">
                      <Button
                        className={style.button}
                        onClick={showForm}
                        nocaps
                        rounded
                        large
                      >
                        {<FormattedMessage {...messages.bookRide} />}
                      </Button>
                    </Flex>
                  </Layout>
                )}
              </IncompleteAddressLightbox>
            )}
          </Layout>
        </Layout>
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Trip.propTypes = {
//   id: PropTypes.string,
//   className: PropTypes.string,
//   showedOnMap: PropTypes.bool,
//   duration: PropTypes.number,
//   price: PropTypes.object.isRequired,
//   priceTagline: PropTypes.string,
//   freeCancellation: PropTypes.bool,
//   taxesAndFeesIncluded: PropTypes.bool,
//   steps: PropTypes.array,
//   returnSteps: PropTypes.array,
//   departureTime: PropTypes.string,
//   tags: PropTypes.array.isRequired,
//   type: PropTypes.string,
//   departureTicket: PropTypes.object,
//   returnTicket: PropTypes.object,
//   isBookable: PropTypes.bool,

//   // Callbacks
//   onScheduleDeparture: PropTypes.func,
//   onClickRouteDetails: PropTypes.func,
//   onClickBookTrip: PropTypes.func,
//   onTripHover: PropTypes.func
// };

export default Trip;
