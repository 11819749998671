import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as autocompleteActions from 'app/actions/autocomplete';
import { overwriteAirportDisplay } from 'app/utils/togglers';

/**
 * Autocomplete container component that connects to the `autocomplete`
 * reducer to get current suggestions list and others. Also it handles search
 * input changes to generate an action for `watchAutocomplete` saga.
 * The saga is getting suggestions and updates autocomplete reducer
 * with loading, error or retreived suggestions.
 *
 * The component implements input interface for `Field` component of
 * `redux-form`. In the value of the field could be a different kind of
 * object. First kind of object is `{ display: 'search text', uncomplete: true }`
 * and this object appears when user enter some text in search input field.
 * The second kind of object is `{ display: 'real value', ...any }` and
 * this object appears when user select some value from suggestions list.
 *
 * So, if `uncomplete` field exists in the value it means that the user
 * did not select any suggestion from the list.
 *
 * You can get first suggested item from the recentest list
 * by `firstSuggestion` field of component instance. Example:
 * ```
 *  <Field
 *    withRef={true}
 *    component={BaseAutocomplete}
 *    ref={(e) => this.field = e}
 *  />
 *  ...
 *  get firstSuggestion() {
 *    return this.field
 *      .getRenderedComponent()
 *      .getWrappedInstance()
 *      .firstSuggestion;
 *  }
 * ```
 *
 * With `requestContext` prop you can pass some object to suggestions getter
 * function in the saga. By default it is undefined.
 */

export const builder = (WrappedAutocomplete) => {
  class AutocompleteContainer extends React.Component {
    constructor(props) {
      super(props);
      this.id = this.props.id || `autocomplete-${Math.random()}`;
    }

    componentDidMount() {
      this.updateSearchQuery({ initLoad: false });
    }

    componentDidUpdate(prevProps) {
      const isRequestContextChanged = !_.isEqual(
        prevProps.requestContext,
        this.suggestionsContext
      );
      if (
        (prevProps.input.value !== this.rawSuggestionValue ||
          isRequestContextChanged) &&
        !this.props.input.value.skipAutocompleteUpdate
      ) {
        this.updateSearchQuery({ isRequestContextChanged });
      }
    }

    componentWillUnmount() {
      const { clearOnUnmount, autocompleteCleanupItems } = this.props;
      if (clearOnUnmount) {
        autocompleteCleanupItems({ id: this.autocompleteId });
      }
    }

    handleChange = (value) => {
      const {
        input: { onChange },
      } = this.props;
      if (onChange) {
        onChange({ display: value, uncomplete: true });
      }
    };

    handleSelectItem = (item) => {
      const {
        input: { onChange = _.noop },
        onSelect = _.noop,
      } = this.props;

      onChange(item);
      onSelect(item);
    };

    updateSearchQuery({ initLoad, isRequestContextChanged }) {
      this.props.autocompleteRequestItems({
        ...this.requestParams,
        isRequestContextChanged,
        initLoad,
      });
    }

    get requestParams() {
      return {
        id: this.autocompleteId,
        query: this.inputValue,
        value: this.rawSuggestionValue,
        target: this.suggestionsTarget,
        changer: this.props.input.onChange,
        requestContext: this.suggestionsContext,
        validated: this.validated,
      };
    }

    get autocompleteId() {
      // give priority to id prom props
      return this.props.id || `${this.id}_${this.inputName}`;
    }

    get isInputUncomplete() {
      const { value } = this.props.input;
      return value && value.uncomplete;
    }

    get rawSuggestionValue() {
      return this.props.input.value;
    }

    get inputValue() {
      const { value } = this.props.input;
      return (value && overwriteAirportDisplay(value)) || '';
    }

    get inputName() {
      return this.props.input.name;
    }

    get suggestionsTarget() {
      return this.props.target;
    }

    get suggestionsContext() {
      return this.props.requestContext;
    }

    get suggestionsList() {
      const { filteredSuggestions, suggestions } =
        this.props.autocompleteFields[this.autocompleteId] || {};
      const { sort } = this.props;
      const disorderedList = filteredSuggestions || suggestions || [];

      const sortedList = sort
        ? disorderedList.sort((a, b) => a.display.localeCompare(b.display))
        : disorderedList;

      return sortedList;
    }

    get validated() {
      const { validated } =
        this.props.autocompleteFields[this.autocompleteId] || {};
      return validated;
    }

    get firstSuggestion() {
      const suggestions = this.suggestionsList;
      return suggestions[0] || undefined;
    }

    render() {
      const {
        target, // eslint-disable-line
        autocompleteFields,
        skipIdForInput,
        input,
        ...props
      } = this.props;

      const passedProps = skipIdForInput ? _.omit(props, 'id') : props;

      const { loading, validated, error, emptySuggestions } =
        autocompleteFields[this.autocompleteId] || {};

      return (
        <WrappedAutocomplete
          {...passedProps}
          loading={loading}
          validated={validated}
          error={error}
          emptySuggestions={emptySuggestions}
          selectedSuggestion={this.rawSuggestionValue}
          suggestions={this.suggestionsList}
          onSelect={this.handleSelectItem}
          uncomplete={this.isInputUncomplete}
          input={{
            ...input,
            onChange: this.handleChange,
            value: this.inputValue,
          }}
        />
      );
    }
  }

  // TODO: move to Flow types
  // AutocompleteContainer.propTypes = {
  //   id: PropTypes.string,
  //   input: PropTypes.object,
  //   name: PropTypes.string,
  //   target: PropTypes.string,
  //   onSelect: PropTypes.func,
  //   requestContext: PropTypes.object,
  //   autocompleteFields: PropTypes.object,
  //   autocompleteRequestItems: PropTypes.func,
  //   autocompleteCleanupItems: PropTypes.func,
  //   clearOnUnmount: PropTypes.bool,
  //   skipIdForInput: PropTypes.bool
  // };

  AutocompleteContainer.defaultProps = {
    clearOnUnmount: false,
  };

  const mapStateToProps = (state) => ({
    autocompleteFields: state.autocomplete,
  });

  return connect(
    mapStateToProps,
    autocompleteActions,
    null
  )(AutocompleteContainer);
};

export default builder;
