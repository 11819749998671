// @flow
import React from 'react';
import ArrowRight from 'mz-icons/chevron-right';
import { Button } from '../Navbar';
import config from 'config';
import { ArrowWrapper, ButtonContainer } from './elements';

type Props = {
  activeCurrency: string,
  appearance: 'desktop' | 'mobile',
  open: () => {},
};

const CurrencyButton = ({ activeCurrency, appearance, open }: Props) => {
  const isDarkMode = config.COLORS_DARK_MODE;
  const isDesktop = appearance === 'desktop';

  return (
    <ButtonContainer $desktop={isDesktop}>
      <Button onClick={open}>
        {activeCurrency}
        <ArrowWrapper $darkMode={isDarkMode} $desktop={isDesktop}>
          <ArrowRight size="xs" rotate={isDesktop && '90deg'} />
        </ArrowWrapper>
      </Button>
    </ButtonContainer>
  );
};

export default CurrencyButton;
