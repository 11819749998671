import { showErrorNotification, showInfoNotification } from 'app/sagas/utils';
import { user } from 'mz-sdk';
import { call, put } from 'redux-saga/effects';
import { logout } from 'app/actions/user-session';
import { getHistory } from 'app/history';

export default function* suspendAccount(action) {
  const history = yield call(getHistory);
  try {
    yield call(user.suspend);
    yield call(showInfoNotification, {
      messageId: 'USER.ACCOUNT_SUSPENDED_ALERT',
      titleId: 'SUCCESS',
    });
    yield call(history.push, '/');
    const logoutThunk = yield call(logout);
    yield put(logoutThunk);
    yield call(action.resolvePromise);
  } catch (error) {
    yield call(showErrorNotification, { error });
    yield call(action.rejectPromise);
  }
}
