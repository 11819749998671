import createMessages from 'mz-intl/create';
import createMessagesObject from '../createMessagesObject';
import currSources from '../sources/currencies.en_US';

export const DOMAIN = 'CURRENCY';

export default createMessages(
  {
    domain: DOMAIN,
    resource: 'currencies',
    disablePush: true,
    allowUnreviewed: true,
    resourceWithoutDomainPrefix: true,
  },
  createMessagesObject(currSources)
);
