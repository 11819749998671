import { fork, take, cancel } from 'redux-saga/effects';
import { AUTOCOMPLETE_TEXTING } from 'app/constants';
import { isAutocompleteTextingOrLoaded } from 'app/sagas/patterns';
import retrieveSuggestions from './retrieveSuggestions';
import filterSuggestions from './filterSuggestions';
import normalizeSuggestionValue from './normalizeSuggestionValue';

/**
 * `takeLatest` with separation tasks by autocomplete id. So, if some
 * autocomplete action is in progress and then started another one,
 * the second action handler will not stop first handler.
 */
export function* takeLatestById(actionSelector, handler) {
  const tasks = {};
  while (true) {
    const action = yield take(actionSelector);
    const autocompleteId = action.payload.id;

    if (tasks[autocompleteId]) {
      yield cancel(tasks[autocompleteId]);
    }
    tasks[autocompleteId] = yield fork(handler, action);
  }
}

/**
 * Watch for latest autocomplete input change action and filtering
 */
export default function* watchAutocomplete() {
  yield fork(takeLatestById, AUTOCOMPLETE_TEXTING, retrieveSuggestions);
  yield fork(
    takeLatestById,
    isAutocompleteTextingOrLoaded,
    normalizeSuggestionValue
  );
  yield fork(takeLatestById, isAutocompleteTextingOrLoaded, filterSuggestions);
}
