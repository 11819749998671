import React from 'react';
import { FormattedMessage } from 'translations';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classnames from 'classnames';
import { formValueSelector } from 'redux-form';
import { toggleOptionalAmenity } from 'app/actions/book-trip';
import UpgradeItem from './UpgradeItem';
import Gratuity, {
  mapStateToProps as gratuityMapStateToProps,
} from 'app/components/book-trip/UpgradeYourTrip/commons/Gratuity';
import {
  BOOK_TRIP_FORM,
  UPGRADES_FIELD_NAME,
  UPGRADE_IDS,
  PRICE_FIELD_NAME,
  GRATUITY_FIELD_NAME,
} from 'app/constants';
import _ from 'lodash';

import style from './style.css';

const messages = {
  insuranceCoverage: { id: 'BOOK_TRIP.UPGRADES.INSURANCE_COVERAGE_LABEL' },
  tollsAndGratuity: { id: 'BOOK_TRIP.TOLLS_AND_GRATUITY' },
  tolls: { id: 'BOOK_TRIP.TOLLS' },
  gratuity: { id: 'BOOK_TRIP.GRATUITY' },
  meetAndGreet: { id: 'BOOK_TRIP.UPGRADES.MEET_AND_GREET_LABEL' },
};

export const GratuitySidebar = (props) => (
  <Gratuity sidebarMode className={style.gratuity} {...props} />
);

/**
 * Upgrades list component
 */
const UpgradesList = ({
  upgrades,
  isGratuityAvailable,
  price,
  onRemoveAmenity,
  readOnly,
  mobile,
}) => {
  const modifiers = {
    [style['list--mobile']]: mobile,
  };
  const staticDataMap = {
    [UPGRADE_IDS.INSURANCE_COVERAGE]: {
      name: <FormattedMessage {...messages.insuranceCoverage} />,
    },
    [UPGRADE_IDS.TOLLS]: {
      name: <FormattedMessage {...messages.tolls} />,
    },
    [UPGRADE_IDS.GRATUITY]: {
      name: <FormattedMessage {...messages.gratuity} />,
      price: isGratuityAvailable && price && price.gratuity,
      editComponent: isGratuityAvailable && GratuitySidebar,
    },
    [UPGRADE_IDS.TOLLS_AND_GRATUITY]: {
      name: <FormattedMessage {...messages.tollsAndGratuity} />,
    },
    [UPGRADE_IDS.MEET_AND_GREET]: {
      name: <FormattedMessage {...messages.meetAndGreet} />,
      price:
        price &&
        price.meetAndGreetPrice &&
        parseFloat(price.meetAndGreetPrice.replace(/[^0-9\.]/g, ''), 10) > 0
          ? price.meetAndGreetPrice
          : undefined,
    },
  };

  return (
    <ul className={classnames(style.list, modifiers)}>
      {upgrades.map((item, index) => {
        const staticItemData = staticDataMap[item.id];
        return (
          <UpgradeItem
            {...item}
            onRemove={() => onRemoveAmenity(item.id)}
            {...staticItemData}
            key={index}
            mobile={mobile}
            readOnly={readOnly}
          />
        );
      })}
    </ul>
  );
};

// TODO: move to Flow types
// UpgradesList.propTypes = {
//   formName: PropTypes.string,
//   upgrades: PropTypes.array,
//   isGratuityAvailable: PropTypes.bool,
//   price: PropTypes.object,
//   mobile: PropTypes.bool,
//   readOnly: PropTypes.bool,
//   onRemoveAmenity: PropTypes.func
// };

export const mapStateToProps = (
  state,
  { formName = BOOK_TRIP_FORM, readOnly }
) => {
  const selector = formValueSelector(formName);

  const price = selector(state, PRICE_FIELD_NAME);
  const gratuity = selector(state, GRATUITY_FIELD_NAME);

  // put Gratuity as last item
  const upgrades = (selector(state, UPGRADES_FIELD_NAME) || [])
    .filter((upgrade) => upgrade.included)
    .map((upgrade) => ({
      ...upgrade,
      price: _.isObject(upgrade.price) ? upgrade.price.price : upgrade.price,
    }))
    .sort((upgrade) => upgrade.id === UPGRADE_IDS.GRATUITY);

  // We add 0% gratuity to display in sidebar to allow user to change it from there
  const gratuityItem = upgrades.find(
    (upgrade) => upgrade.id === UPGRADE_IDS.GRATUITY
  );

  const { isGratuityAvailable } = gratuityMapStateToProps(state);

  if (!gratuityItem && !readOnly && isGratuityAvailable) {
    upgrades.push({
      id: UPGRADE_IDS.GRATUITY,
      caption: `${gratuity}%`,
      price: price && price.gratuity,
    });
  }

  return {
    upgrades,
    price,
    isGratuityAvailable,
  };
};

export default compose(
  connect(mapStateToProps, {
    onRemoveAmenity: toggleOptionalAmenity,
  })
)(UpgradesList);
