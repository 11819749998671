// @flow
import React from 'react';
import HelpTooltip from 'mz-ui-kit/core/HelpTooltip';
import HourlyLabel from 'app/components/common/HourlyLabel';
import MinimumHint from './MinimumHint';
import { Container, SuggestedWrapper, HourlyWrapper } from './elements';
import { FormattedMessage } from 'mz-intl';
import messages from '../../messages.intl';

// types
type Props = {
  mobile: boolean,
  originalRequested: number,
  hoursRequested: number,
  minimum: number,
  includedKms: number,
};

const renderRideDuration = () => (
  <FormattedMessage message={messages.RIDE_DURATION} />
);

const HourlyDuration = ({
  mobile,
  originalRequested,
  hoursRequested,
  minimum,
  includedKms,
}: Props) => {
  const suggested = minimum > originalRequested;
  const minimumMessage = mobile ? 'MIN' : 'MINIMUM';
  const renderDurationPrefix = !mobile ? renderRideDuration : null;

  return (
    <Container>
      {!suggested && (
        <HourlyLabel
          hours={hoursRequested}
          renderPrefix={renderDurationPrefix}
        />
      )}
      {suggested && (
        <SuggestedWrapper>
          <HourlyWrapper>
            <HourlyLabel
              hours={minimum}
              renderPrefix={() => (
                <FormattedMessage message={messages[minimumMessage]} />
              )}
            />
          </HourlyWrapper>
          {!mobile && (
            <HelpTooltip
              showed
              placement="bottom"
              width="200px"
              content={() => <MinimumHint minimum={minimum} />}
            />
          )}
        </SuggestedWrapper>
      )}
      <div>
        <FormattedMessage
          message={messages.INCLUDED_KMS}
          values={{ distance: includedKms }}
        />
      </div>
    </Container>
  );
};

export default HourlyDuration;
