import { call } from 'redux-saga/effects';
import { favouritePlaces } from 'mz-sdk';
import { showErrorNotification } from 'app/sagas/utils';
import _ from 'lodash';

/**
 * Save favourite location
 */
export default function* saveLocation(action) {
  const place = action.payload;
  const preparedPlace = _.omitBy(place, (item) => !item);
  try {
    const saveCall = place.id ? favouritePlaces.update : favouritePlaces.create;
    const savedPlace = yield call(saveCall, preparedPlace);
    yield call(action.resolvePromise, savedPlace);
  } catch (error) {
    yield call(showErrorNotification, { messageId: 'ERRORS.GENERAL_ERROR' });
    yield call(action.rejectPromise, error);
  }
}
