// @flow
import { styled } from 'mz-ui-kit';

export const Container = styled('div', {
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyItems: 'center',
  padding: '20px',
  minHeight: '300px',
  maxWidth: '360px',
});

export const Text = styled('div', ({ $centerText }) => ({
  fontSize: '18px',
  padding: '10px 0 20px 0',
  textAlign: $centerText ? 'center' : 'initial',
}));

export const Title = styled('span', {
  fontSize: '20px',
  fontWeight: 'bold',
});

export const Success = styled('div', {
  fontSize: '102px',
  margin: '20px auto',
});

export const Error = styled('div', {
  flexGrow: 1,
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'flex-end',
});

export const ButtonWrapper = styled('div', {
  minWidth: '80px',
});
