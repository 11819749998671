import React from 'react';
import { isMobileBrowser } from 'mz-ui-kit/utils';
import importWithRetry from 'mz-utils/importWithRetry';

// Constants
const TA_TOOL = 'travel-agent';

// Lazy loaded pages
const TADashboardPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TADashboardPage'))
);
const TAAgentProfilePage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAAgentProfilePage'))
);
const TACompanyInfoPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACompanyInfoPage'))
);
const RegisterPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/RegisterPage'))
);
const TACustomerProfilesPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TACustomerProfilesPage'))
);
const TAPaymentsPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAPaymentsPage'))
);
const TASalesBalancePage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TASalesBalancePage'))
);
const TAWithdrawalsHistoryPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/TAWithdrawalsHistoryPage'))
);
const UserInformationPage = React.lazy(() =>
  importWithRetry(() => import('app/pages/UserInformationPage'))
);
const UserAccountPage = React.lazy(() =>
  isMobileBrowser()
    ? importWithRetry(() => import('app/pages/UserAccountPage/Mobile'))
    : importWithRetry(() => import('app/pages/UserAccountPage/Desktop'))
);

const routes = [
  {
    path: `/${TA_TOOL}/register`,
    component: RegisterPage,
  },
  {
    path: `/${TA_TOOL}/account/update-profile`,
    component: TAAgentProfilePage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account/update-company-information`,
    component: TACompanyInfoPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account/info`,
    component: UserInformationPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/account`,
    component: UserAccountPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/manage-withdrawal-accounts`,
    component: TAPaymentsPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/sales-balance`,
    component: TASalesBalancePage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/withdrawals-history`,
    component: TAWithdrawalsHistoryPage,
    wrapper: TADashboardPage,
  },
  {
    path: `/${TA_TOOL}/manage-profiles/customer`,
    component: TACustomerProfilesPage,
    wrapper: TADashboardPage,
  },
];

export default () => routes;
