import {
  USER_TYPE_TA,
  USER_TYPE_NORMAL,
  USER_TYPE_TC,
  USER_TYPE_CORP,
  TRAVEL_AGENT_MASTER_ACCOUNT,
  TRAVEL_AGENT_SLAVE_ACCOUNT,
} from 'app/constants';

// Constants
const USER_TYPE_NAMES = {
  [USER_TYPE_TA]: 'Travel Agent',
  [USER_TYPE_NORMAL]: 'Normal',
  [USER_TYPE_TC]: 'GBT TC',
  [USER_TYPE_CORP]: 'Corporate',
};
const USER_POSITONS = {
  [TRAVEL_AGENT_MASTER_ACCOUNT]: 'Master',
  [TRAVEL_AGENT_SLAVE_ACCOUNT]: 'Slave',
};

/**
 * We need to make the Mozio user accessible to third-party lib Chameleon
 * in order to track tours per-user.
 * @see https://github.com/mozioinc/mozio-web/issues/1330
 */
export default function setUser({ payload }) {
  let user = null;
  let fsUser = null;

  if (payload && payload.id) {
    const { id: uid, email } = payload;
    user = { uid, email };
    fsUser = {
      email,
      displayName: `${payload.first_name} ${payload.last_name}`,
      agency: payload.company && payload.company.name,
      phone: payload.phone_number || '',
      user_type: USER_TYPE_NAMES[payload.user_type] || 'unknown',
      position: USER_POSITONS[payload.position] || '',
    };
  }

  if (typeof window !== 'undefined') {
    window.mozioUser = user;
    if (window.FS) {
      if (fsUser && fsUser.email) {
        window.FS.identify(fsUser.email, fsUser);
      } else {
        window.FS.identify(false);
      }
    }

    if (window.zE && fsUser && fsUser.email) {
      const widgetTags = window.zESettings.webWidget.chat.tags;
      widgetTags.push(`user_type: ${fsUser.user_type}`);
      if (fsUser.agency) {
        widgetTags.push(`agency: ${fsUser.agency}`);
      }
      if (fsUser.position) {
        widgetTags.push(`position: ${fsUser.position}`);
      }

      window.zE('webWidget', 'prefill', {
        name: { value: fsUser.displayName },
        email: { value: fsUser.email },
        phone: { value: fsUser.phone },
      });
    }
  }

  if (process.env.NODE_ENV === 'development') {
    // For local debugging
    console.log('ZENDESK_INIT_CHAT_INFO', fsUser); // eslint-disable-line
  }
}
