import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import Button from 'app/components/base/Button';
import Icon from 'app/components/base/Icon';
import classnames from 'classnames';

import style from './style.css';

export const MobileScreen = ({
  className,
  children,
  title,
  titleBack,
  titleDone,
  onClickBack,
  onClickDone,
  ...props
}) => (
  <Layout
    {...props}
    fill
    nowrap
    direction="column"
    className={classnames(className, style.container)}
  >
    <Layout className={style.nav} align="space-between center" nowrap>
      {/* Back button */}
      <Layout size="noshrink" align="start center">
        {onClickBack && (
          <Button
            nocaps
            clear
            onClick={onClickBack}
            className={style.nav__button}
          >
            <Layout direction="row" align="start center" nowrap>
              <Icon className={style.nav__backIcon}>chevron_left</Icon>
              {titleBack && <Flex size="noshrink">{titleBack}</Flex>}
            </Layout>
          </Button>
        )}
      </Layout>
      {/* Title */}
      <Layout size="grow" align="center center" className={style.nav__title}>
        {title}
      </Layout>
      {/* Done button */}
      <Layout size="noshrink" align="end center">
        {onClickDone && (
          <Button
            nocaps
            clear
            onClick={onClickDone}
            className={style.nav__button}
          >
            {titleDone}
          </Button>
        )}
      </Layout>
    </Layout>
    <div className={style.content}>{children}</div>
  </Layout>
);

// TODO: move to Flow types
// MobileScreen.propTypes = {
//   className: PropTypes.string,
//   children: PropTypes.node,
//   open: PropTypes.bool,
//   onClickBack: PropTypes.func,
//   onClickDone: PropTypes.func,
//   title: PropTypes.node,
//   titleBack: PropTypes.node,
//   titleDone: PropTypes.node
// };

export default MobileScreen;
