import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { CONFIRM_BOOKING } from 'app/constants';
import confirmBooking from './confirmBooking';
import updateTravelAgent from './updateTravelAgent';

/**
 * Wait for search start event
 */
export default function* watchConfirmBooking() {
  yield fork(takeLatest, CONFIRM_BOOKING, confirmBooking);
  yield fork(takeLatest, CONFIRM_BOOKING, updateTravelAgent);
}
