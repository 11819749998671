import React, { Component } from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import Button from 'app/components/base/Button';
import TimePicker from 'app/components/base/TimePicker';
import Tile from './Tile';
import moment from 'moment';
import { FormattedMessage } from 'translations';
import { getTZLessDate, getMinDate, setTime } from 'app/utils/types';

import style from './style.css';

const messages = {
  title: { id: 'CALENDAR.TITLE' },
  day: { id: 'CALENDAR.TILE' },
};

class Calendar extends Component {
  componentWillMount() {
    this.setState({
      month: moment(this.props.input.value).startOf('month').date(1),
    });
  }

  handleClickDate = (date) => {
    const value = setTime(date, this.props.input.value);
    this.props.input.onChange(value);
  };

  handlePageDown = () => {
    this.setState({
      month: this.state.month.add(1, 'month'),
    });
  };

  handlePageUp = () => {
    this.setState({
      month: this.state.month.subtract(1, 'month'),
    });
  };

  renderWeekdays() {
    const weekdays = [moment().startOf('week').isoWeekday(1)];

    for (let i = 1; i < 7; i++) {
      weekdays.push(moment(weekdays[0]).add(i, 'days'));
    }

    return (
      <Layout align="space-around center" className={style.row} nowrap>
        {weekdays.map((day, index) => (
          <Layout className={style.weekday} align="center center" key={index}>
            <FormattedMessage
              {...messages.day}
              values={{ value: getTZLessDate(day) }}
            />
          </Layout>
        ))}
      </Layout>
    );
  }

  renderTile(item, index) {
    if (!item) {
      return <Tile value={item} key={index} disabled />;
    }

    const { input, target, minDate } = this.props;
    const { month } = this.state;
    const disabled =
      minDate.isAfter(item, 'day') ||
      (target && target.isSame(item, 'day')) ||
      !month.isSame(item, 'month');

    return (
      <Tile
        value={item}
        key={index}
        onClick={this.handleClickDate}
        active={input.value.isSame(item, 'day')}
        target={target && target.isSame(item, 'day')}
        disabled={disabled}
      />
    );
  }

  renderRow(items, index) {
    return (
      <Layout
        align="space-around center"
        className={style.row}
        nowrap
        key={index}
      >
        {items.map((item, i) => {
          return this.renderTile(item, i);
        })}
      </Layout>
    );
  }

  renderTimeRow() {
    const { notime, input, meta } = this.props;

    if (notime) return null;

    return (
      <Layout className={style.section} align="space-around center" nowrap>
        <TimePicker meta={meta} input={input} noarrow direction="top" />
      </Layout>
    );
  }

  renderChildren() {
    const { children } = this.props;
    if (!children) return null;
    return (
      <Layout className={style.section} align="center center">
        {children}
      </Layout>
    );
  }

  render() {
    const {
      minDate,
      intl, // eslint-disable-line no-unused-vars
      onClick,
    } = this.props;
    const month = this.state.month.clone();
    const firstDate = month.clone().isoWeekday(1);
    const rows = [];
    for (let i = 0; i < 6; i++) {
      const tiles = [];
      for (let j = 0; j < 7; j++) {
        const date = firstDate.clone().add(i * 7 + j, 'days');
        tiles.push(month.isSame(date, 'month') ? date : null);
      }
      rows.push(tiles);
    }
    return (
      <Layout
        direction="column"
        onClick={onClick}
        className={style.container}
        align="center center"
        nowrap
      >
        <Layout className={style.section} align="center center">
          <Button
            round
            clear
            alt
            align="center center"
            className={style.chevron}
            disabled={month.isSame(minDate, 'month')}
            onClick={this.handlePageUp}
          >
            <Icon small>chevron_left</Icon>
          </Button>
          <Flex size="auto" className={style.month}>
            <FormattedMessage
              {...messages.title}
              values={{ value: getTZLessDate(month) }}
            />
          </Flex>
          <Button
            round
            clear
            alt
            className={style.chevron}
            align="center center"
            onClick={this.handlePageDown}
          >
            <Icon small>chevron_right</Icon>
          </Button>
        </Layout>
        <Layout className={style.section} direction="column" nowrap>
          {this.renderWeekdays()}
          {rows.map((row, index) => this.renderRow(row, index))}
        </Layout>
        {this.renderTimeRow()}
        {this.renderChildren()}
      </Layout>
    );
  }
}

// TODO: move to Flow types
// Calendar.propTypes = {
//   input: PropTypes.object.isRequired,
//   meta: PropTypes.object.isRequired,
//   notime: PropTypes.bool,
//   children: PropTypes.node,
//   target: PropTypes.object,
//   minDate: PropTypes.object
// };

Calendar.defaultProps = {
  minDate: getMinDate(),
};

export default Calendar;
