import { call, select } from 'redux-saga/effects';
import objectToQueryString from 'mz-utils/objectToQueryString';
import { getFullStorySessionURL } from 'app/utils/fullstory';
import { engage } from 'mz-sdk';
import _ from 'lodash';
import { getSavedBooking, getSavedSearch } from 'app/sagas/selectors';

export default function* userEntersEmail({ payload }) {
  const saved = yield select(getSavedBooking);
  // TODO: this check should be removed after #1227 is closed
  if (!saved) return;
  const { provider, session_id } = saved;
  const savedSearch = yield select(getSavedSearch);
  if (_.isEmpty(savedSearch)) return;
  try {
    const data = {
      email: payload,
      provider_name: provider,
      session_id,
      url: `${window.location.origin}/search/${objectToQueryString(
        savedSearch
      )}`,
    };

    const full_story_url = getFullStorySessionURL();

    if (full_story_url) {
      Object.assign(data, {
        extra_data: { full_story_url },
      });
    }

    yield call(engage.captureEmail, data);
  } catch (err) {
    // fails when the email is already saved
  }
}
