import { createReducer } from '../utils/redux';
import { SET_CURRENT_TRAVELER } from '../constants';

const initialState = {
  current: 0,
};

const actionHandlers = {
  [SET_CURRENT_TRAVELER]: (_, current) => ({ current }),
};

export default createReducer(initialState, actionHandlers);
