import { compose } from 'redux';
import { connect } from 'react-redux';
import { connectSubpages } from 'app/hocs/subpageManagable';
import { bookChangedModeTrip } from 'app/actions/book-trip';
import { getChangedModeTrip } from 'app/sagas/selectors';
import { getMainStep } from 'app/utils/trip';

export const mapStateToProps = (state) => {
  const stateTrip = getChangedModeTrip(state);
  const mainStep = getMainStep(stateTrip);
  const trip = !mainStep ? null : stateTrip;
  return { trip };
};

export const builder = (ComposedComponent) => {
  return compose(
    connectSubpages(),
    connect(mapStateToProps, { bookChangedModeTrip })
  )(ComposedComponent);
};

export default builder;
