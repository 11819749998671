// @flow
import React from 'react';
import { Container, Prefix, Label } from './elements';
import HourlyIcon from 'mz-icons/hourly';
import getTimeString from 'mz-utils/getTimeString';

// types
type Props = {
  hours: number,
  noIcon?: boolean,
  renderPrefix?: () => any,
};

const HourlyLabel = ({ hours, noIcon, renderPrefix }: Props) => {
  const time = getTimeString({ hourDiff: hours });

  return (
    <Container>
      {!noIcon && <HourlyIcon />}
      {renderPrefix && <Prefix>{renderPrefix()}</Prefix>}
      <Label>{time}</Label>
    </Container>
  );
};

export default HourlyLabel;
