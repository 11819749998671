import { call, put, select } from 'redux-saga/effects';
import { actionTypes } from 'redux-form';
import { getBookTripFormValue } from 'app/sagas/selectors';
import resolveFormValues from '../watchLoadBookTrip/resolveFormValues';
import {
  BOOK_TRIP_FORM,
  DEPARTING_TRIP_FIELD_NAME,
  RIDE_DURATION_FIELD_NAME,
} from 'app/constants';

export const CHANGE_RIDE_DURATION_SAGA = 'CHANGE_RIDE_DURATION_SAGA';

/**
 * This saga sets ride duration to it's original value
 */
export default function* restoreOriginalRideDuration() {
  // called with reloadTrip = true to skip not needed steps
  const dapartingTrip = yield select(
    getBookTripFormValue,
    DEPARTING_TRIP_FIELD_NAME
  );
  // we set updatedTrip param to avoid making new api call
  const values = yield call(resolveFormValues, {
    updatedTrip: dapartingTrip,
    reloadTrip: true,
  });
  const hoursNumberBeforeChange = values[RIDE_DURATION_FIELD_NAME];
  // used manual creation of action because we need to indicate that
  // ride duration was changed not from bookTrip form
  const changeAction = {
    type: actionTypes.CHANGE,
    meta: { form: BOOK_TRIP_FORM, field: RIDE_DURATION_FIELD_NAME },
    payload: hoursNumberBeforeChange,
    source: CHANGE_RIDE_DURATION_SAGA,
  };

  yield put(changeAction);
}
