import { fork } from 'redux-saga/effects';
import watchCreditCard from './watchCreditCard';
import watchReservations from './watchReservations';
import watchUserData from './watchUserData';

export default function* watchUserAccount() {
  yield fork(watchCreditCard);
  yield fork(watchReservations);
  yield fork(watchUserData);
}
