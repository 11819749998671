import React from 'react';
import { Layout, Flex } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import style from './style.css';
import Image from 'app/components/base/Image';

const Title = ({ title, onClose, logoSrc, tripInfo, noclose }) => (
  <Layout
    nowrap
    size="noshrink"
    direction="row"
    align="start center"
    className={style.title}
  >
    <Flex size="noshrink">
      <Image src={logoSrc} alt={title} circled className={style.logo} />
    </Flex>

    <Layout direction="column" size="grow">
      {/* Title and close icon */}
      <Layout direction="row" nowrap>
        <Flex size="grow">
          <span className={style.name}>{title}</span>
        </Flex>
        {!noclose && (
          <Flex size="noshrink">
            <Icon className={style.close} onClick={() => onClose()}>
              close
            </Icon>
          </Flex>
        )}
      </Layout>
      {/* trip information */}
      {tripInfo}
    </Layout>
  </Layout>
);

// TODO: move to Flow types
// Title.propTypes = {
//   type: PropTypes.string,
//   noclose: PropTypes.bool,
//   title: PropTypes.string,
//   onClose: PropTypes.func,
//   logoSrc: PropTypes.string.isRequired,
//   tripInfo: PropTypes.node
// };

export default Title;
