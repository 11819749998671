import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import { ADD_COUPON, CLOSE_COUPON_HINT } from 'app/constants';
import addCoupon from './addCoupon';
import closeHint from './closeHint';

/**
 * Saga for actions related to the coupon field in the book-trip page
 */
export default function* watchCoupon() {
  yield fork(takeLatest, ADD_COUPON, addCoupon);
  yield fork(takeLatest, CLOSE_COUPON_HINT, closeHint);
}
