import { call, put } from 'redux-saga/effects';
import { change } from 'redux-form';
import { BOOK_TRIP_FORM, ACCOUNT_ID_FIELD_NAME } from 'app/constants';
import changeAccountId from './changeAccountId';

/**
 * Remove an associated account ID from the selected trip to book
 */
export default function* removeAccountId() {
  yield call(changeAccountId, '');
  yield put(change(BOOK_TRIP_FORM, ACCOUNT_ID_FIELD_NAME, ''));
}
