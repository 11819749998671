import React from 'react';

/**
 * HOC for proper scrolling of items in list.
 * Focused item should be in visible range
 * Requires to exist next DOM refs in wrapped component
 *
 * list - scrollableListDOM
 * selected item (used to scroll to it when component mounted) - selectedItemDOM
 * focused item (element highlighted when navagating by keyboard arrows) - focusedItemDOM
 */
const makeScrollableNavigation = (Wrapped) => {
  class ScrollableList extends React.Component {
    componentDidMount() {
      const listNode = this.wrappedComp.scrollableListDOM;
      const selectedNode = this.wrappedComp.selectedItemDOM;

      if (selectedNode) {
        listNode.scrollTop = selectedNode.offsetTop;
      }
    }

    componentDidUpdate() {
      const itemNode = this.wrappedComp.focusedItemDOM;
      const listNode = this.wrappedComp.scrollableListDOM;

      if (!itemNode || !listNode) return;

      const offsetTopFromBottom = itemNode.offsetTop + itemNode.offsetHeight;
      const scrollTopFromBottom = listNode.scrollTop + listNode.offsetHeight;

      if (listNode.scrollTop > itemNode.offsetTop) {
        listNode.scrollTop = itemNode.offsetTop;
      }

      if (offsetTopFromBottom > scrollTopFromBottom) {
        listNode.scrollTop = offsetTopFromBottom - listNode.offsetHeight;
      }
    }

    render() {
      return <Wrapped ref={(c) => (this.wrappedComp = c)} {...this.props} />;
    }
  }

  return ScrollableList;
};

export default makeScrollableNavigation;
