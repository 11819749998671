const STRINGS = 'strings';

export default [
  {
    name: 'HP_SEARCH_FORM_TITLE',
    type: 'string',
    default: 'HOMEPAGE.HERO.HEADING',
    description: 'Title above search form',
    category: STRINGS,
  },
  {
    name: 'HP_SEARCH_FORM_SUBTITLE',
    type: 'string',
    default: 'HOMEPAGE.HERO.SUB',
    description: 'Subtitle above search form',
    category: STRINGS,
  },
  {
    name: 'HP_FEATURE_MILES_TEXT',
    type: 'string',
    default: 'FEATURES.MILES',
    description: 'Miles feature text',
    category: STRINGS,
  },
  {
    name: 'HP_LOGIN_HEADER',
    type: 'string',
    default: 'HOMEPAGE.HERO.LOGIN_HEADING',
    description: 'Login block header text',
    category: STRINGS,
  },
  {
    name: 'TRAVEL_AGENT_COMPANY_NAME',
    type: 'string',
    default: '',
    description: 'Name of the company that will be prefixed to "Agent ID"',
    category: STRINGS,
  },
  {
    name: 'CUSTOM_HEADER_TITLE',
    type: 'pairs',
    default: [['en_US', 'Global Mobility | Airport Transfers | Mozio']],
    description: 'Custom Title and header',
    category: STRINGS,
  },
  {
    name: 'CUSTOM_HEADER_DESCRIPTION',
    type: 'pairs',
    default: [
      [
        'en_US',
        "Mozio lets you find and book airport transfers, cruise port transfers, or just a ride anywhere in the world! Book taxis, shuttles, trains, limos, vans and more. Get where you're going, sooner!",
      ],
    ],
    description: 'Custom description header',
    category: STRINGS,
  },
  {
    name: 'CUSTOM_HEADER_KEYWORDS',
    type: 'pairs',
    default: [
      [
        'en_US',
        'taxi service, yellow cab, cab, chauffeur, super shuttle, limo, Airport Transfer, Express Train, Ground Transport, ground transportation, Transfer, Transfers, Holiday Taxis, airport transfers, airport taxi, taxis, taxi, transfer, transfers, taxi transfers, hotel transfers, minibus, coach, cities, airport, limousine, shuttles',
      ],
    ],
    description: 'Custom keywords header',
    category: STRINGS,
  },
];
