import { call, put, select } from 'redux-saga/effects';
import { actionTypes } from 'redux-form';
import { getBookTripFormValue } from 'app/sagas/selectors';
import resolveFormValues from '../watchLoadBookTrip/resolveFormValues';
import { BOOK_TRIP_FORM, DEPARTING_TRIP_FIELD_NAME } from 'app/constants';

export const CHANGE_PICKUP_TIME_SAGA = 'CHANGE_PICKUP_TIME_SAGA';

/**
 * This saga sets pickup time to it's original value
 * @param {String} fieldName  Name of changed field.
 *                            DEPARTING_PICKUP_FIELD_NAME or RETURNING_PICKUP_FIELD_NAME consts
 */
export default function* restoreOriginalPickupTime(fieldName) {
  // called with reloadTrip = true to skip not needed steps
  // we use default alternativeTimeIndex as previous value,
  // because it's also used to set initial calendar/time values
  // and changed each time after trip loaded
  const dapartingTrip = yield select(
    getBookTripFormValue,
    DEPARTING_TRIP_FIELD_NAME
  );
  // we set updatedTrip param to avoid making new api call
  const values = yield call(resolveFormValues, {
    updatedTrip: dapartingTrip,
    reloadTrip: true,
  });
  const pickupTimeBeforeChange = values[fieldName];
  // used manual creation of action because we need to indicate that
  // pickup time was changed not from bookTrip form
  const changeAction = {
    type: actionTypes.CHANGE,
    meta: { form: BOOK_TRIP_FORM, field: fieldName },
    payload: pickupTimeBeforeChange,
    source: CHANGE_PICKUP_TIME_SAGA,
  };

  yield put(changeAction);
}
