import { fork } from 'redux-saga/effects';
import { takeLatest } from 'redux-saga';
import handleUpdateMeetAndGreetTrip from './handleUpdateMeetAndGreetTrip';
import { UPDATE_MEET_AND_GREET_TRIP } from 'app/constants';

export default function* watchUpdateMeetAndGreetTrip() {
  yield fork(
    takeLatest,
    UPDATE_MEET_AND_GREET_TRIP,
    handleUpdateMeetAndGreetTrip
  );
}
