import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'mz-intl';
import { Layout } from 'app/components/base/Layout';
import Icon from 'app/components/base/Icon';
import config from 'config';
import messages from './messages.intl';
import { Label } from './elements';

import style from './style.css';

const WAIT_ICON = {
  place: 'wait-residence',
  airport: 'wait-airport',
  train_station: 'wait-train',
  port: 'wait-port',
};

const WAIT_TEXT = {
  place: messages.WAITING_FROM_LOCATION,
  airport: messages.WAITING_FROM_AIRPORT,
  train_station: messages.WAITING_FROM_STATION,
  port: messages.WAITING_FROM_PORT,
};

const WaitingTimeIcon = ({ waitObj, returnTrip }) => (
  <div
    className={classnames(style.item, {
      [style['item--return']]: returnTrip,
    })}
  >
    <Layout direction="row" align="center center" nowrap>
      <Icon
        className={style.icon}
        family="mozio"
        name={WAIT_ICON[waitObj.fromType] || WAIT_ICON.place}
      />
      <Layout direction="column" nowrap>
        <div className={style.time}>{waitObj.minutes} min</div>
        {!config.SPECIFY_IF_WAITING_TIME_IS_FROM_AIRPORT_IN_BTP && (
          <div className={style.label}>
            <FormattedMessage message={messages.FREE_WAIT} />
          </div>
        )}
      </Layout>
    </Layout>
    {config.SPECIFY_IF_WAITING_TIME_IS_FROM_AIRPORT_IN_BTP && (
      <div className={style.label}>
        <Label>
          <FormattedMessage
            message={WAIT_TEXT[waitObj.fromType] || WAIT_TEXT.place}
          />
        </Label>
      </div>
    )}
  </div>
);

// TODO: move to Flow types
// WaitingTimeIcon.propTypes = {
//   waitObj: PropTypes.object,
//   returnTrip: PropTypes.bool
// };

/**
 * Stateless component to show waiting time
 */
const WaitingTime = ({ waitTime }) => {
  if (!config.WAITING_TIME_SHOW) return null;
  return (
    (waitTime.direct || waitTime.return) && (
      <Layout
        className={style.container}
        direction="row"
        align="center center"
        nowrap
      >
        {waitTime.direct && <WaitingTimeIcon waitObj={waitTime.direct} />}
        {waitTime.return && (
          <WaitingTimeIcon waitObj={waitTime.return} returnTrip />
        )}
      </Layout>
    )
  );
};

// TODO: move to Flow types
// WaitingTime.propTypes = {
//   waitTime: PropTypes.object
// };

export default WaitingTime;
