import React from 'react';
import { Container, Flex } from 'app/components/base/Layout';
import DatePicker from './DatePicker';
import TimePicker from './TimePicker';
import SelectButtons from './SelectButtons';
import classnames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import style from './style.css';

/**
 * DateTime picker for mobile screens. It shows calendar for 6 months
 * and time selector at the bottom of the screen.
 *
 * The interface of the component is for using in `Field`.
 * `onChange` called with `Date` object only when user press some
 * of two select buttons.
 */
class MobileDatetimePicker extends React.Component {
  constructor(props, context) {
    super(props, context);
    const {
      input: { value },
      minDate,
    } = props;
    this.state = { localValue: value || minDate || new Date() };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.input.value !== this.props.input.value) {
      this.handleLocalChange(nextProps.input.value);
    }
  }

  handleLocalChange = (newDate) => {
    this.setState({ localValue: newDate });
  };

  handleSelectValue = (newDate) => {
    const {
      input: { onChange },
      onSelect = _.noop,
    } = this.props;

    const momentDate = moment(newDate);
    onChange(momentDate);
    onSelect();
  };

  render() {
    const { localValue } = this.state;
    const {
      className,
      timeTitle,
      minDate,
      maxDate,
      notime,
      size = 'noshrink',
    } = this.props;

    return (
      <Container
        size={size}
        nowrap
        direction="column"
        className={classnames(className, style.container)}
      >
        <Flex size="grow" className={style.scrollable}>
          <DatePicker
            minDate={minDate}
            maxDate={maxDate}
            value={localValue}
            onChange={this.handleLocalChange}
          />
        </Flex>
        <Flex size="noshrink">
          {!notime && (
            <TimePicker
              minDate={minDate}
              title={timeTitle}
              value={localValue}
              onChange={this.handleLocalChange}
            />
          )}
          <SelectButtons
            value={localValue}
            onChange={this.handleSelectValue}
            notime={notime}
          />
        </Flex>
      </Container>
    );
  }
}

// TODO: move to Flow types
// MobileDatetimePicker.propTypes = {
//   input: PropTypes.object,
//   className: PropTypes.string,
//   minDate: PropTypes.object,
//   maxDate: PropTypes.object,
//   timeTitle: PropTypes.string,
//   size: PropTypes.string,
//   onSelect: PropTypes.func,
//   notime: PropTypes.bool
// };

export default MobileDatetimePicker;
