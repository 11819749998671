import React from 'react';
import { FormattedMessage } from 'translations';
import classnames from 'classnames';
import Link from '../Link';

import style from './style.css';

/**
 * Renders a link or text in footer section
 * @return {Component}
 */
export const FooterLink = ({
  className,
  href,
  dark,
  lowercase = true,
  text,
  message,
  ...props
}) => (
  <div className={classnames(className, style.link)} {...props}>
    <Link target="_blank" href={href} dark={dark} uppercase={!lowercase}>
      {text ? text : <FormattedMessage {...message} />}
    </Link>
  </div>
);

// TODO: move to Flow types
// FooterLink.propTypes = {
//   className: PropTypes.string,
//   href: PropTypes.string,
//   dark: PropTypes.bool,
//   lowercase: PropTypes.bool,
//   text: PropTypes.string,
//   message: PropTypes.object
// };

export default FooterLink;
