import React from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'translations';
import { Layout } from 'app/components/base/Layout';
import builder from './builder';

import style from './style.css';

// Constants
const messages = {
  bookingNumber: { id: 'CONFIRMATION_PAGE.CONFIRMATION_NUMBER' },
};

/**
 * Component to show reservation number
 * @param {String} direction
 * @param {String} confirmationNumber
 */
export const ReservationConfirmationNumber = ({
  direction,
  confirmationNumber,
}) => {
  const modifiers = {
    [style[`block--${direction}`]]: true,
  };

  return (
    <Layout
      className={classnames(style.block, modifiers)}
      direction="row"
      size="noshrink"
      align="start end"
      nowrap
    >
      <Layout direction="column" size="grow" nowrap>
        <div className={style.header}>
          <FormattedMessage {...messages.bookingNumber} />
        </div>
        <div className={style.number}>{confirmationNumber}</div>
      </Layout>
    </Layout>
  );
};

export default builder(ReservationConfirmationNumber);
