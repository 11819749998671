import baseConfig from './base';
import experimentsConfig from './experiments';
let overrideConfig = {};

if (typeof window !== 'undefined' && window.APP_CONFIG) {
  // window.APP_CONFIG comming from WLF and it is set on the page by static server
  overrideConfig = window.APP_CONFIG || {};
} else {
  if (process.env.CONFIG_ENV === 'production') {
    overrideConfig = require('./prod').default;
  } else if (process.env.CONFIG_ENV === 'development') {
    overrideConfig = require('./dev').default;
  } else if (process.env.CONFIG_ENV === 'staging') {
    overrideConfig = require('./staging').default;
  } else if (process.env.CONFIG_ENV === 'testing') {
    overrideConfig = require('./testing').default;
  } else if (process.env.CONFIG_ENV === 'local') {
    overrideConfig = require('./local').default;
  } else {
    throw new Error(`Unknown environment "${process.env.CONFIG_ENV}"`);
  }
}

export default Object.assign(
  {},
  {
    ...baseConfig,
    ...experimentsConfig,
    ...overrideConfig,
  }
);
